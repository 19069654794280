import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseApi } from '@api/base.api';
import { IntegrationApiEndPoints } from '@models/api';
import {
  IntegrationInformation,
  QualiaIntegrationNotificationEmail,
  QualiaIntegrationProduct,
  QualiaIntegrationSettings
} from '@models/integration';

@Injectable({
  providedIn: 'root'
})
export class IntegrationApi extends BaseApi<IntegrationApiEndPoints> {
  constructor(injector: Injector) {
    super(injector);

    this.endPoints = this.addBaseUrl({
      GET_QUALIA_INTEGRATION_INFORMATION: '/settings_get_integration/info_integration_qualia',
      GET_QUALIA_INTEGRATION_SETTINGS: '/settings_get_integration/settings_qualia',
      GET_QUALIA_INTEGRATION_NOTIFICATION_EMAILS: '/settings_get_integration/emails_integration_qualia',
      GET_QUALIA_INTEGRATION_PRODUCTS: '/settings_get_integration/product_qualia',
      GET_RESWARE_INTEGRATION_INFORMATION: '/settings_get_integration/info_integration_resware',
      GET_UNITED_TAX_INTEGRATION_INFORMATION: '/settings_get_integration/info_integration_united_tax',
      PUT_UPDATE_QUALIA_INTEGRATION_INFORMATION: '/settings_integration/info_integration_qualia',
      PUT_UPDATE_QUALIA_INTEGRATION_SETTINGS: '/settings_integration/settings_qualia',
      PUT_UPDATE_QUALIA_INTEGRATION_NOTIFICATION_EMAILS: '/settings_integration/emails_integration_qualia',
      PUT_UPDATE_QUALIA_INTEGRATION_PRODUCTS: '/settings_integration/product_qualia/${id}',
      PUT_UPDATE_RESWARE_INTEGRATION_INFORMATION: '/settings_integration/info_integration_resware',
      PUT_UPDATE_UNITED_TAX_INTEGRATION_INFORMATION: '/settings_integration/info_integration_united_tax'
    });
  }

  public getQualiaIntegrationInformation(): Observable<IntegrationInformation> {
    return this.http.get<IntegrationInformation>(this.endPoints.GET_QUALIA_INTEGRATION_INFORMATION);
  }

  public getQualiaIntegrationSettings(): Observable<QualiaIntegrationSettings> {
    return this.http.get<QualiaIntegrationSettings>(this.endPoints.GET_QUALIA_INTEGRATION_SETTINGS);
  }

  public getQualiaIntegrationNotificationEmails(): Observable<QualiaIntegrationNotificationEmail> {
    return this.http.get<QualiaIntegrationNotificationEmail>(this.endPoints.GET_QUALIA_INTEGRATION_NOTIFICATION_EMAILS);
  }

  public getQualiaIntegrationProducts(): Observable<Array<QualiaIntegrationProduct>> {
    return this.http.get<Array<QualiaIntegrationProduct>>(this.endPoints.GET_QUALIA_INTEGRATION_PRODUCTS);
  }

  public getReswareIntegrationInformation(): Observable<IntegrationInformation> {
    return this.http.get<IntegrationInformation>(this.endPoints.GET_RESWARE_INTEGRATION_INFORMATION);
  }

  public getUnitedTaxIntegrationInformation(): Observable<IntegrationInformation> {
    return this.http.get<IntegrationInformation>(this.endPoints.GET_UNITED_TAX_INTEGRATION_INFORMATION);
  }

  public sendQualiaOrderId(id: string): Observable<void> {
    return this.http.post<void>(
      'https://integration.elitepropertyresearch.com/api/ApiQualia/create_order_eos',
      {type: 'order_request', order_id: id}
    );
  }

  public updateQualiaIntegrationInformation(formValue: IntegrationInformation): Observable<void> {
    return this.http.put<void>(this.endPoints.PUT_UPDATE_QUALIA_INTEGRATION_INFORMATION, formValue);
  }

  public updateQualiaIntegrationSettings(formValue: QualiaIntegrationSettings): Observable<void> {
    return this.http.put<void>(this.endPoints.PUT_UPDATE_QUALIA_INTEGRATION_SETTINGS, formValue);
  }

  public updateQualiaIntegrationNotificationEmails(formValue: QualiaIntegrationNotificationEmail): Observable<void> {
    return this.http.put<void>(this.endPoints.PUT_UPDATE_QUALIA_INTEGRATION_NOTIFICATION_EMAILS, formValue);
  }

  public updateQualiaIntegrationProducts(id: string, products: Array<string>): Observable<void> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_QUALIA_INTEGRATION_PRODUCTS, {id});
    return this.http.put<void>(url, products);
  }

  public updateReswareIntegrationInformation(formValue: IntegrationInformation): Observable<void> {
    return this.http.put<void>(this.endPoints.PUT_UPDATE_RESWARE_INTEGRATION_INFORMATION, formValue);
  }

  public updateUnitedTaxIntegrationInformation(formValue: IntegrationInformation): Observable<void> {
    return this.http.put<void>(this.endPoints.PUT_UPDATE_UNITED_TAX_INTEGRATION_INFORMATION, formValue);
  }
}
