import { Directive, OnInit } from '@angular/core';

import { MatInput } from '@angular/material/input';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements OnInit {
  constructor(private input: MatInput) { }

  ngOnInit(): void {
    setTimeout(() => this.input.focus(), 0);
  }
}
