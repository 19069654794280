import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { SelectItem } from '@models/select';
import { ReactiveSelectRendererParams } from '@models/renderer-parameters';

@Component({
  selector: 'app-reactive-select-renderer',
  templateUrl: './reactive-select-renderer.component.html'
})
export class ReactiveSelectRendererComponent implements AgRendererComponent  {
  public params!: ICellRendererParams;
  public form!: any;
  public control: AbstractControl | null = null;

  public key!: number;
  public items: Array<SelectItem> = [];
  public label: string = 'Select';
  public placeholder: string = 'Placeholder';
  public ariaLabel: string = 'Select';
  public classList: string = 'eos-table-reactive-select-renderer-container ';

  private customParams!: ReactiveSelectRendererParams;
  private value: string | null = null;
  private rowId!: string;

  public agInit(params: ICellRendererParams): void {
    const {context, customParams, node} = <any>params;

    this.params = params;
    this.customParams = customParams;

    const {
      controlIndex,
      items = this.items,
      value = this.value,
      label = this.label,
      placeholder = this.placeholder,
      ariaLabel = this.ariaLabel,
      classList = ''
    } = customParams;

    this.key = controlIndex;
    this.items = items;
    this.value = value;
    this.label = label;
    this.placeholder = placeholder;
    this.ariaLabel = ariaLabel;
    this.classList += classList;
    this.rowId = node.id;

    this.form = context.form.controls[this.rowId];
    this.control = this.form?.at(this.key);

    this.control?.patchValue(this.value);
  }

  public refresh(): boolean {
    return false;
  }
}
