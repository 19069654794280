import { Pipe, PipeTransform } from '@angular/core';

import { AutocompleteValue } from '@models/autocomplete';
import { QualityAssurance } from '@models/user';

@Pipe({
  name: 'qualityAssuranceViewValue'
})
export class QualityAssuranceViewValuePipe implements PipeTransform {
  public transform(qa: AutocompleteValue): string {
    return `${(<QualityAssurance>qa).first_name} ${(<QualityAssurance>qa ).last_name || ''} ${(<QualityAssurance>qa).username || ''}`;
  }
}
