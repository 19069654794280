import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { CellClassParams, RowClassParams, ValueFormatterParams } from 'ag-grid-enterprise';

import { LinkRendererComponent } from '@components/table/cell-renderers/link-renderer/link-renderer.component';
import { ActionRendererComponent } from '@components/table/cell-renderers/action-renderer/action-renderer.component';
import { TimeService } from '@services/time.service';
import { UtilitiesService } from '@services/utilities.service';
import { SelectItem } from '@models/select';
import { EmailTemplate, EmailFormValue, EmailTemplateData, SearchEmailTemplate } from '@models/email';
import { User } from '@models/user';
import { TableConfig } from '@models/table';
import { EntityChangeEvent } from '@models/entity';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  public changed$: Subject<EntityChangeEvent> = new Subject<EntityChangeEvent>();

  constructor(
    private timeService: TimeService,
    private utilitiesService: UtilitiesService
  ) { }

  public getTemplateListTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'ID',
          field: 'id',
          minWidth: 60,
          maxWidth: 60
        },
        {
          headerName: 'Name',
          field: 'name'
        },
        {
          headerName: 'Subject',
          field: 'subject'
        },
        {
          headerName: 'Status',
          field: 'active',
          valueFormatter: this.utilitiesService.activeInactiveFormatter,
          cellClass: (params: CellClassParams) => `eos-table-cell-${params.value === '1' ? 'success' : 'danger'}`
        }
      ]
    };
  }

  public getTemplateFilesListTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'Name',
          field: 'name',
          cellRenderer: LinkRendererComponent,
          cellRendererParams: {
            customParams: {
              takeCurrentUrl: true,
              needEmitEvent: true
            }
          },
          minWidth: 250
        },
        {
          headerName: 'Created By',
          field: 'created_by',
          minWidth: 100
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
          minWidth: 100
        },
        {
          headerName: 'Actions',
          field: 'is_deleted',
          cellRenderer: ActionRendererComponent,
          cellRendererParams: {
            customParams: {
              type: 'file'
            }
          },
          sortable: false,
          minWidth: 100
        }
      ],
      rowClassRules: {'eos-table-row-danger': (params: RowClassParams) => params.data?.is_deleted === '1'}
    };
  }

  public getTemplateItems(templates: Array<EmailTemplate>): Array<SelectItem> {
    return templates.map((template: EmailTemplate) => {
      return {
        id: template.id,
        value: template.id,
        viewValue: template.name,
        disabled: template.active === '0'
      };
    });
  }

  public prepareEmailFormValue(
    formValue: { [key: string]: string | SearchEmailTemplate | User | Array<User> },
    orderId: string | null,
    data: EmailTemplateData | null,
    attachedFileIds: Array<string>
  ): EmailFormValue {
    const {
      template,
      from,
      to,
      cc,
      bcc,
      body_html
    } = formValue;

    return {
      order_id: orderId,
      email_template_id: (<SearchEmailTemplate>template)?.id || null,
      subject: <string>formValue.subject,
      from: [(<User>from).email || (<User>from).username],
      to: [(<User>to).email || (<User>to).username],
      cc: cc ? (<Array<User>>cc).map((user: User) => user.email) : [],
      bcc: bcc ? (<Array<User>>bcc).map((user: User) => user.email) : [],
      body_html: <string>body_html,
      body_text: data?.body_text || <string>body_html, // TODO: Unchangeable value even with user changes
      type: (<SearchEmailTemplate>template)?.name === 'Send Invoice' ? 'invoice' : 'notification', // TODO: Not reliable method
      event_key: data?.event_key || null,
      version: '2.0.0',
      attached_files: attachedFileIds
    };
  }
}
