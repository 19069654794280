import { MatDialogConfig } from '@angular/material/dialog';

export const DIALOG_CONFIG: MatDialogConfig = {
  panelClass: 'eos-overlay-panel',
  autoFocus: false
};

export const DISABLED_DIALOG_CONFIG: MatDialogConfig = {
  ...DIALOG_CONFIG,
  disableClose: true,
  closeOnNavigation: false
};
