import { Component, Input, Output } from '@angular/core';

import { Subject } from 'rxjs';

import { PanelAction } from '@models/panel';
import { ChipListItem } from '@models/chip-list';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html'
})
export class PanelComponent {
  @Input() header: string = 'Title';
  @Input() expanded: boolean = false;
  @Input() disabled: boolean = false;
  @Input() hideToggle: boolean = false;
  @Input() actions: Array<PanelAction> = [];
  @Input() statuses: Array<ChipListItem> = [];
  @Input() containerClass: string = '';

  @Output() actionBtnClicked: Subject<string> = new Subject<string>();

  public onActionBtnClick(event: MouseEvent, actionType: string): void {
    event.preventDefault();
    event.stopPropagation();

    this.actionBtnClicked.next(actionType);
  }
}
