import { Component, Injector, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { RowNode } from 'ag-grid-community';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { OrderAutocompleteComponent } from '@components/autocomplete/order-autocomplete/order-autocomplete.component';
import { TableService } from '@services/table.service';
import { AutocompleteChangeValueEvent } from '@models/autocomplete';
import { ReactiveOrderRendererParams } from '@models/renderer-parameters';

@Component({
  selector: 'app-reactive-order-renderer',
  templateUrl: './reactive-order-renderer.component.html'
})
export class ReactiveOrderRendererComponent implements AgRendererComponent {
  @ViewChild(OrderAutocompleteComponent) autocompleteComp!: OrderAutocompleteComponent;

  public params!: ICellRendererParams;
  public form!: any;
  public control: AbstractControl | null = null;

  public key!: number;
  public isRequired: boolean = false;
  public needValidation: boolean = false;

  protected service: TableService;

  private customParams!: ReactiveOrderRendererParams;
  private value!: string | null;
  private rowId!: string;

  constructor(injector: Injector) {
    this.service = injector.get(TableService);
  }

  public agInit(params: ICellRendererParams): void {
    const {context, customParams, node} = <any>params;

    this.params = params;
    this.customParams = customParams;

    const {
      controlIndex,
      value = this.value,
      isRequired = this.isRequired,
      needValidation = this.needValidation
    } = customParams;

    this.key = controlIndex;
    this.isRequired = isRequired;
    this.needValidation = needValidation;
    this.value = value;
    this.rowId = node.id;

    this.form = context.form.controls[this.rowId];
    this.control = this.form?.at(this.key);

    this.control?.patchValue(this.control ? this.control.value : this.value);
  }

  public refresh(): boolean {
    return false;
  }

  public onValueChanged(event: AutocompleteChangeValueEvent): void {
    const node: RowNode | undefined = this.params?.node,
          isAddEvent: boolean = event.type === 'add';

    this.service.rendererAction$.next({
      type: `order-${isAddEvent ? 'select' : 'unselect'}-batch`,
      item: isAddEvent ? event.item : null,
      node,
      rowData: node?.data
    });
  }

  public setFocus(): void {
    this.autocompleteComp.setFocus();
  }
}
