import { Component, Injector } from '@angular/core';

import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { TableService } from '@services/table.service';
import { Researcher } from '@models/user';
import { AutocompleteChangeValueEvent } from '@models/autocomplete';

@Component({
  selector: 'app-quality-assurance-renderer',
  templateUrl: './assignee-renderer.component.html'
})
export class AssigneeRendererComponent implements AgRendererComponent {
  public params!: ICellRendererParams;
  public selectedResearchers: Array<Researcher> = [];
  public isDisabled: boolean = false;

  protected service: TableService;

  constructor(injector: Injector) {
    this.service = injector.get(TableService);
  }

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.selectedResearchers = [...this.params.value];
    this.isDisabled = params.data.active === '0';
  }

  public refresh(): boolean {
    return false;
  }

  public onAssigneeChanged(event: AutocompleteChangeValueEvent): void {
    this.service.rendererAction$.next({
      type: `assignee-${event.type}`,
      item: this.selectedResearchers,
      rowData: this.params?.node?.data,
      node: this.params?.node
    });
  }
}
