import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { CellClassParams } from 'ag-grid-enterprise';

import {
  SuccessNotificationComponent
} from '@components/notifications/success-notification/success-notification.component';
import { ErrorNotificationComponent } from '@components/notifications/error-notification/error-notification.component';
import { AuthService } from '@services/auth.service';
import { UtilitiesService } from '@services/utilities.service';
import { TABLE_TYPES } from '@configs/table';
import { ERROR_NOTIFICATION_CONFIG, SUCCESS_NOTIFICATION_CONFIG } from '@configs/notification';
import { TableConfig } from '@models/table';
import { NotificationEventFormValue } from '@models/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private service: MatSnackBar,
    private authService: AuthService,
    private utilitiesService: UtilitiesService
  ) { }

  public getEventListTableConfig(): TableConfig {
    return {
      colDefs: this.authService.filterTableColumns(TABLE_TYPES.NOTIFICATION, [
        {
          headerName: 'ID',
          field: 'id',
          minWidth: 60,
          maxWidth: 60
        },
        {
          headerName: 'Name',
          field: 'name'
        },
        {
          headerName: 'Key',
          field: 'eventkey'
        },
        {
          headerName: 'Email Template',
          field: 'email_name',
          valueFormatter: this.utilitiesService.textFormatter,
        },
        {
          headerName: 'Status',
          field: 'active',
          valueFormatter: this.utilitiesService.activeInactiveFormatter,
          cellClass: (params: CellClassParams) => `eos-table-cell-${params.value === '1' ? 'success' : 'danger'}`
        }
      ])
    };
  }

  public prepareEventFormValue(value: NotificationEventFormValue): NotificationEventFormValue {
    return {
      ...value,
      settings: [{
        id: 0,
        cc: value.cc,
        bcc: value.bcc
      }]
    };
  }

  public openSuccessNotification(message: string, config?: MatSnackBarConfig): void {
    config ??= {
      ...SUCCESS_NOTIFICATION_CONFIG,
      data: {message}
    };

    this.service.openFromComponent(SuccessNotificationComponent, config);
  }

  public openErrorNotification(message: string, config?: MatSnackBarConfig): void {
    config ??= {
      ...ERROR_NOTIFICATION_CONFIG,
      data: {message}
    };

    this.service.openFromComponent(ErrorNotificationComponent, config);
  }
}
