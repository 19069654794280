<div class="eos-file-list-container">
  <app-search-box [config]="searchBoxConfig" (searchBoxChange)="onSearchBoxChanged()">
    <fieldset *ngIf="filterSectionConfig" class="row" [formGroup]="searchBoxComp.form" slot="filter-section">
      <div class="col-md-12">
        <app-radio-group
          label="Types:"
          class="horizontal"
          controlName="fileType"
          [items]="filterSectionConfig.fileTypeItems">
        </app-radio-group>
      </div>
    </fieldset>
  </app-search-box>

  <app-drag-drop [hidden]="true" #dragAndDrop (fileDropped)="onFileDropped($event)"></app-drag-drop>

  <app-table
    [config]="tableConfig"
    [serverSideDatasource]="serverSideDatasource"
    (tableSelectionChanged)="onSelectionChanged($event)"
    (tableChanged)="onFileChanged($event)">
  </app-table>
</div>
