import { Component } from '@angular/core';

import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { Flag } from '@models/flag';

@Component({
  selector: 'app-flag-renderer',
  templateUrl: './flag-renderer.component.html'
})
export class FlagRendererComponent implements AgRendererComponent {
  public params!: ICellRendererParams;
  public flag!: Flag;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.flag = params.data;
  }

  public refresh(): boolean {
    return false;
  }
}
