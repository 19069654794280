<div class="eos-table-reactive-input-renderer-container">
  <div *ngIf="form" [formGroup]="form">
    <mat-form-field>
      <mat-label>{{label}}</mat-label>
      <input
        [id]="key"
        [type]="type"
        [placeholder]="placeholder"
        [required]="isRequired"
        [attr.aria-label]="ariaLabel"
        [formControlName]="key"
        [mask]="mask"
        [allowNegativeNumbers]="allowNegativeNumbers"
        matInput
        #inputEl
        (keydown)="onKeydown($event)">
      <span *ngIf="prefix" matPrefix>{{prefix}}&nbsp;</span>
      <mat-error *ngIf="control.invalid && (control.dirty || control.touched)">Field is required</mat-error>
    </mat-form-field>
  </div>
</div>
