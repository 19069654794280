import { Component, forwardRef, Injector, Renderer2 } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { forkJoin, of, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { UserAutocompleteComponent } from '@components/autocomplete/user-autocomplete/user-autocomplete.component';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { EmailApi } from '@api/email.api';
import { User } from '@models/user';
import { QueryParams } from '@models/api';
import { AutocompleteUserInputValueGetter, AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';

@Component({
  selector: 'app-accountant-autocomplete',
  templateUrl: './accountant-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => AccountantAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AccountantAutocompleteComponent,
      multi: true
    }
  ]
})
export class AccountantAutocompleteComponent extends UserAutocompleteComponent {
  constructor(injector: Injector, private renderer: Renderer2, private emailApi: EmailApi) {
    super(injector);
  }

  public override onSelected(event: MatAutocompleteSelectedEvent): void {
    super.onSelected(event);

    this.setClass(event.option.value);
  }

  public override writeValue(value: string | AutocompleteValue | null): void {
    super.writeValue(value);

    this.setClass(<User | null>value);
  }

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    const setAllItems: (res: Array<any>) => void = (res: Array<any>): void => {
      usersByCompanyId = res[1];
      const allUsers: Array<User> = this.service
        .createPayableUsers(res[0])
        .concat(this.service.createAccountants(res[2]), usersByCompanyId);

      this.allItems = allUsers.filter((user: User): boolean => user.displayName.toLowerCase().includes(query));
    };
    let queryParams: QueryParams = {},
        usersByCompanyId: Array<User> = [];

    if (this.includeEmployee) {
      queryParams.includeEmployee = this.includeEmployee;
    }

    return forkJoin(
      this.emailApi.getEmails(this.companyId),
      this.api.searchForUsersByCompanyId(this.companyId, {...queryParams, keyword: query}),
      this.api.searchForUsersByRole('accountant')
    ).pipe(
      tap(setAllItems),
      map((): Array<AutocompleteValue> =>
        this.composeAutocompleteValuesWithCustomUserInput(query, null, usersByCompanyId)
      ),
      catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
    );
  }

  protected override composeAutocompleteValuesWithCustomUserInput(
    query: string,
    entityGetter?: AutocompleteUserInputValueGetter,
    values?: Array<AutocompleteValue>
  ): Array<AutocompleteValue> {
    let res: Array<AutocompleteValue> = this.filterBySelected();

    this.keywordFromInput = !values.length && query !== '';

    if (this.keywordFromInput) {
      res = this.addKeywordFromInput
        ? [
          this.createUserFromInputValue(this.inputControl.value),
          ...res
        ]
        : EMPTY_AUTOCOMPLETE_VALUE;
    }

    return res;
  }

  private setClass(value: User | null): void {
    if (value && (value.isAccountant || value.isPayable)) {
      this.renderer.addClass(this.inputEl.nativeElement, 'fw-medium');
    } else {
      this.renderer.removeClass(this.inputEl.nativeElement, 'fw-medium');
    }
  }
}
