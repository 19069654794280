<div [ngClass]="classList" class="eos-checkbox-group-container" [formGroupName]="controlName">
  <p class="eos-checkbox-group-label" *ngIf="label">{{label}}</p>
  <p *ngFor="let item of items">
    <mat-checkbox
      [aria-describedby]="item.ariaDescribedby"
      [aria-label]="item.ariaLabel"
      [aria-labelledby]="item.ariaLabelledby"
      [formControlName]="item.controlName">
      {{item.viewValue}}
    </mat-checkbox>
  </p>
</div>
