import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

import { MatSelectChange } from '@angular/material/select';

import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { GridApi } from 'ag-grid-enterprise';

import { UtilitiesService } from '@services/utilities.service';
import { SelectItem } from '@models/select';
import { PaginationParams } from '@models/table';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements IStatusPanelAngularComp, OnInit, OnDestroy {
  public pageSizeItems: Array<SelectItem> = [];
  public pageSizeItem!: SelectItem;

  public firstRow: string = '0';
  public lastRow: string = '?';
  public rowCount: string = 'more';
  public pageCount: string = 'more';

  public isPreviousBtnDisabled: boolean = true;
  public isNextBtnDisabled: boolean = true;

  public currentPageControl: UntypedFormControl = this.formBuilder.control(1);

  private params!: PaginationParams;
  private gridApi!: GridApi<unknown>;

  private currentPage: number = 0;
  private totalPages: number = 0;

  constructor(private formBuilder: UntypedFormBuilder, private utilitiesService: UtilitiesService) { }

  ngOnInit(): void {
    this.params.api.addEventListener('paginationChanged', this.onPaginationChanged);
  }

  ngOnDestroy(): void {
    this.params.api.removeEventListener('paginationChanged', this.onPaginationChanged);
  }

  public agInit(params: PaginationParams): void {
    this.params = params;
    this.gridApi = params.api;

    this.setPageSize();
  }

  public onPageSizeChanged(event: MatSelectChange): void {
    const pageSize: number = event.value;

    this.params.api.paginationSetPageSize(pageSize);
    this.params.api.setCacheBlockSize(pageSize);

    this.params.onPageSizeChanged(event);
  }

  public onCurrentPageChanged(): void {
    this.gridApi.paginationGoToPage(this.currentPageControl.value - 1);
  }

  public onCurrentPageKeyup(event: KeyboardEvent): void {
    if (!this.utilitiesService.isEnterKey(event)) { return; }
    this.onCurrentPageChanged();
  }

  public onGoToFirstPageBtnClick(): void {
    this.gridApi.paginationGoToFirstPage();
  }

  public onGoToPreviousPageBtnClick(): void {
    this.gridApi.paginationGoToPreviousPage();
  }

  public onGoToNextPageBtnClick(): void {
    this.gridApi.paginationGoToNextPage();
  }

  public onGoToLastPageBtnClick(): void {
    this.gridApi.paginationGoToLastPage();
  }

  private onPaginationChanged: () => void = function (): void {
    let pageSize: number;
    let rowCount: number;
    let lastRow: number;

    if (this.params) {
      this.currentPage = this.gridApi.paginationGetCurrentPage();
      this.totalPages = this.gridApi.paginationGetTotalPages();
      pageSize = this.gridApi.paginationGetPageSize();
      rowCount = this.gridApi.paginationGetRowCount();
      lastRow = (this.currentPage + 1) * pageSize;

      this.firstRow = (rowCount ? (this.currentPage * pageSize + 1) : 0).toString();
      this.lastRow = (lastRow > rowCount ? rowCount : lastRow).toString();
      this.rowCount = rowCount.toString();
      this.pageCount = this.totalPages.toString();

      this.currentPageControl.setValue(rowCount ? (this.currentPage + 1) : 0, {emitEvent: false});

      this.setBtnStatuses();
    }
  }.bind(this);

  private setBtnStatuses(): void {
    this.isPreviousBtnDisabled = this.currentPage === 0;
    this.isNextBtnDisabled = this.currentPage + 1 === this.totalPages;
  }

  private setPageSize(): void {
    this.pageSizeItems = this.params.pageSizeItems;
    this.pageSizeItem = this.params.pageSizeItem;
  }
}
