import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent {
  @Input() public header: string = 'Dialog';
  @Input() public cancelBtnLabel: string = 'Cancel';
}
