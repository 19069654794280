import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';

import { AuthService } from '@services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanLoad {
  constructor(private router: Router, private service: AuthService) { }

  public canLoad(): boolean {
    return this.checkAuth();
  }

  private checkAuth(): boolean {
    if (this.service.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['/login']);

      return false;
    }
  }
}
