import { Injectable } from '@angular/core';

import { MediaMatcher } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  public mobileQuery!: MediaQueryList;

  private onMobileQuery!: () => void;

  constructor(private media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 768px)');
  }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this.onMobileQuery);
  }

  public setHandler(handler: () => void): void {
    this.onMobileQuery = handler;
    this.mobileQuery.addListener(this.onMobileQuery);
  }
}
