import { Pipe, PipeTransform } from '@angular/core';

import { AutocompleteValue } from '@models/autocomplete';
import { Order } from '@models/order';

@Pipe({
  name: 'orderViewValue'
})
export class OrderViewValuePipe implements PipeTransform {
  public transform(order: AutocompleteValue): string {
    const {id, epr_id} = <Order>order;
    return `${epr_id} ${id ? `(${id})` : ''}`;
  }
}
