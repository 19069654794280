import {
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MatSelect, MatSelectChange } from '@angular/material/select';

import { FormValidationService } from '@services/form-validation.service';
import { SelectItem } from '@models/select';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html'
})
export class SelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label: string = 'Choose an option';
  @Input('class') classList!: string;
  @Input() panelClassList!: string;
  @Input() isRequired: boolean = false;
  @Input('aria-label') ariaLabel: string = 'Select';
  @Input() items: Array<SelectItem> = [];
  @Input() selected: SelectItem | null = null;
  @Input() needValidation: boolean = false;
  @Input() hint: string | null = null;

  @Output() openedChanged: Subject<boolean> = new Subject<boolean>();
  @Output() selectionChanged: Subject<MatSelectChange> = new Subject<MatSelectChange>();

  @ViewChild(MatSelect, {static: true}) private selectComp!: MatSelect;

  public selectControl: UntypedFormControl;

  public isValid: boolean = true;
  public errorMsg: string = '';

  protected formBuilder: UntypedFormBuilder;
  protected validationService: FormValidationService;
  protected readonly destroy$: Subject<void> = new Subject();

  private injector: Injector;

  constructor(injector: Injector) {
    this.injector = injector;
    this.formBuilder = this.injector.get(UntypedFormBuilder);
    this.validationService = this.injector.get(FormValidationService);
    this.selectControl = this.formBuilder.control(null);
  }

  ngOnInit(): void {
    this.setValidators();

    this.selectControl.statusChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((): boolean => this.needValidation)
      )
      .subscribe((): void => this.validate());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedChange: SimpleChange = changes.selected;
    let currentValue: SelectItem;

    if (selectedChange) {
      currentValue = selectedChange.currentValue;
      if (!currentValue) { return; }

      this.selectControl.setValue(currentValue.value);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onOpenedChanged(isOpened: boolean): void {
    this.openedChanged.next(isOpened);
  }

  public onClose(): void {
    if (this.needValidation) {
      this.validate();
    }
  }

  public onSelectionChanged(event: MatSelectChange): void {
    this.selected = event.value;
    this.selectionChanged.next(event);
  }

  public open(): void {
    this.selectComp.open();
  }

  protected setValidators(): void {
    if (this.isRequired) {
      this.selectControl.addValidators(Validators.required);
    }
  }

  protected validate(): void {
    this.isValid = !(this.needValidation
      && this.selectControl.invalid
      && (this.selectControl.dirty || this.selectControl.touched));
  }
}
