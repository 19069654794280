<div class="eos-table-flag-chip-list-renderer-container">
  <mat-chip-list aria-label="Flag selection">
    <mat-chip *ngFor="let flag of flags">

      <mat-icon
        *ngIf="flag['label'] !== 'No Results Found'"
        ngClass="{{flag | flagClass}}"
        [matTooltip]="flag | flagTooltip">
        {{flag | flagIcon}}
      </mat-icon>

      <button
        *ngIf="flag['flag_can_edit'] !== null"
        type="button"
        aria-label="Remove flag"
        matChipRemove
        (click)="onRemoveBtnClick(flag)">
        <mat-icon>cancel</mat-icon>
      </button>

    </mat-chip>
  </mat-chip-list>
</div>
