import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

import { iif, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER } from '@angular/cdk/keycodes';

import { ChipsAutocompleteComponent } from '@components/chips-autocomplete/chips-autocomplete.component';
import { UserApi } from '@api/user.api';
import { UserService } from '@services/user.service';
import { userEmailValidator } from '@validators/user-email.validator';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { User } from '@models/user';
import { AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';

@Component({
  selector: 'app-user-chips-autocomplete',
  templateUrl: './user-chips-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => UserChipsAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: UserChipsAutocompleteComponent,
      multi: true
    }
  ]
})
export class UserChipsAutocompleteComponent extends ChipsAutocompleteComponent {
  @Input() public override label: string = 'Users';
  @Input() public override placeholder: string = 'Search for a user...';
  @Input() public companyId: string | null = null;
  @Input() public addKeywordFromInput: boolean = false;

  public readonly separatorKeysCodes: Array<number> = [ENTER];

  protected api: UserApi;
  protected service: UserService;

  constructor(injector: Injector) {
    super(injector);

    this.api = injector.get(UserApi);
    this.service = injector.get(UserService);
  }

  override ngOnInit(): void {
    super.ngOnInit(false);

    this.items$ = this.inputControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      filter((query: AutocompleteValue): boolean => this.isString(query)),
      switchMap((query: string | AutocompleteValue): Observable<Array<AutocompleteValue>> =>
        this.onInputValueChange(query)
      )
    );
  }

  public onAddKeywordFromInput(event: MatChipInputEvent): void {
    if (!this.addKeywordFromInput) { return; }
    const inputControlValue: string = (event.value || '').trim(),
          emailErrors: ValidationErrors | null = this.addKeywordFromInput
            ? userEmailValidator(this.inputControl)
            : null;
    let value: AutocompleteValue;

    if (emailErrors) { return; }

    value = {
      ...this.service.createEmptyUser(),
      email: inputControlValue,
      displayName: inputControlValue
    };

    this.selectedItems.push(value);

    this.onValueChange();
    this.nextValue({type: 'add', item: value});

    event.chipInput!.clear();
  }

  protected onInputValueChange(query: string | AutocompleteValue): Observable<Array<AutocompleteValue>> {
    return iif(
      (): boolean => !!this.companyId,
      this.searchByQuery(<string>query),
      of(EMPTY_AUTOCOMPLETE_VALUE)
    );
  }

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return this.api.searchForUsersByCompanyId(this.companyId, {keyword: query}).pipe(
      tap((users: Array<User>): Array<User> => this.allItems = users),
      map((): Array<AutocompleteValue> => this.composeAutocompleteValues()),
      catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
    );
  }
}
