import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseApi } from '@api/base.api';
import { FlagApiEndPoints, QueryParams } from '@models/api';
import { Flag, FlagAssignmentFormValue, Flags } from '@models/flag';
import { TableData } from '@models/table';
import { Payload } from '@models/response';

@Injectable({
  providedIn: 'root'
})
export class FlagApi extends BaseApi<FlagApiEndPoints> {
  constructor(injector: Injector) {
    super(injector);

    this.endPoints = this.addBaseUrl({
      GET_FLAGS: '/user/order-flag/fetch',
      GET_SYSTEM_FLAGS: '/user/order-flag/system-flags',
      GET_ORDER_FLAGS: '/user/order-flag/user-set-flags',
      POST_UPDATE_SYSTEM_FLAG_ASSIGNMENT: '/user/order-flag/edit-assignment',
      POST_CREATE_FLAG_CONFIGURATION: '/user/order-flag/edit/${flagId}'
    });
  }

  public getFlags(params?: QueryParams): Observable<Flags> {
    return this.http.get<Flags>(this.endPoints.GET_FLAGS, {params: this.setQueryParameters(params)});
  }

  public getSystemFlags(): Observable<TableData<Flag>> {
    return this.http.get<TableData<Flag>>(this.endPoints.GET_SYSTEM_FLAGS);
  }

  public getOrderFlags(): Observable<TableData<Flag>> {
    return this.http.get<TableData<Flag>>(this.endPoints.GET_ORDER_FLAGS);
  }

  public updateSystemFlagAssignment(formValue: FlagAssignmentFormValue): Observable<Array<Flag>> {
    return this.http
      .post<Payload<Array<Flag>>>(this.endPoints.POST_UPDATE_SYSTEM_FLAG_ASSIGNMENT, formValue)
      .pipe(
        map((res: Payload<Array<Flag>>) => res.items)
      );
  }

  public createFlagConfiguration(flagId: string, label: string): Observable<Flag> {
    const url: string = this.substituteParameters(this.endPoints.POST_CREATE_FLAG_CONFIGURATION, {flagId});
    return this.http
      .post<Payload<Flag>>(url, {label})
      .pipe(
        map((res: Payload<Flag>) => res.flag)
      );
  }
}
