<app-dialog header="Add Note">
  <div class="eos-control-width-100" slot="content">
    <mat-form-field>
      <mat-label>Please enter a note</mat-label>
      <textarea
        placeholder="Please enter a note"
        required
        aria-label="Note"
        [formControl]="noteControl"
        appAutofocus
        matInput>
      </textarea>
      <mat-error *ngIf="noteControl.invalid && (noteControl.dirty || noteControl.touched)">{{errorMsg}}</mat-error>
    </mat-form-field>
  </div>
  <button
    type="button"
    class="eos-btn-primary"
    [disabled]="!noteControl.valid"
    mat-flat-button
    slot="action"
    [mat-dialog-close]="noteControl.value">
    Add
  </button>
</app-dialog>

