import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ChipsAutocompleteComponent } from '@components/chips-autocomplete/chips-autocomplete.component';
import { UserApi } from '@api/user.api';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { QualityAssurance } from '@models/user';
import { AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';

@Component({
  selector: 'app-quality-assurance-chips-autocomplete',
  templateUrl: './quality-assurance-chips-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => QualityAssuranceChipsAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: QualityAssuranceChipsAutocompleteComponent,
      multi: true
    }
  ]
})
export class QualityAssuranceChipsAutocompleteComponent extends ChipsAutocompleteComponent {
  @Input() public override label: string = 'Quality Assurances';
  @Input() public override placeholder: string = 'Search for a quality assurance...';

  protected override readonly switchMap: boolean = true;

  constructor(injector: Injector, private api: UserApi) {
    super(injector);
  }

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return this.api.searchForUsersByRole('qc', {page: 1, limit: 10, active: true, keyword: query}).pipe(
        tap((qa: Array<QualityAssurance>): Array<QualityAssurance> => this.allItems = qa),
        map((): Array<AutocompleteValue> => this.composeAutocompleteValues()),
        catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
      );
  }
}
