import { MenuItem } from '@models/menu-item';

export enum TABLE_TYPES {
  ORDER = 'order',
  NOTIFICATION = 'notification',
  FILE = 'file',
  NOTE = 'note',
  TASK = 'task',
  ORDER_PAYMENT = 'orderPayment',
  PRICING = 'pricing'
}

export const ORDER_TABLE_ACTIONS: MenuItem = {
  label: 'Action',
  value: 'action',
  icon: 'settings',
  children: [
    {
      label: 'Edit',
      value: 'edit',
      icon: 'edit',
      action: 'edit'
    },
    {
      label: 'Cancel',
      value: 'cancel',
      icon: 'block',
      action: 'cancel'
    },
    {
      label: 'Files',
      value: 'files',
      icon: 'text_snippet',
      action: 'file-list'
    },
    {
      label: 'Notes',
      value: 'notes',
      icon: 'comment',
      action: 'note-list'
    },
    {
      label: 'Tasks',
      value: 'tasks',
      icon: 'task',
      action: 'task-list'
    }
  ]
};

export const ORDER_TASK_TABLE_ACTIONS: { [key: string]: MenuItem } = {
  new: {
    label: 'Action',
    value: 'action',
    icon: 'settings',
    children: [
      {
        label: 'Note',
        value: 'note',
        icon: 'comment',
        action: 'note-task'
      },
      {
        label: 'Ordered',
        value: 'ordered',
        icon: 'calendar_month',
        action: 'order-task'
      },
      {
        label: 'Skipped',
        value: 'skipped',
        icon: 'close',
        action: 'skip-task'
      }
    ]
  },
  ordered: {
    label: 'Action',
    value: 'action',
    icon: 'settings',
    children: [
      {
        label: 'Note',
        value: 'note',
        icon: 'comment',
        action: 'note-task'
      },
      {
        label: 'Received',
        value: 'received',
        icon: 'check',
        action: 'receive-task'
      },
      {
        label: 'Skipped',
        value: 'skipped',
        icon: 'close',
        action: 'skip-task'
      }
    ]
  },
  received: {
    label: 'Action',
    value: 'action',
    icon: 'settings',
    children: [
      {
        label: 'Note',
        value: 'note',
        icon: 'comment',
        action: 'note-task'
      },
      {
        label: 'Skipped',
        value: 'skipped',
        icon: 'close',
        action: 'skip-task'
      }
    ]
  },
  skipped: {
    label: 'Action',
    value: 'action',
    icon: 'settings',
    children: [{
      label: 'Note',
      value: 'note',
      icon: 'comment',
      action: 'note-task'
    }]
  }
};

export const ORDER_NOTE_TABLE_ACTIONS: MenuItem = {
  label: 'Action',
  value: 'action',
  icon: 'settings',
  children: [
    {
      label: 'Edit',
      value: 'edit',
      icon: 'edit',
      action: 'edit-note'
    },
    {
      label: 'Mark as Deleted',
      value: 'markAsDeleted',
      icon: 'close',
      action: 'delete-note'
    },
    {
      label: 'Restore',
      value: 'restore',
      icon: 'restore',
      action: 'restore-note'
    }
  ]
};

export const ORDER_FILE_TABLE_ACTIONS: MenuItem = {
  label: 'Action',
  value: 'action',
  icon: 'settings',
  children: [
    {
      label: 'Download',
      value: 'download',
      icon: 'file_download',
      action: 'download-file'
    },
    {
      label: 'Mark as Deleted',
      value: 'markAsDeleted',
      icon: 'close',
      action: 'delete-file'
    },
    {
      label: 'Restore',
      value: 'restore',
      icon: 'restore',
      action: 'restore-file'
    }
  ]
};

export const ORDER_PAYMENT_TABLE_ACTIONS: MenuItem = {
  label: 'Action',
  value: 'action',
  icon: 'settings',
  children: [
    {
      label: 'Refund',
      value: 'refund',
      icon: 'reply',
      action: 'refund-payment'
    },
    {
      label: 'Transfer',
      value: 'transfer',
      icon: 'swap_horiz',
      action: 'transfer-payment'
    },
    {
      label: 'Return Transfer',
      value: 'returnTransfer',
      icon: 'swap_horizontal_circle',
      action: 'return-transfer-payment'
    },
    {
      label: 'Note',
      value: 'note',
      icon: 'comment',
      action: 'note-payment'
    }
  ]
};

export const COMPANY_PRICING_TABLE_ACTIONS: MenuItem = {
  label: 'Action',
  value: 'action',
  icon: 'settings',
  children: [
    {
      label: 'Edit',
      value: 'edit',
      icon: 'edit',
      action: 'edit-pricing'
    },
    {
      label: 'Mark as Deleted',
      value: 'markAsDeleted',
      icon: 'close',
      action: 'delete-pricing'
    }
  ]
};

export const FLAG_EVENT_TABLE_ACTIONS: MenuItem = {
  label: 'Action',
  value: 'action',
  icon: 'settings',
  children: [
    {
      label: 'Edit',
      value: 'edit',
      icon: 'edit',
      action: 'edit-system-flag-assignment'
    },
    {
      label: 'Delete',
      value: 'delete',
      icon: 'close',
      action: 'delete-system-flag-assignment'
    }
  ]
};

export const BATCH_TABLE_ACTIONS: MenuItem = {
  label: 'Action',
  value: 'action',
  icon: 'settings',
  children: [
    {
      label: 'View',
      value: 'view',
      icon: 'preview',
      action: 'view-batch'
    },
    {
      label: 'CSV',
      value: 'csv',
      icon: 'filter',
      action: 'csv-batch'
    },
    {
      label: 'PDF',
      value: 'pdf',
      icon: 'picture_as_pdf',
      action: 'pdf-batch'
    },
    {
      label: 'Deposit Slip',
      value: 'depositSlip',
      icon: 'account_balance',
      action: 'deposit-slip-batch'
    },
    {
      label: 'Delete',
      value: 'delete',
      icon: 'close',
      action: 'delete-batch'
    },
    {
      label: 'Delete With Reset SF',
      value: 'deleteWithResetSF',
      icon: 'close',
      action: 'delete-batch-with-reset-search-fee'
    }
  ]
};

export const DEFAULT_TABLE_ACTIONS: MenuItem = {
  label: 'Action',
  value: 'action',
  icon: 'settings',
  children: []
};

export enum BATCH_TABLE_COLUMN_IDS {
  ORDER = 'order',
  CHECK = 'check',
  AMOUNT = 'amount',
  DISCOUNT = 'discount',
  NOTES = 'notes'
}
