import { Injectable } from '@angular/core';

import { Subject, ReplaySubject } from 'rxjs';

import {
  CellClassParams, ColDef,
  EditableCallbackParams,
  ICellRendererParams,
  IServerSideGetRowsParams,
  RowClassParams,
  RowHeightParams,
  ValueFormatterParams
} from 'ag-grid-enterprise';

import moment from 'moment';

import { StatusEditorComponent } from '../../order/order-list/cell-editors/status-editor/status-editor.component';
import {
  ResearcherEditorComponent
} from '../../order/order-list/cell-editors/researcher-editor/researcher-editor.component';
import {
  FlagChipsAutocompleteEditorComponent
} from '../../order/order-list/cell-editors/flag-chips-autocomplete-editor/flag-chips-autocomplete-editor.component';
import {
  FlagChipListRendererComponent
} from '../../order/order-list/cell-renderers/flag-chip-list-renderer/flag-chip-list-renderer.component';
import { LinkRendererComponent } from '@components/table/cell-renderers/link-renderer/link-renderer.component';
import { TotalPinnedRowRendererComponent } from '@components/table/cell-renderers/total-pinned-row-renderer/total-pinned-row-renderer.component';
import { ActionRendererComponent } from '@components/table/cell-renderers/action-renderer/action-renderer.component';
import { SelectRendererComponent } from '@components/table/cell-renderers/select-renderer/select-renderer.component';
import { NoteDetailRendererComponent } from '@components/table/cell-renderers/note-detail-renderer/note-detail-renderer.component';
import { TaskService } from '@services/task.service';
import { TimeService } from '@services/time.service';
import { AuthService } from '@services/auth.service';
import { ListService } from '@services/list.service';
import { UtilitiesService } from '@services/utilities.service';
import {
  ORDER_ISSUE_STATUS_CLASSES,
  ORDER_PAYMENT_STATUS_CLASSES,
  ORDER_STATUS_CLASSES,
  ORDER_STATUSES
} from '@configs/order';
import { ORDER_ISSUE_STATUSES } from '@configs/autocomplete';
import {
  ORDER_NOTIFICATION_RECIPIENTS,
  ORDER_NOTIFICATION_STATES,
  ORDER_NOTIFICATION_STATUSES,
  ORDER_PAYMENT_STATUS_CONTROL_NAME_VALUE_MAPPER,
  ORDER_PAYMENT_STATUSES
} from '@configs/checkbox';
import { PAGINATION_PAGE_SIZE, PAYMENT_VISIBILITY_TYPES } from '@configs/select';
import { YES_NO_RADIO } from '@configs/radio';
import { TABLE_TYPES } from '@configs/table';
import { FILTER_TYPES } from '@configs/search-box';
import { TableConfig } from '@models/table';
import { SelectItem } from '@models/select';
import { FilterSectionConfig, SearchBoxConfig, SearchBoxValue } from '@models/search-box';
import { QueryParams } from '@models/api';
import { Product } from '@models/product';
import {
  QualityAssurance,
  User,
  RawMarketersFormValue,
  MarketerFormValue,
  RawMarketerFormValue
} from '@models/user';
import { Flag } from '@models/flag';
import { ArrayGroupControlConfig } from '@models/array-group';
import { Order, OrderFormValue, RawOrderFormValue } from '@models/order';
import { TaskFormValue, TaskListItem } from '@models/task';
import { Issue, IssueFormValue } from '@models/issue';
import { ChipListItem } from '@models/chip-list';
import { AccountingFormValue, CostType, HardCostFormValue } from '@models/accounting';
import { EntityChangeEvent } from '@models/entity';
import { SearchEmailTemplate } from '@models/email';
import { NotificationAuthor } from '@models/notification';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  public changed$: Subject<EntityChangeEvent> = new Subject<EntityChangeEvent>();
  public productChanged$: Subject<string> = new Subject<string>();
  public ownerBuyerControlsValidityChanged$: Subject<void> = new Subject<void>();
  public estoppelChanged$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public totalCostChanged$: Subject<number> = new Subject<number>();
  public invoiceCreated$: Subject<void> = new Subject<void>();

  constructor(
    private taskService: TaskService,
    private timeService: TimeService,
    private authService: AuthService,
    private listService: ListService,
    private utilitiesService: UtilitiesService
  ) { }

  public getOrderListTableConfig(includeAllPaginationItem: boolean): TableConfig {
    const isClient: boolean = this.authService.isClient();

    return {
      colDefs: this.authService.filterTableColumns(TABLE_TYPES.ORDER, [
        {
          headerName: 'ID',
          field: 'epr_id',
          valueFormatter: this.utilitiesService.textFormatter,
          checkboxSelection: !isClient,
          minWidth: 110
        },
        {
          headerName: 'Add Flag',
          field: 'flags',
          editable: true,
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.value.length ? {component: FlagChipListRendererComponent} : undefined;
          },
          cellEditor: FlagChipsAutocompleteEditorComponent,
          singleClickEdit: true,
          valueFormatter: this.utilitiesService.arrayFormatter,
          sortable: false,
          minWidth: 100
        },
        {
          headerName: 'Actions',
          field: 'actions',
          cellRenderer: ActionRendererComponent,
          cellRendererParams: {
            customParams: {
              type: 'order'
            }
          },
          sortable: false,
          minWidth: 100,
          maxWidth: 100
        },
        {
          headerName: 'Status',
          field: 'status',
          editable: (params: EditableCallbackParams<Order>) => {
            return !(isClient || (this.authService.isResearcher() && params.data.status) === ORDER_STATUSES.SENT_TO_CLIENT);
          },
          cellEditor: StatusEditorComponent,
          singleClickEdit: true,
          minWidth: 130,
          maxWidth: 160
        },
        {
          headerName: 'Assigned',
          field: 'assigned_to_display_name',
          editable: !isClient,
          cellEditor: ResearcherEditorComponent,
          singleClickEdit: true,
          valueFormatter: this.utilitiesService.textFormatter,
          minWidth: 130,
          maxWidth: 190
        },
        {
          headerName: 'Product',
          field: 'product_name',
          minWidth: 110
        },
        {
          headerName: 'Ordered',
          field: 'created',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
          minWidth: 110
        },
        {
          headerName: 'Ordered By',
          field: 'ordered_by',
          minWidth: 110
        },
        {
          headerName: 'Company',
          field: 'company_name',
          wrapText: true,
          minWidth: 110
        },
        {
          headerName: 'Address',
          field: 'full_address',
          wrapText: true,
          minWidth: 180
        },
        {
          headerName: 'County',
          field: 'order_county',
          minWidth: 80
        },
        {
          headerName: 'PMNT Status',
          field: 'payment_status',
          cellClass: this.utilitiesService.getPaymentStatusTableCellClass,
          minWidth: 80
        },
        {
          headerName: 'State',
          field: 'order_state',
          minWidth: 80
        },
        {
          headerName: 'File',
          field: 'order_file_number',
          wrapText: true,
          valueFormatter: this.utilitiesService.textFormatter,
          cellClass: 'eos-table-cell-text-ellipsis',
          minWidth: 110
        },
        {
          headerName: 'Accounting',
          field: 'accounting',
          children: [
            {
              headerName: 'Bal',
              field: 'balance',
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              minWidth: 80
            },
            {
              headerName: 'SF',
              field: 'price',
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              columnGroupShow: 'open',
              minWidth: 80
            },
            {
              headerName: 'HC',
              field: 'totalHardCosts',
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              columnGroupShow: 'open',
              minWidth: 80
            },
            {
              headerName: 'SH',
              field: 'totalSH',
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              columnGroupShow: 'open',
              minWidth: 80
            },
            {
              headerName: 'Misc',
              field: 'misc_amount',
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              columnGroupShow: 'open',
              minWidth: 80
            },
            {
              headerName: 'UD Disc',
              field: 'totalUdDiscNegative',
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              cellClass: this.getCurrencyCellClass,
              columnGroupShow: 'open',
              minWidth: 80
            },
            {
              headerName: 'Order Total',
              field: 'orderTotal',
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              cellClass: this.getCurrencyCellClass,
              columnGroupShow: 'open',
              minWidth: 80
            },
            {
              headerName: 'Disc',
              field: 'discountNegative',
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              cellClass: this.getCurrencyCellClass,
              columnGroupShow: 'open',
              minWidth: 80
            },
            {
              headerName: 'Billed Total',
              field: 'totalBilled',
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              columnGroupShow: 'open',
              sortable: false,
              minWidth: 80
            },
            {
              headerName: 'Pmnts',
              field: 'paymentsNegative',
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              cellClass: this.getCurrencyCellClass,
              columnGroupShow: 'open',
              minWidth: 80
            }
          ]
        },
        {
          headerName: 'Dates',
          field: 'dates',
          children: [
            {
              headerName: 'Closing',
              field: 'order_closing_date_at',
              valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
              minWidth: 110
            },
            {
              headerName: 'Needed',
              field: 'order_needed_date_at',
              valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
              columnGroupShow: 'open',
              minWidth: 110
            },
            {
              headerName: 'Promised',
              field: 'order_promise_date_at',
              valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
              cellClass: (params: CellClassParams) => this.getOverdueRowCellClass(params, 'eos-table-cell'),
              columnGroupShow: 'open',
              minWidth: 110
            },
            {
              headerName: 'Cancel Pending',
              field: 'cancellation_pending_date_at',
              valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
              columnGroupShow: 'open',
              minWidth: 110
            },
            {
              headerName: 'Canceled',
              field: 'canceled_at',
              valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
              columnGroupShow: 'open',
              minWidth: 110
            },
            {
              headerName: 'Billed',
              field: 'billed_date_at',
              valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
              columnGroupShow: 'open',
              minWidth: 110
            },
            {
              headerName: 'Sent To Client',
              field: 'sent_to_client_date',
              valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
              columnGroupShow: 'open',
              minWidth: 110
            }
          ]
        },
        {
          headerName: 'Rush',
          field: 'order_check_rush_order',
          valueFormatter: this.utilitiesService.yesNoFormatter,
          minWidth: 80
        }
      ]).sort((a: ColDef, b: ColDef) => this.authService.sortOrderListTableColumns(a, b)),
      autoCellWidth: true,
      /** undefined because of default value **/
      paginationPageSizeItems: includeAllPaginationItem ? PAGINATION_PAGE_SIZE : undefined,
      animateRows: false,
      suppressColumnMoveAnimation: true,
      debounceVerticalScrollbar: true,
      suppressCellFocus: false
    };
  }

  public getTaskListTableConfig(): TableConfig {
    return {
      colDefs: this.authService.filterTableColumns(TABLE_TYPES.TASK, [
        {
          headerName: 'Name',
          field: 'name'
        },
        {
          headerName: 'Stage',
          field: 'stage',
          minWidth: 80
        },
        {
          headerName: 'Updated By',
          field: 'updated_by',
          valueFormatter: this.utilitiesService.textFormatter,
          minWidth: 100
        },
        {
          headerName: 'Updated',
          field: 'updated',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
          minWidth: 100
        },
        {
          headerName: 'Notes',
          field: 'note',
          cellRenderer: this.utilitiesService.noteCellRenderer,
          autoHeight: true
        },
        {
          headerName: 'Actions',
          field: 'actions',
          cellRenderer: ActionRendererComponent,
          cellRendererParams: {
            customParams: {
              type: 'task'
            }
          },
          sortable: false,
          minWidth: 100
        }
      ]),
      rowClassRules: {
        'eos-table-row-success': (params: RowClassParams) => params.data?.stage === 'New',
        'eos-table-row-info': (params: RowClassParams) => params.data?.stage === 'Ordered',
        'eos-table-row-warning': (params: RowClassParams) => params.data?.stage === 'Received',
        'eos-table-row-danger': (params: RowClassParams) => params.data?.stage === 'Skipped'
      }
    };
  }

  public getIssueListTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'Ticket ID',
          field: 'ticket_id',
          checkboxSelection: true,
          minWidth: 150
        },
        {
          headerName: 'Status',
          field: 'status',
          cellClass: this.getIssueStatusCellClass,
          minWidth: 100
        },
        {
          headerName: 'Researcher',
          field: 'assigned_researcher_name'
        },
        {
          headerName: 'QC',
          field: 'assigned_qc_name'
        },
        {
          headerName: 'Title',
          field: 'name'
        },
        {
          headerName: 'Comments',
          field: 'note_count',
          minWidth: 70
        },
        {
          headerName: 'Order ID',
          field: 'order_epr_id',
          cellRenderer: LinkRendererComponent,
          cellRendererParams: {
            customParams: {
              static: '/order-edit',
              dynamic: 'order_id'
            }
          },
          minWidth: 100
        },
        {
          headerName: 'Updated',
          field: 'updated_timestamp',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
          minWidth: 100
        },
        {
          headerName: 'Created',
          field: 'created_timestamp',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
          minWidth: 100
        }
      ]
    };
  }

  public getIssueNoteListTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'Created By',
          field: 'created_by_name'
        },
        {
          headerName: 'Created',
          field: 'created_timestamp',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params)
        },
        {
          headerName: 'Comment',
          field: 'note',
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.extractText(params.value || ''),
          autoHeight: true,
          minWidth: 400
        }
      ]
    };
  }

  public getRelatedOrderAccountingTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'Order',
          field: 'epr_id',
          cellRenderer: LinkRendererComponent,
          cellRendererParams: {
            customParams: {
              static: '/order-edit',
              dynamic: 'order_id',
              external: true
            }
          },
          minWidth: 80
        },
        {
          headerName: 'Billed',
          field: 'billed_date',
          valueFormatter: (params: ValueFormatterParams) => {
            return !this.isRowPinned(params) ? this.timeService.timestampFormatter(params) : '';
          },
          minWidth: 80
        },
        {
          headerName: 'Check',
          field: 'payment_identifiers',
          valueFormatter: (params: ValueFormatterParams) => {
            return !this.isRowPinned(params) ? this.paymentIdentifierFormatter(params) : '';
          },
          autoHeight: true,
          minWidth: 80
        },
        {
          headerName: 'SF',
          field: 'price',
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
          },
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
          cellClass: this.getCurrencyCellClass,
          minWidth: 80
        },
        {
          headerName: 'HC',
          field: 'hard_costs',
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
          },
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
          cellClass: this.getCurrencyCellClass,
          minWidth: 80
        },
        {
          headerName: 'SH',
          field: 'shipping_handling',
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
          },
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
          cellClass: this.getCurrencyCellClass,
          minWidth: 80
        },
        {
          headerName: 'Misc',
          field: 'misc_amount',
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
          },
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
          cellClass: this.getCurrencyCellClass,
          minWidth: 80
        },
        {
          headerName: 'Order Total',
          field: 'orderTotal',
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
          },
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
          cellClass: this.getCurrencyCellClass,
          minWidth: 80
        },
        {
          headerName: 'Discounts',
          children: [
            {
              headerName: 'SF Disc',
              field: 'totalSfDiscNegative',
              cellRendererSelector: (params: ICellRendererParams) => {
                return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
              },
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              cellClass: this.getCurrencyCellClass,
              minWidth: 80
            },
            {
              headerName: 'UD Disc',
              field: 'totalUdDiscNegative',
              cellRendererSelector: (params: ICellRendererParams) => {
                return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
              },
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              cellClass: this.getCurrencyCellClass,
              columnGroupShow: 'open',
              minWidth: 80
            },
            {
              headerName: 'HC Disc',
              field: 'totalHcDiscountNegative',
              cellRendererSelector: (params: ICellRendererParams) => {
                return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
              },
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              cellClass: this.getCurrencyCellClass,
              columnGroupShow: 'open',
              minWidth: 80
            },
            {
              headerName: 'SH Disc',
              field: 'totalShDiscNegative',
              cellRendererSelector: (params: ICellRendererParams) => {
                return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
              },
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              cellClass: this.getCurrencyCellClass,
              columnGroupShow: 'open',
              minWidth: 80
            },
            {
              headerName: 'Old Disc',
              field: 'oldDiscountNegative',
              cellRendererSelector: (params: ICellRendererParams) => {
                return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
              },
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              cellClass: this.getCurrencyCellClass,
              columnGroupShow: 'open',
              minWidth: 80
            },
            {
              headerName: 'Ttl Disc',
              field: 'totalDiscountNegative',
              cellRendererSelector: (params: ICellRendererParams) => {
                return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
              },
              valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
              cellClass: this.getCurrencyCellClass,
              columnGroupShow: 'open',
              minWidth: 80
            }
          ]
        },
        {
          headerName: 'Billed Amount',
          field: 'billedAmount',
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
          },
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
          cellClass: this.getCurrencyCellClass,
          minWidth: 80
        },
        {
          headerName: 'Pymts',
          field: 'paymentsNegative',
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
          },
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
          cellClass: this.getCurrencyCellClass,
          minWidth: 80
        },
        {
          headerName: 'Bal',
          field: 'balance',
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.node.rowPinned ? {component: TotalPinnedRowRendererComponent} : undefined;
          },
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params),
          cellClass: this.getCurrencyCellClass,
          minWidth: 80
        }
      ]
    };
  }

  public getNotificationTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'status',
          field: 'finished',
          valueFormatter: (params: ValueFormatterParams) => {
            const { finished, is_error, is_pending } = params.data;

            if (is_pending === '1') {
              return 'pending';
            }

            if (is_error === '1') {
              return 'error';
            }

            return finished ? 'completed' : 'scheduled';
          },
          cellClass: (params: CellClassParams) => this.getOrderNotificationCellClass(params)
        },
        {
          headerName: 'Order',
          field: 'epr_id',
          cellRenderer: LinkRendererComponent,
          cellRendererParams: {
            customParams: {
              static: '/order-edit',
              dynamic: 'order_id'
            }
          },
          minWidth: 100
        },
        {
          headerName: 'Created By',
          field: 'created_by'
        },
        {
          headerName: 'Subject',
          field: 'subject',
          autoHeight: true,
          minWidth: 300
        },
        {
          headerName: 'Recipients',
          children: [
            {
              headerName: 'From',
              field: 'from',
              wrapText: true
            },
            {
              headerName: 'To',
              field: 'to',
              wrapText: true,
              columnGroupShow: 'open'
            },
            {
              headerName: 'CC',
              field: 'cc',
              cellRenderer: this.recipientsCellRenderer,
              minWidth: 300,
              wrapText: true,
              columnGroupShow: 'open'
            },
            {
              headerName: 'BCC',
              field: 'bcc',
              cellRenderer: this.recipientsCellRenderer,
              minWidth: 300,
              wrapText: true,
              columnGroupShow: 'open'
            }
          ],
          openByDefault: true
        },
        {
          headerName: 'Dates',
          children: [
            {
              headerName: 'Created',
              field: 'created',
              valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampDateTimeFormatter(params)
            },
            {
              headerName: 'Scheduled',
              field: 'scheduled',
              valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampDateTimeFormatter(params),
              columnGroupShow: 'open'
            },
            {
              headerName: 'Finished',
              field: 'finished',
              valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampDateTimeFormatter(params),
              columnGroupShow: 'open'
            }
          ]
        }
      ],
      getRowHeight: (params: RowHeightParams): number | undefined | null => {
        if (!params.data) { return null; }
        const {cc, bcc} = params.data,
              ccLineCounter: number = cc ? cc.split(/;/).length : 1,
              bccLineCounter: number = bcc ? bcc.split(/;/).length : 1,
              lineCounter: number = ccLineCounter >= bccLineCounter ? ccLineCounter : bccLineCounter;
        return lineCounter > 2 ? 20 * lineCounter : 48;
      }
    };
  }

  public getNotificationFilesListTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'Name',
          field: 'name',
          cellRenderer: LinkRendererComponent,
          cellRendererParams: {
            customParams: {
              takeCurrentUrl: true,
              needEmitEvent: true
            }
          },
          minWidth: 250
        },
        {
          headerName: 'Created By',
          field: 'created_by',
          minWidth: 100
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
          minWidth: 100
        },
        {
          headerName: 'Template File',
          field: 'is_template_file',
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.yesNoFormatter(params)
        }
      ]
    };
  }

  public getPaymentTableConfig(): TableConfig {
    return {
      colDefs: this.authService.filterTableColumns(TABLE_TYPES.ORDER_PAYMENT,[
        {
          headerName: 'ID',
          field: 'id',
          cellRenderer: 'agGroupCellRenderer',
          minWidth: 80
        },
        {
          headerName: 'Batch ID',
          field: 'batch_id',
          cellRenderer: LinkRendererComponent,
          cellRendererParams: {
            customParams: {
              static: '/batch-edit',
              dynamic: 'batch_id',
              external: true
            }
          },
          minWidth: 80
        },
        {
          headerName: 'Batch Name',
          field: 'batch_name'
        },
        {
          headerName: 'Check',
          field: 'payment_identifier'
        },
        {
          headerName: 'Paid',
          field: 'amount'
        },
        {
          headerName: 'Available To Transfer',
          field: 'available_balance'
        },
        {
          headerName: 'Type',
          field: 'payment_type'
        },
        {
          headerName: 'Created By',
          field: 'created_by'
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params)
        },
        {
          headerName: 'Visibility',
          field: 'internal',
          cellRenderer: SelectRendererComponent,
          cellRendererParams: {
            customParams: {
              eventType: 'visibility-change-payment',
              items: PAYMENT_VISIBILITY_TYPES,
              label: 'Visibility',
              ariaLabel: 'Payment visibility type'
            }
          },
          sortable: false,
          minWidth: 200
        },
        {
          headerName: 'Actions',
          field: 'actions',
          cellRenderer: ActionRendererComponent,
          cellRendererParams: {
            customParams: {
              type: 'order-payment'
            }
          },
          sortable: false,
          minWidth: 150
        }
      ]),
      masterDetail: true,
      detailCellRenderer: <any>NoteDetailRendererComponent,
      detailRowHeight: 50,
      rowClassRules: {
        'eos-table-row-warning': (params: RowClassParams) => params.data?.payment_type.includes('Transfer'),
        'eos-table-row-danger': (params: RowClassParams) => params.data?.payment_type.includes('Refund')
      },
      expandDetailRow: true
    };
  }

  public getAuditTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'Created By',
          field: 'created_by'
        },
        {
          headerName: 'Date',
          field: 'created',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params)
        },
        {
          headerName: 'Field',
          field: 'field_name'
        },
        {
          headerName: 'Original Value',
          field: 'original_value'
        },
        {
          headerName: 'New Value',
          field: 'new_value'
        }
      ]
    };
  }

  public getSearchBoxConfig(config: {products: Array<Product>} & FilterSectionConfig): SearchBoxConfig {
    return {
      searchSection: {
        search: true,
        searchValue: ''
      },
      filterSection: this.authService.filterSearchBoxConfig(FILTER_TYPES.ORDER, {
        onlyClientOrders: true,
        onlyClientOrderItems: YES_NO_RADIO,
        products: true,
        productItems: config.products,
        productValue: [],
        orderOrdered: true,
        orderOrderedValue: config.orderOrderedValue,
        orderPromised: true,
        orderNeeded: true,
        orderCanceled: true,
        orderSentToClient: true,
        orderStatuses: true,
        orderStatusItems: Object.values(ORDER_STATUSES),
        orderStatusValue: config.orderStatusValue,
        fileNumber: true,
        fileNumberValue: '',
        orderPaymentStatuses: true,
        orderPaymentStatusItems: ORDER_PAYMENT_STATUSES,
        orderNoAssignee: true,
        orderNoAssigneeItems: YES_NO_RADIO,
        orderNoAssigneeValue: config.orderNoAssigneeValue,
        orderAssignee: true,
        orderAssigneeValue: config.orderAssigneeValue,
        company: true,
        doBusinessAs: true,
        owner: true,
        ownerValue: '',
        buyer: true,
        buyerValue: '',
        folio: true,
        folioValue: '',
        county: true,
        countyValue: '',
        state: true,
        vendor: true,
        vendorValue: '',
        orderFlags: true,
        orderFlagValue: [],
        orderOverdue: true,
        orderOverdueItems: config.orderOverdueItems,
        onlyQualiaOrders: true
      })
    };
  }

  public getIssueListSearchBoxConfig(): SearchBoxConfig {
    return {
      searchSection: {
        search: true
      },
      filterSection: this.authService.filterSearchBoxConfig(FILTER_TYPES.ISSUE, {
        orderIssueStatuses: true,
        orderIssueStatusItems: ORDER_ISSUE_STATUSES,
        orderIssueDate: true,
        orderIssueQAs: true,
        orderIssueResearchers: true
      }),
      searchSectionActions: true
    };
  }

  public getNotificationListSearchConfig(): SearchBoxConfig {
    return {
      searchSection: {
        search: true
      },
      filterSection: this.authService.filterSearchBoxConfig(FILTER_TYPES.NOTIFICATION, {
        orderNotificationStatuses: true,
        orderNotificationStatusItems: ORDER_NOTIFICATION_STATUSES,
        email: true,
        orderNotificationRecipients: true,
        orderNotificationRecipientItems: ORDER_NOTIFICATION_RECIPIENTS,
        date: true,
        orderNotificationStates: true,
        orderNotificationStateItems: ORDER_NOTIFICATION_STATES,
        emailTemplates: true,
        orderNotificationAuthors: true
      })
    };
  }

  public getArrayGroupConfig(required: boolean): ArrayGroupControlConfig {
    return {
      type: 'text',
      label: 'Full name',
      placeholder: 'Full name',
      required,
      autocomplete: 'name',
      ariaLabel: 'Full name'
    };
  }

  public prepareOrderListQueryParams(value: SearchBoxValue, params?: IServerSideGetRowsParams, overdueDate?: string): QueryParams {
    const {
        onlyClientOrders,
        products,
        orderOrdered,
        orderPromised,
        orderNeeded,
        orderCanceled,
        orderSentToClient,
        orderAssignee,
        orderFlags,
        orderOverdue,
        onlyQualiaOrders
      } = value,
      {start: orderedStart, end: orderedEnd} = orderOrdered,
      {start: promisedStart, end: promisedEnd} = orderPromised || { },
      {start: neededStart, end: neededEnd} = orderNeeded || { },
      {start: canceledStart, end: canceledEnd} = orderCanceled,
      {start: sentToClientStart, end: sentToClientEnd} = orderSentToClient || { },
      {overdue} = orderOverdue || { },
      orderStatuses = overdue ? null : value.orderStatuses;

    overdueDate ??= this.timeService.formatDateTime(this.timeService.addEndDay(this.timeService.createDate()));

    return {
      ...this.listService.prepareBaseQueryParams(value, params),
      onlyClientOrders: onlyClientOrders === undefined ? true : onlyClientOrders,
      ordered_from: orderedStart ? this.timeService.formatDateTime(this.timeService.addStartDay(orderedStart)) : null,
      ordered_to: orderedEnd ? this.timeService.formatDateTime(this.timeService.addEndDay(orderedEnd)) : null,
      promise_from: promisedStart ? this.timeService.formatDateTime(this.timeService.addStartDay(promisedStart)) : null,
      promise_to: promisedEnd ? this.timeService.formatDateTime(this.timeService.addEndDay(promisedEnd)) : null,
      needed_from: neededStart ? this.timeService.formatDateTime(this.timeService.addStartDay(neededStart)) : null,
      needed_to: neededEnd ? this.timeService.formatDateTime(this.timeService.addEndDay(neededEnd)) : null,
      cancel_date: canceledStart ? this.timeService.formatDateTime(this.timeService.addStartDay(canceledStart)) : null,
      cancel_date_to: canceledEnd ? this.timeService.formatDateTime(this.timeService.addEndDay(canceledEnd)) : null,
      sent_to_client_from: sentToClientStart ? this.timeService.formatDateTime(this.timeService.addStartDay(sentToClientStart)) : null,
      sent_to_client_to: sentToClientEnd ? this.timeService.formatDateTime(this.timeService.addEndDay(sentToClientEnd)) : null,
      product_ids: products ? products.map((product: Product) => product.id).join(',') : null,
      statuses: orderStatuses ? orderStatuses.join(',') : null,
      file_number: value.fileNumber,
      payment_statuses:
        Object.entries(value.orderPaymentStatuses)
          .filter(([key, value]) => value ? key : value)
          .map(([key, value]) => ORDER_PAYMENT_STATUS_CONTROL_NAME_VALUE_MAPPER[key])
          .join(','),
      not_assigned: value.orderNoAssignee,
      assigned_to: orderAssignee ? orderAssignee.map((user: User) => user.id).join(',') : null,
      company_ids: value.company?.id || null,
      doBusinessAs: value.doBusinessAs,
      owner_name: value.owner,
      buyer_name: value.buyer,
      folio_id: value.folio,
      county: value.county,
      state: value.state,
      vendor_invoice_id: value.vendor,
      searchFlags: orderFlags ? orderFlags.map((flag: Flag) => flag.flag_id || flag.order_flag_id).join(',') : null,
      overdue: overdue ? overdue : null,
      overdue_date: overdue ? overdueDate : null,
      onlyQualiaOrders: onlyQualiaOrders || null
    };
  }

  public prepareIssueListQueryParams(value: SearchBoxValue, params: IServerSideGetRowsParams, orderId: string | null = null): QueryParams {
    const {orderIssueStatuses, orderIssueDate, orderIssueQAs, orderIssueResearchers} = value,
          {start, end} = orderIssueDate || { };

    return {
      ...this.listService.prepareBaseQueryParams(value, params),
      order_id: orderId,
      status: orderIssueStatuses ? [...orderIssueStatuses] : null,
      date_range_from: start ? this.timeService.formatDateTime(this.timeService.addStartDay(start)) : null,
      date_range_to: end ? this.timeService.formatDateTime(this.timeService.addEndDay(end)) : null,
      assigned_qc: orderIssueQAs ? orderIssueQAs.map((qa: QualityAssurance) => qa.id) : null,
      assigned_researcher: orderIssueResearchers ? orderIssueResearchers.map((user: User) => user.id as string) : null
    };
  }

  public prepareNotificationListQueryParams(value: SearchBoxValue, params: IServerSideGetRowsParams): QueryParams {
    const {
        orderNotificationStatuses,
        orderNotificationRecipients,
        date,
        orderNotificationStates,
        emailTemplates,
        orderNotificationAuthors
      } = value,
      {complete, scheduled, error, pending} = orderNotificationStatuses,
      {
        from: email_address_from,
        to: email_address_to,
        cc: email_address_cc,
        bcc: email_address_bcc
      } = orderNotificationRecipients,
      {
        created: date_range_created,
        scheduled: date_range_scheduled,
        finished: date_range_finished
      } = orderNotificationStates,
      {start, end} = date;

    return {
      ...this.listService.prepareBaseQueryParams(value, params),
      complete,
      scheduled,
      error,
      pending,
      email_text: value.email,
      email_address_from,
      email_address_to,
      email_address_cc,
      email_address_bcc,
      date_range_created,
      date_range_scheduled,
      date_range_finished,
      from: start ? this.timeService.formatDateTime(this.timeService.addStartDay(start)) : null,
      to: end ? this.timeService.formatDateTime(this.timeService.addEndDay(end)) : null,
      email_template_id: emailTemplates && emailTemplates.length
        ? emailTemplates.map((template: SearchEmailTemplate) => template.id)
        : null,
      created_by: orderNotificationAuthors && orderNotificationAuthors.length
        ? orderNotificationAuthors.map((author: NotificationAuthor) => author.id)
        : null
    };
  }

  public getStatusItemByValue(items: Array<SelectItem>, value: string): SelectItem {
    return items.find((item: SelectItem) => item.value === value);
  }

  public getOverdueRowCellClass(params: RowClassParams | CellClassParams, baseClass: string): string {
    const order: Order = params.data || { },
          rowClass: string = this.getExpirationClass(baseClass, order.order_promise_date);

    return rowClass !== baseClass && this.canOverdueStatus(order.status) ? rowClass : baseClass;
  }

  public prepareOrderFormValue(value: RawOrderFormValue, loanFile: File = null, isUpdate: boolean = false): OrderFormValue {
    const {general, property} = value,
          {estoppel, survey, loan} = general,
          res: OrderFormValue = {
            orderOverrideStatus: false,
            status: general.status || ORDER_STATUSES.NEW,
            // general
            company: general.company.id,
            user: <string>general.user.id,
            product: general.product,
            optionalProducts: [], // TODO: what is it?
            add_estoppel: general.service && !isUpdate && general.service.add_estoppel !== undefined
              ? general.service.add_estoppel
              : false,
            add_survey: general.service && !isUpdate && general.service.add_survey !== undefined
              ? general.service.add_survey
              : false,
            vendor_invoice_id: general.vendor_invoice_id,
            assigned_to: general.assigned_to,
            check_rush_order: general.check.check_rush_order,
            in_foreclosure: general.check.in_foreclosure,
            property_refinanced: general.check.property_refinanced,
            isQualia: general.isQualia,
            closing_date: this.timeService.formatDateTime(this.timeService.addEndDay(general.closing_date)),
            needed_date: general.needed_date
              ? this.timeService.formatDateTime(this.timeService.addEndDay(general.needed_date))
              : null,
            promise_date: general.promise_date
              ? this.timeService.formatDateTime(this.timeService.addEndDay(general.promise_date))
              : null,
            comment: general.comment,
            // property
            ...property,
            owner_names: property.owner_names?.join(',') || null,
            buyer_names: property.buyer_names?.join(',') || null,
            address_1: property.address_1.line_1
          };

    if (estoppel) {
      res.estoppel_sale_price = estoppel.estoppel_sale_price;
      res.estoppel_sale_price_na = !!estoppel.estoppel_sale_price;
      res.assoc_no = estoppel.assoc_no;
      res.assoc_no_unknown = !estoppel.assoc_no;
      res.assoc_name = estoppel.assoc_name;
      res.assoc_name_unknown = !estoppel.assoc_name;
      res.assoc_contact = estoppel.assoc_contact;
      res.assoc_contact_unknown = !estoppel.assoc_contact;
      res.assoc_contact_phone = estoppel.assoc_contact_phone;
      res.assoc_contact_phone_unknown = !estoppel.assoc_contact_phone;
      res.assoc_contact_email = estoppel.assoc_contact_email;
      res.assoc_contact_email_unknown = !estoppel.assoc_contact_email;
      res.optional_comment = estoppel.optional_comment;
    }

    if (survey) {
      res.elevation_certificate = survey.elevation_certificate;
      res.type_of_survey = survey.type_of_survey;
      res.property_contact_name = survey.property_contact_name;
      res.property_contact_phone = survey.property_contact_phone;
      res.property_contact_phone_unknown = !survey.property_contact_phone;
      res.property_contact_email = survey.property_contact_email;
      res.property_contact_email_unknown = !survey.property_contact_email;
      res.lender = survey.lender;
      res.property_contact_lender_unknown = !survey.lender;
      res.survey_gate_info = survey.survey_gate_info;
      res.property_contact_gate_unknown = !survey.survey_gate_info;
      res.title_insurance = survey.title_insurance;
      res.legal_info = survey.legal_info;
      res.survey_needed_date = survey.survey_needed_date
        ? this.timeService.formatDateTime(this.timeService.addEndDay(survey.survey_needed_date))
        : null;
      res.survey_paper_copy = survey.survey_paper_copy;
    }

    if (loan) {
      res.loan_bank_name = loan.loan_bank_name;
      res.loan_number = loan.loan_number;
      res.loan_check_date = loan.loan_check_date
        ? this.timeService.formatDateTime(this.timeService.addEndDay(loan.loan_check_date))
        : null;
      res.loan_mortgage_count = loan.loan_mortgage_count ? Number(loan.loan_mortgage_count) : null;
      res.loan_original_payoff = loan.loan_original_payoff ? Number(loan.loan_original_payoff) : null;
      res.loan_amount_paid = loan.loan_amount_paid ? Number(loan.loan_amount_paid) : null;
      res.loan_external_auth = loanFile ? loanFile.name : null;
    }

    return res;
  }

  public prepareIssueFormValue(
    formValue: IssueFormValue,
    data: Order | Issue,
    qaItem: SelectItem,
    researcherItem: SelectItem
  ): IssueFormValue {
    return {
      ticket_id: null,
      order_id: (<Issue>data).order_id ? (<Issue>data).order_id : data.id,
      order_epr_id: (<Issue>data).order_epr_id ? (<Issue>data).order_epr_id : (<Order>data).epr_id,
      parent_id: null,
      ...formValue,
      status: 'Creating',
      assigned_qc_name: qaItem.viewValue,
      assigned_researcher_name: researcherItem.viewValue,
      cancel_date: null,
      cancelled_by: null,
      cancelled_by_name: null,
      created_by: null,
      created_by_name: null,
      created_timestamp: null,
      description: `<p>${formValue.description}</p>`,
      isCancelled: null,
      isComplete: null,
      isNew: null,
      isNotCreated: true,
      isPendingCancel: null,
      isPendingReview: null,
      isRejected: null,
      isUnassigned: null,
      qc_resolve_date_timestamp: null,
      rejected_by_name: null,
      rejected_date: null,
      rejected_date_timestamp: null,
      resolve_date_timestamp: null,
      resolved_by: null,
      resolved_by_name: null,
      updated_by: null,
      updated_by_name: null,
      updated_timestamp: null
    };
  }

  public prepareTaskFormValue(formValue: TaskFormValue, types: Array<TaskListItem>, order_id: string): TaskFormValue {
    return {
      ...formValue,
      order_id,
      tasks: formValue.tasks.map((name: string) => this.taskService.getTypeIdByName(name, types))
    };
  }

  public prepareAccountingFromValue(formValue: any): AccountingFormValue {
    const {main, total_cost, billed_date, accounting_notes, invoice_memo, hard_costs} = formValue;
    return {
      ...main,
      total_cost,
      billed_date: billed_date ? this.timeService.formatDateTime(this.timeService.addEndDay(billed_date)) : null,
      accounting_notes,
      invoice_memo,
      hard_costs: hard_costs.map((hardCost: HardCostFormValue) => ({...hardCost}))
    };
  }

  public prepareMarketerFormValue(formValue: RawMarketersFormValue): Array<MarketerFormValue> {
    return formValue.marketers.map((value: RawMarketerFormValue) => {
      const {id, marketer, active, created_by} = value,
            {first_name, last_name, displayName} = marketer;
      return {
        id,
        user_id: marketer.user_id || marketer.id,
        first_name,
        last_name,
        searchText: displayName,
        active,
        created_by
      };
    });
  }

  public getHardCostTypeItems(types: Array<CostType>): Array<SelectItem> {
    return types.map((type: CostType) => {
      return {
        id: type.id,
        value: type.id,
        viewValue: type.name
      };
    });
  }

  public getOrderPanelStatuses(order: Order): Array<ChipListItem> {
    const {status, payment_status, order_promise_date} = order,
          res: Array<ChipListItem> = [
            {
              label: status,
              class: `eos-chip-list-item-${ORDER_STATUS_CLASSES[status]}`
            },
            {
              label: payment_status,
              class: `eos-chip-list-item-${ORDER_PAYMENT_STATUS_CLASSES[payment_status]}`
            }
          ];
    let data: ChipListItem | null = null;

    if (this.showExpirationChipItem(order_promise_date)) {
      data = this.getExpirationChipListItemData(order_promise_date);

      res.push({
        label: data.label,
        class: this.getExpirationClass('eos-chip-list-item', order_promise_date),
        tooltip: data.tooltip
      });
    }

    return res;
  }

  public isCancelStatus(status: string): boolean {
    return status === ORDER_STATUSES.CANCELLATION_PENDING
      || status === ORDER_STATUSES.CANCELLED
      || status === ORDER_STATUSES.CANCELLED_HARD_COST_PAYABLE
      || status === ORDER_STATUSES.CANCELLED_ESTOPPEL_REFUND_NEEDED
      || status === ORDER_STATUSES.ACCOUNTING_CANCELLED
      || status === ORDER_STATUSES.ACCOUNTING_CANCELLED_HARD_COST_PAYABLE;
  }

  public canOverdueStatus(status: string): boolean {
    return status === ORDER_STATUSES.NEW
      || status === ORDER_STATUSES.ASSIGNED
      || status === ORDER_STATUSES.RESEARCH
      || status === ORDER_STATUSES.TO_BE_BUILT
      || status === ORDER_STATUSES.REVIEW
      || status === ORDER_STATUSES.HOLD
      || status === ORDER_STATUSES.PENDING_ESTOPPEL
      || status === ORDER_STATUSES.UPDATED;
  }

  private paymentIdentifierFormatter(params: ValueFormatterParams): string {
    return params.value ? params.value.map((v: string) => v).join(', ') : '---';
  }

  private getIssueStatusCellClass(params: CellClassParams): string {
    return `eos-table-cell-${ORDER_ISSUE_STATUS_CLASSES[params.value]}`;
  }

  private getCurrencyCellClass(params: CellClassParams): string {
    return `eos-table-cell-${params.value < 0 ? 'danger' : 'default'}`;
  }

  private getExpirationClass(baseClass: string, value?: string): string {
    const today: moment.Moment = this.timeService.addStartDay(moment()),
          required: moment.Moment = this.timeService.addWorkdays(today, 2),
          promised: moment.Moment | null = value ? moment(value) : null;

    if (!promised) { return baseClass; }

    if (promised <= today) {
      baseClass = `${baseClass}-danger`;
    } else if (promised > today && promised <= required) {
      baseClass = `${baseClass}-warning`;
    }

    return baseClass;
  }

  private getOrderNotificationCellClass(params: CellClassParams): string {
    const { finished, is_error, is_pending } = params.data,
          clazz: string = 'eos-table-cell-';

    if (is_pending === '1') {
      return `${clazz}default`;
    }

    if (is_error === '1') {
      return `${clazz}danger`;
    }

    return `${clazz}${finished ? 'success' : 'info'}`;
  }

  private getExpirationChipListItemData(value?: string): ChipListItem | null {
    const today: moment.Moment = this.timeService.addStartDay(moment()),
          required: moment.Moment = this.timeService.addWorkdays(today, 2),
          promised: moment.Moment | null = value ? moment(value) : null;
    let res: ChipListItem | null = null;

    if (!promised) { return res; }

    if (promised <= today) {
      res = {
        label: 'Past Due',
        tooltip: 'Past Due is due today or was due prior to today'
      };
    } else if (promised > today && promised <= required) {
      res = {
        label: 'Order Due Soon',
        tooltip: 'Order Due Soon is due within 2 business days'
      };
    }

    return res;
  }

  private showExpirationChipItem(value?: string): boolean {
    const today: moment.Moment = this.timeService.addStartDay(moment()),
          required: moment.Moment = this.timeService.addWorkdays(today, 2),
          promised: moment.Moment | null = value ? moment(value) : null;
    return promised && ((promised <= today) || (promised > today && promised <= required));
  }

  private isRowPinned(params: any): boolean {
    return params.node.isRowPinned();
  }

  private recipientsCellRenderer(params: ICellRendererParams): string {
    return params.value ? params.value.replaceAll(/;/g, '<br>') : '---';
  }
}
