import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { debounceTime, map, startWith } from 'rxjs/operators';

import { ChipsAutocompleteComponent } from '@components/chips-autocomplete/chips-autocomplete.component';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { Product } from '@models/product';
import { AutocompleteValue } from '@models/autocomplete';

@Component({
  selector: 'app-product-chips-autocomplete',
  templateUrl: './product-chips-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => ProductChipsAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ProductChipsAutocompleteComponent,
      multi: true
    }
  ]
})
export class ProductChipsAutocompleteComponent extends ChipsAutocompleteComponent {
  @Input() public override label: string = 'Products';
  @Input() public override placeholder: string = 'Search for a product...';

  constructor(injector: Injector) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit(false);

    this.items$ = this.inputControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      map((query: string): Array<AutocompleteValue> => this.composeAutocompleteValues(query))
    );
  }

  protected override composeAutocompleteValues(query: string): Array<AutocompleteValue> {
    const res: Array<AutocompleteValue> = this.filterByQuery(query);
    return res.length ? res : EMPTY_AUTOCOMPLETE_VALUE;
  }

  protected override filterByQuery(query: string): Array<Product> {
    return <Array<Product>>this.filteredItems.filter(
      (product: Product): boolean => product.name.toLowerCase().includes(query)
    );
  }
}
