import { EmptyAutocompleteValue } from '@models/autocomplete';

export const EMPTY_AUTOCOMPLETE_VALUE: Array<EmptyAutocompleteValue> = [{
  id: '',
  epr_id: 'No Results Found',
  name: 'No Results Found',
  displayName: 'No Results Found',
  display: 'No Results Found',
  label: 'No Results Found',
  first_name: 'No Results Found',
  active: '0'
}];

export const ORDER_ISSUE_STATUSES: Array<string> = [
  'Assigned',
  'Complete',
  'Pending Review',
  'Cancelled',
  'Pending Cancel',
  'Rejected',
  'Deleted'
];

export const COMPANY_SEARCH_CATEGORIES: Array<string> = [
  'name',
  'address',
  'city',
  'state',
  'zip',
  'phone'
];

export const USER_ROLES: Array<string> = [
  'Admin',
  'Manager',
  'Accountant',
  'Researcher',
  'Marketer',
  'Client',
  'Lead',
  'Qc'
];
