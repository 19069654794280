import { Injectable, Injector } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseApi } from '@api/base.api';
import { PaymentApiEndPoints, QueryParams } from '@models/api';
import { Payload } from '@models/response';
import { TableData } from '@models/table';
import { Payment, BatchPaymentFormValue, BatchPayment, NewBatchPayment } from '@models/payment';
import { PaymentNoteFormValue } from '@models/note';

@Injectable({
  providedIn: 'root'
})
export class PaymentApi extends BaseApi<PaymentApiEndPoints> {
  constructor(injector: Injector) {
    super(injector);

    this.endPoints = this.addBaseUrl({
      GET_BATCHES: '/payments/batches',
      GET_BATCH_PAYMENTS: '/payments/batches/${id}',
      GET_PAYMENTS: '/payments/payment-search',
      GET_PAYMENTS_BY_ORDER_ID: '/payments/order/${id}',
      GET_BATCH_DRAFT: '/batch-draft/${userId}/get',
      GET_BATCH_DRAFT_HISTORY: '/batch-draft-history/${userId}',
      POST_CREATE_BATCH_PAYMENT: '/payments',
      POST_CREATE_BATCH_TRANSFER: '/payments/transfer',
      POST_CREATE_BATCH_DRAFT: '/batch-draft/${userId}/update',
      POST_REVERT_BATCH_DRAFT: '/batch-draft/${userId}/revert',
      POST_UPDATE_NOTE: '/payments/edit-notes/${id}',
      POST_UPDATE_VISIBILITY: '/payments/visibility',
      POST_DELETE_BATCH: '/batch/delete-batch/${id}',
      POST_DELETE_BATCH_WITH_RESET_SEARCH_FEE: '/batch/delete-batch/${id}/reset-sf',
      DELETE_BATCH_DRAFT: '/batch-draft/${userId}/delete',
      DELETE_BATCH_DRAFT_HISTORY: '/batch-draft-history/${userId}/delete'
    });
  }

  public getBatches(params?: QueryParams): Observable<TableData<BatchPayment>> {
    return this.http.get<TableData<BatchPayment>>(
      this.endPoints.GET_BATCHES,
      {params: this.setQueryParameters(params)}
    );
  }

  public downloadBatches(responseType: string, params?: QueryParams): Observable<string | ArrayBuffer> {
    return this.http.get<string | ArrayBuffer>(
      this.endPoints.GET_BATCHES,
      {responseType: <'json'>responseType, params: this.setQueryParameters(params)}
    );
  }

  public getBatchPayments(id: string, params?: QueryParams): Observable<TableData<Payment>> {
    const url: string = this.substituteParameters(this.endPoints.GET_BATCH_PAYMENTS, {id});
    return this.http.get<TableData<Payment>>(url, {params: this.setQueryParameters(params)});
  }

  public downloadBatchPayments(id: string, responseType: string, params?: QueryParams): Observable<string | ArrayBuffer> {
    const url: string = this.substituteParameters(this.endPoints.GET_BATCH_PAYMENTS, {id});
    return this.http.get<string | ArrayBuffer>(
      url,
      {responseType: <'json'>responseType, params: this.setQueryParameters(params)}
    );
  }

  public getPayments(params?: QueryParams): Observable<TableData<Payment>> {
    return this.http.get<TableData<Payment>>(this.endPoints.GET_PAYMENTS, {params: this.setQueryParameters(params)});
  }

  public getPaymentsCSV(params?: QueryParams): Observable<string> {
    return this.http.get<string>(
      this.endPoints.GET_PAYMENTS,
      {responseType: <'json'>'text', params: this.setQueryParameters(params)}
    );
  }

  public getPaymentsByOrderId(id: string, params?: QueryParams): Observable<TableData<Payment>> {
    const url: string = this.substituteParameters(this.endPoints.GET_PAYMENTS_BY_ORDER_ID, {id});
    return this.http.get<TableData<Payment>>(url, {params: this.setQueryParameters(params)});
  }

  public getBatchDraft(userId: string): Observable<Array<NewBatchPayment>> {
    const url: string = this.substituteParameters(this.endPoints.GET_BATCH_DRAFT, {userId});
    return this.http.get<Array<NewBatchPayment>>(url);
  }

  public getBatchDraftHistory(userId: string): Observable<Array<NewBatchPayment>> {
    const url: string = this.substituteParameters(this.endPoints.GET_BATCH_DRAFT_HISTORY, {userId});
    return this.http.get<Array<NewBatchPayment>>(url);
  }

  public createBatchPayment(formValue: BatchPaymentFormValue): Observable<Array<Payment>> {
    return this.http
      .post<Payload<Array<Payment>>>(this.endPoints.POST_CREATE_BATCH_PAYMENT, formValue)
      .pipe(
        map((res: Payload<Array<Payment>>) => res.items)
      );
  }

  public createBatchTransfer(formValue: BatchPaymentFormValue): Observable<Array<Payment>> {
    return this.http
      .post<Payload<Array<Payment>>>(this.endPoints.POST_CREATE_BATCH_TRANSFER, formValue)
      .pipe(
        map((res: Payload<Array<Payment>>) => res.items)
      );
  }

  public createBatchDraft(userId: string, formValue: Array<NewBatchPayment>): Observable<Array<NewBatchPayment>> {
    const url: string = this.substituteParameters(this.endPoints.POST_CREATE_BATCH_DRAFT, {userId});
    return this.http.post<Array<NewBatchPayment>>(url, formValue);
  }

  public revertBatchDraft(userId: string, formValue: Array<NewBatchPayment>): Observable<Array<NewBatchPayment>> {
    const url: string = this.substituteParameters(this.endPoints.POST_REVERT_BATCH_DRAFT, {userId});
    return this.http.post<Array<NewBatchPayment>>(url, formValue);
  }

  public updateNote(id: string, formValue: { payment: PaymentNoteFormValue }): Observable<Payment> {
    const url: string = this.substituteParameters(this.endPoints.POST_UPDATE_NOTE, {id});
    return this.http
      .post<null>(url, formValue)
      .pipe(
        map((res: Payload<Payment>) => res.payment)
      );
  }

  public updateVisibility(formValue: BatchPaymentFormValue): Observable<Payment> {
    return this.http
      .post<Payload<Payment>>(this.endPoints.POST_UPDATE_VISIBILITY, formValue)
      .pipe(
        map((res: Payload<Payment>) => res.payment)
      );
  }

  public deleteBatch(id: string): Observable<void> {
    const url: string = this.substituteParameters(this.endPoints.POST_DELETE_BATCH, {id});
    return this.http.post<void>(url, null);
  }

  public deleteBatchWithResetSearchFee(id: string): Observable<void> {
    const url: string = this.substituteParameters(this.endPoints.POST_DELETE_BATCH_WITH_RESET_SEARCH_FEE, {id});
    return this.http.post<void>(url, null);
  }

  public deleteBatchDraft(userId: string): Observable<Array<NewBatchPayment>> {
    const url: string = this.substituteParameters(this.endPoints.DELETE_BATCH_DRAFT, {userId});
    return this.http.delete<Array<NewBatchPayment>>(url);
  }

  public deleteBatchDraftHistory(userId: string): Observable<Array<NewBatchPayment>> {
    const url: string = this.substituteParameters(this.endPoints.DELETE_BATCH_DRAFT_HISTORY, {userId});
    return this.http.delete<Array<NewBatchPayment>>(url);
  }
}
