import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AutocompleteComponent } from '@components/autocomplete/autocomplete.component';
import { UserApi } from '@api/user.api';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';
import { Marketer } from '@models/user';

@Component({
  selector: 'app-marketer-autocomplete',
  templateUrl: './marketer-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => MarketerAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: MarketerAutocompleteComponent,
      multi: true
    }
  ]
})
export class MarketerAutocompleteComponent extends AutocompleteComponent {
  @Input() public override label: string = 'Marketer';
  @Input() public override placeholder: string = 'Search for a marketer...';

  public readOnly: boolean = false;

  protected override readonly switchMap: boolean = true;

  constructor(injector: Injector, private api: UserApi) {
    super(injector);
  }

  public displayFn = (marketer: Marketer): string => {
    return marketer ? marketer.displayName : '';
  };

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return this.api.searchForUsersByRole('marketer', {keyword: query}).pipe(
      tap((marketers: Array<Marketer>): Array<Marketer> => this.allItems = marketers),
      map((): Array<AutocompleteValue> => this.composeAutocompleteValues()),
      catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
    );
  }
}
