<div class="eos-template-file-list-container">
  <app-drag-drop [hidden]="true" #dragAndDrop (fileDropped)="onFileDropped($event)"></app-drag-drop>

  <app-table
    [config]="tableConfig"
    [serverSideDatasource]="serverSideDatasource"
    (tableSelectionChanged)="onSelectionChanged($event)"
    (tableChanged)="onFileChanged($event)">
  </app-table>
</div>
