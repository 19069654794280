<div class="eos-drag-drop-container {{containerClass}}">
  <div
    [ngClass]="{'eos-drag-drop-zone': true, 'eos-drag-drop-zone-disabled': disabled}"
    appDnd
    (fileDrop)="onFileDropped($event)">
    <input type="file" #input [multiple]="multiple" (change)="onFileInputChange($event)">
    <div>
      <p>
        Drag & Drop {{multiple ? 'file(s)' : 'file'}} here<br>
        - or -
      </p>
      <button
        type="button"
        class="eos-btn-primary"
        [disabled]="disabled"
        mat-flat-button
        (click)="input.click()">
        Select {{multiple ? 'file(s)' : 'file'}} from the computer
      </button>
    </div>
    <p>{{label}}</p>
  </div>
  <ol *ngIf="files.length">
    <li *ngFor="let file of files; index as i">
      <span>{{file.name}}</span>
      <button
        type="button"
        class="eos-btn-delete"
        aria-label="Remove file"
        [disabled]="disabled"
        mat-icon-button
        (click)="onDeleteBtnClick(i)">
        <mat-icon>close</mat-icon>
      </button>
    </li>
  </ol>
</div>
