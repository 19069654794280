<div class="eos-autocomplete-container">
  <mat-form-field [ngClass]="classList">

    <mat-label>{{label}}</mat-label>

    <input
      type="text"
      [placeholder]="placeholder"
      [readonly]="isReadonly"
      [attr.aria-label]="ariaLabel"
      [formControl]="inputControl"
      [matAutocomplete]="autocomplete"
      matInput
      (input)="onInput()"
      (blur)="onBlur()">

    <mat-hint *ngIf="hint">{{hint}}</mat-hint>

    <mat-error *ngIf="!isValid && (inputControl.dirty || inputControl.touched)">{{errorMsg}}</mat-error>

    <mat-autocomplete
      [displayWith]="displayFn"
      autoActiveFirstOption
      #autocomplete
      (optionSelected)="onSelected($event)">
      <mat-option *ngFor="let item of items$ | async" [value]="item" [disabled]="item['active'] === '0'">
        {{item | qualityAssuranceViewValue}}
      </mat-option>
    </mat-autocomplete>

  </mat-form-field>
</div>
