import { Pipe, PipeTransform } from '@angular/core';

import { Flag } from '@models/flag';
import { AutocompleteValue } from '@models/autocomplete';

@Pipe({
  name: 'flagTooltip'
})
export class FlagTooltipPipe implements PipeTransform {
  public transform(flag: AutocompleteValue): string {
    let label: string = '',
        note: string = '',
        res: string = 'You can not edit this flag';

    if ((<Flag>flag).can_edit === '1' || (<Flag>flag).flag_can_edit === '1') {
      label = ((<Flag>flag).label || (<Flag>flag).flag_label);
      note = (<Flag>flag).flag_note ? `- ${(<Flag>flag).flag_note}` : '';
      res = `${label} ${note}`.trim();
    }

    return res;
  }
}
