import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { RowNode } from 'ag-grid-community';

import { TableService } from '@services/table.service';
import { LinkRendererParams } from '@models/renderer-parameters';

@Component({
  selector: 'app-link-renderer',
  templateUrl: './link-renderer.component.html'
})
export class LinkRendererComponent implements AgRendererComponent {
  public params!: ICellRendererParams;
  public customParams!: LinkRendererParams;

  public link!: string;
  public href!: string;

  constructor(private router: Router, private service: TableService) { }

  public onLinkClick(): void {
    const node: RowNode = this.params.node;

    if (!this.customParams.needEmitEvent) { return; }

    this.service.rendererAction$.next({
      type: 'link-click',
      item: null,
      rowData: node?.data,
      node
    });
  }

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.customParams = params['customParams'];

    this.createLink();
  }

  public refresh(): boolean {
    return false;
  }

  private createLink(): void {
    const customParams: LinkRendererParams = this.customParams,
          staticUrlPart: string | undefined = customParams.static,
          dynamicUrlPart: string | undefined = this.params.data[customParams.dynamic];

    if (customParams.external) {
      this.href = this.router.serializeUrl(
        this.router.createUrlTree([staticUrlPart, dynamicUrlPart])
      );
    } else {
      this.link = customParams.takeCurrentUrl
        ? this.router.url
        : `${staticUrlPart}${dynamicUrlPart && `/${dynamicUrlPart}`}`;
    }
  }
}
