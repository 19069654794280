<app-dialog header="Return Transfer" cancelBtnLabel="No">
  <div slot="content">
    <h4>Would you like to make a return Transfer?</h4>
    <p>{{message}}</p>
  </div>
  <button
    type="button"
    class="eos-btn-primary"
    mat-flat-button
    slot="action"
    (click)="onYesBtnClick()">
    Yes
  </button>
</app-dialog>
