import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

import { UtilitiesService } from '@services/utilities.service';
import { Radio } from '@models/radio';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class RadioGroupComponent implements OnInit {
  @Input() public label!: string;
  @Input('class') classList: string = '';
  @Input() public controlName!: string;
  @Input() public items: Array<Radio> = [];

  public id: string;

  constructor(private service: UtilitiesService) {
    this.id = this.service.guid();
  }

  ngOnInit(): void {
    this.classList += ' eos-radio-group-container';
  }
}
