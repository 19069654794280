import { Component, forwardRef, Input, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

import { Subject } from 'rxjs';

import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-chips-input',
  templateUrl: './chips-input.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef((): any => ChipsInputComponent),
    multi: true
  }]
})
export class ChipsInputComponent implements ControlValueAccessor {
  @Input() public label!: string;
  @Input() public placeholder: string = 'New...';
  @Input('class') public classList!: string;
  @Input('aria-label') public ariaLabel: string = 'Chips input';
  @Input() public items: Array<string> = [];
  @Input() public validator: (control: AbstractControl) => boolean;

  @Output() chipsInputChanged: Subject<Array<string>> = new Subject<Array<string>>();

  public readonly separatorKeysCodes: Array<number> = [ENTER];
  public inputControl: UntypedFormControl;

  public touched: boolean = false;
  public isValid: boolean = true;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.inputControl = this.formBuilder.control(null);
    this.validator = (control: AbstractControl): boolean => true;
  }

  public onChange(_: any): void { };

  public onTouched(): void { };

  public onAddKeywordFromInput(event: MatChipInputEvent): void {
    const value: string = (event.value || '').trim();
    this.validate(this.inputControl);
    if (!this.isValid) { return; }

    this.items.push(value);

    this.nextValue(this.items);

    event.chipInput!.clear();
  }

  public onRemoveBtnClick(item: string): void {
    const index: number = this.items.indexOf(item);
    if (index < 0) { return; }

    this.items.splice(index, 1);

    this.nextValue(this.items);
  }

  public writeValue(value: Array<string>): void {
    this.items = value || [];
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  protected nextValue(value: Array<string>): void {
    this.markAsTouched();
    this.onChange(value);

    this.chipsInputChanged.next(value);
  }

  private validate(control: AbstractControl): void {
    this.isValid = this.validator(control);
  }
}
