<div class="eos-template-file-panel-container">
  <app-panel
    header="Template Files"
    [actions]="panelActions"
    [expanded]="expanded"
    (actionBtnClicked)="onPanelAction($event)">
    <div
      class="eos-drag-drop-container"
      slot="body"
      appDnd
      [validFileExtensions]="validFileExtensions"
      [authorizedRoles]="roles"
      (fileDrop)="onFileDropped($event)">
      <app-template-file-list
        [api]="api"
        [service]="service"
        [id]="id"
        [isSelectable]="isSelectable"
        #fileListComponent>
      </app-template-file-list>
    </div>
  </app-panel>
</div>
