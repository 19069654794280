import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { TableRendererActionEvent } from '@models/table';
import { IconRendererParams } from '@models/renderer-parameters';
import { Company } from '@models/company';
import { Payment } from '@models/payment';
import {
  CompanyStatementReport,
  JobsByCompanyReport,
  SalesByMarketerReport,
  UserStatementReport
} from '@models/report';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  public rendererAction$: Subject<TableRendererActionEvent<unknown>> = new Subject<TableRendererActionEvent<unknown>>();

  public getIconRendererParams(
    entity: Company | Payment | JobsByCompanyReport | SalesByMarketerReport | CompanyStatementReport | UserStatementReport
  ): Array<IconRendererParams> {
    const res: Array<IconRendererParams> = [];

    if (entity.company_from_qualia) {
      res.push({
        icon: 'qualia',
        isSvgIcon: true,
        tooltip: 'Qualia Company'
      });
    }

    if (entity.company_has_custom_pricing) {
      res.push({
        icon: 'attach_money',
        isSvgIcon: false,
        tooltip: 'Custom Pricing'
      });
    }

    return res;
  }
}
