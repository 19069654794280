import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { OrderContext } from '@contexts/order.context';
import { Order } from '@models/order';

@Component({
  selector: 'app-special-instruction',
  templateUrl: './special-instruction.component.html',
  styleUrls: ['./special-instruction.component.scss']
})
export class SpecialInstructionComponent implements OnInit, OnDestroy {
  @Input() public specialInstructions: string | null = null;

  @ViewChild('divEl')
  set setDivEl(el: ElementRef<HTMLDivElement>) {
    this.divEl = el;
  }

  public showInstruction: boolean = false;

  private divEl: ElementRef<HTMLDivElement> | null = null;
  private readonly destroy$: Subject<void> = new Subject();

  constructor(private context: OrderContext) { }

  ngOnInit(): void {
    this.context.order$
      .pipe(
        takeUntil(this.destroy$),
        tap((order: Order | null): void => {
          this.showInstruction = false;
          this.specialInstructions = this.specialInstructions || (order && order.company_special_instructions);
        }),
        filter((): boolean => !!this.specialInstructions),
        tap((): boolean => this.showInstruction = true)
      )
      .subscribe((): number =>
        setTimeout((): string => this.divEl.nativeElement.innerHTML = this.specialInstructions, 0)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
