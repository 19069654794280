import { Component, forwardRef, Injector } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {
  UserChipsAutocompleteComponent
} from '@components/chips-autocomplete/user-chips-autocomplete/user-chips-autocomplete.component';
import { EmailApi } from '@api/email.api';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { User } from '@models/user';
import { AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';
import { CompanyEmail } from '@models/email';

@Component({
  selector: 'app-accountant-chips-autocomplete',
  templateUrl: './accountant-chips-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => AccountantChipsAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AccountantChipsAutocompleteComponent,
      multi: true
    }
  ]
})
export class AccountantChipsAutocompleteComponent extends UserChipsAutocompleteComponent {
  constructor(injector: Injector, private emailApi: EmailApi) {
    super(injector);
  }

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return forkJoin(
      this.emailApi.getEmails(this.companyId),
      this.api.searchForUsersByCompanyId(this.companyId, {keyword: query}),
      this.api.searchForUsersByRole('accountant')
    ).pipe(
      tap((res: Array<Array<CompanyEmail | AutocompleteValue>>): void => this.setAllItems(res, query)),
      map((): Array<AutocompleteValue> => this.composeAutocompleteValues()),
      catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
    );
  }

  private setAllItems(res: Array<Array<CompanyEmail | AutocompleteValue>>, query: string): void {
    const allUsers: Array<User> = this.service
      .createPayableUsers(<Array<CompanyEmail>>res[0])
      .concat(
        <Array<Extract<AutocompleteValue, User>>>res[1],
        this.service.createAccountants(<Array<Extract<AutocompleteValue, User>>>res[2])
      );

    this.allItems = allUsers.filter((user: User) => user.displayName.toLowerCase().includes(query));
  }
}
