<div class="eos-menu-container">
  <div
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    (mouseenter)="onMouseEnter(menuTrigger)"
    (mouseleave)="onMouseLeave(menuTrigger)">
    <button type="button" aria-label="Menu toggle" mat-icon-button>
      <mat-icon matListIcon>{{item.icon}}</mat-icon>
    </button>
  </div>

  <mat-menu class="eos-menu {{clazz}}" #menu="matMenu" [hasBackdrop]="false" [overlapTrigger]="false">
    <div (mouseenter)="onMouseEnter(menuTrigger)" (mouseleave)="onMouseLeave(menuTrigger)">
      <a
        *ngFor="let child of (useAuthPipe ? (item.children | authNavigation) : item.children)"
        [routerLink]="child.routerLink"
        mat-menu-item>
        <mat-icon>circle</mat-icon>
        {{child.label}}
      </a>
    </div>
  </mat-menu>
</div>
