<div class="eos-table-reactive-order-renderer-container">
  <div *ngIf="form" [formGroup]="form">
    <app-order-autocomplete
      class="eos-table-reactive-order-renderer"
      ariaLabel="Order"
      [isRequired]="isRequired"
      [formControlName]="key"
      [needValidation]="needValidation"
      (valueChange)="onValueChanged($event)">
    </app-order-autocomplete>
  </div>
</div>
