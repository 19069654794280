import { Injectable } from '@angular/core';

import { TimeService } from '@services/time.service';
import { Task, TaskList, TaskListItem, TaskLists } from '@models/task';
import { SelectItem } from '@models/select';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  constructor(private timeService: TimeService) { }

  public extractNote(note: string): string {
    return note.replace(/<[^>]+>.+?<[^>]+>\s*\-+\s*/gm, '');
  }

  public createNote(task: Task, note: string): string {
    return `<strong>${task.name} ${task.next_stage_name} on ${this.timeService.getTodayDateTime()}</strong> - ${note}`;
  }

  public getListItems(lists: TaskLists, withNoTaskItem: boolean = true, needDisable: boolean = true): Array<SelectItem> {
    const res: Array<SelectItem> = Object.values(lists).map((list: TaskList) => {
      return {
        id: list.id,
        value: list.id,
        viewValue: list.name,
        disabled: needDisable && !list.active
      };
    });

    /** Need to hard code 'No Task List' item id and value **/
    if (!withNoTaskItem) {
      res.unshift({
        id: 999,
        value: 999,
        viewValue: 'No Task List'
      });
    }

    return res;
  }

  public getListItemById(items: Array<SelectItem>, id: number): SelectItem {
    return items.find((item: SelectItem) => item.id === id);
  }

  public getTypeItems(types: Array<TaskListItem>): Array<string> {
    return types.map((type: TaskListItem) => type.name);
  }

  public getTypeIdByName(name: string, types: Array<TaskListItem>): number {
    return types.find((item: TaskListItem) => item.name === name).id;
  }
}
