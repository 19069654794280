import { Component, inject } from '@angular/core';

import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { NotificationData } from '@models/notification';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent<Notification> {
  public data: NotificationData;

  private snackRef: MatSnackBarRef<Notification>;

  constructor() {
    this.data = inject(MAT_SNACK_BAR_DATA);
    this.snackRef = inject(MatSnackBarRef);
  }

  public dismiss(): void {
    this.snackRef.dismiss();
  }
}
