import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';

import { AgGridModule } from 'ag-grid-angular';

import { QuillModule } from 'ngx-quill';

import { NgChartsModule } from 'ng2-charts';

import { NgxMaskModule } from 'ngx-mask';

import { AutocompleteComponent } from '@components/autocomplete/autocomplete.component';
import { CompanyAutocompleteComponent } from '@components/autocomplete/company-autocomplete/company-autocomplete.component';
import { UserAutocompleteComponent } from '@components/autocomplete/user-autocomplete/user-autocomplete.component';
import { OrderAutocompleteComponent } from '@components/autocomplete/order-autocomplete/order-autocomplete.component';
import { MarketerAutocompleteComponent } from '@components/autocomplete/marketer-autocomplete/marketer-autocomplete.component';
import { AssigneeAutocompleteComponent } from '@components/autocomplete/assignee-autocomplete/assignee-autocomplete.component';
import { QualityAssuranceAutocompleteComponent } from '@components/autocomplete/quality-assurance-autocomplete/quality-assurance-autocomplete.component';
import { EmailTemplateAutocompleteComponent } from '@components/autocomplete/email-template-autocomplete/email-template-autocomplete.component';
import { AddressAutocompleteComponent } from '@components/autocomplete/address-autocomplete/address-autocomplete.component';
import { AccountantAutocompleteComponent } from '@components/autocomplete/accountant-autocomplete/accountant-autocomplete.component';
import { ChipsAutocompleteComponent } from '@components/chips-autocomplete/chips-autocomplete.component';
import { AssigneeChipsAutocompleteComponent } from '@components/chips-autocomplete/assignee-chips-autocomplete/assignee-chips-autocomplete.component';
import { FlagChipsAutocompleteComponent } from '@components/chips-autocomplete/flag-chips-autocomplete/flag-chips-autocomplete.component';
import { ProductChipsAutocompleteComponent } from '@components/chips-autocomplete/product-chips-autocomplete/product-chips-autocomplete.component';
import { UserChipsAutocompleteComponent } from '@components/chips-autocomplete/user-chips-autocomplete/user-chips-autocomplete.component';
import { QualityAssuranceChipsAutocompleteComponent } from '@components/chips-autocomplete/quality-assurance-chips-autocomplete/quality-assurance-chips-autocomplete.component';
import { EmailTemplateChipsAutocompleteComponent } from '@components/chips-autocomplete/email-template-chips-autocomplete/email-template-chips-autocomplete.component';
import { OrderNotificationAuthorChipsAutocompleteComponent } from '@components/chips-autocomplete/order-notification-author-chips-autocomplete/order-notification-author-chips-autocomplete.component';
import { AccountantChipsAutocompleteComponent } from '@components/chips-autocomplete/accountant-chips-autocomplete/accountant-chips-autocomplete.component';
import { ChipsInputComponent } from '@components/chips-input/chips-input.component';
import { NotificationComponent } from '@components/notifications/notification.component';
import { SuccessNotificationComponent } from '@components/notifications/success-notification/success-notification.component';
import { ErrorNotificationComponent } from '@components/notifications/error-notification/error-notification.component';
import { MenuComponent } from '@components/menu/menu.component';
import { TableComponent } from '@components/table/table.component';
import { SelectEditorComponent } from '@components/table/cell-editors/select-editor/select-editor.component';
import { SelectRendererComponent } from '@components/table/cell-renderers/select-renderer/select-renderer.component';
import { DropdownRendererComponent } from '@components/table/cell-renderers/dropdown-renderer/dropdown-renderer.component';
import { LinkRendererComponent } from '@components/table/cell-renderers/link-renderer/link-renderer.component';
import { ActionRendererComponent } from '@components/table/cell-renderers/action-renderer/action-renderer.component';
import { TotalPinnedRowRendererComponent } from '@components/table/cell-renderers/total-pinned-row-renderer/total-pinned-row-renderer.component';
import { AssigneeRendererComponent } from '@components/table/cell-renderers/assignee-renderer/assignee-renderer.component';
import { NoteDetailRendererComponent } from '@components/table/cell-renderers/note-detail-renderer/note-detail-renderer.component';
import { ReactiveInputRendererComponent } from '@components/table/cell-renderers/reactive-input-renderer/reactive-input-renderer.component';
import { ReactiveOrderRendererComponent } from '@components/table/cell-renderers/reactive-order-renderer/reactive-order-renderer.component';
import { ReactiveSelectRendererComponent } from '@components/table/cell-renderers/select-renderer/reactive-select-renderer/reactive-select-renderer.component';
import { FlagRendererComponent } from '@components/table/cell-renderers/flag-renderer/flag-renderer.component';
import { ChipsInputRendererComponent } from '@components/table/cell-renderers/chips-input-renderer/chips-input-renderer.component';
import { IconRendererComponent } from '@components/table/cell-renderers/icon-renderer/icon-renderer.component';
import { PaginationComponent } from '@components/table/status-bar-panels/pagination/pagination.component';
import { SearchBoxComponent } from '@components/search-box/search-box.component';
import { DateRangeComponent } from '@components/date-range/date-range.component';
import { ArrayGroupComponent } from '@components/array-group/array-group.component';
import { CheckboxGroupComponent } from '@components/checkbox-group/checkbox-group.component';
import { RadioGroupComponent } from '@components/radio-group/radio-group.component';
import { SelectComponent } from '@components/select/select.component';
import { ReactiveSelectComponent } from '@components/select/reactive-select/reactive-select.component';
import { DragDropComponent } from '@components/drag-drop/drag-drop.component';
import { DialogComponent } from '@components/dialog/dialog.component';
import { NoteDialogComponent } from '@components/dialog/note-dialog/note-dialog.component';
import { RefundDialogComponent } from '@components/dialog/refund-dialog/refund-dialog.component';
import { TransferDialogComponent } from '@components/dialog/transfer-dialog/transfer-dialog.component';
import { ReturnTransferDialogComponent } from '@components/dialog/return-transfer-dialog/return-transfer-dialog.component';
import { ConfirmationDialogComponent } from '@components/dialog/confirmation-dialog/confirmation-dialog.component';
import { WarningDialogComponent } from '@components/dialog/warning-dialog/warning-dialog.component';
import { EmailDialogComponent } from '@components/dialog/email-dialog/email-dialog.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { ListComponent } from '@components/list/list.component';
import { FileListComponent } from '@components/file/file-list/file-list.component';
import { TemplateFileListComponent } from '@components/file/template-file-list/template-file-list.component';
import { PanelComponent } from '@components/panel/panel.component';
import { FilePanelComponent } from '@components/file/file-panel/file-panel.component';
import { TemplateFilePanelComponent } from '@components/file/template-file-panel/template-file-panel.component';
import { EditorComponent } from '@components/editor/editor.component';
import { SystemNotificationComponent } from '@components/system-notification/system-notification.component';
import { SpecialInstructionComponent } from '@components/special-instruction/special-instruction.component';
import { IntegrationInformationPanelComponent } from '@components/panel/integration-information-panel/integration-information-panel.component';

import { DndDirective } from '@directives/dnd.directive';
import { AutofocusDirective } from '@directives/autofocus.directive';

import { CompanyViewValuePipe } from '@pipes/company-view-value.pipe';
import { FlagIconPipe } from '@pipes/flag-icon.pipe';
import { FlagClassPipe } from '@pipes/flag-class.pipe';
import { FlagDisabledPipe } from '@pipes/flag-disabled.pipe';
import { FlagTooltipPipe } from '@pipes/flag-tooltip.pipe';
import { QualityAssuranceViewValuePipe } from '@pipes/quality-assurance-view-value.pipe';
import { OrderViewValuePipe } from '@pipes/order-view-value.pipe';
import { AuthNavigationPipe } from '@pipes/auth-navigation.pipe';
import { AuthHeaderMenuPipe } from '@pipes/auth-header-menu.pipe';
import { PathNavigationPipe } from '@pipes/path-navigation.pipe';

const moment = _rollupMoment || _moment;

@NgModule({
  declarations: [
    AutocompleteComponent,
    CompanyAutocompleteComponent,
    UserAutocompleteComponent,
    OrderAutocompleteComponent,
    MarketerAutocompleteComponent,
    AssigneeAutocompleteComponent,
    QualityAssuranceAutocompleteComponent,
    EmailTemplateAutocompleteComponent,
    AddressAutocompleteComponent,
    AccountantAutocompleteComponent,
    ChipsAutocompleteComponent,
    AssigneeChipsAutocompleteComponent,
    FlagChipsAutocompleteComponent,
    ProductChipsAutocompleteComponent,
    UserChipsAutocompleteComponent,
    QualityAssuranceChipsAutocompleteComponent,
    EmailTemplateChipsAutocompleteComponent,
    OrderNotificationAuthorChipsAutocompleteComponent,
    AccountantChipsAutocompleteComponent,
    ChipsInputComponent,
    NotificationComponent,
    SuccessNotificationComponent,
    ErrorNotificationComponent,
    MenuComponent,
    TableComponent,
    SelectEditorComponent,
    SelectRendererComponent,
    DropdownRendererComponent,
    LinkRendererComponent,
    ActionRendererComponent,
    TotalPinnedRowRendererComponent,
    AssigneeRendererComponent,
    NoteDetailRendererComponent,
    ReactiveInputRendererComponent,
    ReactiveOrderRendererComponent,
    ReactiveSelectRendererComponent,
    FlagRendererComponent,
    ChipsInputRendererComponent,
    IconRendererComponent,
    PaginationComponent,
    SearchBoxComponent,
    DateRangeComponent,
    ArrayGroupComponent,
    CheckboxGroupComponent,
    RadioGroupComponent,
    SelectComponent,
    ReactiveSelectComponent,
    DragDropComponent,
    DialogComponent,
    NoteDialogComponent,
    RefundDialogComponent,
    TransferDialogComponent,
    ReturnTransferDialogComponent,
    ConfirmationDialogComponent,
    WarningDialogComponent,
    EmailDialogComponent,
    SpinnerComponent,
    ListComponent,
    FileListComponent,
    TemplateFileListComponent,
    PanelComponent,
    FilePanelComponent,
    TemplateFilePanelComponent,
    EditorComponent,
    SystemNotificationComponent,
    SpecialInstructionComponent,
    IntegrationInformationPanelComponent,
    DndDirective,
    AutofocusDirective,
    CompanyViewValuePipe,
    FlagIconPipe,
    FlagClassPipe,
    FlagDisabledPipe,
    FlagTooltipPipe,
    QualityAssuranceViewValuePipe,
    OrderViewValuePipe,
    AuthNavigationPipe,
    AuthHeaderMenuPipe,
    PathNavigationPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatSnackBarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatChipsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSlideToggleModule,
    AgGridModule,
    QuillModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  providers: [
    CompanyViewValuePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    }
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatMenuModule,
    MatExpansionModule,
    MatSelectModule,
    MatChipsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTooltipModule,
    MatSlideToggleModule,
    AgGridModule,
    NgChartsModule,
    NgxMaskModule,
    AutocompleteComponent,
    CompanyAutocompleteComponent,
    UserAutocompleteComponent,
    OrderAutocompleteComponent,
    MarketerAutocompleteComponent,
    AssigneeAutocompleteComponent,
    QualityAssuranceAutocompleteComponent,
    EmailTemplateAutocompleteComponent,
    AddressAutocompleteComponent,
    AccountantAutocompleteComponent,
    ChipsAutocompleteComponent,
    AssigneeChipsAutocompleteComponent,
    FlagChipsAutocompleteComponent,
    ProductChipsAutocompleteComponent,
    UserChipsAutocompleteComponent,
    QualityAssuranceChipsAutocompleteComponent,
    EmailTemplateChipsAutocompleteComponent,
    OrderNotificationAuthorChipsAutocompleteComponent,
    AccountantChipsAutocompleteComponent,
    ChipsInputComponent,
    SuccessNotificationComponent,
    ErrorNotificationComponent,
    MenuComponent,
    TableComponent,
    SelectEditorComponent,
    SelectRendererComponent,
    DropdownRendererComponent,
    LinkRendererComponent,
    TotalPinnedRowRendererComponent,
    AssigneeRendererComponent,
    NoteDetailRendererComponent,
    ReactiveInputRendererComponent,
    ReactiveOrderRendererComponent,
    ReactiveSelectRendererComponent,
    FlagRendererComponent,
    ChipsInputRendererComponent,
    IconRendererComponent,
    DateRangeComponent,
    SearchBoxComponent,
    ArrayGroupComponent,
    CheckboxGroupComponent,
    RadioGroupComponent,
    SelectComponent,
    ReactiveSelectComponent,
    DragDropComponent,
    DialogComponent,
    NoteDialogComponent,
    RefundDialogComponent,
    TransferDialogComponent,
    ReturnTransferDialogComponent,
    EmailDialogComponent,
    SpinnerComponent,
    ListComponent,
    FileListComponent,
    TemplateFileListComponent,
    PanelComponent,
    FilePanelComponent,
    TemplateFilePanelComponent,
    EditorComponent,
    SystemNotificationComponent,
    SpecialInstructionComponent,
    IntegrationInformationPanelComponent,
    AutofocusDirective,
    FlagIconPipe,
    FlagClassPipe,
    FlagDisabledPipe,
    FlagTooltipPipe,
    OrderViewValuePipe,
    AuthNavigationPipe,
    AuthHeaderMenuPipe,
    PathNavigationPipe,
    QualityAssuranceViewValuePipe
  ]
})
export class SharedModule { }
