import { Component, Injector } from '@angular/core';

import { MatSelectChange } from '@angular/material/select';

import { ICellEditorParams } from 'ag-grid-enterprise';
import { RowNode } from 'ag-grid-community';

import { SelectEditorComponent } from '@components/table/cell-editors/select-editor/select-editor.component';
import { OrderService } from '@services/order.service';
import { ORDER_STATUSES_SELECT_ITEMS } from '@configs/select';

@Component({
  selector: 'app-status-editor',
  templateUrl: './status-editor.component.html',
  styleUrls: ['./status-editor.component.scss']
})
export class StatusEditorComponent extends SelectEditorComponent {
  constructor(injector: Injector, private orderService: OrderService) {
    super(injector);

    this.items = ORDER_STATUSES_SELECT_ITEMS;
    this.classList += 'eos-table-status-editor-container eos-control-wrapper-p-0';
    this.panelClassList += 'eos-table-status-editor-panel';
  }

  public override onSelectionChanged(event: MatSelectChange): void {
    const node: RowNode = this.params?.node || <RowNode>{ },
          nodeClone: RowNode = <RowNode>{
            ...node,
            data: {...node.data}
          };

    this.selected = this.orderService.getStatusItemByValue(this.items, event.value);

    this.service.rendererAction$.next({
      type: 'status-change',
      item: this.selected,
      rowData: nodeClone.data,
      node: nodeClone
    });

    this.params.api.stopEditing();
  }

  public override agInit(params: ICellEditorParams): void {
    this.params = params;
    this.selected = params.value ? this.orderService.getStatusItemByValue(this.items, params.value) : null;
  }
}
