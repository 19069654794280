<div class="eos-chips-input-container">
  <mat-form-field [ngClass]="classList">

    <mat-label>{{label}}</mat-label>

    <mat-chip-list aria-label="Item selection" #chipList>
      <mat-chip *ngFor="let item of items" (removed)="onRemoveBtnClick(item)">
        {{item}}
        <button type="button" aria-label="Remove chip" matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        type="text"
        [placeholder]="placeholder"
        [attr.aria-label]="ariaLabel"
        [formControl]="inputControl"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="onAddKeywordFromInput($event)">
    </mat-chip-list>

    <mat-hint>Please press Enter to add a new value</mat-hint>

  </mat-form-field>
</div>
