import { Component, Injector } from '@angular/core';

import { FilePanelComponent } from '@components/file/file-panel/file-panel.component';
import { EmailApi } from '@api/email.api';
import { EmailService } from '@services/email.service';
import { AuthService } from '@services/auth.service';
import { FILE_EXTENSION_PDF } from '@configs/file-extensions';
import { FILE_PANEL_ACTIONS, PANEL_TYPES } from '@configs/panel';
import { USER_ROLES } from '@configs/role-matrix';
import { PanelAction } from '@models/panel';

@Component({
  selector: 'app-template-file-panel',
  templateUrl: './template-file-panel.component.html'
})
export class TemplateFilePanelComponent extends FilePanelComponent<EmailApi, EmailService> {
  public roles: Array<string> = [USER_ROLES.ADMIN];
  public override validFileExtensions: Array<string> = FILE_EXTENSION_PDF;
  public override panelActions: Array<PanelAction>;

  constructor(injector: Injector, private authService: AuthService) {
    super(injector);

    this.setPanelActions();
  }

  public override onFileDropped(fileList: FileList): void {
    if (!this.authService.isAdmin()) { return; }

    super.onFileDropped(fileList);
  }

  private setPanelActions(): void {
    this.panelActions = this.authService.filterPanelActions(PANEL_TYPES.TEMPLATE_FILE, FILE_PANEL_ACTIONS);
  }
}
