<div class="eos-date-range-container">
  <mat-form-field>
    <mat-label>{{label}}</mat-label>
    <mat-date-range-input
      [formGroupName]="controlName"
      [rangePicker]="picker"
      (focus)="picker.open()"
      (click)="picker.open()">
      <input placeholder="Start date" matStartDate formControlName="start">
      <input placeholder="End date" matEndDate formControlName="end">
    </mat-date-range-input>
    <mat-datepicker-toggle [for]="picker" [aria-label]="ariaLabel" matSuffix></mat-datepicker-toggle>
    <mat-date-range-picker [touchUi]="mediaService.mobileQuery.matches" #picker></mat-date-range-picker>
  </mat-form-field>
</div>
