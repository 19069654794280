<div class="eos-table-icon-renderer-container">
  <ng-container *ngTemplateOutlet="customParams.length ? iconList : empty"></ng-container>
</div>

<!--Empty template-->
<ng-template #empty>
  <span>---</span>
</ng-template>

<!--Icon list template-->
<ng-template #iconList>
  <span *ngFor="let item of customParams">
    <ng-container *ngTemplateOutlet="item.isSvgIcon ? svgIcon : icon; context: {item: item}"></ng-container>
  </span>
</ng-template>

<!--Icon template-->
<ng-template #icon let-item="item">
  <mat-icon [matTooltip]="item.tooltip">{{item.icon}}</mat-icon>
</ng-template>

<!--Svg icon template-->
<ng-template #svgIcon let-item="item">
  <mat-icon slot="icon" [svgIcon]="item.icon" [matTooltip]="item.tooltip"></mat-icon>
</ng-template>
