import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, switchMap, throwError } from 'rxjs';
import { catchError, filter, finalize, map, take } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';

import {
  ErrorNotificationComponent
} from '@shared/components/notifications/error-notification/error-notification.component';

import { AuthApi } from '@api/auth.api';
import { AuthService } from '@services/auth.service';
import { SpinnerService } from '@services/spinner.service';
import { ERROR_NOTIFICATION_CONFIG } from '@configs/notification';
import { Response, ResponseError } from '@models/response';
import { AuthTokens } from '@models/auth';

@Injectable()
export class HandlersInterceptor implements HttpInterceptor {
  private isAccessTokenRefreshing: boolean = false;

  constructor(
    private router: Router,
    private notificationService: MatSnackBar,
    private api: AuthApi,
    private service: AuthService,
    private spinnerService: SpinnerService
  ) { }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): any {
    const silentRequests: Array<string> = ['batch-draft'],
          needShowSpinner: boolean = !silentRequests.some((value: string) => request.url.includes(value));

    if (needShowSpinner) {
      this.spinnerService.show();
    }

    return next.handle(request).pipe(
      map(this.onGetResponse),
      catchError((response: HttpErrorResponse) =>
        this.needRefreshToken(response)
          ? this.refreshAccessToken(request, next)
          : this.onError(response)
      ),
      finalize(() => this.spinnerService.hide())
    );
  }

  private onGetResponse(httpEvent: HttpEvent<unknown>): HttpEvent<unknown> {
    let payload: string | Object | Array<unknown>,
        body: string | Array<unknown> | Response<unknown>,
        event: HttpEvent<unknown> = httpEvent;

    if (event instanceof HttpResponse) {
      body = typeof event.body === 'string'
        ? {success: true, error: null, data: event.body, version: 1}
        : {...event.body as Object} as Response<unknown>;

      if (body.success) {
        payload = body.data as string | Object | Array<unknown>;

        if (payload) {

          if (typeof payload === 'string') {
            body = payload;
          } else if (Array.isArray(payload)) {
            body = [...payload];
          } else {
            body = {...payload as Object} as Response<unknown>
          }
        }

        event = event.clone({body});
      } else if (!body.success && body.error) {
        throw new HttpErrorResponse({
          error: body.error,
          headers: event.headers,
          status: 490,
          statusText: 'Custom EOS server error',
          url: event.url as string
        });
      }
    }

    return event;
  }

  private onError(response: HttpErrorResponse): Observable<never> {
    const {status, statusText, error} = response,
          {exception, fields} = error || { };
    let errorMessage: string = '';

    switch (status) {
      case 490:
      case 400:
        if (exception) {
          for (const msg of Object.values(exception)) {
            errorMessage += msg;
          }
        } else if (fields) {
          return throwError(() => error);
        }

        break;
      case 401:
      case 403:
        return this.unauthorised(error);
      default:
        errorMessage = statusText;
        break;
    }

    this.notificationService.openFromComponent(
      ErrorNotificationComponent,
      {
        ...ERROR_NOTIFICATION_CONFIG,
        data: {message: errorMessage}
      });

    return throwError(() => errorMessage);
  }

  private unauthorised(error: ResponseError): Observable<never> {
    this.service.removeAuthDetails();
    this.router.navigate(['/login']);

    return throwError(() => error);
  }

  private needRefreshToken(response: HttpErrorResponse): boolean {
    return response instanceof HttpErrorResponse
      && response.status === 401
      && this.service.isLoggedIn();
  }

  private getRequestWithAccessToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.service.addAccessTokenToRequest(request)).pipe(
      map(this.onGetResponse)
    );
  }

  private makeRefreshAccessTokenRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.isAccessTokenRefreshing = true;
    this.service.hasValidAccessToken$.next(false);

    return this.api.refreshAccessToken(this.service.tokens).pipe(
      switchMap((tokens: AuthTokens) => {
        this.service.tokens = tokens;

        this.service.hasValidAccessToken$.next(true);

        return this.getRequestWithAccessToken(request, next);
      }),
      catchError((response: HttpErrorResponse) => {
        this.service.hasValidAccessToken$.complete();

        return this.unauthorised(response?.error);
      }),
      finalize(() => this.isAccessTokenRefreshing = false),
    );
  }

  private refreshTokenRequestAlreadySent(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.service.hasValidAccessToken$.pipe(
      filter<boolean>(Boolean),
      take(1),
      switchMap(() => this.getRequestWithAccessToken(request, next))
    );
  }

  private refreshAccessToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return !this.isAccessTokenRefreshing
      ? this.makeRefreshAccessTokenRequest(request, next)
      : this.refreshTokenRequestAlreadySent(request, next);
  }
}
