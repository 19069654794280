import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseApi } from '@api/base.api';
import { QueryParams, UserApiEndPoints } from '@models/api';
import { Payload } from '@models/response';
import {
  Marketer,
  MarketerFormValue,
  QualityAssurance,
  QualityAssuranceAssignment,
  Researcher,
  ReswareCredentials,
  User,
  UserFormValue
} from '@models/user';
import { TableData } from '@models/table';
import { Pricing, PricingFormValue } from '@models/pricing';
import { Product } from '@models/product';
import { Discount, RawDiscountFormValue } from '@models/discount';
import { Audit } from '@models/audit';
import { ProfileSmtpSettingsFormValue } from '@models/profile';

@Injectable({
  providedIn: 'root'
})
export class UserApi extends BaseApi<UserApiEndPoints> {
  constructor(injector: Injector) {
    super(injector);

    this.endPoints = this.addBaseUrl({
      GET_USER: '/users/all/${id}',
      GET_USERS: '/users/all',
      GET_USERS_BY_COMPANY_ID: '/users/company/${companyId}',
      GET_INACTIVE_USERS: '/users/to-activate',
      GET_SEARCH_USERS_BY_ROLE: '/user_search/role/${role}',
      GET_SEARCH_USERS_BY_COMPANY_ID: '/user_search/company/${companyId}',
      GET_RESEARCHERS: '/researchers',
      GET_MARKETERS_BY_ORDER_ID: '/marketers/${orderId}',
      GET_PRICES: '/user/product/prices/${id}',
      GET_PRODUCTS: '/user/products/available/${id}',
      GET_AUDIT: '/user/audits/${id}',
      GET_PRICING_AUDIT: '/user/product/audits/${id}',
      GET_QA: '/qc/list_pag',
      GET_SEARCH_FOR_QA: '/qc/search',
      GET_DISCOUNTS: '/order-settings/ux-discount',
      /**
       * Customer requirement. Users should have an additional panel.
       * The panel contains information about internal user credentials.
       * Need for integration EOS with Resware software.
       * **/
      GET_RESWARE_USER_CREDENTIALS: '/user/resware_creed/${id}',
      POST_CREATE_USER: '/user',
      POST_CREATE_PRICING: '/user/product/edit/${id}',
      POST_CREATE_UPDATE_DISCOUNT: '/order-settings/ux-discount/edit',
      POST_UPDATE_MARKETERS_BY_ORDER_ID: '/marketers/${orderId}',
      POST_UPDATE_QA: '/qc/edit',
      POST_UPLOAD_PROFILE_AVATAR: '/profile-pic/upload-photo/${userId}',
      /**
       * Customer requirement. Users should have an additional panel.
       * The panel contains information about internal user credentials.
       * Need for integration EOS with Resware software.
       * **/
      POST_UPDATE_RESWARE_USER_CREDENTIALS: '/user/resware_creed/${id}',
      PUT_UPDATE_USER: '/user/${id}',
      PUT_UPDATE_PRICING: '/user/product/edit/${id}/${productId}',
      PUT_UPDATE_PROFILE_SMTP_SETTINGS: '/profile'
    });
  }

  public getUser(id: string | number): Observable<User> {
    const url: string = this.substituteParameters(this.endPoints.GET_USER, {id});
    return this.http
      .get<Payload<User>>(url)
      .pipe(
        map((res: Payload<User>) => res.item)
      );
  }

  public getUsers(params?: QueryParams): Observable<TableData<User>> {
    return this.http.get<TableData<User>>(this.endPoints.GET_USERS, {params: this.setQueryParameters(params)});
  }

  public getUsersByCompanyId(companyId: string, params?: QueryParams): Observable<TableData<User>> {
    const url: string = this.substituteParameters(this.endPoints.GET_USERS_BY_COMPANY_ID, {companyId});
    return this.http.get<TableData<User>>(url, {params: this.setQueryParameters(params)});
  }

  public getInactiveUsers(params?: QueryParams): Observable<TableData<User>> {
    return this.http.get<TableData<User>>(
      this.endPoints.GET_INACTIVE_USERS,
      {params: this.setQueryParameters(params)}
    );
  }

  public searchForUsersByRole(role: string, params?: QueryParams): Observable<Array<User | Marketer | Researcher | QualityAssurance>> {
    const url: string = this.substituteParameters(this.endPoints.GET_SEARCH_USERS_BY_ROLE, {role});
    return this.http
      .get<Payload<Array<User | Marketer | Researcher>>>(url, {params: this.setQueryParameters(params)})
      .pipe(
        map((res: Payload<Array<User | Marketer | Researcher>>) => res.items)
      );
  }

  public searchForUsersByCompanyId(companyId: string, params?: QueryParams): Observable<Array<User>> {
    const url: string = this.substituteParameters(this.endPoints.GET_SEARCH_USERS_BY_COMPANY_ID, {companyId});
    return this.http
      .get<Payload<Array<User>>>(url, {params: this.setQueryParameters(params)})
      .pipe(
        map((res: Payload<Array<User>>) => res.items)
      );
  }

  public getCSV(params?: QueryParams): Observable<string> {
    return this.http.get<string>(
      this.endPoints.GET_USERS,
      {responseType: <'json'>'text', params: this.setQueryParameters(params)}
    );
  }

  public getUsersByCompanyIdCSV(companyId: string, params?: QueryParams): Observable<string> {
    const url: string = this.substituteParameters(this.endPoints.GET_USERS_BY_COMPANY_ID, {companyId});
    return this.http.get<string>(
      url,
      {responseType: <'json'>'text', params: this.setQueryParameters(params)}
    );
  }

  public getResearchers(): Observable<Array<Researcher>> {
    return this.http
      .get<Payload<Array<Researcher>>>(this.endPoints.GET_RESEARCHERS)
      .pipe(
        map((res: Payload<Array<Researcher>>) => res.researchers)
      );
  }

  public getMarketersByOrderId(orderId: string, params?: QueryParams): Observable<Array<Marketer>> {
    const url: string = this.substituteParameters(this.endPoints.GET_MARKETERS_BY_ORDER_ID, {orderId});
    return this.http
      .get<Payload<Array<Marketer>>>(url, {params: this.setQueryParameters(params)})
      .pipe(
        map((res: Payload<Array<Marketer>>) => res.items)
      );
  }

  public getPrices(id: string, params?: QueryParams): Observable<TableData<Pricing>> {
    const url: string = this.substituteParameters(this.endPoints.GET_PRICES, {id});
    return this.http.get<TableData<Pricing>>(url, {params: this.setQueryParameters(params)});
  }

  public getProducts(id: string): Observable<Array<Product>> {
    const url: string = this.substituteParameters(this.endPoints.GET_PRODUCTS, {id});
    return this.http
      .get<Payload<Array<Product>>>(url)
      .pipe(
        map((res: Payload<Array<Product>>) => res.items)
      );
  }

  public getAudit(id: string, params: QueryParams = {}, isPricing = false): Observable<TableData<Audit>> {
    const endPoint: string = isPricing ? this.endPoints.GET_PRICING_AUDIT : this.endPoints.GET_AUDIT,
      url: string = this.substituteParameters(endPoint, {id});
    return this.http.get<TableData<Audit>>(url, {params: this.setQueryParameters(params)});
  }

  public getQA(params?: QueryParams): Observable<TableData<QualityAssurance>> {
    return this.http.get<TableData<QualityAssurance>>(
      this.endPoints.GET_QA,
      {params: this.setQueryParameters(params)}
    );
  }

  public searchForQualityAssurance(params?: QueryParams): Observable<Array<QualityAssurance>> {
    return this.http.get<Array<QualityAssurance>>(
      this.endPoints.GET_SEARCH_FOR_QA,
      {params: this.setQueryParameters(params)}
    );
  }

  public getDiscounts(id: string, params?: QueryParams): Observable<TableData<Discount>> {
    params.user_id = id;
    return this.http.get<TableData<Discount>>(
      this.endPoints.GET_DISCOUNTS,
      {params: this.setQueryParameters(params)}
    );
  }

  /**
   * Customer requirement. Users should have an additional panel.
   * The panel contains information about internal user credentials.
   * Need for integration EOS with Resware software.
   * **/
  public getReswareUserCredentials(id: string): Observable<ReswareCredentials> {
    const url: string = this.substituteParameters(this.endPoints.GET_RESWARE_USER_CREDENTIALS, {id});
    return this.http
      .get<Payload<ReswareCredentials>>(url)
      .pipe(
        map((res: Payload<ReswareCredentials>) => res.item)
      );
  }

  public createUser(formValue: UserFormValue): Observable<User> {
    return this.http
      .post<Payload<User>>(this.endPoints.POST_CREATE_USER, formValue)
      .pipe(
        map((res: Payload<User>) => res.item)
      );
  }

  public createPricing(id: string, formValue: PricingFormValue): Observable<Pricing> {
    const url: string = this.substituteParameters(this.endPoints.POST_CREATE_PRICING, {id});
    return this.http
      .post<Payload<Pricing>>(url, formValue)
      .pipe(
        map((res: Payload<Pricing>) => res.item)
      );
  }

  public createUpdateDiscount(id: string, formValue: Discount | RawDiscountFormValue): Observable<Discount> {
    formValue.user_id = id;
    return this.http.post<Discount>(this.endPoints.POST_CREATE_UPDATE_DISCOUNT, formValue);
  }

  public updateMarketersByOrderId(orderId: string, formValue: Array<MarketerFormValue>): Observable<Array<Marketer>> {
    const url: string = this.substituteParameters(this.endPoints.POST_UPDATE_MARKETERS_BY_ORDER_ID, {orderId});
    return this.http
      .post<Payload<Array<Marketer>>>(url, {items: formValue})
      .pipe(
        map((res: Payload<Array<Marketer>>) => res.items)
      );
  }

  public updateQA(formValue: QualityAssuranceAssignment): Observable<void> {
    return this.http.post<void>(this.endPoints.POST_UPDATE_QA, formValue);
  }

  public uploadProfileAvatar(userId: string, file: File): Observable<any> {
    const url: string = this.substituteParameters(this.endPoints.POST_UPLOAD_PROFILE_AVATAR, {userId});
    return this.http
      .post<Payload<any>>(url, this.prepareFormDataBody(null, [file]))
      .pipe(
        map((res: Payload<any>) => res.item)
      );
  }

  /**
   * Customer requirement. Users should have an additional panel.
   * The panel contains information about internal user credentials.
   * Need for integration EOS with Resware software.
   * **/
  public updateReswareUserCredentials(id: string, formValue: ReswareCredentials): Observable<void> {
    const url: string = this.substituteParameters(this.endPoints.POST_UPDATE_RESWARE_USER_CREDENTIALS, {id});
    return this.http.post<void>(url, formValue);
  }

  public updateUser(id: string, formValue: UserFormValue): Observable<User> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_USER, {id});
    return this.http
      .put<Payload<User>>(url, formValue)
      .pipe(
        map((res: Payload<User>) => res.item)
      );
  }

  public updatePricing(id: string, productId: string, formValue: PricingFormValue): Observable<Pricing> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_PRICING, {id, productId});
    return this.http
      .put<Payload<Pricing>>(url, formValue)
      .pipe(
        map((res: Payload<Pricing>) => res.item)
      );
  }

  public updateProfileSmtpSettings(formValue: ProfileSmtpSettingsFormValue): Observable<User> {
    return this.http
      .put<Payload<User>>(this.endPoints.PUT_UPDATE_PROFILE_SMTP_SETTINGS, formValue)
      .pipe(
        map((res: Payload<User>) => res.item)
      );
  }
}
