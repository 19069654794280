<app-dialog [header]="header" cancelBtnLabel="Close">
  <div class="eos-control-width-100" slot="content">

    <app-panel
      *ngIf="showInformationPanel"
      containerClass="mb-20"
      header="information"
      [statuses]="orderStatuses"
      [expanded]="true">
      <div class="p-20" slot="body">
        <app-special-instruction [specialInstructions]="data.specialInstructions"></app-special-instruction>

        <ng-container *ngIf="data.order">
          <div class="eos-email-dialog-order-by">
            <strong class="mr-10">Order By:</strong>
            <a class="eos-link" [routerLink]="['/user-edit/', data.order.user_id]" target="_blank">
              {{data.order.user_display_name}}
            </a>
          </div>

          <div>
            <strong class="mr-10">Client File Number:</strong>
            <span>{{data.order.order_file_number || '---'}}</span>
          </div>
        </ng-container>
      </div>
    </app-panel>

    <form [formGroup]="form" novalidate>
      <app-email-template-autocomplete formControlName="template"></app-email-template-autocomplete>

      <ng-container
        *ngTemplateOutlet="currentUserRole !== userRoles.ACCOUNTANT ? default : accountant; context: {form: form}">
      </ng-container>

      <mat-form-field>
        <mat-label>Subject</mat-label>
        <input
          type="text"
          placeholder="Subject"
          aria-label="Email subject"
          formControlName="subject"
          matInput>
        <mat-hint>The email subject line</mat-hint>
        <mat-error *ngIf="subjectControl.invalid && (subjectControl.dirty || subjectControl.touched)">
          {{getErrorMsg(subjectControl, 'subject')}}
        </mat-error>
      </mat-form-field>

      <app-editor label="Body" controlName="body_html"></app-editor>

      <app-drag-drop
        containerClass="mt-20"
        label="*All files will be attached to the email"
        (fileDropped)="onFileDropped($event)">
      </app-drag-drop>
    </form>

    <div *ngIf="data.withFileList" class="mt-20">
      <app-file-panel
        [api]="orderApi"
        [service]="orderService"
        [id]="data.order.id"
        [isSelectable]="true"
        [expanded]="true">
      </app-file-panel>
    </div>

    <div *ngIf="templateId" class="mt-20">
      <app-template-file-panel
        [api]="api"
        [service]="service"
        [id]="templateId"
        [expanded]="true">
      </app-template-file-panel>
    </div>

  </div>
  <button
    type="button"
    class="eos-btn-primary"
    [disabled]="!form.valid"
    mat-flat-button
    slot="action"
    (click)="onSendBtnClick()">
    Send
  </button>
</app-dialog>

<!--All roles except accountant template-->
<ng-template #default let-form="form">
  <div [formGroup]="form">

    <app-user-autocomplete
      label="From"
      hint="The email address this email will be sent from"
      [companyId]="companyId"
      formControlName="from"
      [isRequired]="true"
      [needValidation]="true"
      [addKeywordFromInput]="true">
    </app-user-autocomplete>

    <app-user-autocomplete
      label="To"
      hint="The email address this email will be sent to"
      [companyId]="companyId"
      formControlName="to"
      [isRequired]="true"
      [needValidation]="true"
      [addKeywordFromInput]="true"
      [includeEmployee]="sendToClientAndEmployee">
    </app-user-autocomplete>

    <app-user-chips-autocomplete
      label="Cc"
      hint="Who will be Cc'd on this email"
      [companyId]="companyId"
      formControlName="cc"
      [addKeywordFromInput]="true">
    </app-user-chips-autocomplete>

    <app-user-chips-autocomplete
      label="Bcc"
      hint="Who will be Bcc'd on this email"
      [companyId]="companyId"
      formControlName="bcc"
      [addKeywordFromInput]="true">
    </app-user-chips-autocomplete>

  </div>
</ng-template>

<!--Accountant role template-->
<ng-template #accountant let-form="form">
  <div [formGroup]="form">

    <app-accountant-autocomplete
      label="From"
      hint="The email address this email will be sent from"
      [companyId]="companyId"
      formControlName="from"
      [isRequired]="true"
      [needValidation]="true"
      [addKeywordFromInput]="true">
    </app-accountant-autocomplete>

    <app-accountant-autocomplete
      label="To"
      hint="The email address this email will be sent to"
      [companyId]="companyId"
      formControlName="to"
      [isRequired]="true"
      [needValidation]="true"
      [addKeywordFromInput]="true">
    </app-accountant-autocomplete>

    <app-accountant-chips-autocomplete
      label="Cc"
      hint="Who will be Cc'd on this email"
      [companyId]="companyId"
      formControlName="cc"
      [addKeywordFromInput]="true">
    </app-accountant-chips-autocomplete>

    <app-accountant-chips-autocomplete
      label="Bcc"
      hint="Who will be Bcc'd on this email"
      [companyId]="companyId"
      formControlName="bcc"
      [addKeywordFromInput]="true">
    </app-accountant-chips-autocomplete>

  </div>
</ng-template>
