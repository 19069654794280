<div mat-dialog-title>
  <h4>{{header}}</h4>
  <ng-content select="[slot=header]"></ng-content>
  <button type="button" aria-label="Close dialog" mat-icon-button [mat-dialog-close]>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <ng-content select="[slot=content]"></ng-content>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" aria-label="Close dialog" mat-button [mat-dialog-close]>{{cancelBtnLabel}}</button>
  <ng-content select="[slot=action]"></ng-content>
</mat-dialog-actions>
