import { Component, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MatSelectChange } from '@angular/material/select';

import {
  CellClickedEvent,
  FirstDataRenderedEvent,
  GridReadyEvent,
  GridSizeChangedEvent,
  RowNode,
  RowClickedEvent,
  RowSelectedEvent,
  SelectionChangedEvent,
  GetRowIdParams,
  PaginationChangedEvent
} from 'ag-grid-community';
import {
  GridApi,
  ColumnApi,
  IServerSideDatasource,
  RowClassParams,
  RowClassRules,
  RefreshCellsParams,
  RowDataTransaction,
  Column
} from 'ag-grid-enterprise';

import { PaginationComponent } from '@components/table/status-bar-panels/pagination/pagination.component';
import { TableService } from '@services/table.service';
import { PAGINATION_PAGE_SIZE } from '@configs/select';
import { TableChangeEvent, TableChangeEventProp, TableConfig, TableRendererActionEvent } from '@models/table';
import { Researcher } from '@models/user';
import { Order } from '@models/order';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  providers: [TableService]
})
export class TableComponent<Entity> implements OnInit, OnDestroy {
  @Input() config: TableConfig = {colDefs: []};
  @Input() serverSideDatasource: IServerSideDatasource | null = null;
  @Input() rowData: Array<Entity> = [];

  @Output() tableReady: Subject<TableComponent<Entity>> = new Subject<TableComponent<Entity>>();
  @Output() tableChanged: Subject<TableChangeEvent> = new Subject<TableChangeEvent>();
  @Output() tableSelectionChanged: Subject<Array<RowNode>> = new Subject<Array<RowNode>>();
  @Output() tableRowClicked: Subject<RowClickedEvent> = new Subject<RowClickedEvent>();
  @Output() tableCellClicked: Subject<CellClickedEvent> = new Subject<CellClickedEvent>();
  @Output() paginationChanged: Subject<PaginationChangedEvent> = new Subject<PaginationChangedEvent>();
  @Output() paginationPageSizeChanged: Subject<MatSelectChange> = new Subject<MatSelectChange>()

  public gridApi!: GridApi;
  public gridColumnApi!: ColumnApi;

  protected selectedRows: Array<RowNode> = [];

  protected rowClassRules: RowClassRules = {};

  protected readonly destroy$: Subject<void> = new Subject();

  protected service: TableService;

  constructor(injector: Injector) {
    this.service = injector.get(TableService);
  }

  ngOnInit(): void {
    this.setUpGrid();

    this.service.rendererAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: TableRendererActionEvent<Entity>): void => this.onRendererAction(event));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public refreshStore(): void {
    this.gridApi.refreshServerSide({purge: false});
  }

  public setRowData(data: Array<Entity>): void {
    this.gridApi.setRowData(data);
  }

  public getRowData(): Array<Entity> {
    const res: Array<Entity> = [];

    this.gridApi.forEachNode((node: RowNode): number => res.push(node.data));

    return res;
  }

  public setRowDataById(rowId: string, data: Entity): void {
    const rowNode: RowNode = this.gridApi.getRowNode(rowId);
    rowNode.setData(data);
  }

  public applyTransaction(transaction: RowDataTransaction): RowDataTransaction {
    return this.gridApi.applyTransaction(transaction);
  }

  public flashCell(rowNode: RowNode, column: string): void {
    this.gridApi.flashCells({rowNodes: [rowNode], columns: [column]});
  }

  public redrawRows(rowsNodes: Array<RowNode>): void {
    this.gridApi.redrawRows({rowNodes: [...rowsNodes]});
  }

  public deselectAll(): void {
    this.gridApi.deselectAll();
  }

  public toggleNoRowsOverlay(state: string): void {
    if (state === 'show') {
      this.gridApi.showNoRowsOverlay();
    } else {
      this.gridApi.hideOverlay();
    }
  }

  public setBottomPinnedRowData(data: Array<{ [key: string]: string | number }>): void {
    this.gridApi.setPinnedBottomRowData(data);
  }

  public refreshCells(params: RefreshCellsParams): void {
    this.gridApi.refreshCells(params);
  }

  public getRowNodes(): Array<RowNode> {
    const res: Array<RowNode> = [];

    this.gridApi.forEachNode((node: RowNode): number => res.push(node));

    return res;
  }

  public setFocusOnTheFirstCell(): void {
    const firstColumn: Column = this.gridColumnApi.getColumns()[0],
          nextCellRenderer: any = this.gridApi.getCellRendererInstances({columns: [firstColumn]})[0];

    setTimeout((): void => {
      if (nextCellRenderer && ('setFocus' in nextCellRenderer)) {
        nextCellRenderer.setFocus();
      }
    }, 50);
  }

  protected onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();

    this.tableReady.next(this);
  }

  protected onFirstDataRendered(event: FirstDataRenderedEvent): void {
    this.gridApi.sizeColumnsToFit();

    if (this.config.expandDetailRow) {
      setTimeout(
        () => this.gridApi.forEachNode((node: RowNode) => node.setExpanded(true)),
        0
      );
    }
  }

  protected onGridSizeChanged(event: GridSizeChangedEvent): void {
    this.gridApi.sizeColumnsToFit();
  }

  // TODO: need to move event's handlers outside base class
  protected onRendererAction(event: TableRendererActionEvent<Entity>): void {
    switch (event.type) {
      case 'dropdown-note-task':
      case 'dropdown-order-task':
      case 'dropdown-receive-task':
      case 'dropdown-skip-task':
      case 'dropdown-edit-note':
      case 'dropdown-delete-note':
      case 'dropdown-restore-note':
      case 'dropdown-edit-pricing':
      case 'dropdown-delete-pricing':
      case 'dropdown-download-file':
      case 'dropdown-delete-file':
      case 'dropdown-restore-file':
      case 'dropdown-refund-payment':
      case 'dropdown-transfer-payment':
      case 'dropdown-return-transfer-payment':
      case 'dropdown-note-payment':
      case 'dropdown-edit-system-flag-assignment':
      case 'dropdown-delete-system-flag-assignment':
      case 'dropdown-view-batch':
      case 'dropdown-csv-batch':
      case 'dropdown-pdf-batch':
      case 'dropdown-deposit-slip-batch':
      case 'dropdown-delete-batch':
      case 'dropdown-delete-batch-with-reset-search-fee':
      case 'order-unselect-batch':
      case 'link-click':
        this.nextTableChange(event, null);
        break;
      case 'status-change-discount':
        this.nextTableChange(event, {is_active: <string>event.item});
        break;
      case 'type-change-file':
        this.nextTableChange(event, {type: <string>event.item});
        break;
      case 'status-change-user':
        this.nextTableChange(event, {active: <string>event.item});
        break;
      case 'administrator-change-user':
        this.nextTableChange(event, {is_company_administrator: <string>event.item});
        break;
      case 'visibility-change-payment':
        this.nextTableChange(event, {internal: <number>event.item});
        break;
      case 'assignee-add':
      case 'assignee-remove':
        this.nextTableChange(event, {researchers: <Array<Researcher>>event.item});
        break;
      case 'order-select-batch':
        this.nextTableChange(event, {order: <Order>event.item});
        break;
      case 'amount-change-batch':
        this.nextTableChange(event, {amount: <string>event.item});
        break;
      case 'discount-change-batch':
        this.nextTableChange(event, {discount: <string>event.item});
        break;
      case 'chips-input-qualia-product-change':
        this.nextTableChange(event, {products: <Array<string>>event.item});
    }
  }

  protected onRowSelected(event: RowSelectedEvent): void {
    const node: RowNode = event.node,
          isSelected: boolean = node.isSelected();
    let index: number;

    if (isSelected) {
      this.selectedRows.push(node);
    } else {
      index = this.selectedRows.findIndex((n: RowNode): boolean => n.id === node.id);
      this.selectedRows.splice(index, 1);
    }

    this.tableSelectionChanged.next(this.selectedRows);
  }

  protected onSelectionChanged(event: SelectionChangedEvent): void { }

  protected onRowClicked(event: RowClickedEvent): void {
    if (window.getSelection().toString() === '') {
      this.tableRowClicked.next(event);
    }
  }

  protected onCellClicked(event: CellClickedEvent): void {
    if (window.getSelection().toString() === '') {
      this.tableCellClicked.next(event);
    }
  }

  protected onPaginationChanged(event: PaginationChangedEvent): void {
    this.paginationChanged.next(event);
  }

  protected onPaginationPageSizeChanged = (event: MatSelectChange): void => {
    this.paginationPageSizeChanged.next(event);
  }

  protected nextTableChange(event: TableRendererActionEvent<Entity>, changedProp: TableChangeEventProp): void {
    this.tableChanged.next({
      id: (<any>event.rowData).id,
      type: event.type,
      changedProp,
      rowNode: event.node
    });
  }

  protected setUpGrid(): void {
    this.inheritConfig();
  }

  protected getRowId(params: GetRowIdParams): string | number {
    const data: any = params.data;
    return data.id || data.epr_id || data.company_id || data.user_id;
  }

  protected getRowClass = (params: RowClassParams): string => {
    return 'eos-table-row';
  };

  private inheritConfig(): void {
    const {
        defaultColDef = { },
        rowModelType,
        autoCellWidth = null,
        pagination = true,
        suppressCellFocus = true,
        animateRows = true,
        suppressColumnMoveAnimation = false,
        domLayout = 'autoHeight',
        debounceVerticalScrollbar = false,
        /** Remove by default 'All' item **/
        paginationPageSizeItems = PAGINATION_PAGE_SIZE.slice(0, PAGINATION_PAGE_SIZE.length - 1),
        paginationPageSizeItem = PAGINATION_PAGE_SIZE[2]
      } = this.config,
      pageSize: number = <number>paginationPageSizeItem.value;

    Object.assign(
      this.config,
      {
        defaultColDef: {
          sortable: true,
          unSortIcon: true,
          filter: false,
          resizable: true,
          flex: 1,
          minWidth: autoCellWidth ? null : defaultColDef?.minWidth || 150,
          ...defaultColDef,
        },
        rowModelType: rowModelType || 'serverSide',
        serverSideDatasource: this.serverSideDatasource,
        serverSideInfiniteScroll: true,
        cacheBlockSize: pageSize,
        paginationPageSize: pageSize,
        getRowId: this.getRowId,
        getRowClass: this.getRowClass,
        animateRows,
        rowHeight: 50,
        rowSelection: 'multiple',
        pagination,
        suppressPaginationPanel: true,
        suppressCellFocus,
        suppressRowClickSelection: true,
        suppressLoadingOverlay: true,
        suppressContextMenu: true,
        suppressColumnMoveAnimation,
        enableCellTextSelection: true,
        stopEditingWhenCellsLoseFocus: false,
        debug: false,
        domLayout,
        debounceVerticalScrollbar,
        statusBar: {
          statusPanels: [{
            statusPanel: PaginationComponent,
            statusPanelParams: {
              pageSizeItems: paginationPageSizeItems,
              pageSizeItem: paginationPageSizeItem,
              onPageSizeChanged: this.onPaginationPageSizeChanged
            },
            align: 'center'
          }]
        },
        gridReady: (event: GridReadyEvent): void => this.onGridReady(event),
        onFirstDataRendered: (event: FirstDataRenderedEvent): void => this.onFirstDataRendered(event),
        onGridSizeChanged: (event: GridSizeChangedEvent): void => this.onGridSizeChanged(event),
        onRowSelected: (event: RowSelectedEvent): void => this.onRowSelected(event),
        onSelectionChanged: (event: SelectionChangedEvent): void => this.onSelectionChanged(event),
        onRowClicked: (event: RowClickedEvent): void => this.onRowClicked(event),
        onCellClicked: (event: CellClickedEvent): void => this.onCellClicked(event),
        onPaginationChanged: (event: PaginationChangedEvent): void => this.onPaginationChanged(event)
      }
    );
  }
}
