import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { distinctUntilChanged, map, scan } from 'rxjs/operators';

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { SpinnerComponent } from '@components/spinner/spinner.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private spinnerTopRef: OverlayRef;
  private spinner$: Subject<number> = new Subject();

  constructor(private overlay: Overlay) {
    this.spinnerTopRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });

    this.spinner$
      .asObservable()
      .pipe(
        scan((acc: number, next: number) => {
          if (!next) { return 0; }
          return (acc + next) >= 0 ? acc + next : 0;
        }, 0),
        map((val: number) => val > 0),
        distinctUntilChanged()
      )
      .subscribe(
        (actionType: boolean) => {
          if (actionType) {
            this.spinnerTopRef.attach(new ComponentPortal(SpinnerComponent));
          } else if (this.spinnerTopRef.hasAttached()) {
            this.spinnerTopRef.detach();
          }
        }
      );
  }

  public show(): void {
    this.spinner$.next(1);
  }

  public hide(): void {
    this.spinner$.next(-1);
  }

  public reset(): void {
    this.spinner$.next(0);
  }
}
