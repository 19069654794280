import {
  AfterViewInit,
  Component,
  DoCheck,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormGroup,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormControl,
  NgControl
} from '@angular/forms';

import { emptyValueValidator } from '@validators/empty-value.validator';
import { ArrayGroupControlConfig } from '@models/array-group';

@Component({
  selector: 'app-array-group',
  templateUrl: './array-group.component.html',
  styleUrls: ['./array-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ArrayGroupComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ArrayGroupComponent,
      multi: true
    }
  ]
})
export class ArrayGroupComponent implements OnChanges, DoCheck, AfterViewInit, ControlValueAccessor, Validator {
  @Input() public legend!: string;
  @Input() public config!: ArrayGroupControlConfig;

  get arrayControl(): UntypedFormArray {
    return <UntypedFormArray>this.form.get('arrayGroup');
  }

  public form: FormGroup;
  public isDisabled: boolean = false;

  private ngControl!: NgControl;
  private touched = false;

  constructor(private injector: Injector, private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      arrayGroup: this.formBuilder.array([null])
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const configChange: SimpleChange = changes.config,
          requiredValidator: ValidatorFn = Validators.required,
          validators: Array<ValidatorFn> = [requiredValidator, emptyValueValidator];
    let isRequired: boolean = false,
        hasValidator: boolean = false;

    if (!configChange.firstChange && configChange.currentValue.required !== configChange.previousValue.required) {
      this.arrayControl.controls.forEach((control: AbstractControl): void => {
        isRequired = this.config.required;
        hasValidator = control.hasValidator(requiredValidator);

        if (isRequired && !hasValidator) {
          control.addValidators(validators);
        } else if (!isRequired && hasValidator) {
          control.removeValidators(validators);
        }

        control.updateValueAndValidity();
      });

      this.onValidationChange();
    }
  }

  ngDoCheck(): void {
    if (this.touched !== this.ngControl?.touched) {
      this.markAsTouched();
    }
  }

  ngAfterViewInit(): void {
    this.ngControl = this.injector.get(NgControl);
  }

  public onChange(_: any): void { };

  public onTouched(): void { };

  public onValidationChange(): void { };

  public onAddBtnClick(): void {
    this.addControl();

    this.onChange(this.form.value.arrayGroup);
  }

  public onDeleteBtnClick(index: number): void {
    this.arrayControl.removeAt(index);

    this.onChange(this.form.value.arrayGroup);
  }

  public onInput(): void {
    this.onChange(this.form.value.arrayGroup);
  }

  public writeValue(values: Array<string> | null): void {
    if (!values) { return; }

    this.arrayControl.clear();

    values.forEach((value: string): void => this.addControl(value));
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.form.markAllAsTouched();
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;

    this.arrayControl.controls.forEach((control: FormControl): void => {
      if (isDisabled) {
        control.disable();
      } else {
        control.enable();
      }
    });
  }

  public validate(): ValidationErrors | null {
    return this.form.valid ? null : {invalid: true};
  }

  private addControl(value: string = null): void {
    this.arrayControl.push(
      this.formBuilder.control(
        value,
        this.config.required ? [Validators.required, emptyValueValidator] : null
      )
    );
  }
}
