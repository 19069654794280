<div class="eos-chips-autocomplete-container">
  <mat-form-field [ngClass]="classList">

    <mat-label>{{label}}</mat-label>

    <mat-chip-list [formControl]="listControl" aria-label="Item selection" #chipList>
      <mat-chip *ngFor="let item of selectedItems" (removed)="onRemoveBtnClick(item)">
        {{item}}
        <button type="button" aria-label="Remove chip" matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        type="text"
        [placeholder]="placeholder"
        [readonly]="isReadonly"
        [attr.aria-label]="ariaLabel"
        [formControl]="inputControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        matInput
        #inputEl
        (input)="onInput()"
        (blur)="onBlur($event)">
    </mat-chip-list>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event)">
      <mat-option *ngFor="let item of items$ | async" [value]="item">{{item}}</mat-option>
    </mat-autocomplete>

    <mat-hint *ngIf="hint">{{hint}}</mat-hint>

    <mat-error *ngIf="!isValid && (listControl.dirty || listControl.touched)">{{errorMsg}}</mat-error>

  </mat-form-field>
  <div *ngIf="needSelectAllControls">
    <button
      type="button"
      class="eos-btn-link-primary"
      [disabled]="listControl.disabled"
      mat-flat-button
      (click)="onSelectAllClick()">
      Select All
    </button>
    <button
      type="button"
      class="eos-btn-link-primary"
      [disabled]="listControl.disabled"
      mat-flat-button
      (click)="onUnselectAllClick()">
      Unselect All
    </button>
  </div>
</div>
