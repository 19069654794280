import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseApi } from '@api/base.api';
import { CompanyApiEndPoints, QueryParams } from '@models/api';
import { Payload } from '@models/response';
import { Company, CompanyFormValue } from '@models/company';
import { TableData } from '@models/table';
import { CompanyNote, CompanyNoteFormValue } from '@models/note';
import { Discount, RawDiscountFormValue } from '@models/discount';
import { Audit } from '@models/audit';
import { File as FileModel, FileUpdateFormValue } from '@models/file';
import { Pricing, PricingFormValue } from '@models/pricing';
import { Product } from '@models/product';

@Injectable({
  providedIn: 'root'
})
export class CompanyApi extends BaseApi<CompanyApiEndPoints> {
  constructor(injector: Injector) {
    super(injector);

    this.endPoints = this.addBaseUrl({
      GET_COMPANY: '/companies/${id}',
      GET_COMPANIES: '/companies',
      GET_CSV: '/companies?export=csv',
      GET_NOTES: '/company_notes/${id}',
      GET_AUDIT: '/company/audits/${id}',
      GET_PRICING_AUDIT: '/company/product/audits/${id}',
      GET_FILES: '/company/files/${id}/search/fields',
      GET_FILE: '/company/files/${id}/download/${fileId}',
      GET_PRICES: '/company/product/prices/${id}',
      GET_PRODUCTS: '/company/products/available/${id}',
      GET_DISCOUNTS: '/order-settings/ux-discount',
      POST_CREATE_COMPANY: '/company',
      POST_CREATE_NOTE: '/company_note',
      POST_CREATE_UPDATE_DISCOUNT: '/order-settings/ux-discount/edit',
      POST_CREATE_PRICING: '/company/product/edit/${id}',
      POST_SEARCH_FOR_COMPANIES: '/company_search',
      POST_UPLOAD_FILES: '/upload_company_files/${id}',
      PUT_UPDATE_COMPANY: '/company/${id}',
      PUT_UPDATE_NOTE: '/company_note/${id}',
      PUT_UPDATE_FILE: '/company/files/${id}/edit/${fileId}',
      PUT_UPDATE_PRICING: '/company/product/edit/${id}/${productId}'
    });
  }

  public getCompany(id: string): Observable<Company> {
    const url: string = this.substituteParameters(this.endPoints.GET_COMPANY, {id});
    return this.http
      .get<Payload<Company>>(url)
      .pipe(
        map((res: Payload<Company>) => res.company)
      );
  }

  public getCompanies(params?: QueryParams): Observable<TableData<Company>> {
    return this.http.get<TableData<Company>>(
      this.endPoints.GET_COMPANIES,
      {params: this.setQueryParameters(params)}
      );
  }

  public getCSV(params?: QueryParams): Observable<string> {
    return this.http.get<string>(
      this.endPoints.GET_CSV,
      {responseType: <'json'>'text', params: this.setQueryParameters(params)}
    );
  }

  public getNotes(id: string, params?: QueryParams): Observable<TableData<CompanyNote>> {
    const url: string = this.substituteParameters(this.endPoints.GET_NOTES, {id});
    return this.http.get<TableData<CompanyNote>>(url, {params: this.setQueryParameters(params)});
  }

  public getDiscounts(id: string, params?: QueryParams): Observable<TableData<Discount>> {
    params.company_id = id;
    return this.http.get<TableData<Discount>>(
      this.endPoints.GET_DISCOUNTS,
      {params: this.setQueryParameters(params)}
    );
  }

  public getAudit(id: string, params: QueryParams = { }, isPricing = false): Observable<TableData<Audit>> {
    const endPoint: string = isPricing ? this.endPoints.GET_PRICING_AUDIT : this.endPoints.GET_AUDIT,
          url: string = this.substituteParameters(endPoint, {id});
    return this.http.get<TableData<Audit>>(url, {params: this.setQueryParameters(params)});
  }

  public getFiles(id: string, params?: QueryParams): Observable<TableData<FileModel>> {
    const url: string = this.substituteParameters(this.endPoints.GET_FILES, {id});
    return this.http.get<TableData<FileModel>>(url, {params: this.setQueryParameters(params)});
  }

  public getFile(id: string, fileId: string): Observable<string> {
    const url: string = this.substituteParameters(this.endPoints.GET_FILE, {id, fileId});
    return this.http
      .get<Payload<string>>(url)
      .pipe(
        map((res: Payload<string>) => res.url)
      );
  }

  public getPrices(id: string, params?: QueryParams): Observable<TableData<Pricing>> {
    const url: string = this.substituteParameters(this.endPoints.GET_PRICES, {id});
    return this.http.get<TableData<Pricing>>(url, {params: this.setQueryParameters(params)});
  }

  public getProducts(id: string): Observable<Array<Product>> {
    const url: string = this.substituteParameters(this.endPoints.GET_PRODUCTS, {id});
    return this.http
      .get<Payload<Array<Product>>>(url)
      .pipe(
        map((res: Payload<Array<Product>>) => res.items)
      );
  }

  public createCompany(formValue: CompanyFormValue): Observable<Company> {
    return this.http
      .post<Payload<Company>>(this.endPoints.POST_CREATE_COMPANY, formValue)
      .pipe(
        map((res: Payload<Company>) => res.company)
      );
  }

  public createNote(companyId: string, formValue: Omit<CompanyNoteFormValue, 'company_id'>): Observable<CompanyNote> {
    return this.http
      .post<Payload<CompanyNote>>(this.endPoints.POST_CREATE_NOTE, {company_id: companyId, ...formValue})
      .pipe(
        map((res: Payload<CompanyNote>) => res.note)
      );
  }

  public createUpdateDiscount(id: string, formValue: Discount | RawDiscountFormValue): Observable<Discount> {
    formValue.company_id = id;
    return this.http.post<Discount>(this.endPoints.POST_CREATE_UPDATE_DISCOUNT, formValue);
  }

  public createPricing(id: string, formValue: PricingFormValue): Observable<Pricing> {
    const url: string = this.substituteParameters(this.endPoints.POST_CREATE_PRICING, {id});
    return this.http
      .post<Payload<Pricing>>(url, formValue)
      .pipe(
        map((res: Payload<Pricing>) => res.item)
      );
  }

  public searchForCompanies(params?: QueryParams): Observable<Array<Company>> {
    return this.http
      .post<Payload<Array<Company>>>(this.endPoints.POST_SEARCH_FOR_COMPANIES, params)
      .pipe(
        map((res: Payload<Array<Company>>) => res.companies)
      );
  }

  public uploadFiles(id: string, files: Array<File>): Observable<null> {
    const url: string = this.substituteParameters(this.endPoints.POST_UPLOAD_FILES, {id});
    return this.http.post<null>(url, this.prepareFormDataBody(null, files));
  }

  public updateCompany(id: string, formValue: CompanyFormValue): Observable<Company> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_COMPANY, {id});
    return this.http
      .put<Payload<Company>>(url, formValue)
      .pipe(
        map((res: Payload<Company>) => res.company)
      );
  }

  public updateNote(id: string, note: CompanyNote): Observable<CompanyNote> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_NOTE, {id});
    return this.http
      .put<Payload<CompanyNote>>(url, note)
      .pipe(
        map((res: Payload<CompanyNote>) => res.note)
      );
  }

  public updateFile(fileId: string, formValue: FileUpdateFormValue, id: string): Observable<FileModel> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_FILE, {id, fileId});
    return this.http
      .put<Payload<FileModel>>(url, formValue)
      .pipe(
        map((res: Payload<FileModel>) => res.item)
      );
  }

  public updatePricing(id: string, productId: string, formValue: PricingFormValue): Observable<Pricing> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_PRICING, {id, productId});
    return this.http
      .put<Payload<Pricing>>(url, formValue)
      .pipe(
        map((res: Payload<Pricing>) => res.item)
      );
  }
}
