<app-dialog header="Transfer" cancelBtnLabel="Close">
  <div class="eos-control-width-100" slot="content">
    <app-panel header="From" [expanded]="true">
      <div class="container-fluid py-20" slot="body">

        <div class="row">
          <div class="col-md-4">
            <strong class="mr-10">Order ID:</strong>
            <span>{{payment.epr_id}} ({{payment.order_id}})</span>
          </div>
          <div class="col-md-4">
            <strong class="mr-10">Batch ID:</strong>
            <span>{{payment.batch_id}}</span>
          </div>
          <div class="col-md-4">
            <strong class="mr-10">Max. Transfer Amount:</strong>
            <span>{{payment.available_balance | currency}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <strong class="mr-10">Transaction ID:</strong>
            <span>{{payment.id}}</span>
          </div>
          <div class="col-md-4">
            <strong class="mr-10">Batch Name:</strong>
            <span>{{payment.batch_name}}</span>
          </div>
          <div class="col-md-4">
            <strong class="mr-10">Balance:</strong>
            <span>{{payment.balance | currency}}</span>
          </div>
        </div>

      </div>
    </app-panel>
    <form class="mt-20" [formGroup]="transferForm" novalidate>
      <div class="container-fluid">

        <div class="row">
          <div class="col-md-6">
            <app-order-autocomplete
              label="To"
              aria-label="Transfer to order"
              formControlName="order"
              [isRequired]="true"
              [needValidation]="true">
            </app-order-autocomplete>
          </div>
          <div class="col-md-6">
            <mat-form-field>
              <mat-label>Amount To Transfer</mat-label>
              <input
                type="number"
                step="1"
                placeholder="Amount To Transfer"
                required
                autocomplete="transaction-amount"
                aria-label="Amount to transfer"
                min="0.01"
                max="payment.available_balance"
                formControlName="amount"
                matInput>
              <span matPrefix>$&nbsp;</span>
              <mat-error *ngIf="amountControl.invalid && (amountControl.dirty || amountControl.touched)">
                {{getErrorMsg(amountControl, 'paymentAmount')}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <mat-form-field>
              <mat-label>Note</mat-label>
              <textarea
                placeholder="Please enter a note"
                required
                aria-label="Transfer note"
                formControlName="notes"
                matInput>
              </textarea>
              <mat-error *ngIf="noteControl.invalid && (noteControl.dirty || noteControl.touched)">
                {{getErrorMsg(noteControl, 'note')}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

      </div>
    </form>
  </div>
  <button
    type="button"
    class="eos-btn-primary"
    [disabled]="!transferForm.valid"
    mat-flat-button
    slot="action"
    (click)="onTransferBtnClick()">
    Transfer
  </button>
</app-dialog>
