import { Pipe, PipeTransform } from '@angular/core';

import { Company } from '@models/company';
import { AutocompleteValue } from '@models/autocomplete';

@Pipe({
  name: 'companyViewValue'
})
export class CompanyViewValuePipe implements PipeTransform {
  public transform(company: AutocompleteValue): string {
    return (<Company>company).id
      ? `${(<Company>company).name} - ${(<Company>company).city}, ${(<Company>company).state}`
      : (<Company>company).name;
  }
}
