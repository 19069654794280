import { Pipe, PipeTransform } from '@angular/core';

import { AuthService } from '@services/auth.service';
import { MenuItem } from '@models/menu-item';

@Pipe({
  name: 'pathNavigation'
})
export class PathNavigationPipe implements PipeTransform {
  constructor(private service: AuthService) { }

  //TODO: works only for company id parameter
  public transform(items: Array<MenuItem>): Array<MenuItem> {
    return items.map((item: MenuItem): MenuItem => {
      const pathSegment: string | null = item.pathSegment || null;

      if (pathSegment && pathSegment === 'companyId') {
        item.pathSegment = this.service.company.id;
      }

      return item;
    });
  }
}
