import { Injectable } from '@angular/core';

import {
  CellClassParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  RowClassParams,
  SortModelItem,
  ValueFormatterParams
} from 'ag-grid-enterprise';

import { LinkRendererComponent } from '@components/table/cell-renderers/link-renderer/link-renderer.component';
import { SelectRendererComponent } from '@components/table/cell-renderers/select-renderer/select-renderer.component';
import { ActionRendererComponent } from '@components/table/cell-renderers/action-renderer/action-renderer.component';
import { AuthService } from '@services/auth.service';
import { TimeService } from '@services/time.service';
import { UtilitiesService } from '@services/utilities.service';
import {
  ACTIVE_INACTIVE_RADIO,
  DISCOUNT_SEARCH_TYPE_RADIO,
  NOTE_TYPE_RADIO,
  ORDER_FILE_TYPE_RADIO,
  STICKY_NOTE_RADIO
} from '@configs/radio';
import { ACTIVE_INACTIVE, ORDER_FILE_TYPES } from '@configs/select';
import { TABLE_TYPES } from '@configs/table';
import { FILTER_TYPES } from '@configs/search-box';
import { TableConfig } from '@models/table';
import { SearchBoxConfig, SearchBoxValue } from '@models/search-box';
import { QueryParams } from '@models/api';
import { Discount } from '@models/discount';
import { SelectItem } from '@models/select';

@Injectable({
  providedIn: 'root'
})
export class ListService {
  constructor(
    private authService: AuthService,
    private timeService: TimeService,
    private utilitiesService: UtilitiesService
  ) { }

  public prepareBaseQueryParams(
    value: SearchBoxValue | null = null,
    params: IServerSideGetRowsParams | null = null
  ): QueryParams {
    const request: IServerSideGetRowsRequest | null = params?.request || null,
          paginationPageSize: number = params?.api.paginationGetPageSize() || 50;

    return {
      page: request ? Math.floor(request.startRow / paginationPageSize + 1) : 1,
      limit: paginationPageSize,
      sortModel: request && request.sortModel.length ? request.sortModel : null,
      keyword: value?.search
    };
  }

  public getNoteListTableConfig(): TableConfig {
    return {
      colDefs: this.authService.filterTableColumns(TABLE_TYPES.NOTE, [
        {
          headerName: 'Note',
          field: 'note',
          cellRenderer: this.utilitiesService.noteCellRenderer,
          autoHeight: true
        },
        {
          headerName: 'Created By',
          field: 'created_by',
          maxWidth: 200
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
          maxWidth: 200
        },
        {
          headerName: 'Type',
          field: 'internal',
          valueFormatter: (params: ValueFormatterParams) => this.noteTypeFormatter(params),
          maxWidth: 100
        },
        {
          headerName: 'Actions',
          field: 'is_deleted',
          cellRenderer: ActionRendererComponent,
          cellRendererParams: {
            customParams: {
              type: 'note'
            }
          },
          sortable: false,
          maxWidth: 100
        }
      ]),
      rowClassRules: {
        'eos-table-row-danger': (params: RowClassParams) => params.data?.is_deleted === '1',
        'eos-table-row-warning': (params: RowClassParams) => params.data?.is_sticky === '1'
      }
    };
  }

  public getAuditListTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'Created By',
          field: 'created_by',
          minWidth: 100
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
          minWidth: 100
        },
        {
          headerName: 'Field',
          field: 'field_name'
        },
        {
          headerName: 'Original Value',
          field: 'original_value',
          cellRenderer: this.utilitiesService.noteCellRenderer,
          autoHeight: true,
          minWidth: 400
        },
        {
          headerName: 'New Value',
          field: 'new_value',
          cellRenderer: this.utilitiesService.noteCellRenderer,
          autoHeight: true,
          minWidth: 400
        }
      ]
    };
  }

  public getFileListTableConfig(isSelectable: boolean = false): TableConfig {
    return {
      colDefs: this.authService.filterTableColumns(TABLE_TYPES.FILE, [
        {
          headerName: 'Name',
          field: 'name',
          cellRenderer: LinkRendererComponent,
          cellRendererParams: {
            customParams: {
              takeCurrentUrl: true,
              needEmitEvent: true
            }
          },
          checkboxSelection: isSelectable,
          minWidth: 250
        },
        {
          headerName: 'Created By',
          field: 'created_by',
          minWidth: 100
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params),
          minWidth: 100
        },
        {
          headerName: 'Type',
          field: 'internal',
          cellRenderer: SelectRendererComponent,
          cellRendererParams: {
            customParams: {
              eventType: 'type-change-file',
              items: ORDER_FILE_TYPES,
              label: 'Status',
              ariaLabel: 'Order status'
            }
          },
          sortable: false,
          minWidth: 100
        },
        {
          headerName: 'Actions',
          field: 'is_deleted',
          cellRenderer: ActionRendererComponent,
          cellRendererParams: {
            customParams: {
              type: 'file'
            }
          },
          sortable: false,
          minWidth: 100
        }
      ]),
      rowClassRules: {'eos-table-row-danger': (params: RowClassParams) => params.data?.is_deleted === '1'}
    };
  }

  public getPricingListTableConfig(): TableConfig {
    return {
      colDefs: this.authService.filterTableColumns(TABLE_TYPES.PRICING, [
        {
          headerName: 'Product',
          field: 'name'
        },
        {
          headerName: 'Price',
          field: 'product_price',
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params)
        },
        {
          headerName: 'Custom Price',
          field: 'price',
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params)
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params)
        },
        {
          headerName: 'Created By',
          field: 'created_by_display_name'
        },
        {
          headerName: 'Notes',
          field: 'notes',
          cellRenderer: this.utilitiesService.noteCellRenderer,
          autoHeight: true,
          minWidth: 300
        },
        {
          headerName: 'Deactivate Reason',
          field: 'deactivate_reason',
          cellRenderer: this.utilitiesService.noteCellRenderer,
          autoHeight: true
        },
        {
          headerName: 'Status',
          field: 'active',
          valueFormatter: this.utilitiesService.activeInactiveFormatter,
          cellClass: (params: CellClassParams) => `eos-table-cell-${params.value === '1' ? 'success' : 'danger'}`
        },
        {
          headerName: 'Actions',
          field: 'actions',
          cellRenderer: ActionRendererComponent,
          cellRendererParams: {
            customParams: {
              type: 'pricing'
            }
          },
          sortable: false
        }
      ]),
      rowClassRules: {'eos-table-row-danger': (params: RowClassParams) => params.data?.active === '0'}
    };
  }

  public getDiscountListTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'UX',
          field: 'update_number',
          valueFormatter: (params: ValueFormatterParams) => `U${params.value}`,
          minWidth: 60,
          maxWidth: 60
        },
        {
          headerName: 'Amount',
          field: 'amount',
          valueFormatter: (params: ValueFormatterParams) => this.discountAmountFormatter(params)
        },
        {
          headerName: 'Company',
          field: 'company_name',
          valueFormatter: this.utilitiesService.textFormatter
        },
        {
          headerName: 'User',
          field: 'user_display',
          valueFormatter: this.utilitiesService.textFormatter,
          minWidth: 300
        },
        {
          headerName: 'Status',
          field: 'is_active',
          cellRenderer: SelectRendererComponent,
          cellRendererParams: {
            customParams: {
              eventType: 'status-change-discount',
              items: ACTIVE_INACTIVE,
              label: 'Status',
              classList: 'eos-table-active-status-renderer-container',
              ariaLabel: 'Discount status'
            }
          },
          sortable: false,
          minWidth: 160,
          maxWidth: 160
        }
      ]
    };
  }

  public getNoteListSearchBoxConfig(): SearchBoxConfig {
    return {
      searchSection: {
        search: true
      },
      filterSection: this.authService.filterSearchBoxConfig(FILTER_TYPES.NOTE, {
        noteTypes: true,
        noteTypeItems: NOTE_TYPE_RADIO,
        noteTypeValue: 'all',
        stickyNotes: true,
        stickyNoteItems: STICKY_NOTE_RADIO,
        stickyNoteValue: 'lock'
      })
    };
  }

  public getFileListSearchBoxConfig(): SearchBoxConfig {
    return {
      searchSection: {
        search: true
      },
      filterSection: this.authService.filterSearchBoxConfig(FILTER_TYPES.FILE, {
        fileTypes: true,
        fileTypeItems: ORDER_FILE_TYPE_RADIO,
        fileTypeValue: 'all'
      })
    };
  }

  public getPricingListSearchBoxConfig(): SearchBoxConfig {
    return {
      searchSection: {
        search: true
      },
      filterSection: {
        pricingStatuses: true,
        pricingStatusItems: ACTIVE_INACTIVE_RADIO,
        pricingStatusValue: 'active'
      }
    };
  }

  public getDiscountSettingListSearchBoxConfig(): SearchBoxConfig {
    return {
      searchSection: {
        search: true
      },
      filterSection: {
        discountSearchTypes: true,
        discountSearchTypeItems: DISCOUNT_SEARCH_TYPE_RADIO,
        discountSearchTypeValue: 'all',
        discountStatuses: true,
        discountStatusItems: ACTIVE_INACTIVE_RADIO,
        discountStatusValue: 'all'
      }
    };
  }

  public prepareNoteListQueryParams(value: SearchBoxValue, params: IServerSideGetRowsParams): QueryParams {
    const {stickyNotes: isSticky} = value;
    return {
      ...this.prepareBaseQueryParams(value, params),
      show: value.noteType || 'all',
      unlockSticky: isSticky === 'unlock' ? 1 : 0
    };
  }

  public prepareFileListQueryParams(value: SearchBoxValue, params: IServerSideGetRowsParams): QueryParams {
    return {
      ...this.prepareBaseQueryParams(value, params),
      show: value.fileType || 'all'
    };
  }

  public preparePricingListQueryParams(value: SearchBoxValue, params: IServerSideGetRowsParams): QueryParams {
    return {
      ...this.prepareBaseQueryParams(value, params),
      status: value.status
    };
  }

  public prepareDiscountListQueryParams(value: SearchBoxValue, params: IServerSideGetRowsParams): QueryParams {
    return {
      ...this.prepareBaseQueryParams(value, params),
      orderBy: 'udm.amount',
      status: value.status,
      search_type: value.searchType
    };
  }

  /** Mutate the select item array **/
  public modifyDiscountNumbersByDiscounts(selectItems: Array<SelectItem>, discounts: Array<Discount>): void {
    selectItems.forEach((item: SelectItem) => {
      discounts.forEach((discount: Discount) => {
        if (item.value !== Number(discount.update_number)) { return; }
        item.disabled = true;
      });
    });
  }

  private discountAmountFormatter(params: ValueFormatterParams): string {
    const {data, value} = params;
    return data.is_percentage === '1' ? `${value} %` : this.utilitiesService.currencyFormatter(params)
  }

  private noteTypeFormatter(params: ValueFormatterParams): string {
    //TODO: to make different note types look the same. Manager only in the order type
    /*let res: string = params.value === '1' ?  'Internal' : 'External';
    return params.data.is_manager === '1' ? 'Manager' : res;*/
    return params.value === '1' ? 'Internal' : 'External';
  }

  /** Server internal and UI values not the same **/
  private mapSortModelItemValues(item: SortModelItem): SortModelItem {
    let res: SortModelItem;

    switch (item.colId) {
      case 'paymentsNegative':
        res = {
          colId: 'payments',
          sort: item.sort
        };
        break;
      default:
        res = item;
    }

    return res;
  }
}
