import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AutocompleteComponent } from '@components/autocomplete/autocomplete.component';
import { CompanyViewValuePipe } from '@pipes/company-view-value.pipe';
import { CompanyApi } from '@api/company.api';
import { CompanyService } from '@services/company.service';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';
import { Company } from '@models/company';
import { User } from '@models/user';

@Component({
  selector: 'app-company-autocomplete',
  templateUrl: './company-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => CompanyAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: CompanyAutocompleteComponent,
      multi: true
    }
  ]
})
export class CompanyAutocompleteComponent extends AutocompleteComponent {
  @Input() public override label: string = 'Company';
  @Input() public override placeholder: string = 'Search for a company...';
  @Input() public canCreateNewCompany: boolean = false;
  @Input() public needIcon: boolean = false;

  protected override readonly switchMap: boolean = true;

  constructor(
    injector: Injector,
    private router: Router,
    private api: CompanyApi,
    private service: CompanyService,
    private pipe: CompanyViewValuePipe
  ) {
    super(injector);
  }

  public onPrefixIconBtnClicked(): void {
    if (!this.selectedItem) { return; }
    const url: string = this.router.serializeUrl(
      this.router.createUrlTree(['company-edit', (<User>this.selectedItem).id])
    );

    window.open(url, '_blank');
  }

  public displayFn = (company: Company): string => {
    return company ? this.pipe.transform(company) : '';
  };

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return this.api.searchForCompanies({keyword: query}).pipe(
      map((companies: Array<Company>): Array<AutocompleteValue> => this.composeAutocompleteValues(companies)),
      catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
    );
  }

  protected override composeAutocompleteValues(companies: Array<Company>): Array<AutocompleteValue> {
    let res: Array<AutocompleteValue> = companies;

    if (!companies.length) {
      res = this.canCreateNewCompany
        ? [{
          ...this.service.emptyCompany,
          name: this.inputControl.value
        }]
        : EMPTY_AUTOCOMPLETE_VALUE
    }

    return res;
  }
}
