import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { DateRange } from '@angular/material/datepicker';

import moment from 'moment';

import { Checkbox } from '@models/checkbox';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  public removeControls(form: UntypedFormGroup, names: Array<string>): void {
    names.forEach((name: string) => form.removeControl(name));
  }

  public addDateControl(form: UntypedFormGroup, name: string, value: DateRange<moment.Moment> | null = null): void {
    let start = value ? value.start : null,
        end = value ? value.end : null;

    form.addControl(name, new UntypedFormGroup({
      start: new UntypedFormControl(start),
      end: new UntypedFormControl(end)
    }));
  }

  public addCheckboxGroupControl(form: UntypedFormGroup, name: string, items: Array<Checkbox>, values?: any): void {
    form.addControl(name, new UntypedFormGroup({ }));

    items.forEach(
      (checkbox: Checkbox) => {
        const controlName: string = checkbox.controlName;

        (<UntypedFormGroup>form.get(name)).addControl(
          controlName,
          new UntypedFormControl(values ? values[controlName] : checkbox.value)
        )
      }
    );
  }

  public disableFormGroup(group: UntypedFormGroup): void {
    group.disable({emitEvent: false});

    Object.values(group.controls).forEach((control: AbstractControl) => {
      if ((<UntypedFormGroup>control).controls) {
        this.disableFormGroup(<UntypedFormGroup>control);
      }

      control.disable({emitEvent: false});
    });
  }

  public toggleControlRequiredValidator(control: AbstractControl, add: boolean): void {
    const requiredValidator: ValidatorFn = Validators.required,
          hasValidator: boolean = control.hasValidator(requiredValidator);

    if (add && !hasValidator) {
      control.addValidators(requiredValidator);
    } else if (!add && hasValidator) {
      control.removeValidators(requiredValidator);
    }

    control.updateValueAndValidity();
  }

  public resetForm(form: UntypedFormGroup): void {
    form.markAsPristine();
    form.markAsUntouched();
    form.updateValueAndValidity();
  }
}
