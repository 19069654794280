import { Radio } from '@models/radio';

export const YES_NO_RADIO: Array<Radio> = [
  {
    id: 0,
    value: false,
    viewValue: 'No',
    ariaLabel: 'No'
  },
  {
    id: 1,
    value: true,
    viewValue: 'Yes',
    ariaLabel: 'Yes'
  }
];

export const ACTIVE_INACTIVE_RADIO: Array<Radio> = [
  {
    id: 0,
    value: 'all',
    viewValue: 'All',
    ariaLabel: 'Active and inactive types'
  },
  {
    id: 1,
    value: 'active',
    viewValue: 'Active',
    ariaLabel: 'Active type'
  },
  {
    id: 2,
    value: 'inactive',
    viewValue: 'Inactive',
    ariaLabel: 'Inactive type'
  }
];

export const ORDER_BATCH_UPDATE_RADIO: Array<Radio> = [
  {
    id: 0,
    value: 'status',
    viewValue: 'Status',
    ariaLabel: 'Update orders by status'
  },
  {
    id: 1,
    value: 'flag',
    viewValue: 'Flag',
    ariaLabel: 'Update orders by flag'
  }
];

export const ORDER_ISSUE_BATCH_UPDATE_RADIO: Array<Radio> = [
  {
    id: 0,
    value: 'status',
    viewValue: 'Status',
    ariaLabel: 'Update order issues by status'
  },
  {
    id: 1,
    value: 'researcher',
    viewValue: 'Researcher',
    ariaLabel: 'Update order issues by researcher'
  },
  {
    id: 2,
    value: 'qa',
    viewValue: 'QC',
    ariaLabel: 'Update order issues by quality control'
  }
];

export const ORDER_FILE_TYPE_RADIO: Array<Radio> = [
  {
    id: 0,
    value: 'all',
    viewValue: 'All',
    ariaLabel: 'Search for all file types'
  },
  {
    id: 1,
    value: 'internal',
    viewValue: 'Internal',
    ariaLabel: 'Search for internal files'
  },
  {
    id: 2,
    value: 'external',
    viewValue: 'External',
    ariaLabel: 'Search for external files'
  }
];

export const NOTE_TYPE_RADIO: Array<Radio> = [
  {
    id: 0,
    value: 'all',
    viewValue: 'All',
    ariaLabel: 'Search for all note types'
  },
  {
    id: 1,
    value: 'internal',
    viewValue: 'Internal',
    ariaLabel: 'Search for internal notes'
  },
  {
    id: 2,
    value: 'external',
    viewValue: 'External',
    ariaLabel: 'Search for external notes'
  }
  //TODO: to make different note types look the same. Manager only in the order type
  /*{
    id: 3,
    value: 'manager',
    viewValue: 'Manager',
    ariaLabel: 'Search for manager notes'
  }*/
];

export const STICKY_NOTE_RADIO: Array<Radio> = [
  {
    id: 0,
    value: 'lock',
    viewValue: 'Lock',
    ariaLabel: 'Search for sticky notes'
  },
  {
    id: 1,
    value: 'unlock',
    viewValue: 'Unlock',
    ariaLabel: 'Search for non-sticky notes'
  }
];

export const DISCOUNT_TYPE_RADIO: Array<Radio> = [
  {
    id: 0,
    value: '0',
    viewValue: 'Amount deducted from cost ($)',
    ariaLabel: 'Discount type is dollar'
  },
  {
    id: 1,
    value: '1',
    viewValue: 'Percentage deducted from cost (%)',
    ariaLabel: 'Discount type is percentage'
  }
];

export const USER_TYPE_RADIO: Array<Radio> = [
  {
    id: 0,
    value: 'employee',
    viewValue: 'Employee',
    ariaLabel: 'Employee user type'
  },
  {
    id: 1,
    value: 'customer',
    viewValue: 'Customer',
    ariaLabel: 'Customer user type'
  }
];

export const DISCOUNT_SEARCH_TYPE_RADIO: Array<Radio> = [
  {
    id: 0,
    value: 'all',
    viewValue: 'All',
    ariaLabel: 'Search for all types'
  },
  {
    id: 1,
    value: 'System',
    viewValue: 'System',
    ariaLabel: 'Search for system type'
  },
  {
    id: 2,
    value: 'Company',
    viewValue: 'Company',
    ariaLabel: 'Search for company type'
  },
  {
    id: 3,
    value: 'User',
    viewValue: 'User',
    ariaLabel: 'Search for user type'
  }
];
