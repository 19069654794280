import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Settings } from '@models/settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsContext {
  public notificationSettings$: BehaviorSubject<Settings | null> = new BehaviorSubject<Settings | null>(null);
  public orderSettings$: BehaviorSubject<Settings | null> = new BehaviorSubject<Settings | null>(null);
  public userSettings$: BehaviorSubject<Settings | null> = new BehaviorSubject<Settings| null>(null);
  public defaultSettings$: BehaviorSubject<Settings | null> = new BehaviorSubject<Settings| null>(null);
  public supportSettings$: BehaviorSubject<Settings | null> = new BehaviorSubject<Settings| null>(null);
  public companySettings$: BehaviorSubject<Settings | null> = new BehaviorSubject<Settings| null>(null);
  public smtpSettings$: BehaviorSubject<Settings | null> = new BehaviorSubject<Settings| null>(null);
  public smtpDefaultSettings$: BehaviorSubject<Settings | null> = new BehaviorSubject<Settings| null>(null);

  public nextNotificationSettings(settings: Settings): void {
    this.notificationSettings$.next(settings);
  }

  public nextOrderSettings(settings: Settings): void {
    this.orderSettings$.next(settings);
  }

  public nextUserSettings(settings: Settings): void {
    this.userSettings$.next(settings);
  }

  public nextDefaultSettings(settings: Settings): void {
    this.defaultSettings$.next(settings);
  }

  public nextSupportSettings(settings: Settings): void {
    this.supportSettings$.next(settings);
  }

  public nextCompanySettings(settings: Settings): void {
    this.companySettings$.next(settings);
  }

  public nextSmtpSettings(settings: Settings): void {
    this.smtpSettings$.next(settings);
  }

  public nextSmtpDefaultSettings(settings: Settings): void {
    this.smtpDefaultSettings$.next(settings);
  }
}
