import { Component, Injector, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MatSelectChange } from '@angular/material/select';

import { ICellEditorParams } from 'ag-grid-enterprise';

import { SelectEditorComponent } from '@components/table/cell-editors/select-editor/select-editor.component';
import { UserContext } from '@contexts/user.context';
import { UserService } from '@services/user.service';
import { Researcher } from '@models/user';

@Component({
  selector: 'app-researcher-editor',
  templateUrl: './researcher-editor.component.html',
  styleUrls: ['./researcher-editor.component.scss']
})
export class ResearcherEditorComponent extends SelectEditorComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();

  constructor(injector: Injector, private context: UserContext, private userService: UserService) {
    super(injector);

    this.classList += 'eos-table-researcher-editor-container eos-control-wrapper-p-0';
    this.panelClassList += 'eos-table-researcher-editor-panel';
  }

  ngOnInit(): void {
    this.context.researchers$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (researchers: Array<Researcher>) => {
          this.items = this.userService.getResearcherItems(researchers);
          this.selected = this.userService.getResearcherItemByUserId(this.items, this.params.data.assigned_to);
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public override onSelectionChanged(event: MatSelectChange): void {
    this.selected = this.userService.getResearcherItemByUserId(this.items, event.value);

    this.service.rendererAction$.next({
      type: 'researcher-change',
      item: this.selected,
      rowData: this.params?.node?.data,
      node: this.params?.node
    });

    this.params.api.stopEditing();
  }

  public override agInit(params: ICellEditorParams): void {
    this.params = params;
  }

  public override getValue(): string {
    return <string>this.selected?.viewValue;
  }
}
