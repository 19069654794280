import { Injectable } from '@angular/core';
import { CurrencyPipe, PercentPipe } from '@angular/common';

import { CellClassParams, ICellRendererParams, ValueFormatterParams } from 'ag-grid-enterprise';

import { ORDER_PAYMENT_STATUS_CLASSES } from '@configs/order';
import { Address } from '@models/address';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  constructor(private currencyPipe: CurrencyPipe, private percentPipe: PercentPipe) { }

  public parseJson(value: string): unknown {
    let res: unknown;

    try {
      res = JSON.parse(value);
    } catch (error: unknown) {
      res = null;
    }

    return res;
  }

  public guid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);

      return v.toString(16);
    });
  }

  public extractText(html: string): string {
    return html.replace(/<\/?[^>]+(>|$)/g, ' ').trim();
  }

  public prepareActiveValue(status: string): string {
    return status === 'active' ? '1' : '0';
  }

  public noteCellRenderer(params: ICellRendererParams, pattern: string = '\n'): string {
    return params.value ? params.value.replaceAll(pattern, '<br>') : '---'
  }

  public currencyFormatter(params: ValueFormatterParams): string {
    return this.currencyPipe.transform(params.value || 0);
  }

  public percentFormatter(value: number, digitsInfo?: string): string {
    return this.percentPipe.transform(value || 0, digitsInfo);
  }

  public textFormatter(params: ValueFormatterParams): string {
    return params.value ? params.value : '---';
  }

  public arrayFormatter(params: ValueFormatterParams): string {
    return params.value.length ? params.value : '---';
  }

  public emptyTextFormatter(params: ValueFormatterParams, formatter: (params: ValueFormatterParams) => unknown): string {
    return params.value ? formatter.call(this, params) : '';
  }

  public numberFormatter(params: ValueFormatterParams): string {
    return params.value ? params.value : 0;
  }

  public yesNoFormatter(params: ValueFormatterParams): string {
    return params.value === '1' ? 'Yes' : 'No';
  }

  public activeInactiveFormatter(params: ValueFormatterParams): string {
    return params.value === '1' ? 'Active' : 'Inactive';
  }

  /** Have two the same methods because of server models (for example company and order) **/
  public addressFormatter(params: ValueFormatterParams): string {
    const {order_address_2 = '', order_city = '', order_state = '', order_zip = ''} = params.data;
    return order_state && order_zip
      ? `${params.value} ${order_address_2 || ''} ${order_city}, ${order_state} ${order_zip}`
      : `${params.value} ${order_address_2 || ''} ${order_city}`;
  }

  public companyAddressFormatter(params: ValueFormatterParams): string {
    const {city = '', state = '', zip = ''} = params.data;
    return `${params.value} ${city}, ${state} ${zip}`;
  }

  public phoneNumberFormatter(params: ValueFormatterParams): string {
    const phone: string = params.value.replace(/\D/g, '');

    return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
  }

  public getPaymentStatusTableCellClass(params: CellClassParams): string {
    return `eos-table-cell-payment-status-${ORDER_PAYMENT_STATUS_CLASSES[params.value]}`;
  }

  public isNavigationKey(event: KeyboardEvent): boolean {
    const LEFT_KEY: string = 'ArrowLeft',
          RIGHT_KEY: string = 'ArrowRight',
          key: string = event.key;

    return key === LEFT_KEY || key === RIGHT_KEY;
  }

  public isEnterKey(event: KeyboardEvent): boolean {
    const ENTER_KEY: string = 'Enter';
    return event.key === ENTER_KEY;
  }

  public isHomeKey(event: KeyboardEvent): boolean {
    const ENTER_KEY: string = 'Home';
    return event.key === ENTER_KEY;
  }

  public isEndKey(event: KeyboardEvent): boolean {
    const ENTER_KEY: string = 'End';
    return event.key === ENTER_KEY;
  }

  public isCopyPasteKey(event: KeyboardEvent): boolean {
    const KEY_C: string = 'c',
          KEY_V: string = 'v',
          key: string = event.key;

    return event.ctrlKey && (key === KEY_C || key === KEY_V);
  }

  public capitalizeFirstLetter(value: string): string {
    const words: Array<string> = value.split(' ');

    words.forEach((word: string, index: number) => words[index] = word.charAt(0).toUpperCase() + word.slice(1));

    return words.join(' ');
  }

  public createEmptyAddress(value: string): Address {
    return {
      id: this.guid(),
      display: value,
      line_1: value
    };
  }

  public validateFileExtensions(file: File | DataTransferItem, extensions: Array<string>): boolean {
    return extensions.some((pattern: string) => file.type.includes(pattern));
  }

  public isString(value: unknown): boolean {
    return typeof value === 'string';
  }

  public trim(value: unknown): unknown {
    return this.isString(value) ? (<string>value).trim() : value;
  }
}
