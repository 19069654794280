import { Injectable } from '@angular/core';

import { DateRange } from '@angular/material/datepicker';

import { ValueFormatterParams } from 'ag-grid-enterprise';

import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  private timezone: string = 'America/New_York';

  public createDate(data?: string | number | undefined): moment.Moment {
    return moment(data);
  }

  public addWorkdays(date: moment.Moment, days: number): moment.Moment {
    const increment: number = days / Math.abs(days),
          clonedDate: moment.Moment = date
            .clone()
            .add(Math.floor(Math.abs(days) / 5) * 7 * increment, 'days');
    let remaining: number = days % 5;

    while (remaining !== 0) {
      clonedDate.add(increment, 'days');

      if (clonedDate.isoWeekday() !== 6 && clonedDate.isoWeekday() !== 7) {
        remaining -= increment;
      }
    }

    return clonedDate;
  }

  public addStartDay(value: moment.Moment): moment.Moment {
    return value.startOf('day');
  }

  public addStartMonth(value: moment.Moment): moment.Moment {
    return value.startOf('month');
  }

  public addEndDay(value: moment.Moment): moment.Moment {
    return value.endOf('day');
  }

  public addEndMonth(value: moment.Moment): moment.Moment {
    return value.endOf('month');
  }

  public formatDateTime(value: moment.Moment): string {
    return value.format('MM/DD/YYYY HH:mm:ss');
  }

  public formatDate(value: moment.Moment): string {
    return value.format('MM/DD/YYYY');
  }

  public formatDateFromTimestamp(value: number): string {
    return moment.tz(moment.unix(value), this.timezone).format('MM/DD/YYYY');
  }

  public formatTimeFromTimestamp(value: number): string {
    return moment.tz(moment.unix(value), this.timezone).format('hh:mm A');
  }

  public formatDateTimeFromTimestamp(value: number): string {
    return moment.tz(moment.unix(value), this.timezone).format('MM/DD/YYYY hh:mm A');
  }

  public getTodayDate(): string {
    return this.formatDate(moment());
  }

  public getTodayDateTime(): string {
    return moment().format('MM/DD/YYYY hh:mm A');
  }

  public getDateTimeRange(from: moment.Moment, to: moment.Moment): DateRange<moment.Moment> {
    return new DateRange(from, to);
  }

  public getTodayDateTimeRange(): DateRange<moment.Moment> {
    const today: moment.Moment = moment();
    return this.getDateTimeRange(today, today);
  }

  public getPreviousMonthDateTimeRange(): DateRange<moment.Moment> {
    const date: moment.Moment = moment().subtract(1, 'months');
    return this.getDateTimeRange(this.addStartMonth(date.clone()), this.addEndMonth(date.clone()));
  }

  public getFromMonthAgoToTodayDateTimeRange(): DateRange<moment.Moment> {
    const today: moment.Moment = moment(),
          dateMonthAgo: moment.Moment = moment().subtract(1, 'months');
    return new DateRange(dateMonthAgo, today);
  }

  public getFromMonthStartToTodayDateTimeRange(): DateRange<moment.Moment> {
    const today: moment.Moment = moment(),
          monthStart: moment.Moment = this.addStartMonth(today.clone());
    return new DateRange(monthStart, today);
  }

  public getFromWeekAgoToTodayDateTimeRange(): DateRange<moment.Moment> {
    const today: moment.Moment = moment(),
          dateWeekAgo: moment.Moment = moment().subtract(1, 'weeks');
    return new DateRange(dateWeekAgo, today);
  }

  public getCurrentMonthNumber(): number {
    return moment().month();
  }

  public getMonthNameByNumber(number: number): string {
    return moment().month(number).format('MMM');
  }

  public timestampFormatter(params: ValueFormatterParams): string {
    const value: string = params.value;
    return value ? this.formatDateTimeFromTimestamp(parseInt(value)) : '---';
  }

  public timestampTimeFormatter(params: ValueFormatterParams): string {
    const value: string = params.value;
    return value ? this.formatTimeFromTimestamp(parseInt(value)) : '---';
  }

  public timestampDateTimeFormatter(params: ValueFormatterParams): string {
    const value: string = params.value;
    return value ? this.formatDateTimeFromTimestamp(parseInt(value)) : '---';
  }

  public getMonthNames(quantity: number): Array<string> {
    const res: Array<string> = [];

    for (let i = quantity; i > 0; i--) {
      res.push(moment().subtract(i, 'months').format('MMM'));
    }

    return res;
  }

  public getCurrentYear(): number {
    return moment().year();
  }
}
