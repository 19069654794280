import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { emptyValueValidator } from '@validators/empty-value.validator';
import { ERROR_MESSAGES } from '@configs/form-errors';
import { DialogData } from '@models/dialog';

@Component({
  selector: 'app-note-dialog',
  templateUrl: './note-dialog.component.html'
})
export class NoteDialogComponent {
  public noteControl: UntypedFormControl = this.formBuilder.control(
    this.data.note,
    [Validators.required, emptyValueValidator]
  );

  public errorMsg: string = ERROR_MESSAGES.required;

  constructor(@Inject(MAT_DIALOG_DATA) private data: DialogData, private formBuilder: UntypedFormBuilder) { }
}
