<div class="eos-table-note-detail-renderer-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-1">
        <span>Note: </span>
      </div>
      <div class="col-md-11">
        <ng-container *ngTemplateOutlet="isLink ? linkTemp : textTemp"></ng-container>
      </div>
    </div>
  </div>
</div>

<!--Text template-->
<ng-template #textTemp>
  <span>{{params.data.notes}}</span>
</ng-template>

<!--Link template-->
<ng-template #linkTemp>
  <a class="eos-link" [routerLink]="link" target="_blank">{{params.data.notes}}</a>
</ng-template>
