import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ChipsAutocompleteComponent } from '@components/chips-autocomplete/chips-autocomplete.component';
import { EmailApi } from '@api/email.api';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { TableData } from '@models/table';
import { SearchEmailTemplate } from '@models/email';
import { AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';

@Component({
  selector: 'app-email-template-chips-autocomplete',
  templateUrl: './email-template-chips-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => EmailTemplateChipsAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: EmailTemplateChipsAutocompleteComponent,
      multi: true
    }
  ]
})
export class EmailTemplateChipsAutocompleteComponent extends ChipsAutocompleteComponent {
  @Input() public override label: string = 'Email Template';
  @Input() public override placeholder: string = 'Search for an email template...';

  protected override readonly switchMap: boolean = true;

  constructor(injector: Injector, private api: EmailApi) {
    super(injector);
  }

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return this.api.getSearchForTemplates({page: 1, limit: 10, keyword: query}).pipe(
      tap((data: TableData<SearchEmailTemplate>): TableData<SearchEmailTemplate> => this.allItems = data.items),
      map((): Array<AutocompleteValue> => this.composeAutocompleteValues()),
      catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
    );
  }
}
