import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ChipsAutocompleteComponent } from '@components/chips-autocomplete/chips-autocomplete.component';
import { UserApi } from '@api/user.api';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { Researcher } from '@models/user';
import { AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';

@Component({
  selector: 'app-assignee-chips-autocomplete',
  templateUrl: './assignee-chips-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => AssigneeChipsAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AssigneeChipsAutocompleteComponent,
      multi: true
    }
  ]
})
export class AssigneeChipsAutocompleteComponent extends ChipsAutocompleteComponent {
  @Input() public override label: string = 'Assignee To';
  @Input() public override placeholder: string = 'Search for a user...';
  @Input() public override startWith: boolean = true;

  protected override readonly switchMap: boolean = true;

  constructor(injector: Injector, private api: UserApi) {
    super(injector);
  }

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return this.api.searchForUsersByRole(
      'researcher',
      {page: 1, limit: 10, active: true, keyword: query}
    ).pipe(
      tap((researchers: Array<Researcher>): Array<Researcher> => this.allItems = researchers),
      map((): Array<AutocompleteValue> => this.composeAutocompleteValues()),
      catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
    );
  }
}
