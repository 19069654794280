import { Component } from '@angular/core';

import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { TableService } from '@services/table.service';
import { Flag } from '@models/flag';

@Component({
  selector: 'app-flag-chip-list-renderer',
  templateUrl: './flag-chip-list-renderer.component.html'
})
export class FlagChipListRendererComponent implements AgRendererComponent {
  public flags: Array<Flag> = [];

  private params!: ICellRendererParams;

  constructor(private service: TableService) { }

  public onRemoveBtnClick(flag: Flag): void {
    this.service.rendererAction$.next({
      type: 'flag-remove',
      item: flag,
      rowData: this.params?.node?.data,
      node: this.params?.node
    });
  }

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.flags = [...this.params.value];
  }

  public refresh(): boolean {
    return false;
  }
}
