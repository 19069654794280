import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable, of, takeUntil } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { AutocompleteComponent } from '@components/autocomplete/autocomplete.component';
import { OrderApi } from '@api/order.api';
import { UtilitiesService } from '@services/utilities.service';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';
import { Address, Addresses } from '@models/address';
import { ResponseError } from '@models/response';

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => AddressAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AddressAutocompleteComponent,
      multi: true
    }
  ]
})
export class AddressAutocompleteComponent extends AutocompleteComponent {
  @Input() public override label: string = 'Address';
  @Input() public override placeholder: string = 'Search for an address...';

  protected override readonly startWith: boolean = false;
  protected override readonly switchMap: boolean = true;

  private searchToken: string | null = null;

  constructor(injector: Injector, private api: OrderApi, private service: UtilitiesService) {
    super(injector);
  }

  public override onSelected(event: MatAutocompleteSelectedEvent): void {
    // TODO: should be rewrite after server issue EPRES-274
    const address: Address = event.option.value;

    if (address.google_place_id) {
      this.api.getAddress(this.searchToken, event.option.value.google_place_id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (address: Address): void => {
            event.option.value = address;
            super.onSelected(event);

            /** Reset display value from full address to street **/
            this.inputEl.nativeElement.value = address.line_1;
          },
          (error: string | ResponseError): void => { }
        );
    } else {
      super.onSelected(event);

      /** Reset display value from full address to street **/
      this.inputEl.nativeElement.value = address.line_1;
    }
  }

  public displayFn = (address: Address): string => {
    return address ? address.display : '';
  };

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return this.api.searchForAddresses(query).pipe(
      tap((addresses: Addresses): void => this.setAllItems(query, addresses)),
      map((): Array<AutocompleteValue> =>
        this.composeAutocompleteValuesWithCustomUserInput(query, this.service.createEmptyAddress)
      ),
      catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
    );
  }

  private setAllItems(query: string, addresses: Addresses): void {
    let hasTheSameAddress: boolean = false;

    this.allItems = addresses.items;
    this.searchToken = addresses.searchToken;

    // TODO: should be rewrite after server issue EPRES-274
    if (this.addKeywordFromInput && query !== '') {
      this.allItems.forEach((address: Address): void => {
        if (address.display === query || (address.line_1 && address.line_1 === query)) {
          hasTheSameAddress = true;
        }
      });

      if (!hasTheSameAddress) {
        this.allItems.unshift({
          ...this.service.createEmptyAddress(query)
        });
      }
    }
  }
}
