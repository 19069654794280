<div class="eos-table-dropdown-renderer-container">
  <button type="button" [disabled]="!data.children.length" mat-button [matMenuTriggerFor]="menu">
    <mat-icon>{{data.icon}}</mat-icon>
    {{data.label}}
  </button>
  <mat-menu #menu="matMenu">
    <button type="button" *ngFor="let child of data.children" mat-menu-item (click)="onBtnClick(child.action)">
      <mat-icon>{{child.icon}}</mat-icon>
      {{child.label}}
    </button>
  </mat-menu>
</div>
