import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '@core/guards/authentication.guard';
import { FlagResolver } from '@resolvers/flag.resolver';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./auth/sign-up/sign-up.module').then(m => m.SignUpModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./auth/password-recovery/password-recovery.module').then(m => m.PasswordRecoveryModule)
  },
  {
    path: 'change-password/:token',
    loadChildren: () => import('./auth/password-new/password-new.module').then(m => m.PasswordNewModule)
  },
  {
    path: '',
    loadChildren: () => import('./main-layout/main-layout.module').then(m => m.MainLayoutModule),
    canLoad: [AuthenticationGuard],
    resolve: [FlagResolver]
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
