import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { SettingsContext } from 'app/core/contexts/settings.context';
import { Settings } from 'app/shared/models/settings';

@Component({
  selector: 'app-system-notification',
  templateUrl: './system-notification.component.html',
  styleUrls: ['./system-notification.component.scss']
})
export class SystemNotificationComponent implements OnInit, OnDestroy {
  @Input('class') classList: string = '';

  @ViewChild('divEl')
  set setDivEl(el: ElementRef<HTMLDivElement>) {
    this.divEl = el;
  }

  public showNotification: boolean = false;

  private divEl: ElementRef<HTMLDivElement> | null = null;
  private readonly destroy$: Subject<void> = new Subject();

  constructor(private context: SettingsContext) { }

  ngOnInit(): void {
    this.context.notificationSettings$
      .pipe(
        takeUntil(this.destroy$),
        tap((): boolean => this.showNotification = false),
        filter((settings: Settings | null): boolean => !!settings?.notification?.systemwide),
        tap((): boolean => this.showNotification = true)
      )
      .subscribe((settings: Settings): void => {
        setTimeout((): void => {
          if (this.divEl) {
            this.divEl.nativeElement.innerHTML = settings.notification.systemwide;
          }
        }, 0)
      });

    this.classList += ' eos-system-notification-container';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
