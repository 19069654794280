import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import {
  CellPosition,
  Column,
  ICellRendererParams,
  IServerSideGetRowsParams,
  RowClassParams,
  TabToNextCellParams,
  ValueFormatterParams,
  ValueGetterParams
} from 'ag-grid-enterprise';
import { CellKeyDownEvent, SuppressKeyboardEventParams } from 'ag-grid-community';

import { ActionRendererComponent } from '@components/table/cell-renderers/action-renderer/action-renderer.component';
import { LinkRendererComponent } from '@components/table/cell-renderers/link-renderer/link-renderer.component';
import {
  NoteDetailRendererComponent
} from '@components/table/cell-renderers/note-detail-renderer/note-detail-renderer.component';
import {
  ReactiveInputRendererComponent
} from '@components/table/cell-renderers/reactive-input-renderer/reactive-input-renderer.component';
import {
  ReactiveOrderRendererComponent
} from '@components/table/cell-renderers/reactive-order-renderer/reactive-order-renderer.component';
import {
  ReactiveSelectRendererComponent
} from '@components/table/cell-renderers/select-renderer/reactive-select-renderer/reactive-select-renderer.component';
import { IconRendererComponent } from '@components/table/cell-renderers/icon-renderer/icon-renderer.component';
import { ListService } from '@services/list.service';
import { TimeService } from '@services/time.service';
import { TableService } from '@services/table.service';
import { UtilitiesService } from '@services/utilities.service';
import { ORDER_STATUSES } from '@configs/order';
import { ORDER_PAYMENT_STATUS_CONTROL_NAME_VALUE_MAPPER, ORDER_PAYMENT_STATUSES } from '@configs/checkbox';
import { PAGINATION_PAGE_SIZE, PAYMENT_TYPES } from '@configs/select';
import { BATCH_TABLE_COLUMN_IDS } from '@configs/table';
import {
  BatchPaymentFormValue,
  NewBatchPayment,
  NewBatchPaymentFormValue,
  Payment,
  RawBatchPaymentFormValue,
  RawRefundFormValue,
  RawTransferFormValue
} from '@models/payment';
import { TableConfig } from '@models/table';
import { SearchBoxConfig, SearchBoxValue } from '@models/search-box';
import { QueryParams } from '@models/api';
import { Order } from '@models/order';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(
    private listService: ListService,
    private timeService: TimeService,
    private tableService: TableService,
    private utilitiesService: UtilitiesService
  ) { }

  public emptyNewBatchPayment: NewBatchPayment = {
    id: '',
    order: null,
    company: '',
    address: '',
    orderTotal: '',
    orderBalance: null,
    groupBalance: null,
    check: '',
    amount: null,
    discount: null,
    newOrderBalance: null,
    newGroupBalance: null,
    paymentType: '',
    orderStatus: '',
    paymentStatus: '',
    notes: ''
  };

  public getBatchListTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'ID',
          field: 'id',
          minWidth: 110,
          maxWidth: 110
        },
        {
          headerName: 'Name',
          field: 'batch_name'
        },
        {
          headerName: 'Total Amount',
          field: 'batch_total',
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params)
        },
        {
          headerName: 'Deposit Amount',
          field: 'deposit_total',
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params)
        },
        {
          headerName: 'Payments',
          field: 'payments_total'
        },
        {
          headerName: 'Created By',
          field: 'created_by'
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params)
        },
        {
          headerName: 'Actions',
          field: 'actions',
          cellRenderer: ActionRendererComponent,
          cellRendererParams: {
            customParams: {
              type: 'batch'
            }
          },
          sortable: false,
          minWidth: 100
        }
      ],
      paginationPageSizeItems: PAGINATION_PAGE_SIZE
    };
  }

  public getBatchPaymentListTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'ID',
          field: 'id',
          cellRenderer: 'agGroupCellRenderer',
          minWidth: 90,
          maxWidth: 90
        },
        {
          headerName: 'Order ID',
          field: 'epr_id',
          cellRenderer: LinkRendererComponent,
          cellRendererParams: {
            customParams: {
              static: '/order-edit',
              dynamic: 'order_id',
              external: true
            }
          },
          minWidth: 100
        },
        {
          headerName: 'Client',
          field: 'company_name'
        },
        {
          headerName: 'Address',
          field: 'order_address_1',
          valueFormatter: this.utilitiesService.addressFormatter,
          minWidth: 200
        },
        {
          headerName: 'Check',
          field: 'payment_identifier'
        },
        {
          headerName: 'Order Total',
          field: 'orderTotal',
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params)
        },
        {
          headerName: 'Paid',
          field: 'amount',
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params)
        },
        {
          headerName: 'Available To Transfer',
          field: 'balance',
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params)
        },
        {
          headerName: 'Group Total',
          field: 'group_total',
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params)
        },
        {
          headerName: 'Group Balance',
          field: 'group_balance',
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params)
        },
        {
          headerName: 'Type',
          field: 'payment_type'
        },
        {
          headerName: 'Order Status',
          field: 'status'
        },
        {
          headerName: 'Payment Status',
          field: 'payment_status',
          cellClass: this.utilitiesService.getPaymentStatusTableCellClass
        },
        {
          headerName: 'Created By',
          field: 'created_by'
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params)
        },
        {
          headerName: 'Actions',
          field: 'actions',
          cellRenderer: ActionRendererComponent,
          cellRendererParams: {
            customParams: {
              type: 'order-payment'
            }
          },
          sortable: false,
          minWidth: 100
        }
      ],
      masterDetail: true,
      detailCellRenderer: <any>NoteDetailRendererComponent,
      isRowMaster: (payment: Payment) => !!payment.notes,
      detailRowHeight: 50,
      rowClassRules: {
        'eos-table-row-warning': (params: RowClassParams) => params.data?.payment_type.includes('Transfer'),
        'eos-table-row-danger': (params: RowClassParams) => params.data?.payment_type.includes('Refund')
      },
      paginationPageSizeItems: PAGINATION_PAGE_SIZE
    };
  }

  public getBatchPaymentNewListTableConfig(form: UntypedFormGroup): TableConfig {
    return {
      rowModelType: 'clientSide',
      paginationPageSizeItems: PAGINATION_PAGE_SIZE,
      paginationPageSizeItem: PAGINATION_PAGE_SIZE[PAGINATION_PAGE_SIZE.length - 1],
      context: {form},
      suppressCellFocus: false,
      tabToNextCell: this.tabToNextCell,
      onCellKeyDown: (event: CellKeyDownEvent) => this.onCellKeyDown(event),
      colDefs: [
        {
          headerName: 'Order ID',
          field: 'order',
          cellRenderer: ReactiveOrderRendererComponent,
          cellRendererParams: {
            customParams: {
              controlIndex: 0,
              value: null
            }
          },
          minWidth: 150
        },
        {
          headerName: 'Comp',
          field: 'company'
        },
        {
          headerName: 'Address',
          field: 'address',
          valueFormatter: this.utilitiesService.addressFormatter,
          minWidth: 200
        },
        {
          headerName: 'Order Total',
          field: 'orderTotal',
          valueFormatter: (params: ValueFormatterParams) => {
            return this.utilitiesService.emptyTextFormatter(params, this.utilitiesService.currencyFormatter);
          }
        },
        {
          headerName: 'Order Balance',
          field: 'orderBalance',
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value === null ? '' : this.utilitiesService.currencyFormatter(params);
          }
        },
        {
          headerName: 'Group Balance',
          field: 'groupBalance',
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value === null ? '' : this.utilitiesService.currencyFormatter(params);
          }
        },
        {
          headerName: 'Check',
          field: 'check',
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.node.data.order ? {component: ReactiveInputRendererComponent} : undefined;
          },
          cellRendererParams: {
            customParams: {
              controlIndex: 1,
              type: 'text',
              label: 'Check',
              placeholder: 'Check',
              isRequired: true,
              ariaLabel: 'Payment check number',
              value: null,
              onKeydown: this.enterToNextCell
            }
          },
          minWidth: 110,
          maxWidth: 150
        },
        {
          headerName: 'Amount',
          field: 'amount',
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.node.data.order ? {component: ReactiveInputRendererComponent} : undefined;
          },
          cellRendererParams: {
            customParams: {
              controlIndex: 2,
              type: 'text',
              label: 'Amount',
              placeholder: 'Amount',
              isRequired: true,
              ariaLabel: 'Payment amount',
              prefix: '$',
              eventType: 'amount-change-batch',
              value: null,
              mask: 'separator.2',
              allowNegativeNumbers: true,
              onKeydown: this.enterToNextCell
            }
          },
          minWidth: 110,
          maxWidth: 150
        },
        {
          headerName: 'Discount',
          field: 'discount',
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.node.data.order ? {component: ReactiveInputRendererComponent} : undefined;
          },
          cellRendererParams: {
            customParams: {
              controlIndex: 3,
              type: 'text',
              label: 'Discount',
              placeholder: 'Discount',
              isRequired: true,
              ariaLabel: 'Payment discount',
              prefix: '$',
              eventType: 'discount-change-batch',
              value: null,
              mask: 'separator.2',
              allowNegativeNumbers: true,
              onKeydown: this.enterToNextCell
            }
          },
          minWidth: 110,
          maxWidth: 150
        },
        {
          headerName: 'New Order Balance',
          field: 'newOrderBalance',
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value === null ? '' : this.utilitiesService.currencyFormatter(params);
          }
        },
        {
          headerName: 'New Group Balance',
          field: 'newGroupBalance',
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value === null ? '' : this.utilitiesService.currencyFormatter(params);
          }
        },
        {
          headerName: 'Pmt Type',
          field: 'paymentType',
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.node.data.order ? {component: ReactiveSelectRendererComponent} : undefined;
          },
          cellRendererParams: {
            customParams: {
              controlIndex: 4,
              items: PAYMENT_TYPES,
              label: 'Payment Type',
              ariaLabel: 'Payment type',
              classList: 'eos-table-payment-type-renderer-container',
              value: PAYMENT_TYPES[0].value
            }
          },
          minWidth: 110,
          maxWidth: 150
        },
        {
          headerName: 'Order Status',
          field: 'orderStatus'
        },
        {
          headerName: 'Pmt Status',
          field: 'paymentStatus',
          cellClass: this.utilitiesService.getPaymentStatusTableCellClass
        },
        {
          headerName: 'Notes',
          field: 'notes',
          cellRendererSelector: (params: ICellRendererParams) => {
            return params.node.data.order ? {component: ReactiveInputRendererComponent} : undefined;
          },
          cellRendererParams: {
            customParams: {
              controlIndex: 5,
              type: 'text',
              label: 'Notes',
              placeholder: 'Notes',
              ariaLabel: 'Payment notes',
              value: null,
              onKeydown: this.enterToNextCell
            }
          },
          minWidth: 110,
          maxWidth: 150
        }
      ],
      defaultColDef: {
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => this.suppressKeyboardEvent(params),
        sortable: false,
        minWidth: 88
      }
    };
  }

  public getPaymentListTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: '',
          field: 'company_from_qualia',
          cellRenderer: IconRendererComponent,
          valueGetter: (params: ValueGetterParams) => this.tableService.getIconRendererParams(params.data),
          sortable: false,
          minWidth: 68,
          maxWidth: 68
        },
        {
          headerName: 'Batch ID',
          field: 'batch_id',
          cellRenderer: 'agGroupCellRenderer',
          minWidth: 100,
          maxWidth: 100
        },
        {
          headerName: 'Order ID',
          field: 'epr_id',
          cellRenderer: LinkRendererComponent,
          cellRendererParams: {
            customParams: {
              static: '/order-edit',
              dynamic: 'order_id',
              external: true
            }
          },
          minWidth: 100
        },
        {
          headerName: 'Batch Name',
          field: 'batch_name'
        },
        {
          headerName: 'Company',
          field: 'company_name'
        },
        {
          headerName: 'Address',
          field: 'order_address_1',
          valueFormatter: this.utilitiesService.addressFormatter,
          minWidth: 200
        },
        {
          headerName: 'Check',
          field: 'payment_identifier'
        },
        {
          headerName: 'Type',
          field: 'payment_type'
        },
        {
          headerName: 'Amount',
          field: 'amount',
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params)
        },
        {
          headerName: 'Balance',
          field: 'balance',
          valueFormatter: (params: ValueFormatterParams) => this.utilitiesService.currencyFormatter(params)
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params)
        }
      ],
      masterDetail: true,
      detailCellRenderer: <any>NoteDetailRendererComponent,
      isRowMaster: (payment: Payment) => !!payment.notes,
      detailRowHeight: 50
    };
  }

  public getBatchListSearchBoxConfig(): SearchBoxConfig {
    return {
      searchSection: {
        search: true
      },
      filterSection: {
        orderStatuses: true,
        orderStatusItems: Object.values(ORDER_STATUSES),
        orderPaymentStatuses: true,
        orderPaymentStatusItems: ORDER_PAYMENT_STATUSES,
        date: true,
        company: true
      },
      searchSectionActions: true
    };
  }

  public getBatchPaymentListSearchBoxConfig(): SearchBoxConfig {
    return {
      searchSection: {
        search: true
      },
      filterSection: {
        orderStatuses: true,
        orderStatusItems: Object.values(ORDER_STATUSES),
        orderPaymentStatuses: true,
        orderPaymentStatusItems: ORDER_PAYMENT_STATUSES,
        date: true
      },
      searchSectionActions: true
    };
  }

  public getPaymentListSearchBoxConfig(): SearchBoxConfig {
    return {
      searchSection: {
        search: true
      },
      filterSection: {
        date: true,
        company: true,
        amount: true,
        address: true,
        notes: true,
        eprId: true,
        folio: true,
        check: true,
        refundId: true,
        onlyQualiaCompanies: true,
        doBusinessAs: true
      },
      searchSectionActions: true
    };
  }

  public prepareBatchListQueryParams(value: SearchBoxValue, params: IServerSideGetRowsParams): QueryParams {
    const {date, orderStatuses, orderPaymentStatuses} = value,
          {start, end} = date;

    return {
      ...this.listService.prepareBaseQueryParams(value, params),
      statuses: orderStatuses ? orderStatuses.join(',') : null,
      paymentStatuses: orderPaymentStatuses
        ? Object.entries(orderPaymentStatuses)
          .filter(([key, value]) => value ? key : value)
          .map(([key, value]) => ORDER_PAYMENT_STATUS_CONTROL_NAME_VALUE_MAPPER[key])
          .join(',')
        : null,
      from: start ? this.timeService.formatDateTime(this.timeService.addStartDay(start)) : null,
      to: end ? this.timeService.formatDateTime(this.timeService.addEndDay(end)) : null,
      company_id: value.company?.id,
      amount: value.amount,
      address: value.address,
      notes: value.notes,
      epr_id: value.eprId,
      folio_id: value.folio,
      check_id: value.check,
      refund_id: value.refundId,
      batch_id: value.batchId,
      order: 'p.id',
      kw_address: true,
      kw_amount: true,
      kw_payment: true,
      kw_refund: true,
      kw_epr_id: true,
      kw_folio_id: true,
      kw_batch_id: true,
      kw_notes: true
    };
  }

  public prepareBatchPaymentListQueryParams(value: SearchBoxValue, params: IServerSideGetRowsParams): QueryParams {
    const {date, orderStatuses, orderPaymentStatuses} = value,
          {start, end} = date;

    return {
      ...this.listService.prepareBaseQueryParams(value, params),
      statuses: orderStatuses ? orderStatuses.join(',') : null,
      paymentStatuses: orderPaymentStatuses
        ? Object.entries(orderPaymentStatuses)
          .filter(([key, value]) => value ? key : value)
          .map(([key, value]) => ORDER_PAYMENT_STATUS_CONTROL_NAME_VALUE_MAPPER[key])
          .join(',')
        : null,
      from: start ? this.timeService.formatDateTime(this.timeService.addStartDay(start)) : null,
      to: end ? this.timeService.formatDateTime(this.timeService.addEndDay(end)) : null
    };
  }

  public preparePaymentListQueryParams(value: SearchBoxValue, params: IServerSideGetRowsParams): QueryParams {
    const {start, end} = value.date;

    return {
      ...this.listService.prepareBaseQueryParams(value, params),
      from: start ? this.timeService.formatDateTime(this.timeService.addStartDay(start)) : null,
      to: end ? this.timeService.formatDateTime(this.timeService.addEndDay(end)) : null,
      company_id: value.company?.id,
      amount: value.amount,
      address: value.address,
      notes: value.notes,
      epr_id: value.eprId,
      folio_id: value.folio,
      check_id: value.check,
      refund_id: value.refundId,
      batch_id: value.batchId,
      onlyQualiaCompanies: value.onlyQualiaCompanies || null,
      doBusinessAs: value.doBusinessAs,
      order: 'p.id',
      kw_address: true,
      kw_amount: true,
      kw_payment: true,
      kw_refund: true,
      kw_epr_id: true,
      kw_folio_id: true,
      kw_batch_id: true,
      kw_notes: true
    };
  }

  public prepareBatchFormValue(formValue: RawRefundFormValue, payment: Payment): BatchPaymentFormValue {
    const {id} = payment;

    return {
      batch_id: payment.batch_id,
      payments: [{
        ...formValue,
        id,
        order_id: payment.order_id,
        payment_identifier: payment.payment_identifier,
        refunded_from: id,
        amount: -Math.abs(formValue.amount),
      }]
    };
  }

  public prepareNewBatchFormValue(formValue: RawBatchPaymentFormValue): BatchPaymentFormValue {
    const payments: Array<NewBatchPaymentFormValue> = [];

    Object.values(formValue).forEach((paymentFormValue: Array<any>) => {
      if (!paymentFormValue[0]) { return; }
      const [order, payment_identifier, amount, discount, payment_type, notes] = paymentFormValue;

      payments.push({
        order_id: (<Order>order).id,
        payment_identifier,
        amount: <number>amount,
        discount: <number>discount,
        payment_type,
        notes
      });
    });

    return {payments};
  }

  public prepareBatchTransferFormValue(formValue: RawTransferFormValue, payment: Payment): BatchPaymentFormValue {
    const {id, order_id} = payment;

    return {
      payments: [{
        ...formValue,
        id,
        order_id,
        amount: Math.abs(formValue.amount),
        transfer_to_order_id: formValue.order.id
      }]
    };
  }

  public getEmptyRowNodeDataChunk(currentRowDataLength: number, n: number = 50): Array<NewBatchPayment> {
    const res: Array<NewBatchPayment> = [],
          length: number = n + currentRowDataLength;

    for (let i = currentRowDataLength; i < length; i++) {
      res.push({...this.emptyNewBatchPayment, id: String(i)});
    }

    return res;
  }

  public mapOrderOnNewBatchPayment(order: Order, newBatchPayment: NewBatchPayment): NewBatchPayment {
    const {
        company_name: company,
        order_address_1: address,
        total_cost: orderTotal,
        balance: orderBalance,
        group_total: groupBalance,
        status: orderStatus,
        payment_status: paymentStatus,
        orderQualiaDiscount: discount
      } = order,
      {id, check, paymentType, notes} = newBatchPayment;

    return {
      id,
      order,
      company,
      address: this.utilitiesService.addressFormatter(<ValueFormatterParams>{value: address, data: order}),
      orderTotal,
      orderBalance,
      groupBalance,
      check,
      amount: orderBalance,
      discount: parseFloat(discount),
      newOrderBalance: 0,
      newGroupBalance: groupBalance - orderBalance,
      paymentType,
      orderStatus,
      paymentStatus,
      notes
    }
  }

  private tabToNextCell(params: TabToNextCellParams): (CellPosition | null) {
    const {
        api,
        columnApi,
        nextCellPosition,
        previousCellPosition,
        backwards
      } = params,
      allColumns: Array<Column> = columnApi.getColumns();

    let nextCell: CellPosition | null = null,
        nextColumnId: string,
        nextColumn: Column,
        nextCellRenderer: any;

    switch (previousCellPosition.column.getColId()) {
      case BATCH_TABLE_COLUMN_IDS.ORDER:
        nextColumnId = backwards ? BATCH_TABLE_COLUMN_IDS.NOTES : BATCH_TABLE_COLUMN_IDS.CHECK;
        break;
      case BATCH_TABLE_COLUMN_IDS.CHECK:
        nextColumnId = backwards ? BATCH_TABLE_COLUMN_IDS.ORDER : BATCH_TABLE_COLUMN_IDS.AMOUNT;
        break;
      case BATCH_TABLE_COLUMN_IDS.AMOUNT:
        nextColumnId = backwards ? BATCH_TABLE_COLUMN_IDS.CHECK : BATCH_TABLE_COLUMN_IDS.NOTES;
        break;
      case BATCH_TABLE_COLUMN_IDS.DISCOUNT:
        nextColumnId = backwards ? BATCH_TABLE_COLUMN_IDS.AMOUNT : BATCH_TABLE_COLUMN_IDS.NOTES;
        break;
      case BATCH_TABLE_COLUMN_IDS.NOTES:
        nextColumnId = backwards ? BATCH_TABLE_COLUMN_IDS.AMOUNT : BATCH_TABLE_COLUMN_IDS.ORDER;
        break;
      default:
        nextColumnId = nextCellPosition.column.getColId();
        break;
    }

    nextColumn = columnApi.getColumn(nextColumnId);

    if (!backwards) {
      nextCell = nextColumnId !== BATCH_TABLE_COLUMN_IDS.ORDER
        ? {...previousCellPosition, column: nextColumn}
        : nextCellPosition || {column: nextColumn, rowIndex: 0, rowPinned: previousCellPosition.rowPinned};
    } else {
      nextCell = nextColumnId !== BATCH_TABLE_COLUMN_IDS.NOTES
        ? {...previousCellPosition, column: nextColumn}
        : nextCellPosition || {
        column: nextColumn,
        rowIndex: api.paginationGetRowCount(),
        rowPinned: previousCellPosition.rowPinned
      };
    }

    nextCellRenderer = api.getCellRendererInstances({columns: [nextColumn]})[nextCell.rowIndex];
    setTimeout(() => nextCellRenderer?.setFocus(), 50);

    return nextCell;
  }

  private enterToNextCell(event: KeyboardEvent, that: ReactiveInputRendererComponent): void {
    const {api, columnApi, data} = that.params,
          currentRowIndex: number = api.getFocusedCell().rowIndex,
          nextRowIndex: number = currentRowIndex + 1 > api.getLastDisplayedRow() ? 0 : currentRowIndex + 1,
          nextColumn: Column = columnApi.getColumn(BATCH_TABLE_COLUMN_IDS.ORDER);
    let nextCellRenderer: any;
    if (!this.utilitiesService.isEnterKey(event) || data.newOrderBalance !== 0) { return; }

    nextCellRenderer = api.getCellRendererInstances({columns: [nextColumn]})[nextRowIndex];

    api.setFocusedCell(nextRowIndex, BATCH_TABLE_COLUMN_IDS.ORDER);
    setTimeout(() => nextCellRenderer?.setFocus(), 50);
  }

  private onCellKeyDown(e: CellKeyDownEvent): void {
    const {api, columnApi, event, rowIndex} = e,
          backwards: boolean = this.utilitiesService.isHomeKey(<KeyboardEvent>event),
          allColumns: Array<Column> = columnApi.getColumns();
    let nextColumnId: string,
        nextColumn: Column,
        nextCellRenderer: any;
    if (!(backwards || this.utilitiesService.isEndKey(<KeyboardEvent>event))) { return; }

    nextColumnId = backwards ? allColumns[0].getColId() : allColumns[allColumns.length - 1].getColId();
    nextColumn = columnApi.getColumn(nextColumnId);
    nextCellRenderer = api.getCellRendererInstances({columns: [nextColumn]})[rowIndex];

    e.api.setFocusedCell(e.rowIndex, nextColumnId);
    setTimeout(() => nextCellRenderer?.setFocus(), 50);
  }

  private suppressKeyboardEvent(params: SuppressKeyboardEventParams): boolean {
    const event: KeyboardEvent = params.event,
          needSuppress: boolean = this.utilitiesService.isHomeKey(event) || this.utilitiesService.isEndKey(event);

    if (needSuppress) {
      event.preventDefault();
    }

    return needSuppress;
  }
}
