import { RoleMatrix } from '@models/role-matrix';
import { ORDER_STATUSES } from '@configs/order';

export enum USER_ROLES {
  ADMIN = 'admin',
  MANAGER = 'manager',
  MARKETER = 'marketer',
  ACCOUNTANT = 'accountant',
  LEAD = 'lead',
  RESEARCHER = 'researcher',
  QC = 'qc',
  CLIENT = 'client',
  CLIENT_ADMIN = 'clientAdmin'
}

export const ROLE_MATRIX: RoleMatrix = {
  menu: {
    systemReport: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],
    loginByUserList: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],

    dashboard: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],

    orderIssues: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],

    orderNotifications: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],

    orders: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT,
      USER_ROLES.CLIENT_ADMIN
    ],
    orderList: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT,
      USER_ROLES.CLIENT_ADMIN
    ],
    addOrder: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT,
      USER_ROLES.CLIENT_ADMIN
    ],

    companies: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],
    companyList: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],
    addCompany: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],

    users: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT_ADMIN
    ],
    userList: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT_ADMIN
    ],
    inactiveUserList: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.RESEARCHER
    ],
    addUser: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT_ADMIN
    ],

    reports: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT
    ],
    jobsByCompany: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT
    ],
    jobsByCounty: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],
    salesByMarketer: [
      USER_ROLES.ADMIN,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT
    ],
    skippedTasks: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],
    ordersWithNoTasks: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],
    companyStatement: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    customerStatement: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    /** Temporary remove separate view. The dashboard has this report **/
    /*ordersByResearcher: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],*/
    dueOverdueOrders: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],
    completedOrdersByResearcher: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT
    ],
    orderIssuesByResearcher: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],
    ordersToResearchStatus: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],
    revenueByResearcher: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    commissionsPaidByMarketer: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    commissionsOrderedByMarketer: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    cancelledOrders: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    sentToClientByUser: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],

    payments: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    batchPaymentList: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    addBatch: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    paymentList: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],

    settings: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.CLIENT_ADMIN
    ],
    companyEdit: [USER_ROLES.CLIENT_ADMIN],
    emailTemplate: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER
    ],
    notificationEvent: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.CLIENT_ADMIN
    ],
    qaResearcherAssignment: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER
    ],
    system: [USER_ROLES.ADMIN],
    smtp: [USER_ROLES.ADMIN],
    systemFlags: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    discounts: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],

    integrations: [USER_ROLES.ADMIN],
    qualiaIntegration: [USER_ROLES.ADMIN],
    reswareIntegration: [USER_ROLES.ADMIN],
    unitedTaxIntegration: [USER_ROLES.ADMIN],

    techSupport: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER
    ],
    issueForOfficeAdmin: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],
    issueForDevTeam: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],

    eliteNet: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ]
  },

  headerMenu: {
    dashboard: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],
    profile: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT,
      USER_ROLES.CLIENT_ADMIN
    ],
    logout: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT,
      USER_ROLES.CLIENT_ADMIN
    ]
  },

  route: {
    'login-by-user': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],

    dashboard: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],

    'order-issues': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],

    'order-notifications': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],

    orders: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT,
      USER_ROLES.CLIENT_ADMIN
    ],
    'order-new': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT,
      USER_ROLES.CLIENT_ADMIN
    ],
    'order-edit': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT,
      USER_ROLES.CLIENT_ADMIN
    ],

    companies: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],
    'company-new': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],
    'company-edit': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT_ADMIN
    ],

    users: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT_ADMIN
    ],
    'inactive-users': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.RESEARCHER
    ],
    'user-new': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT_ADMIN
    ],
    'user-edit': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT_ADMIN
    ],

    'jobs-by-company': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT
    ],
    'jobs-by-county': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],
    'sales-by-marketer': [
      USER_ROLES.ADMIN,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT
    ],
    'skipped-tasks': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],
    'no-tasks': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],
    'company-statement': [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    'customer-statement': [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    /** Temporary remove separate view. The dashboard has this report **/
    /*'orders-by-researcher': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],*/
    'orders-by-due': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],
    'completed-orders-by-researcher': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT
    ],
    'order-issues-by-researcher': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],
    'orders-to-research-status': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],
    'revenue-by-researcher': [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    'commissions-paid-by-marketer': [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    'commissions-ordered-by-marketer': [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    'sent-to-client-by-user': [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],

    batches: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    'batch-edit': [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    'batch-new': [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    payments: [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],

    'email-templates': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER
    ],
    'email-template-new': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER
    ],
    'email-template-edit': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER
    ],
    'notification-events': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.CLIENT_ADMIN
    ],
    'notification-event-edit': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.CLIENT_ADMIN
    ],
    'qc-researcher': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER
    ],
    'system-settings': [USER_ROLES.ADMIN],
    'smtp-settings': [USER_ROLES.ADMIN],
    'system-flags': [
      USER_ROLES.ADMIN,
      USER_ROLES.ACCOUNTANT
    ],
    'discount-settings': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT
    ],

    'qualia-integration': [USER_ROLES.ADMIN],
    'resware-integration': [USER_ROLES.ADMIN],
    'united-tax-integration': [USER_ROLES.ADMIN],

    'support-office': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],
    'support-dev': [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC
    ],
    profile: [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.MARKETER,
      USER_ROLES.ACCOUNTANT,
      USER_ROLES.LEAD,
      USER_ROLES.RESEARCHER,
      USER_ROLES.QC,
      USER_ROLES.CLIENT,
      USER_ROLES.CLIENT_ADMIN
    ]
  },

  filter: {
    order: {
      default: [
        'products',
        'orderOrdered',
        'orderPromised',
        'orderNeeded',
        'orderCanceled',
        'orderSentToClient',
        'orderStatuses',
        'fileNumber',
        'orderPaymentStatuses',
        'orderNoAssignee',
        'orderAssignee',
        'company',
        'doBusinessAs',
        'owner',
        'buyer',
        'folio',
        'county',
        'state',
        'vendor',
        'orderFlags',
        'orderOverdue',
        'onlyQualiaOrders'
      ],
      [USER_ROLES.CLIENT]: [
        'products',
        'orderOrdered',
        'orderNeeded',
        'orderCanceled',
        'orderStatuses',
        'orderPaymentStatuses'
      ],
      [USER_ROLES.CLIENT_ADMIN]: [
        'onlyClientOrders',
        'products',
        'orderOrdered',
        'orderPromised',
        'orderNeeded',
        'orderCanceled',
        'orderStatuses',
        'orderPaymentStatuses'
      ]
    },
    notification: {
      default: [
        'orderNotificationStatuses',
        'email',
        'orderNotificationRecipients',
        'date',
        'orderNotificationStates',
        'emailTemplates'
      ],
      [USER_ROLES.ADMIN]: [
        'orderNotificationStatuses',
        'email',
        'orderNotificationRecipients',
        'date',
        'orderNotificationStates',
        'emailTemplates',
        'orderNotificationAuthors'
      ],
      [USER_ROLES.CLIENT]: [
        'orderNotificationStatuses',
        'email',
        'orderNotificationRecipients',
        'date',
        'orderNotificationStates'
      ],
      [USER_ROLES.CLIENT_ADMIN]: [
        'orderNotificationStatuses',
        'email',
        'orderNotificationRecipients',
        'date',
        'orderNotificationStates'
      ]
    },
    file: {
      default: ['fileTypes'],
      [USER_ROLES.CLIENT]: [],
      [USER_ROLES.CLIENT_ADMIN]: []
    },
    note: {
      default: [
        'noteTypes',
        'stickyNotes'
      ],
      [USER_ROLES.CLIENT]: [],
      [USER_ROLES.CLIENT_ADMIN]: []
    },
    issue: {
      default: [
        'orderIssueStatuses',
        'orderIssueDate',
        'orderIssueQAs',
        'orderIssueResearchers'
      ],
      [USER_ROLES.RESEARCHER]: [
        'orderIssueStatuses',
        'orderIssueDate'
      ]
    }
  },

  orderStatusFilter: {
    default: [ORDER_STATUSES.NEW],
    [USER_ROLES.RESEARCHER]: [
      ORDER_STATUSES.NEW,
      ORDER_STATUSES.ASSIGNED,
      ORDER_STATUSES.RESEARCH,
      ORDER_STATUSES.TO_BE_BUILT,
      ORDER_STATUSES.REVIEW
    ],
    [USER_ROLES.QC]: [
      ORDER_STATUSES.REVIEW
    ],
    [USER_ROLES.CLIENT]: [],
    [USER_ROLES.CLIENT_ADMIN]: []
  },

  tableColumn: {
    order: {
      default: [
        'epr_id',
        'flags',
        'actions',
        'status',
        'assigned_to_display_name',
        'product_name',
        'created',
        'company_name',
        'full_address',
        'order_county',
        'order_state',
        'order_file_number',
        'dates',
        'order_promise_date_at',
        'order_needed_date_at',
        'order_closing_date_at',
        'cancellation_pending_date_at',
        'canceled_at',
        'order_check_rush_order',
        'payment_status'
      ],
      [USER_ROLES.CLIENT]: [
        'epr_id',
        'actions',
        'status',
        'assigned_to_display_name',
        'product_name',
        'created',
        'full_address',
        'order_county',
        'order_state',
        'order_file_number',
        'dates',
        'order_needed_date_at',
        'order_closing_date_at',
        'order_check_rush_order'
      ],
      [USER_ROLES.CLIENT_ADMIN]: [
        'epr_id',
        'actions',
        'status',
        'assigned_to_display_name',
        'product_name',
        'created',
        'full_address',
        'order_county',
        'order_state',
        'order_file_number',
        'dates',
        'order_needed_date_at',
        'order_closing_date_at',
        'order_check_rush_order'
      ],
      [USER_ROLES.ACCOUNTANT]: [
        'epr_id',
        'flags',
        'actions',
        'status',
        'assigned_to_display_name',
        'product_name',
        'created',
        'company_name',
        'full_address',
        'order_county',
        'order_state',
        'order_file_number',
        'accounting',
        'balance',
        'price',
        'totalHardCosts',
        'totalSH',
        'misc_amount',
        'totalUdDiscNegative',
        'orderTotal',
        'discountNegative',
        'totalBilled',
        'paymentsNegative',
        'dates',
        'order_closing_date_at',
        'billed_date_at',
        'sent_to_client_date',
        'payment_status'
      ],
      [USER_ROLES.MARKETER]: [
        'epr_id',
        'flags',
        'actions',
        'status',
        'assigned_to_display_name',
        'product_name',
        'created',
        'ordered_by',
        'company_name',
        'full_address',
        'order_county',
        'order_state',
        'order_file_number',
        'dates',
        'order_promise_date_at',
        'order_needed_date_at',
        'order_closing_date_at',
        'cancellation_pending_date_at',
        'canceled_at',
        'order_check_rush_order',
        'payment_status'
      ],
      [USER_ROLES.RESEARCHER]: [
        'epr_id',
        'flags',
        'actions',
        'status',
        'assigned_to_display_name',
        'product_name',
        'created',
        'ordered_by',
        'company_name',
        'full_address',
        'order_county',
        'order_state',
        'order_file_number',
        'dates',
        'order_promise_date_at',
        'order_needed_date_at',
        'order_closing_date_at',
        'cancellation_pending_date_at',
        'canceled_at',
        'order_check_rush_order',
        'payment_status'
      ],
      [USER_ROLES.QC]: [
        'epr_id',
        'flags',
        'actions',
        'status',
        'assigned_to_display_name',
        'product_name',
        'created',
        'ordered_by',
        'company_name',
        'full_address',
        'order_county',
        'order_state',
        'order_file_number',
        'dates',
        'order_promise_date_at',
        'order_needed_date_at',
        'order_closing_date_at',
        'cancellation_pending_date_at',
        'canceled_at',
        'order_check_rush_order',
        'payment_status'
      ]
    },
    file: {
      default: [
        'name',
        'created_by',
        'created',
        'internal',
        'is_deleted'
      ],
      [USER_ROLES.CLIENT]: [
        'name',
        'created_by',
        'created',
        'is_deleted'
      ],
      [USER_ROLES.CLIENT_ADMIN]: [
        'name',
        'created_by',
        'created',
        'is_deleted'
      ]
    },
    note: {
      default: [
        'note',
        'created_by',
        'created',
        'internal',
        'is_deleted'
      ],
      [USER_ROLES.CLIENT]: [
        'note',
        'created_by',
        'created'
      ],
      [USER_ROLES.CLIENT_ADMIN]: [
        'note',
        'created_by',
        'created'
      ]
    },
    task: {
      default: [
        'name',
        'stage',
        'updated_by',
        'updated',
        'note',
        'actions'
      ],
      [USER_ROLES.CLIENT]: [
        'name',
        'stage',
        'updated_by',
        'updated',
        'note'
      ],
      [USER_ROLES.CLIENT_ADMIN]: [
        'name',
        'stage',
        'updated_by',
        'updated',
        'note'
      ]
    },
    notification: {
      default: [
        'id',
        'name',
        'eventkey',
        'email_name',
        'active',
        'actions'
      ],
      [USER_ROLES.CLIENT_ADMIN]: [
        'name',
        'active',
        'actions'
      ]
    },
    orderPayment: {
      default: [
        'id',
        'batch_id',
        'batch_name',
        'payment_identifier',
        'amount',
        'available_balance',
        'payment_type',
        'created_by',
        'created',
        'internal',
        'actions'
      ],
      [USER_ROLES.MARKETER]: [
        'id',
        'batch_id',
        'batch_name',
        'payment_identifier',
        'amount',
        'available_balance',
        'payment_type',
        'created_by',
        'created',
        'internal'
      ],
      [USER_ROLES.LEAD]: [
        'id',
        'batch_id',
        'batch_name',
        'payment_identifier',
        'amount',
        'available_balance',
        'payment_type',
        'created_by',
        'created',
        'internal'
      ],
      [USER_ROLES.RESEARCHER]: [
        'id',
        'batch_id',
        'batch_name',
        'payment_identifier',
        'amount',
        'available_balance',
        'payment_type',
        'created_by',
        'created',
        'internal'
      ],
      [USER_ROLES.QC]: [
        'id',
        'batch_id',
        'batch_name',
        'payment_identifier',
        'amount',
        'available_balance',
        'payment_type',
        'created_by',
        'created',
        'internal'
      ]
    },
    pricing: {
      default: [
        'name',
        'product_price',
        'price',
        'created',
        'created_by_display_name',
        'notes',
        'deactivate_reason',
        'active',
        'actions'
      ],
      [USER_ROLES.MARKETER]: [
        'name',
        'product_price',
        'price',
        'created',
        'created_by_display_name',
        'notes',
        'deactivate_reason',
        'active'
      ],
      [USER_ROLES.LEAD]: [
        'name',
        'product_price',
        'price',
        'created',
        'created_by_display_name',
        'notes',
        'deactivate_reason',
        'active'
      ],
      [USER_ROLES.RESEARCHER]: [
        'name',
        'product_price',
        'price',
        'created',
        'created_by_display_name',
        'notes',
        'deactivate_reason',
        'active'
      ],
      [USER_ROLES.QC]: [
        'name',
        'product_price',
        'price',
        'created',
        'created_by_display_name',
        'notes',
        'deactivate_reason',
        'active'
      ]
    }
  },

  tableAction: {
    note: {
      default: [
        'edit',
        'markAsDeleted',
        'restore'
      ],
      [USER_ROLES.MARKETER]: ['markAsDeleted'],
      [USER_ROLES.ACCOUNTANT]: [
        'edit',
        'markAsDeleted'
      ],
      [USER_ROLES.LEAD]: ['markAsDeleted'],
      [USER_ROLES.RESEARCHER]: ['markAsDeleted'],
      [USER_ROLES.QC]: ['markAsDeleted']
    },
    file: {
      default: [
        'download',
        'markAsDeleted',
        'restore'
      ],
      [USER_ROLES.MARKETER]: [
        'download',
        'markAsDeleted'
      ],
      [USER_ROLES.ACCOUNTANT]: [
        'download',
        'markAsDeleted'
      ],
      [USER_ROLES.LEAD]: [
        'download',
        'markAsDeleted'
      ],
      [USER_ROLES.RESEARCHER]: [
        'download',
        'markAsDeleted'
      ],
      [USER_ROLES.QC]: [
        'download',
        'markAsDeleted'
      ],
      [USER_ROLES.CLIENT]: ['download'],
      [USER_ROLES.CLIENT_ADMIN]: ['download']
    },
    orderPayment: {
      default: [
        'refund',
        'transfer',
        'returnTransfer',
        'note'
      ],
      [USER_ROLES.MANAGER]: ['note']
    }
  },

  panelAction: {
    task: {
      default: ['create'],
      [USER_ROLES.RESEARCHER]: [],
      [USER_ROLES.CLIENT]: [],
      [USER_ROLES.CLIENT_ADMIN]: []
    },
    notification: {
      default: ['email'],
      [USER_ROLES.CLIENT]: [],
      [USER_ROLES.CLIENT_ADMIN]: []
    },
    payment: {
      default: ['make'],
      [USER_ROLES.MANAGER]: [],
      [USER_ROLES.MARKETER]: [],
      [USER_ROLES.LEAD]: [],
      [USER_ROLES.RESEARCHER]: [],
      [USER_ROLES.QC]: []
    },
    marketer: {
      default: ['add'],
      [USER_ROLES.MARKETER]: [],
      [USER_ROLES.ACCOUNTANT]: [],
      [USER_ROLES.LEAD]: [],
      [USER_ROLES.RESEARCHER]: [],
      [USER_ROLES.QC]: []
    },
    pricing: {
      default: ['create'],
      [USER_ROLES.MARKETER]: [],
      [USER_ROLES.LEAD]: [],
      [USER_ROLES.RESEARCHER]: [],
      [USER_ROLES.QC]: []
    },
    issue: {
      default: ['create'],
      [USER_ROLES.RESEARCHER]: []
    },
    viewIssue: {
      default: [
        'edit',
        'complete',
        'cancel',
        'approve-completion',
        'reject-completion',
        'approve-cancellation',
        'reject-cancellation'
      ],
      [USER_ROLES.RESEARCHER]: [
        'complete',
        'cancel',
        'approve-completion',
        'reject-completion',
        'approve-cancellation',
        'reject-cancellation'
      ]
    },
    newUser: {
      default: ['smtp'],
      [USER_ROLES.CLIENT]: [],
      [USER_ROLES.CLIENT_ADMIN]: []
    },
    templateFile: {
      default: [],
      [USER_ROLES.ADMIN]: ['upload']
    }
  }
};
