import { Checkbox } from '@models/checkbox';

export const ORDER_PAYMENT_STATUSES: Array<Checkbox> = [
  {
    id: 0,
    value: false,
    viewValue: 'Unpaid',
    controlName: 'unpaid',
    ariaLabel: 'Unpaid order payment status'
  },
  {
    id: 1,
    value: false,
    viewValue: 'Partially Paid',
    controlName: 'partially',
    ariaLabel: 'Partially paid order payment status'
  },
  {
    id: 2,
    value: false,
    viewValue: 'Paid In Full',
    controlName: 'paid',
    ariaLabel: 'Paid in full order payment status'
  }
];

/** Is used for order search query parameters. (Order service) **/
export const ORDER_PAYMENT_STATUS_CONTROL_NAME_VALUE_MAPPER: { [key: string]: string } = {
  paid: 'Paid in Full',
  partially: 'Partially Paid',
  unpaid: 'Unpaid'
};

export const ORDER_OVERDUE: Array<Checkbox> = [{
  id: 0,
  value: false,
  viewValue: 'Show Overdue Orders',
  controlName: 'overdue',
  ariaLabel: 'Show overdue orders'
}];

export const NEW_ORDER_SERVICES: Array<Checkbox> = [
  {
    id: 0,
    value: false,
    viewValue: 'Estoppel',
    controlName: 'add_estoppel',
    ariaLabel: 'Add estoppel field set'
  },
  {
    id: 1,
    value: false,
    viewValue: 'Survey',
    controlName: 'add_survey',
    ariaLabel: 'Add survey field set'
  }
];

export const NEW_ORDER_CHECKS: Array<Checkbox> = [
  {
    id: 0,
    value: false,
    viewValue: 'Check For Rush Order',
    controlName: 'check_rush_order',
    ariaLabel: 'Order is urgent'
  },
  {
    id: 1,
    value: false,
    viewValue: 'Property In Foreclosure',
    controlName: 'in_foreclosure',
    ariaLabel: 'Property are in foreclosure'
  },
  {
    id: 2,
    value: false,
    viewValue: 'Property Refinanced',
    controlName: 'property_refinanced',
    ariaLabel: 'Property are refinanced'
  }
];

export const SYSTEM_FLAG_ASSIGNMENT_PERMISSIONS: Array<Checkbox> = [
  {
    id: 0,
    value: false,
    viewValue: 'Can Add',
    controlName: 'can_add',
    ariaLabel: 'System flag assignment can add permission'
  },
  {
    id: 1,
    value: false,
    viewValue: 'Can Edit',
    controlName: 'can_edit',
    ariaLabel: 'System flag assignment can edit permission'
  }
];

export const ORDER_NOTIFICATION_STATUSES: Array<Checkbox> = [
  {
    id: 0,
    value: false,
    viewValue: 'Complete',
    controlName: 'complete',
    ariaLabel: 'Order notification complete status'
  },
  {
    id: 1,
    value: false,
    viewValue: 'Scheduled',
    controlName: 'scheduled',
    ariaLabel: 'Order notification scheduled status'
  },
  {
    id: 2,
    value: false,
    viewValue: 'Error',
    controlName: 'error',
    ariaLabel: 'Order notification error status'
  },
  {
    id: 3,
    value: false,
    viewValue: 'Pending',
    controlName: 'pending',
    ariaLabel: 'Order notification pending status'
  }
];

export const ORDER_NOTIFICATION_RECIPIENTS: Array<Checkbox> = [
  {
    id: 0,
    value: false,
    viewValue: 'From',
    controlName: 'from',
    ariaLabel: 'Order notification from recipient'
  },
  {
    id: 1,
    value: false,
    viewValue: 'To',
    controlName: 'to',
    ariaLabel: 'Order notification to recipient'
  },
  {
    id: 2,
    value: false,
    viewValue: 'CC',
    controlName: 'cc',
    ariaLabel: 'Order notification cc recipient'
  },
  {
    id: 3,
    value: false,
    viewValue: 'BCC',
    controlName: 'bcc',
    ariaLabel: 'Order notification bcc recipient'
  }
];

export const ORDER_NOTIFICATION_STATES: Array<Checkbox> = [
  {
    id: 0,
    value: false,
    viewValue: 'Created',
    controlName: 'created',
    ariaLabel: 'Order notification created state'
  },
  {
    id: 1,
    value: false,
    viewValue: 'Scheduled',
    controlName: 'scheduled',
    ariaLabel: 'Order notification scheduled state'
  },
  {
    id: 2,
    value: false,
    viewValue: 'Sent',
    controlName: 'finished',
    ariaLabel: 'Order notification sent state'
  }
];

export const COMPANY_INTEGRATIONS: Array<Checkbox> = [
  {
    id: 0,
    value: false,
    viewValue: 'Belongs to Resware company',
    controlName: 'company_from_resware',
    ariaLabel: 'A new company which is belongs to the Resware'
  },
  {
    id: 1,
    value: false,
    viewValue: 'Belongs to Qualia company',
    controlName: 'company_from_qualia',
    ariaLabel: 'A new company which is belongs to the Qualia'
  }
];

export const COMPANY_PRICING_INFORMATION: Array<Checkbox> = [
  {
    id: 0,
    value: false,
    viewValue: 'Company has special pricing',
    controlName: 'company_has_custom_pricing',
    ariaLabel: 'A new company that has special pricing'
  }
];
