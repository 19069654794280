import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Subject } from 'rxjs';

import jwt_decode from 'jwt-decode';

import { ColDef, ColGroupDef } from 'ag-grid-enterprise';

import { ROLE_MATRIX, USER_ROLES } from '@configs/role-matrix';
import { TABLE_TYPES } from '@configs/table';
import { PANEL_TYPES } from '@configs/panel';
import { FILTER_TYPES } from '@configs/search-box';
import { AuthTokens, AuthDetails, JWT, SignUpFormValue } from '@models/auth';
import { User } from '@models/user';
import { Company } from '@models/company';
import { FilterSectionConfig } from '@models/search-box';
import { RoleMatrixPermissions } from '@models/role-matrix';
import { PanelAction } from '@models/panel';
import { MenuItem } from '@models/menu-item';
import { SearchEmailTemplate } from '@models/email';
import { HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public hasValidAccessToken$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public accessTokenUpdated$: Subject<void> = new Subject<void>();

  public redirectUrl: string | null = null;

  constructor(private router: Router) { }

  get authDetails(): AuthDetails | null {
    const parsedToken: JWT | null = this.parseToken(this.accessToken),
          authDetails: AuthDetails | null = parsedToken?.data || null;

    if (!authDetails) {
      this.removeAuthDetails();
      this.router.navigate(['/login']);
    }

    return authDetails;
  }

  get tokens(): AuthTokens {
    return {
      jwt: this.accessToken,
      jwt_refresh: this.refreshToken
    };
  }

  set tokens(tokens: AuthTokens) {
    const {jwt: accessToke, jwt_refresh: refreshToken} = tokens;

    this.accessToken = accessToke;
    this.refreshToken = refreshToken;
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken');
  }

  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
    this.accessTokenUpdated$.next();
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken');
  }

  set refreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  get user(): User {
    return this.authDetails.user;
  }

  get role(): string {
    return this.authDetails.role;
  }

  get company(): Company {
    return this.authDetails.company;
  }

  get sendInvoiceEmailTemplate(): SearchEmailTemplate {
    return this.authDetails.sendInvoiceEmailTemplate;
  }

  public removeAuthDetails(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  public addAccessTokenToRequest(request: HttpRequest<unknown>): HttpRequest<unknown> {
    return request.clone({headers: request.headers.set('Authorization', `Bearer ${this.accessToken}`)});
  }

  public isLoggedIn(): boolean {
    return this.accessToken !== null;
  }

  /** General case **/
  public isClient(userRole?: string | undefined): boolean {
    const role: string = userRole || this.role;
    return role === USER_ROLES.CLIENT || role === USER_ROLES.CLIENT_ADMIN;
  }

  /**
   * In several places need to define client like user with only role 'client'.
   * In general case client is a user with either 'client' or 'clientAdmin' role.
   * **/
  public isPureClient(): boolean {
    return this.role === USER_ROLES.CLIENT;
  }

  public isClientAdmin(): boolean {
    return this.role === USER_ROLES.CLIENT_ADMIN;
  }

  public isAdmin(): boolean {
    return this.role === USER_ROLES.ADMIN;
  }

  public isManager(): boolean {
    return this.role === USER_ROLES.MANAGER;
  }

  public isMarketer(): boolean {
    return this.role === USER_ROLES.MARKETER;
  }

  public isAccountant(): boolean {
    return this.role === USER_ROLES.ACCOUNTANT;
  }

  public isResearcher(): boolean {
    return this.role === USER_ROLES.RESEARCHER;
  }

  public isQC(): boolean {
    return this.role === USER_ROLES.QC;
  }

  public prepareSignUpFormValue(formValue: SignUpFormValue): SignUpFormValue {
    const {company, newCompany, ...rest} = formValue;
    return {
      ...rest,
      company: {
        ...company,
        ...newCompany
      }
    };
  }

  public filterTableColumns(type: TABLE_TYPES, columnDefs: Array<ColDef | ColGroupDef>): Array<ColDef | ColGroupDef> {
    const permissions: RoleMatrixPermissions = ROLE_MATRIX.tableColumn[type],
          columnsByRole: Array<string> = permissions[this.role] || permissions['default'];

    return columnDefs.filter((column: ColDef | ColGroupDef) => {
      if ((<ColGroupDef>column).children) {
        (<ColGroupDef>column).children = this.filterTableColumns(type, (<ColGroupDef>column).children);
      }

      return columnsByRole.includes((<ColDef>column).field);
    });
  }

  public filterSearchBoxConfig(type: FILTER_TYPES, config: FilterSectionConfig): FilterSectionConfig | null {
    const permissions: RoleMatrixPermissions = ROLE_MATRIX.filter[type],
          searchConfigByRole: Array<string> = permissions[this.role] || permissions['default'];
    let includeFilter: boolean = false,
        hasFilterSection: boolean = false;

    Object.entries(config).forEach(([key, value]) => {
      /** Make sure that required property has information about should we render filter element or not **/
      if (typeof value !== 'boolean' || key.includes('Value')) { return; }

      includeFilter = searchConfigByRole.includes(key);
      config[key] = includeFilter;

      if (includeFilter) {
        hasFilterSection = true;
      }
    });

    return hasFilterSection ? config : null;
  }

  public filterOrderStatusValues(): Array<string> {
    const permissions: RoleMatrixPermissions = ROLE_MATRIX.orderStatusFilter;
    return permissions[this.role] || permissions['default'];
  }

  public filterPanelActions(type: PANEL_TYPES, actions: Array<PanelAction>): Array<PanelAction> {
    const permissions: RoleMatrixPermissions = ROLE_MATRIX.panelAction[type],
          actionsByRole: Array<string> = permissions[this.role] || permissions['default'];

    return actions.filter((action: PanelAction) => actionsByRole.includes(action.type));
  }

  public filterTableActions(type: TABLE_TYPES, item: MenuItem): MenuItem {
    const permissions: RoleMatrixPermissions = ROLE_MATRIX.tableAction[type],
          actionsByRole: Array<string> = permissions[this.role] || permissions['default'];

    item.children = item.children.filter((item: MenuItem) => actionsByRole.includes(item.value));

    return item;
  }

  public sortOrderListTableColumns(a: ColDef, b: ColDef): number {
    let priorityFields: Array<string> = [],
        aWeight: number,
        bWeight: number;

    if (this.isAccountant()) {
        priorityFields = ['accounting', 'dates'];
        aWeight = priorityFields.includes(a.field) ? 1 : 0;
        bWeight = priorityFields.includes(b.field) ? 1 : 0;

        return bWeight - aWeight;
    }

    return 1;
  }

  private parseToken(token: string): JWT | null {
    let res: JWT | null = null;

    try {
      res = jwt_decode(token);
    } catch(error: unknown) {
      res = null;
    }

    return res;
  }
}
