import { Injectable } from '@angular/core';

import { ReplaySubject, BehaviorSubject } from 'rxjs';

import { Marketer, QualityAssurance, Researcher, User } from '@models/user';

@Injectable({
  providedIn: 'root'
})
export class UserContext {
  public user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public researchers$: BehaviorSubject<Array<Researcher>> = new BehaviorSubject<Array<Researcher>>(null);
  public qualityAssurances$: ReplaySubject<Array<QualityAssurance>> = new ReplaySubject<Array<QualityAssurance>>(1);
  public marketers$: BehaviorSubject<Array<Marketer>> = new BehaviorSubject<Array<Marketer>>(null);

  public nextUser(user: User): void {
    this.user$.next(user);
  }

  public nextResearchers(researchers: Array<Researcher>): void {
    this.researchers$.next(researchers);
  }

  public nextQualityAssurances(qualityAssurances: Array<QualityAssurance>): void {
    this.qualityAssurances$.next(qualityAssurances);
  }

  public nextMarketers(marketers: Array<Marketer>): void {
    this.marketers$.next(marketers);
  }

  public getMarketerById(id: string): Marketer {
    return this.marketers$.getValue().find((marketer: Marketer) => id === marketer.id);
  }

  public getResearcherById(id: string): Researcher {
    return this.researchers$.getValue().find((researcher: Researcher) => id === researcher.user_id);
  }
}
