export const FILE_EXTENSIONS: Array<string> = [
  'image/',
  'application/pdf',
  'text/plain',
  'text/csv',
  'doc',
  'docx',
  'xlsx',
  'xls'
];

export const FILE_EXTENSION_PDF: Array<string> = [
  'application/pdf'
];
