import {
  Component,
  forwardRef,
  Injector,
  Input,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, takeUntil } from 'rxjs/operators';

import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { ChipsAutocompleteComponent } from '@components/chips-autocomplete/chips-autocomplete.component';
import { FlagContext } from '@contexts/flag.context';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { Flag } from '@models/flag';
import { AutocompleteChangeValueEvent, AutocompleteValue } from '@models/autocomplete';

@Component({
  selector: 'app-flag-chips-autocomplete',
  templateUrl: './flag-chips-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => FlagChipsAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: FlagChipsAutocompleteComponent,
      multi: true
    }
  ]
})
export class FlagChipsAutocompleteComponent extends ChipsAutocompleteComponent {
  @ViewChild(MatAutocompleteTrigger) autocompleteTriggerComp!: MatAutocompleteTrigger;

  @Input() public override label: string = 'Flags';
  @Input() public override placeholder: string = 'Search for a flag...';

  @Output() openedChanged: Subject<boolean> = new Subject<boolean>();

  constructor(injector: Injector, private context: FlagContext) {
    super(injector);
  }

  override ngOnInit(): void {
    this.context.roleSystemFlags$
      .pipe(takeUntil(this.destroy$))
      .subscribe((flags: Array<Flag>): Array<Flag> => this.allItems = [...flags]);

    this.items$ = this.inputControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      filter((query: AutocompleteValue): boolean => this.isString(query)),
      map((query: string): Array<AutocompleteValue> => this.composeAutocompleteValues(query))
    );

    super.ngOnInit(false);
  }

  override ngOnChanges(changes: SimpleChanges): void {
    const selectedItemsChange: SimpleChange | undefined = changes.selectedItems;

    if (selectedItemsChange && !selectedItemsChange.firstChange) {
      this.filteredItems = this.filterBySelected();
      this.inputControl.setValue('');
    }
  }

  public onOpenedChanged(isOpened: boolean): void {
    this.openedChanged.next(isOpened);
  }

  public onClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public override onRemoveBtnClick(flag: AutocompleteValue): boolean | void {
    const notRemovable: boolean = (<Flag>flag).flag_can_edit === null;
    let index: number;
    if (notRemovable) { return false; }

    index = this.selectedItems.findIndex(
      (f: Flag): boolean => (f.order_flag_id || f.flag_id) === ((<Flag>flag).order_flag_id || (<Flag>flag).flag_id)
    );
    this.selectedItems.splice(index, 1);

    this.cleanInput();

    this.nextValue({type: 'remove', item: flag});
  }

  public override onSelected(event: MatAutocompleteSelectedEvent): void {
    const flag: Flag = event.option.value;

    if (this.selectedItems.length === 4) {
      this.selectedItems.splice(-1, 1, flag);
    } else {
      this.selectedItems.push(flag);
    }

    this.cleanInput();

    this.nextValue({type: 'add', item: flag});
  }

  public openPanel(): void {
    this.autocompleteTriggerComp.openPanel();
  }

  protected override composeAutocompleteValues(query: string): Array<AutocompleteValue> {
    const res: Array<AutocompleteValue> = this.filterByQuery(query);
    return res.length ? res : EMPTY_AUTOCOMPLETE_VALUE;
  }

  protected override filterBySelected(): Array<Flag> {
    return <Array<Flag>>this.allItems.filter((flag: Flag): boolean =>
      !this.selectedItems.find((f: Flag): boolean => (f.flag_id || f.order_flag_id) === flag.order_flag_id)
    );
  }

  protected override filterByQuery(query: string): Array<Flag> {
    return <Array<Flag>>this.filteredItems.filter((flag: Flag): boolean => flag.label.toLowerCase().includes(query));
  }

  protected override nextValue(event: AutocompleteChangeValueEvent): void {
    this.onChange(this.selectedItems);
    this.valueChange.next(event);
  }
}
