import { Component, Injector } from '@angular/core';

import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { RowNode } from 'ag-grid-community';

import { TableService } from '@services/table.service';
import { ChipsInputRendererParams } from '@models/renderer-parameters';

@Component({
  selector: 'app-chips-input-renderer',
  templateUrl: './chips-input-renderer.component.html'
})
export class ChipsInputRendererComponent implements AgRendererComponent {
  public params!: ICellRendererParams;
  public items: Array<string> = [];
  public label: string = 'Chips Input';
  public placeholder: string = 'New...';
  public ariaLabel: string = 'Chips input';
  public classList: string = 'eos-table-chips-input-renderer-container ';

  protected service: TableService;

  private customParams!: ChipsInputRendererParams;

  constructor(injector: Injector) {
    this.service = injector.get(TableService);
  }

  public onChange(value: Array<string>): void {
    const node: RowNode | undefined = this.params?.node;

    this.service.rendererAction$.next({
      type: this.customParams.eventType,
      item: value,
      rowData: node?.data,
      node
    });
  }

  public agInit(params: ICellRendererParams): void {
    const {value = [], customParams} = <any>params;

    this.params = params;
    this.customParams = customParams;

    const {
      label = this.label,
      placeholder = this.placeholder,
      ariaLabel = this.ariaLabel,
      classList = ''
    } = customParams;

    this.items = value;

    this.label = label;
    this.placeholder = placeholder;
    this.ariaLabel = ariaLabel;
    this.classList += classList;
  }

  public refresh(): boolean {
    return false;
  }
}
