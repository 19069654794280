<div class="eos-table-assignee-renderer-container">
  <app-assignee-chips-autocomplete
    class="eos-assignee-chips-autocomplete-renderer"
    ariaLabel="Search for a researcher"
    [selectedItems]="selectedResearchers"
    [isDisabled]="isDisabled"
    [startWith]="false"
    (valueChange)="onAssigneeChanged($event)">
  </app-assignee-chips-autocomplete>
</div>
