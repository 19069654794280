import moment from 'moment';

import { DateRange } from '@angular/material/datepicker';

import { SearchBoxValue } from '@models/search-box';

export enum FILTER_TYPES {
  ORDER = 'order',
  NOTIFICATION = 'notification',
  FILE = 'file',
  NOTE = 'note',
  TASK = 'task',
  ISSUE = 'issue'
}

/**
 * @description
 * Use default value only for employee roles because of wrong business flow
 * Need to have documentation about orders list and orders filter components working flow
 * **/
export const ORDER_SEARCH_BOX_INITIAL_VALUE_FOR_EMPLOYEE: SearchBoxValue = {
  products: [],
  orderOrdered: <DateRange<moment.Moment>>{start: null, end: null},
  orderPromised: <DateRange<moment.Moment>>{start: null, end: null},
  orderNeeded: <DateRange<moment.Moment>>{start: null, end: null},
  orderCanceled: <DateRange<moment.Moment>>{start: null, end: null},
  orderSentToClient: <DateRange<moment.Moment>>{start: null, end: null},
  orderStatuses: [],
  fileNumber: '',
  orderPaymentStatuses: {unpaid: false, partially: false, paid: false},
  orderNoAssignee: null,
  orderAssignee: [],
  company: null,
  doBusinessAs: '',
  owner: '',
  buyer: '',
  folio: '',
  county: '',
  state: null,
  vendor: '',
  orderFlags: [],
  orderOverdue: {overdue: false},
  onlyQualiaOrders: false,
  search: ''
};
