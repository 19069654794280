import { ORDER_STATUSES } from '@configs/order';
import { SelectItem } from '@models/select';

export const STATES: Array<SelectItem> = [
  {
    id: 0,
    value: 'AL',
    viewValue: 'Alabama'
  }, {
    id: 1,
    value: 'AK',
    viewValue: 'Alaska'
  }, {
    id: 2,
    value: 'AZ',
    viewValue: 'Arizona'
  }, {
    id: 3,
    value: 'AR',
    viewValue: 'Arkansas'
  }, {
    id: 4,
    value: 'CA',
    viewValue: 'California'
  }, {
    id: 5,
    value: 'CO',
    viewValue: 'Colorado'
  }, {
    id: 6,
    value: 'CT',
    viewValue: 'Connecticut'
  }, {
    id: 7,
    value: 'DE',
    viewValue: 'Delaware'
  }, {
    id: 8,
    value: 'FL',
    viewValue: 'Florida'
  }, {
    id: 9,
    value: 'GA',
    viewValue: 'Georgia'
  }, {
    id: 10,
    value: 'HI',
    viewValue: 'Hawaii'
  }, {
    id: 11,
    value: 'ID',
    viewValue: 'Idaho'
  }, {
    id: 12,
    value: 'IL',
    viewValue: 'Illinois'
  }, {
    id: 13,
    value: 'IN',
    viewValue: 'Indiana'
  }, {
    id: 14,
    value: 'IA',
    viewValue: 'Iowa'
  }, {
    id: 15,
    value: 'KS',
    viewValue: 'Kansas'
  }, {
    id: 16,
    value: 'KY',
    viewValue: 'Kentucky'
  }, {
    id: 17,
    value: 'LA',
    viewValue: 'Louisiana'
  }, {
    id: 18,
    value: 'ME',
    viewValue: 'Maine'
  }, {
    id: 19,
    value: 'MD',
    viewValue: 'Maryland'
  }, {
    id: 20,
    value: 'MA',
    viewValue: 'Massachusetts'
  }, {
    id: 21,
    value: 'MI',
    viewValue: 'Michigan'
  }, {
    id: 22,
    value: 'MN',
    viewValue: 'Minnesota'
  }, {
    id: 23,
    value: 'MS',
    viewValue: 'Mississippi'
  }, {
    id: 24,
    value: 'MO',
    viewValue: 'Missouri'
  }, {
    id: 25,
    value: 'MT',
    viewValue: 'Montana'
  }, {
    id: 26,
    value: 'NE',
    viewValue: 'Nebraska'
  }, {
    id: 27,
    value: 'NV',
    viewValue: 'Nevada'
  }, {
    id: 28,
    value: 'NH',
    viewValue: 'New Hampshire'
  }, {
    id: 29,
    value: 'NJ',
    viewValue: 'New Jersey'
  }, {
    id: 30,
    value: 'NM',
    viewValue: 'New Mexico'
  }, {
    id: 31,
    value: 'NY',
    viewValue: 'New York'
  }, {
    id: 32,
    value: 'NC',
    viewValue: 'North Carolina'
  }, {
    id: 33,
    value: 'ND',
    viewValue: 'North Dakota'
  }, {
    id: 34,
    value: 'OH',
    viewValue: 'Ohio'
  }, {
    id: 35,
    value: 'OK',
    viewValue: 'Oklahoma'
  }, {
    id: 36,
    value: 'OR',
    viewValue: 'Oregon'
  }, {
    id: 37,
    value: 'PA',
    viewValue: 'Pennsylvania'
  }, {
    id: 38,
    value: 'RI',
    viewValue: 'Rhode Island'
  }, {
    id: 39,
    value: 'SC',
    viewValue: 'South Carolina'
  }, {
    id: 40,
    value: 'SD',
    viewValue: 'South Dakota'
  }, {
    id: 41,
    value: 'TN',
    viewValue: 'Tennessee'
  }, {
    id: 42,
    value: 'TX',
    viewValue: 'Texas'
  }, {
    id: 43,
    value: 'UT',
    viewValue: 'Utah'
  }, {
    id: 44,
    value: 'VT',
    viewValue: 'Vermont'
  }, {
    id: 45,
    value: 'VA',
    viewValue: 'Virginia'
  }, {
    id: 46,
    value: 'WA',
    viewValue: 'Washington'
  }, {
    id: 47,
    value: 'WV',
    viewValue: 'West Virginia'
  }, {
    id: 48,
    value: 'WI',
    viewValue: 'Wisconsin'
  }, {
    id: 49,
    value: 'WY',
    viewValue: 'Wyoming'
  }, {
    id: 50,
    value: 'DC',
    viewValue: 'District of Columbia'
  }, {
    id: 51,
    value: 'VI',
    viewValue: 'U.S. Virgin Islands'
  }
];

export const COUNTIES: { [key: string]: Array<SelectItem> } = {
  "AL": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Autauga",
      "viewValue": "Autauga"
    },
    {
      "id": 2,
      "value": "Baldwin",
      "viewValue": "Baldwin"
    },
    {
      "id": 3,
      "value": "Barbour",
      "viewValue": "Barbour"
    },
    {
      "id": 4,
      "value": "Bibb",
      "viewValue": "Bibb"
    },
    {
      "id": 5,
      "value": "Blount",
      "viewValue": "Blount"
    },
    {
      "id": 6,
      "value": "Bullock",
      "viewValue": "Bullock"
    },
    {
      "id": 7,
      "value": "Butler",
      "viewValue": "Butler"
    },
    {
      "id": 8,
      "value": "Calhoun",
      "viewValue": "Calhoun"
    },
    {
      "id": 9,
      "value": "Chambers",
      "viewValue": "Chambers"
    },
    {
      "id": 10,
      "value": "Cherokee",
      "viewValue": "Cherokee"
    },
    {
      "id": 11,
      "value": "Chilton",
      "viewValue": "Chilton"
    },
    {
      "id": 12,
      "value": "Choctaw",
      "viewValue": "Choctaw"
    },
    {
      "id": 13,
      "value": "Clarke",
      "viewValue": "Clarke"
    },
    {
      "id": 14,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 15,
      "value": "Cleburne",
      "viewValue": "Cleburne"
    },
    {
      "id": 16,
      "value": "Coffee",
      "viewValue": "Coffee"
    },
    {
      "id": 17,
      "value": "Colbert",
      "viewValue": "Colbert"
    },
    {
      "id": 18,
      "value": "Conecuh",
      "viewValue": "Conecuh"
    },
    {
      "id": 19,
      "value": "Coosa",
      "viewValue": "Coosa"
    },
    {
      "id": 20,
      "value": "Covington",
      "viewValue": "Covington"
    },
    {
      "id": 21,
      "value": "Crenshaw",
      "viewValue": "Crenshaw"
    },
    {
      "id": 22,
      "value": "Cullman",
      "viewValue": "Cullman"
    },
    {
      "id": 23,
      "value": "Dale",
      "viewValue": "Dale"
    },
    {
      "id": 24,
      "value": "Dallas",
      "viewValue": "Dallas"
    },
    {
      "id": 25,
      "value": "DeKalb",
      "viewValue": "DeKalb"
    },
    {
      "id": 26,
      "value": "Elmore",
      "viewValue": "Elmore"
    },
    {
      "id": 27,
      "value": "Escambia",
      "viewValue": "Escambia"
    },
    {
      "id": 28,
      "value": "Etowah",
      "viewValue": "Etowah"
    },
    {
      "id": 29,
      "value": "Fayette",
      "viewValue": "Fayette"
    },
    {
      "id": 30,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 31,
      "value": "Geneva",
      "viewValue": "Geneva"
    },
    {
      "id": 32,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 33,
      "value": "Hale",
      "viewValue": "Hale"
    },
    {
      "id": 34,
      "value": "Henry",
      "viewValue": "Henry"
    },
    {
      "id": 35,
      "value": "Houston",
      "viewValue": "Houston"
    },
    {
      "id": 36,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 37,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 38,
      "value": "Lamar",
      "viewValue": "Lamar"
    },
    {
      "id": 39,
      "value": "Lauderdale",
      "viewValue": "Lauderdale"
    },
    {
      "id": 40,
      "value": "Lawrence",
      "viewValue": "Lawrence"
    },
    {
      "id": 41,
      "value": "Lee",
      "viewValue": "Lee"
    },
    {
      "id": 42,
      "value": "Limestone",
      "viewValue": "Limestone"
    },
    {
      "id": 43,
      "value": "Lowndes",
      "viewValue": "Lowndes"
    },
    {
      "id": 44,
      "value": "Macon",
      "viewValue": "Macon"
    },
    {
      "id": 45,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 46,
      "value": "Marengo",
      "viewValue": "Marengo"
    },
    {
      "id": 47,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 48,
      "value": "Marshall",
      "viewValue": "Marshall"
    },
    {
      "id": 49,
      "value": "Mobile",
      "viewValue": "Mobile"
    },
    {
      "id": 50,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 51,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 52,
      "value": "Morgan",
      "viewValue": "Morgan"
    },
    {
      "id": 53,
      "value": "Perry",
      "viewValue": "Perry"
    },
    {
      "id": 54,
      "value": "Pickens",
      "viewValue": "Pickens"
    },
    {
      "id": 55,
      "value": "Pike",
      "viewValue": "Pike"
    },
    {
      "id": 56,
      "value": "Randolph",
      "viewValue": "Randolph"
    },
    {
      "id": 57,
      "value": "Russell",
      "viewValue": "Russell"
    },
    {
      "id": 58,
      "value": "St. Clair",
      "viewValue": "St. Clair"
    },
    {
      "id": 59,
      "value": "Shelby",
      "viewValue": "Shelby"
    },
    {
      "id": 60,
      "value": "Sumter",
      "viewValue": "Sumter"
    },
    {
      "id": 61,
      "value": "Talladega",
      "viewValue": "Talladega"
    },
    {
      "id": 62,
      "value": "Tallapoosa",
      "viewValue": "Tallapoosa"
    },
    {
      "id": 63,
      "value": "Tuscaloosa",
      "viewValue": "Tuscaloosa"
    },
    {
      "id": 64,
      "value": "Walker",
      "viewValue": "Walker"
    },
    {
      "id": 65,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 66,
      "value": "Wilcox",
      "viewValue": "Wilcox"
    },
    {
      "id": 67,
      "value": "Winston",
      "viewValue": "Winston"
    }
  ],
  "AK": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Aleutians East",
      "viewValue": "Aleutians East"
    },
    {
      "id": 2,
      "value": "Aleutians West",
      "viewValue": "Aleutians West"
    },
    {
      "id": 3,
      "value": "Anchorage",
      "viewValue": "Anchorage"
    },
    {
      "id": 4,
      "value": "Bethel",
      "viewValue": "Bethel"
    },
    {
      "id": 5,
      "value": "Bristol Bay",
      "viewValue": "Bristol Bay"
    },
    {
      "id": 6,
      "value": "Denali",
      "viewValue": "Denali"
    },
    {
      "id": 7,
      "value": "Dillingham",
      "viewValue": "Dillingham"
    },
    {
      "id": 8,
      "value": "Fairbanks North Star",
      "viewValue": "Fairbanks North Star"
    },
    {
      "id": 9,
      "value": "Haines",
      "viewValue": "Haines"
    },
    {
      "id": 10,
      "value": "Hoonah-Angoon",
      "viewValue": "Hoonah-Angoon"
    },
    {
      "id": 11,
      "value": "Juneau",
      "viewValue": "Juneau"
    },
    {
      "id": 12,
      "value": "Kenai Peninsula",
      "viewValue": "Kenai Peninsula"
    },
    {
      "id": 13,
      "value": "Ketchikan Gateway",
      "viewValue": "Ketchikan Gateway"
    },
    {
      "id": 14,
      "value": "Kodiak Island",
      "viewValue": "Kodiak Island"
    },
    {
      "id": 15,
      "value": "Lake and Peninsula",
      "viewValue": "Lake and Peninsula"
    },
    {
      "id": 16,
      "value": "Matanuska-Susitna",
      "viewValue": "Matanuska-Susitna"
    },
    {
      "id": 17,
      "value": "Nome",
      "viewValue": "Nome"
    },
    {
      "id": 18,
      "value": "North Slope",
      "viewValue": "North Slope"
    },
    {
      "id": 19,
      "value": "Northwest Arctic",
      "viewValue": "Northwest Arctic"
    },
    {
      "id": 20,
      "value": "Petersburg",
      "viewValue": "Petersburg"
    },
    {
      "id": 21,
      "value": "Prince of Wales-Hyder",
      "viewValue": "Prince of Wales-Hyder"
    },
    {
      "id": 22,
      "value": "Sitka",
      "viewValue": "Sitka"
    },
    {
      "id": 23,
      "value": "Skagway-Hoonah-Angoon",
      "viewValue": "Skagway-Hoonah-Angoon"
    },
    {
      "id": 24,
      "value": "Skagway",
      "viewValue": "Skagway"
    },
    {
      "id": 25,
      "value": "Southeast Fairbanks",
      "viewValue": "Southeast Fairbanks"
    },
    {
      "id": 26,
      "value": "Valdez-Cordova",
      "viewValue": "Valdez-Cordova"
    },
    {
      "id": 27,
      "value": "Wade Hampton",
      "viewValue": "Wade Hampton"
    },
    {
      "id": 28,
      "value": "Wrangell",
      "viewValue": "Wrangell"
    },
    {
      "id": 29,
      "value": "Yakutat",
      "viewValue": "Yakutat"
    },
    {
      "id": 30,
      "value": "Yukon-Koyukuk",
      "viewValue": "Yukon-Koyukuk"
    }
  ],
  "AZ": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Apache",
      "viewValue": "Apache"
    },
    {
      "id": 2,
      "value": "Cochise",
      "viewValue": "Cochise"
    },
    {
      "id": 3,
      "value": "Coconino",
      "viewValue": "Coconino"
    },
    {
      "id": 4,
      "value": "Gila",
      "viewValue": "Gila"
    },
    {
      "id": 5,
      "value": "Graham",
      "viewValue": "Graham"
    },
    {
      "id": 6,
      "value": "Greenlee",
      "viewValue": "Greenlee"
    },
    {
      "id": 7,
      "value": "La Paz",
      "viewValue": "La Paz"
    },
    {
      "id": 8,
      "value": "Maricopa",
      "viewValue": "Maricopa"
    },
    {
      "id": 9,
      "value": "Mohave",
      "viewValue": "Mohave"
    },
    {
      "id": 10,
      "value": "Navajo",
      "viewValue": "Navajo"
    },
    {
      "id": 11,
      "value": "Pima",
      "viewValue": "Pima"
    },
    {
      "id": 12,
      "value": "Pinal",
      "viewValue": "Pinal"
    },
    {
      "id": 13,
      "value": "Santa Cruz",
      "viewValue": "Santa Cruz"
    },
    {
      "id": 14,
      "value": "Yavapai",
      "viewValue": "Yavapai"
    },
    {
      "id": 15,
      "value": "Yuma",
      "viewValue": "Yuma"
    }
  ],
  "AR": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Arkansas",
      "viewValue": "Arkansas"
    },
    {
      "id": 2,
      "value": "Ashley",
      "viewValue": "Ashley"
    },
    {
      "id": 3,
      "value": "Baxter",
      "viewValue": "Baxter"
    },
    {
      "id": 4,
      "value": "Benton",
      "viewValue": "Benton"
    },
    {
      "id": 5,
      "value": "Boone",
      "viewValue": "Boone"
    },
    {
      "id": 6,
      "value": "Bradley",
      "viewValue": "Bradley"
    },
    {
      "id": 7,
      "value": "Calhoun",
      "viewValue": "Calhoun"
    },
    {
      "id": 8,
      "value": "Carroll",
      "viewValue": "Carroll"
    },
    {
      "id": 9,
      "value": "Chicot",
      "viewValue": "Chicot"
    },
    {
      "id": 10,
      "value": "Clark",
      "viewValue": "Clark"
    },
    {
      "id": 11,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 12,
      "value": "Cleburne",
      "viewValue": "Cleburne"
    },
    {
      "id": 13,
      "value": "Cleveland",
      "viewValue": "Cleveland"
    },
    {
      "id": 14,
      "value": "Columbia",
      "viewValue": "Columbia"
    },
    {
      "id": 15,
      "value": "Conway",
      "viewValue": "Conway"
    },
    {
      "id": 16,
      "value": "Craighead",
      "viewValue": "Craighead"
    },
    {
      "id": 17,
      "value": "Crawford",
      "viewValue": "Crawford"
    },
    {
      "id": 18,
      "value": "Crittenden",
      "viewValue": "Crittenden"
    },
    {
      "id": 19,
      "value": "Cross",
      "viewValue": "Cross"
    },
    {
      "id": 20,
      "value": "Dallas",
      "viewValue": "Dallas"
    },
    {
      "id": 21,
      "value": "Desha",
      "viewValue": "Desha"
    },
    {
      "id": 22,
      "value": "Drew",
      "viewValue": "Drew"
    },
    {
      "id": 23,
      "value": "Faulkner",
      "viewValue": "Faulkner"
    },
    {
      "id": 24,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 25,
      "value": "Fulton",
      "viewValue": "Fulton"
    },
    {
      "id": 26,
      "value": "Garland",
      "viewValue": "Garland"
    },
    {
      "id": 27,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 28,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 29,
      "value": "Hempstead",
      "viewValue": "Hempstead"
    },
    {
      "id": 30,
      "value": "Hot Spring",
      "viewValue": "Hot Spring"
    },
    {
      "id": 31,
      "value": "Howard",
      "viewValue": "Howard"
    },
    {
      "id": 32,
      "value": "Independence",
      "viewValue": "Independence"
    },
    {
      "id": 33,
      "value": "Izard",
      "viewValue": "Izard"
    },
    {
      "id": 34,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 35,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 36,
      "value": "Johnson",
      "viewValue": "Johnson"
    },
    {
      "id": 37,
      "value": "Lafayette",
      "viewValue": "Lafayette"
    },
    {
      "id": 38,
      "value": "Lawrence",
      "viewValue": "Lawrence"
    },
    {
      "id": 39,
      "value": "Lee",
      "viewValue": "Lee"
    },
    {
      "id": 40,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 41,
      "value": "Little River",
      "viewValue": "Little River"
    },
    {
      "id": 42,
      "value": "Logan",
      "viewValue": "Logan"
    },
    {
      "id": 43,
      "value": "Lonoke",
      "viewValue": "Lonoke"
    },
    {
      "id": 44,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 45,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 46,
      "value": "Miller",
      "viewValue": "Miller"
    },
    {
      "id": 47,
      "value": "Mississippi",
      "viewValue": "Mississippi"
    },
    {
      "id": 48,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 49,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 50,
      "value": "Nevada",
      "viewValue": "Nevada"
    },
    {
      "id": 51,
      "value": "Newton",
      "viewValue": "Newton"
    },
    {
      "id": 52,
      "value": "Ouachita",
      "viewValue": "Ouachita"
    },
    {
      "id": 53,
      "value": "Perry",
      "viewValue": "Perry"
    },
    {
      "id": 54,
      "value": "Phillips",
      "viewValue": "Phillips"
    },
    {
      "id": 55,
      "value": "Pike",
      "viewValue": "Pike"
    },
    {
      "id": 56,
      "value": "Poinsett",
      "viewValue": "Poinsett"
    },
    {
      "id": 57,
      "value": "Polk",
      "viewValue": "Polk"
    },
    {
      "id": 58,
      "value": "Pope",
      "viewValue": "Pope"
    },
    {
      "id": 59,
      "value": "Prairie",
      "viewValue": "Prairie"
    },
    {
      "id": 60,
      "value": "Pulaski",
      "viewValue": "Pulaski"
    },
    {
      "id": 61,
      "value": "Randolph",
      "viewValue": "Randolph"
    },
    {
      "id": 62,
      "value": "St. Francis",
      "viewValue": "St. Francis"
    },
    {
      "id": 63,
      "value": "Saline",
      "viewValue": "Saline"
    },
    {
      "id": 64,
      "value": "Scott",
      "viewValue": "Scott"
    },
    {
      "id": 65,
      "value": "Searcy",
      "viewValue": "Searcy"
    },
    {
      "id": 66,
      "value": "Sebastian",
      "viewValue": "Sebastian"
    },
    {
      "id": 67,
      "value": "Sevier",
      "viewValue": "Sevier"
    },
    {
      "id": 68,
      "value": "Sharp",
      "viewValue": "Sharp"
    },
    {
      "id": 69,
      "value": "Stone",
      "viewValue": "Stone"
    },
    {
      "id": 70,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 71,
      "value": "Van Buren",
      "viewValue": "Van Buren"
    },
    {
      "id": 72,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 73,
      "value": "White",
      "viewValue": "White"
    },
    {
      "id": 74,
      "value": "Woodruff",
      "viewValue": "Woodruff"
    },
    {
      "id": 75,
      "value": "Yell",
      "viewValue": "Yell"
    }
  ],
  "CA": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Alameda",
      "viewValue": "Alameda"
    },
    {
      "id": 2,
      "value": "Alpine",
      "viewValue": "Alpine"
    },
    {
      "id": 3,
      "value": "Amador",
      "viewValue": "Amador"
    },
    {
      "id": 4,
      "value": "Butte",
      "viewValue": "Butte"
    },
    {
      "id": 5,
      "value": "Calaveras",
      "viewValue": "Calaveras"
    },
    {
      "id": 6,
      "value": "Colusa",
      "viewValue": "Colusa"
    },
    {
      "id": 7,
      "value": "Contra Costa",
      "viewValue": "Contra Costa"
    },
    {
      "id": 8,
      "value": "Del Norte",
      "viewValue": "Del Norte"
    },
    {
      "id": 9,
      "value": "El Dorado",
      "viewValue": "El Dorado"
    },
    {
      "id": 10,
      "value": "Fresno",
      "viewValue": "Fresno"
    },
    {
      "id": 11,
      "value": "Glenn",
      "viewValue": "Glenn"
    },
    {
      "id": 12,
      "value": "Humboldt",
      "viewValue": "Humboldt"
    },
    {
      "id": 13,
      "value": "Imperial",
      "viewValue": "Imperial"
    },
    {
      "id": 14,
      "value": "Inyo",
      "viewValue": "Inyo"
    },
    {
      "id": 15,
      "value": "Kern",
      "viewValue": "Kern"
    },
    {
      "id": 16,
      "value": "Kings",
      "viewValue": "Kings"
    },
    {
      "id": 17,
      "value": "Lake",
      "viewValue": "Lake"
    },
    {
      "id": 18,
      "value": "Lassen",
      "viewValue": "Lassen"
    },
    {
      "id": 19,
      "value": "Los Angeles",
      "viewValue": "Los Angeles"
    },
    {
      "id": 20,
      "value": "Madera",
      "viewValue": "Madera"
    },
    {
      "id": 21,
      "value": "Marin",
      "viewValue": "Marin"
    },
    {
      "id": 22,
      "value": "Mariposa",
      "viewValue": "Mariposa"
    },
    {
      "id": 23,
      "value": "Mendocino",
      "viewValue": "Mendocino"
    },
    {
      "id": 24,
      "value": "Merced",
      "viewValue": "Merced"
    },
    {
      "id": 25,
      "value": "Modoc",
      "viewValue": "Modoc"
    },
    {
      "id": 26,
      "value": "Mono",
      "viewValue": "Mono"
    },
    {
      "id": 27,
      "value": "Monterey",
      "viewValue": "Monterey"
    },
    {
      "id": 28,
      "value": "Napa",
      "viewValue": "Napa"
    },
    {
      "id": 29,
      "value": "Nevada",
      "viewValue": "Nevada"
    },
    {
      "id": 30,
      "value": "Orange",
      "viewValue": "Orange"
    },
    {
      "id": 31,
      "value": "Placer",
      "viewValue": "Placer"
    },
    {
      "id": 32,
      "value": "Plumas",
      "viewValue": "Plumas"
    },
    {
      "id": 33,
      "value": "Riverside",
      "viewValue": "Riverside"
    },
    {
      "id": 34,
      "value": "Sacramento",
      "viewValue": "Sacramento"
    },
    {
      "id": 35,
      "value": "San Benito",
      "viewValue": "San Benito"
    },
    {
      "id": 36,
      "value": "San Bernardino",
      "viewValue": "San Bernardino"
    },
    {
      "id": 37,
      "value": "San Diego",
      "viewValue": "San Diego"
    },
    {
      "id": 38,
      "value": "San Francisco",
      "viewValue": "San Francisco"
    },
    {
      "id": 39,
      "value": "San Joaquin",
      "viewValue": "San Joaquin"
    },
    {
      "id": 40,
      "value": "San Luis Obispo",
      "viewValue": "San Luis Obispo"
    },
    {
      "id": 41,
      "value": "San Mateo",
      "viewValue": "San Mateo"
    },
    {
      "id": 42,
      "value": "Santa Barbara",
      "viewValue": "Santa Barbara"
    },
    {
      "id": 43,
      "value": "Santa Clara",
      "viewValue": "Santa Clara"
    },
    {
      "id": 44,
      "value": "Santa Cruz",
      "viewValue": "Santa Cruz"
    },
    {
      "id": 45,
      "value": "Shasta",
      "viewValue": "Shasta"
    },
    {
      "id": 46,
      "value": "Sierra",
      "viewValue": "Sierra"
    },
    {
      "id": 47,
      "value": "Siskiyou",
      "viewValue": "Siskiyou"
    },
    {
      "id": 48,
      "value": "Solano",
      "viewValue": "Solano"
    },
    {
      "id": 49,
      "value": "Sonoma",
      "viewValue": "Sonoma"
    },
    {
      "id": 50,
      "value": "Stanislaus",
      "viewValue": "Stanislaus"
    },
    {
      "id": 51,
      "value": "Sutter",
      "viewValue": "Sutter"
    },
    {
      "id": 52,
      "value": "Tehama",
      "viewValue": "Tehama"
    },
    {
      "id": 53,
      "value": "Trinity",
      "viewValue": "Trinity"
    },
    {
      "id": 54,
      "value": "Tulare",
      "viewValue": "Tulare"
    },
    {
      "id": 55,
      "value": "Tuolumne",
      "viewValue": "Tuolumne"
    },
    {
      "id": 56,
      "value": "Ventura",
      "viewValue": "Ventura"
    },
    {
      "id": 57,
      "value": "Yolo",
      "viewValue": "Yolo"
    },
    {
      "id": 58,
      "value": "Yuba",
      "viewValue": "Yuba"
    }
  ],
  "CO": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adams",
      "viewValue": "Adams"
    },
    {
      "id": 2,
      "value": "Alamosa",
      "viewValue": "Alamosa"
    },
    {
      "id": 3,
      "value": "Arapahoe",
      "viewValue": "Arapahoe"
    },
    {
      "id": 4,
      "value": "Archuleta",
      "viewValue": "Archuleta"
    },
    {
      "id": 5,
      "value": "Baca",
      "viewValue": "Baca"
    },
    {
      "id": 6,
      "value": "Bent",
      "viewValue": "Bent"
    },
    {
      "id": 7,
      "value": "Boulder",
      "viewValue": "Boulder"
    },
    {
      "id": 8,
      "value": "Broomfield",
      "viewValue": "Broomfield"
    },
    {
      "id": 9,
      "value": "Chaffee",
      "viewValue": "Chaffee"
    },
    {
      "id": 10,
      "value": "Cheyenne",
      "viewValue": "Cheyenne"
    },
    {
      "id": 11,
      "value": "Clear Creek",
      "viewValue": "Clear Creek"
    },
    {
      "id": 12,
      "value": "Conejos",
      "viewValue": "Conejos"
    },
    {
      "id": 13,
      "value": "Costilla",
      "viewValue": "Costilla"
    },
    {
      "id": 14,
      "value": "Crowley",
      "viewValue": "Crowley"
    },
    {
      "id": 15,
      "value": "Custer",
      "viewValue": "Custer"
    },
    {
      "id": 16,
      "value": "Delta",
      "viewValue": "Delta"
    },
    {
      "id": 17,
      "value": "Denver",
      "viewValue": "Denver"
    },
    {
      "id": 18,
      "value": "Dolores",
      "viewValue": "Dolores"
    },
    {
      "id": 19,
      "value": "Douglas",
      "viewValue": "Douglas"
    },
    {
      "id": 20,
      "value": "Eagle",
      "viewValue": "Eagle"
    },
    {
      "id": 21,
      "value": "El Paso",
      "viewValue": "El Paso"
    },
    {
      "id": 22,
      "value": "Elbert",
      "viewValue": "Elbert"
    },
    {
      "id": 23,
      "value": "Fremont",
      "viewValue": "Fremont"
    },
    {
      "id": 24,
      "value": "Garfield",
      "viewValue": "Garfield"
    },
    {
      "id": 25,
      "value": "Gilpin",
      "viewValue": "Gilpin"
    },
    {
      "id": 26,
      "value": "Grand",
      "viewValue": "Grand"
    },
    {
      "id": 27,
      "value": "Gunnison",
      "viewValue": "Gunnison"
    },
    {
      "id": 28,
      "value": "Hinsdale",
      "viewValue": "Hinsdale"
    },
    {
      "id": 29,
      "value": "Huerfano",
      "viewValue": "Huerfano"
    },
    {
      "id": 30,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 31,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 32,
      "value": "Kiowa",
      "viewValue": "Kiowa"
    },
    {
      "id": 33,
      "value": "Kit Carson",
      "viewValue": "Kit Carson"
    },
    {
      "id": 34,
      "value": "Lake",
      "viewValue": "Lake"
    },
    {
      "id": 35,
      "value": "La Plata",
      "viewValue": "La Plata"
    },
    {
      "id": 36,
      "value": "Larimer",
      "viewValue": "Larimer"
    },
    {
      "id": 37,
      "value": "Las Animas",
      "viewValue": "Las Animas"
    },
    {
      "id": 38,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 39,
      "value": "Logan",
      "viewValue": "Logan"
    },
    {
      "id": 40,
      "value": "Mesa",
      "viewValue": "Mesa"
    },
    {
      "id": 41,
      "value": "Mineral",
      "viewValue": "Mineral"
    },
    {
      "id": 42,
      "value": "Moffat",
      "viewValue": "Moffat"
    },
    {
      "id": 43,
      "value": "Montezuma",
      "viewValue": "Montezuma"
    },
    {
      "id": 44,
      "value": "Montrose",
      "viewValue": "Montrose"
    },
    {
      "id": 45,
      "value": "Morgan",
      "viewValue": "Morgan"
    },
    {
      "id": 46,
      "value": "Otero",
      "viewValue": "Otero"
    },
    {
      "id": 47,
      "value": "Ouray",
      "viewValue": "Ouray"
    },
    {
      "id": 48,
      "value": "Park",
      "viewValue": "Park"
    },
    {
      "id": 49,
      "value": "Phillips",
      "viewValue": "Phillips"
    },
    {
      "id": 50,
      "value": "Pitkin",
      "viewValue": "Pitkin"
    },
    {
      "id": 51,
      "value": "Prowers",
      "viewValue": "Prowers"
    },
    {
      "id": 52,
      "value": "Pueblo",
      "viewValue": "Pueblo"
    },
    {
      "id": 53,
      "value": "Rio Blanco",
      "viewValue": "Rio Blanco"
    },
    {
      "id": 54,
      "value": "Rio Grande",
      "viewValue": "Rio Grande"
    },
    {
      "id": 55,
      "value": "Routt",
      "viewValue": "Routt"
    },
    {
      "id": 56,
      "value": "Saguache",
      "viewValue": "Saguache"
    },
    {
      "id": 57,
      "value": "San Juan",
      "viewValue": "San Juan"
    },
    {
      "id": 58,
      "value": "San Miguel",
      "viewValue": "San Miguel"
    },
    {
      "id": 59,
      "value": "Sedgwick",
      "viewValue": "Sedgwick"
    },
    {
      "id": 60,
      "value": "Summit",
      "viewValue": "Summit"
    },
    {
      "id": 61,
      "value": "Teller",
      "viewValue": "Teller"
    },
    {
      "id": 62,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 63,
      "value": "Weld",
      "viewValue": "Weld"
    },
    {
      "id": 64,
      "value": "Yuma",
      "viewValue": "Yuma"
    }
  ],
  "CT": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Fairfield",
      "viewValue": "Fairfield"
    },
    {
      "id": 2,
      "value": "Hartford",
      "viewValue": "Hartford"
    },
    {
      "id": 3,
      "value": "Litchfield",
      "viewValue": "Litchfield"
    },
    {
      "id": 4,
      "value": "Middlesex",
      "viewValue": "Middlesex"
    },
    {
      "id": 5,
      "value": "New Haven",
      "viewValue": "New Haven"
    },
    {
      "id": 6,
      "value": "New London",
      "viewValue": "New London"
    },
    {
      "id": 7,
      "value": "Tolland",
      "viewValue": "Tolland"
    },
    {
      "id": 8,
      "value": "Windham",
      "viewValue": "Windham"
    }
  ],
  "DE": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Kent",
      "viewValue": "Kent"
    },
    {
      "id": 2,
      "value": "New Castle",
      "viewValue": "New Castle"
    },
    {
      "id": 3,
      "value": "Sussex",
      "viewValue": "Sussex"
    }
  ],
  "FL": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Alachua",
      "viewValue": "Alachua"
    },
    {
      "id": 2,
      "value": "Baker",
      "viewValue": "Baker"
    },
    {
      "id": 3,
      "value": "Bay",
      "viewValue": "Bay"
    },
    {
      "id": 4,
      "value": "Bradford",
      "viewValue": "Bradford"
    },
    {
      "id": 5,
      "value": "Brevard",
      "viewValue": "Brevard"
    },
    {
      "id": 6,
      "value": "Broward",
      "viewValue": "Broward"
    },
    {
      "id": 7,
      "value": "Calhoun",
      "viewValue": "Calhoun"
    },
    {
      "id": 8,
      "value": "Charlotte",
      "viewValue": "Charlotte"
    },
    {
      "id": 9,
      "value": "Citrus",
      "viewValue": "Citrus"
    },
    {
      "id": 10,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 11,
      "value": "Collier",
      "viewValue": "Collier"
    },
    {
      "id": 12,
      "value": "Columbia",
      "viewValue": "Columbia"
    },
    {
      "id": 13,
      "value": "DeSoto",
      "viewValue": "DeSoto"
    },
    {
      "id": 14,
      "value": "Dixie",
      "viewValue": "Dixie"
    },
    {
      "id": 15,
      "value": "Duval",
      "viewValue": "Duval"
    },
    {
      "id": 16,
      "value": "Escambia",
      "viewValue": "Escambia"
    },
    {
      "id": 17,
      "value": "Flagler",
      "viewValue": "Flagler"
    },
    {
      "id": 18,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 19,
      "value": "Gadsden",
      "viewValue": "Gadsden"
    },
    {
      "id": 20,
      "value": "Gilchrist",
      "viewValue": "Gilchrist"
    },
    {
      "id": 21,
      "value": "Glades",
      "viewValue": "Glades"
    },
    {
      "id": 22,
      "value": "Gulf",
      "viewValue": "Gulf"
    },
    {
      "id": 23,
      "value": "Hamilton",
      "viewValue": "Hamilton"
    },
    {
      "id": 24,
      "value": "Hardee",
      "viewValue": "Hardee"
    },
    {
      "id": 25,
      "value": "Hendry",
      "viewValue": "Hendry"
    },
    {
      "id": 26,
      "value": "Hernando",
      "viewValue": "Hernando"
    },
    {
      "id": 27,
      "value": "Highlands",
      "viewValue": "Highlands"
    },
    {
      "id": 28,
      "value": "Hillsborough",
      "viewValue": "Hillsborough"
    },
    {
      "id": 29,
      "value": "Holmes",
      "viewValue": "Holmes"
    },
    {
      "id": 30,
      "value": "Indian River",
      "viewValue": "Indian River"
    },
    {
      "id": 31,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 32,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 33,
      "value": "Lafayette",
      "viewValue": "Lafayette"
    },
    {
      "id": 34,
      "value": "Lake",
      "viewValue": "Lake"
    },
    {
      "id": 35,
      "value": "Lee",
      "viewValue": "Lee"
    },
    {
      "id": 36,
      "value": "Leon",
      "viewValue": "Leon"
    },
    {
      "id": 37,
      "value": "Levy",
      "viewValue": "Levy"
    },
    {
      "id": 38,
      "value": "Liberty",
      "viewValue": "Liberty"
    },
    {
      "id": 39,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 40,
      "value": "Manatee",
      "viewValue": "Manatee"
    },
    {
      "id": 41,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 42,
      "value": "Martin",
      "viewValue": "Martin"
    },
    {
      "id": 43,
      "value": "Miami-Dade",
      "viewValue": "Miami-Dade"
    },
    {
      "id": 44,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 45,
      "value": "Nassau",
      "viewValue": "Nassau"
    },
    {
      "id": 46,
      "value": "Okaloosa",
      "viewValue": "Okaloosa"
    },
    {
      "id": 47,
      "value": "Okeechobee",
      "viewValue": "Okeechobee"
    },
    {
      "id": 48,
      "value": "Orange",
      "viewValue": "Orange"
    },
    {
      "id": 49,
      "value": "Osceola",
      "viewValue": "Osceola"
    },
    {
      "id": 50,
      "value": "Palm Beach",
      "viewValue": "Palm Beach"
    },
    {
      "id": 51,
      "value": "Pasco",
      "viewValue": "Pasco"
    },
    {
      "id": 52,
      "value": "Pinellas",
      "viewValue": "Pinellas"
    },
    {
      "id": 53,
      "value": "Polk",
      "viewValue": "Polk"
    },
    {
      "id": 54,
      "value": "Putnam",
      "viewValue": "Putnam"
    },
    {
      "id": 55,
      "value": "St. Johns",
      "viewValue": "St. Johns"
    },
    {
      "id": 56,
      "value": "St. Lucie",
      "viewValue": "St. Lucie"
    },
    {
      "id": 57,
      "value": "Santa Rosa",
      "viewValue": "Santa Rosa"
    },
    {
      "id": 58,
      "value": "Sarasota",
      "viewValue": "Sarasota"
    },
    {
      "id": 59,
      "value": "Seminole",
      "viewValue": "Seminole"
    },
    {
      "id": 60,
      "value": "Sumter",
      "viewValue": "Sumter"
    },
    {
      "id": 61,
      "value": "Suwannee",
      "viewValue": "Suwannee"
    },
    {
      "id": 62,
      "value": "Taylor",
      "viewValue": "Taylor"
    },
    {
      "id": 63,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 64,
      "value": "Volusia",
      "viewValue": "Volusia"
    },
    {
      "id": 65,
      "value": "Wakulla",
      "viewValue": "Wakulla"
    },
    {
      "id": 66,
      "value": "Walton",
      "viewValue": "Walton"
    },
    {
      "id": 67,
      "value": "Washington",
      "viewValue": "Washington"
    }
  ],
  "GA": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Appling",
      "viewValue": "Appling"
    },
    {
      "id": 2,
      "value": "Atkinson",
      "viewValue": "Atkinson"
    },
    {
      "id": 3,
      "value": "Bacon",
      "viewValue": "Bacon"
    },
    {
      "id": 4,
      "value": "Baker",
      "viewValue": "Baker"
    },
    {
      "id": 5,
      "value": "Baldwin",
      "viewValue": "Baldwin"
    },
    {
      "id": 6,
      "value": "Banks",
      "viewValue": "Banks"
    },
    {
      "id": 7,
      "value": "Barrow",
      "viewValue": "Barrow"
    },
    {
      "id": 8,
      "value": "Bartow",
      "viewValue": "Bartow"
    },
    {
      "id": 9,
      "value": "Ben Hill",
      "viewValue": "Ben Hill"
    },
    {
      "id": 10,
      "value": "Berrien",
      "viewValue": "Berrien"
    },
    {
      "id": 11,
      "value": "Bibb",
      "viewValue": "Bibb"
    },
    {
      "id": 12,
      "value": "Bleckley",
      "viewValue": "Bleckley"
    },
    {
      "id": 13,
      "value": "Brantley",
      "viewValue": "Brantley"
    },
    {
      "id": 14,
      "value": "Brooks",
      "viewValue": "Brooks"
    },
    {
      "id": 15,
      "value": "Bryan",
      "viewValue": "Bryan"
    },
    {
      "id": 16,
      "value": "Bulloch",
      "viewValue": "Bulloch"
    },
    {
      "id": 17,
      "value": "Burke",
      "viewValue": "Burke"
    },
    {
      "id": 18,
      "value": "Butts",
      "viewValue": "Butts"
    },
    {
      "id": 19,
      "value": "Calhoun",
      "viewValue": "Calhoun"
    },
    {
      "id": 20,
      "value": "Camden",
      "viewValue": "Camden"
    },
    {
      "id": 21,
      "value": "Candler",
      "viewValue": "Candler"
    },
    {
      "id": 22,
      "value": "Carroll",
      "viewValue": "Carroll"
    },
    {
      "id": 23,
      "value": "Catoosa",
      "viewValue": "Catoosa"
    },
    {
      "id": 24,
      "value": "Charlton",
      "viewValue": "Charlton"
    },
    {
      "id": 25,
      "value": "Chatham",
      "viewValue": "Chatham"
    },
    {
      "id": 26,
      "value": "Chattahoochee",
      "viewValue": "Chattahoochee"
    },
    {
      "id": 27,
      "value": "Chattooga",
      "viewValue": "Chattooga"
    },
    {
      "id": 28,
      "value": "Cherokee",
      "viewValue": "Cherokee"
    },
    {
      "id": 29,
      "value": "Clarke",
      "viewValue": "Clarke"
    },
    {
      "id": 30,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 31,
      "value": "Clayton",
      "viewValue": "Clayton"
    },
    {
      "id": 32,
      "value": "Clinch",
      "viewValue": "Clinch"
    },
    {
      "id": 33,
      "value": "Cobb",
      "viewValue": "Cobb"
    },
    {
      "id": 34,
      "value": "Coffee",
      "viewValue": "Coffee"
    },
    {
      "id": 35,
      "value": "Colquitt",
      "viewValue": "Colquitt"
    },
    {
      "id": 36,
      "value": "Columbia",
      "viewValue": "Columbia"
    },
    {
      "id": 37,
      "value": "Cook",
      "viewValue": "Cook"
    },
    {
      "id": 38,
      "value": "Coweta",
      "viewValue": "Coweta"
    },
    {
      "id": 39,
      "value": "Crawford",
      "viewValue": "Crawford"
    },
    {
      "id": 40,
      "value": "Crisp",
      "viewValue": "Crisp"
    },
    {
      "id": 41,
      "value": "Dade",
      "viewValue": "Dade"
    },
    {
      "id": 42,
      "value": "Dawson",
      "viewValue": "Dawson"
    },
    {
      "id": 43,
      "value": "DeKalb",
      "viewValue": "DeKalb"
    },
    {
      "id": 44,
      "value": "Decatur",
      "viewValue": "Decatur"
    },
    {
      "id": 45,
      "value": "Dodge",
      "viewValue": "Dodge"
    },
    {
      "id": 46,
      "value": "Dooly",
      "viewValue": "Dooly"
    },
    {
      "id": 47,
      "value": "Dougherty",
      "viewValue": "Dougherty"
    },
    {
      "id": 48,
      "value": "Douglas",
      "viewValue": "Douglas"
    },
    {
      "id": 49,
      "value": "Early",
      "viewValue": "Early"
    },
    {
      "id": 50,
      "value": "Echols",
      "viewValue": "Echols"
    },
    {
      "id": 51,
      "value": "Effingham",
      "viewValue": "Effingham"
    },
    {
      "id": 52,
      "value": "Elbert",
      "viewValue": "Elbert"
    },
    {
      "id": 53,
      "value": "Emanuel",
      "viewValue": "Emanuel"
    },
    {
      "id": 54,
      "value": "Evans",
      "viewValue": "Evans"
    },
    {
      "id": 55,
      "value": "Fannin",
      "viewValue": "Fannin"
    },
    {
      "id": 56,
      "value": "Fayette",
      "viewValue": "Fayette"
    },
    {
      "id": 57,
      "value": "Floyd",
      "viewValue": "Floyd"
    },
    {
      "id": 58,
      "value": "Forsyth",
      "viewValue": "Forsyth"
    },
    {
      "id": 59,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 60,
      "value": "Fulton",
      "viewValue": "Fulton"
    },
    {
      "id": 61,
      "value": "Gilmer",
      "viewValue": "Gilmer"
    },
    {
      "id": 62,
      "value": "Glascock",
      "viewValue": "Glascock"
    },
    {
      "id": 63,
      "value": "Glynn",
      "viewValue": "Glynn"
    },
    {
      "id": 64,
      "value": "Gordon",
      "viewValue": "Gordon"
    },
    {
      "id": 65,
      "value": "Grady",
      "viewValue": "Grady"
    },
    {
      "id": 66,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 67,
      "value": "Gwinnett",
      "viewValue": "Gwinnett"
    },
    {
      "id": 68,
      "value": "Habersham",
      "viewValue": "Habersham"
    },
    {
      "id": 69,
      "value": "Hall",
      "viewValue": "Hall"
    },
    {
      "id": 70,
      "value": "Hancock",
      "viewValue": "Hancock"
    },
    {
      "id": 71,
      "value": "Haralson",
      "viewValue": "Haralson"
    },
    {
      "id": 72,
      "value": "Harris",
      "viewValue": "Harris"
    },
    {
      "id": 73,
      "value": "Hart",
      "viewValue": "Hart"
    },
    {
      "id": 74,
      "value": "Heard",
      "viewValue": "Heard"
    },
    {
      "id": 75,
      "value": "Henry",
      "viewValue": "Henry"
    },
    {
      "id": 76,
      "value": "Houston",
      "viewValue": "Houston"
    },
    {
      "id": 77,
      "value": "Irwin",
      "viewValue": "Irwin"
    },
    {
      "id": 78,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 79,
      "value": "Jasper",
      "viewValue": "Jasper"
    },
    {
      "id": 80,
      "value": "Jeff Davis",
      "viewValue": "Jeff Davis"
    },
    {
      "id": 81,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 82,
      "value": "Jenkins",
      "viewValue": "Jenkins"
    },
    {
      "id": 83,
      "value": "Johnson",
      "viewValue": "Johnson"
    },
    {
      "id": 84,
      "value": "Jones",
      "viewValue": "Jones"
    },
    {
      "id": 85,
      "value": "Lamar",
      "viewValue": "Lamar"
    },
    {
      "id": 86,
      "value": "Lanier",
      "viewValue": "Lanier"
    },
    {
      "id": 87,
      "value": "Laurens",
      "viewValue": "Laurens"
    },
    {
      "id": 88,
      "value": "Lee",
      "viewValue": "Lee"
    },
    {
      "id": 89,
      "value": "Liberty",
      "viewValue": "Liberty"
    },
    {
      "id": 90,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 91,
      "value": "Long",
      "viewValue": "Long"
    },
    {
      "id": 92,
      "value": "Lowndes",
      "viewValue": "Lowndes"
    },
    {
      "id": 93,
      "value": "Lumpkin",
      "viewValue": "Lumpkin"
    },
    {
      "id": 94,
      "value": "McDuffie",
      "viewValue": "McDuffie"
    },
    {
      "id": 95,
      "value": "McIntosh",
      "viewValue": "McIntosh"
    },
    {
      "id": 96,
      "value": "Macon",
      "viewValue": "Macon"
    },
    {
      "id": 97,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 98,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 99,
      "value": "Meriwether",
      "viewValue": "Meriwether"
    },
    {
      "id": 100,
      "value": "Miller",
      "viewValue": "Miller"
    },
    {
      "id": 101,
      "value": "Mitchell",
      "viewValue": "Mitchell"
    },
    {
      "id": 102,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 103,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 104,
      "value": "Morgan",
      "viewValue": "Morgan"
    },
    {
      "id": 105,
      "value": "Murray",
      "viewValue": "Murray"
    },
    {
      "id": 106,
      "value": "Muscogee",
      "viewValue": "Muscogee"
    },
    {
      "id": 107,
      "value": "Newton",
      "viewValue": "Newton"
    },
    {
      "id": 108,
      "value": "Oconee",
      "viewValue": "Oconee"
    },
    {
      "id": 109,
      "value": "Oglethorpe",
      "viewValue": "Oglethorpe"
    },
    {
      "id": 110,
      "value": "Paulding",
      "viewValue": "Paulding"
    },
    {
      "id": 111,
      "value": "Peach",
      "viewValue": "Peach"
    },
    {
      "id": 112,
      "value": "Pickens",
      "viewValue": "Pickens"
    },
    {
      "id": 113,
      "value": "Pierce",
      "viewValue": "Pierce"
    },
    {
      "id": 114,
      "value": "Pike",
      "viewValue": "Pike"
    },
    {
      "id": 115,
      "value": "Polk",
      "viewValue": "Polk"
    },
    {
      "id": 116,
      "value": "Pulaski",
      "viewValue": "Pulaski"
    },
    {
      "id": 117,
      "value": "Putnam",
      "viewValue": "Putnam"
    },
    {
      "id": 118,
      "value": "Quitman",
      "viewValue": "Quitman"
    },
    {
      "id": 119,
      "value": "Rabun",
      "viewValue": "Rabun"
    },
    {
      "id": 120,
      "value": "Randolph",
      "viewValue": "Randolph"
    },
    {
      "id": 121,
      "value": "Richmond",
      "viewValue": "Richmond"
    },
    {
      "id": 122,
      "value": "Rockdale",
      "viewValue": "Rockdale"
    },
    {
      "id": 123,
      "value": "Schley",
      "viewValue": "Schley"
    },
    {
      "id": 124,
      "value": "Screven",
      "viewValue": "Screven"
    },
    {
      "id": 125,
      "value": "Seminole",
      "viewValue": "Seminole"
    },
    {
      "id": 126,
      "value": "Spalding",
      "viewValue": "Spalding"
    },
    {
      "id": 127,
      "value": "Stephens",
      "viewValue": "Stephens"
    },
    {
      "id": 128,
      "value": "Stewart",
      "viewValue": "Stewart"
    },
    {
      "id": 129,
      "value": "Sumter",
      "viewValue": "Sumter"
    },
    {
      "id": 130,
      "value": "Talbot",
      "viewValue": "Talbot"
    },
    {
      "id": 131,
      "value": "Taliaferro",
      "viewValue": "Taliaferro"
    },
    {
      "id": 132,
      "value": "Tattnall",
      "viewValue": "Tattnall"
    },
    {
      "id": 133,
      "value": "Taylor",
      "viewValue": "Taylor"
    },
    {
      "id": 134,
      "value": "Telfair",
      "viewValue": "Telfair"
    },
    {
      "id": 135,
      "value": "Terrell",
      "viewValue": "Terrell"
    },
    {
      "id": 136,
      "value": "Thomas",
      "viewValue": "Thomas"
    },
    {
      "id": 137,
      "value": "Tift",
      "viewValue": "Tift"
    },
    {
      "id": 138,
      "value": "Toombs",
      "viewValue": "Toombs"
    },
    {
      "id": 139,
      "value": "Towns",
      "viewValue": "Towns"
    },
    {
      "id": 140,
      "value": "Treutlen",
      "viewValue": "Treutlen"
    },
    {
      "id": 141,
      "value": "Troup",
      "viewValue": "Troup"
    },
    {
      "id": 142,
      "value": "Turner",
      "viewValue": "Turner"
    },
    {
      "id": 143,
      "value": "Twiggs",
      "viewValue": "Twiggs"
    },
    {
      "id": 144,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 145,
      "value": "Upson",
      "viewValue": "Upson"
    },
    {
      "id": 146,
      "value": "Walker",
      "viewValue": "Walker"
    },
    {
      "id": 147,
      "value": "Walton",
      "viewValue": "Walton"
    },
    {
      "id": 148,
      "value": "Ware",
      "viewValue": "Ware"
    },
    {
      "id": 149,
      "value": "Warren",
      "viewValue": "Warren"
    },
    {
      "id": 150,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 151,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 152,
      "value": "Webster",
      "viewValue": "Webster"
    },
    {
      "id": 153,
      "value": "Wheeler",
      "viewValue": "Wheeler"
    },
    {
      "id": 154,
      "value": "White",
      "viewValue": "White"
    },
    {
      "id": 155,
      "value": "Whitfield",
      "viewValue": "Whitfield"
    },
    {
      "id": 156,
      "value": "Wilcox",
      "viewValue": "Wilcox"
    },
    {
      "id": 157,
      "value": "Wilkes",
      "viewValue": "Wilkes"
    },
    {
      "id": 158,
      "value": "Wilkinson",
      "viewValue": "Wilkinson"
    },
    {
      "id": 159,
      "value": "Worth",
      "viewValue": "Worth"
    }
  ],
  "HI": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Hawaii",
      "viewValue": "Hawaii"
    },
    {
      "id": 2,
      "value": "Honolulu",
      "viewValue": "Honolulu"
    },
    {
      "id": 3,
      "value": "Kalawao",
      "viewValue": "Kalawao"
    },
    {
      "id": 4,
      "value": "Kauai",
      "viewValue": "Kauai"
    },
    {
      "id": 5,
      "value": "Maui",
      "viewValue": "Maui"
    }
  ],
  "ID": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Ada",
      "viewValue": "Ada"
    },
    {
      "id": 2,
      "value": "Adams",
      "viewValue": "Adams"
    },
    {
      "id": 3,
      "value": "Bannock",
      "viewValue": "Bannock"
    },
    {
      "id": 4,
      "value": "Bear Lake",
      "viewValue": "Bear Lake"
    },
    {
      "id": 5,
      "value": "Benewah",
      "viewValue": "Benewah"
    },
    {
      "id": 6,
      "value": "Bingham",
      "viewValue": "Bingham"
    },
    {
      "id": 7,
      "value": "Blaine",
      "viewValue": "Blaine"
    },
    {
      "id": 8,
      "value": "Boise",
      "viewValue": "Boise"
    },
    {
      "id": 9,
      "value": "Bonner",
      "viewValue": "Bonner"
    },
    {
      "id": 10,
      "value": "Bonneville",
      "viewValue": "Bonneville"
    },
    {
      "id": 11,
      "value": "Boundary",
      "viewValue": "Boundary"
    },
    {
      "id": 12,
      "value": "Butte",
      "viewValue": "Butte"
    },
    {
      "id": 13,
      "value": "Camas",
      "viewValue": "Camas"
    },
    {
      "id": 14,
      "value": "Canyon",
      "viewValue": "Canyon"
    },
    {
      "id": 15,
      "value": "Caribou",
      "viewValue": "Caribou"
    },
    {
      "id": 16,
      "value": "Cassia",
      "viewValue": "Cassia"
    },
    {
      "id": 17,
      "value": "Clark",
      "viewValue": "Clark"
    },
    {
      "id": 18,
      "value": "Clearwater",
      "viewValue": "Clearwater"
    },
    {
      "id": 19,
      "value": "Custer",
      "viewValue": "Custer"
    },
    {
      "id": 20,
      "value": "Elmore",
      "viewValue": "Elmore"
    },
    {
      "id": 21,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 22,
      "value": "Fremont",
      "viewValue": "Fremont"
    },
    {
      "id": 23,
      "value": "Gem",
      "viewValue": "Gem"
    },
    {
      "id": 24,
      "value": "Gooding",
      "viewValue": "Gooding"
    },
    {
      "id": 25,
      "value": "Idaho",
      "viewValue": "Idaho"
    },
    {
      "id": 26,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 27,
      "value": "Jerome",
      "viewValue": "Jerome"
    },
    {
      "id": 28,
      "value": "Kootenai",
      "viewValue": "Kootenai"
    },
    {
      "id": 29,
      "value": "Latah",
      "viewValue": "Latah"
    },
    {
      "id": 30,
      "value": "Lemhi",
      "viewValue": "Lemhi"
    },
    {
      "id": 31,
      "value": "Lewis",
      "viewValue": "Lewis"
    },
    {
      "id": 32,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 33,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 34,
      "value": "Minidoka",
      "viewValue": "Minidoka"
    },
    {
      "id": 35,
      "value": "Nez Perce",
      "viewValue": "Nez Perce"
    },
    {
      "id": 36,
      "value": "Oneida",
      "viewValue": "Oneida"
    },
    {
      "id": 37,
      "value": "Owyhee",
      "viewValue": "Owyhee"
    },
    {
      "id": 38,
      "value": "Payette",
      "viewValue": "Payette"
    },
    {
      "id": 39,
      "value": "Power",
      "viewValue": "Power"
    },
    {
      "id": 40,
      "value": "Shoshone",
      "viewValue": "Shoshone"
    },
    {
      "id": 41,
      "value": "Teton",
      "viewValue": "Teton"
    },
    {
      "id": 42,
      "value": "Twin Falls",
      "viewValue": "Twin Falls"
    },
    {
      "id": 43,
      "value": "Valley",
      "viewValue": "Valley"
    },
    {
      "id": 44,
      "value": "Washington",
      "viewValue": "Washington"
    }
  ],
  "IL": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adams",
      "viewValue": "Adams"
    },
    {
      "id": 2,
      "value": "Alexander",
      "viewValue": "Alexander"
    },
    {
      "id": 3,
      "value": "Bond",
      "viewValue": "Bond"
    },
    {
      "id": 4,
      "value": "Boone",
      "viewValue": "Boone"
    },
    {
      "id": 5,
      "value": "Brown",
      "viewValue": "Brown"
    },
    {
      "id": 6,
      "value": "Bureau",
      "viewValue": "Bureau"
    },
    {
      "id": 7,
      "value": "Calhoun",
      "viewValue": "Calhoun"
    },
    {
      "id": 8,
      "value": "Carroll",
      "viewValue": "Carroll"
    },
    {
      "id": 9,
      "value": "Cass",
      "viewValue": "Cass"
    },
    {
      "id": 10,
      "value": "Champaign",
      "viewValue": "Champaign"
    },
    {
      "id": 11,
      "value": "Christian",
      "viewValue": "Christian"
    },
    {
      "id": 12,
      "value": "Clark",
      "viewValue": "Clark"
    },
    {
      "id": 13,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 14,
      "value": "Clinton",
      "viewValue": "Clinton"
    },
    {
      "id": 15,
      "value": "Coles",
      "viewValue": "Coles"
    },
    {
      "id": 16,
      "value": "Cook",
      "viewValue": "Cook"
    },
    {
      "id": 17,
      "value": "Crawford",
      "viewValue": "Crawford"
    },
    {
      "id": 18,
      "value": "Cumberland",
      "viewValue": "Cumberland"
    },
    {
      "id": 19,
      "value": "DeKalb",
      "viewValue": "DeKalb"
    },
    {
      "id": 20,
      "value": "De Witt",
      "viewValue": "De Witt"
    },
    {
      "id": 21,
      "value": "Douglas",
      "viewValue": "Douglas"
    },
    {
      "id": 22,
      "value": "DuPage",
      "viewValue": "DuPage"
    },
    {
      "id": 23,
      "value": "Edgar",
      "viewValue": "Edgar"
    },
    {
      "id": 24,
      "value": "Edwards",
      "viewValue": "Edwards"
    },
    {
      "id": 25,
      "value": "Effingham",
      "viewValue": "Effingham"
    },
    {
      "id": 26,
      "value": "Fayette",
      "viewValue": "Fayette"
    },
    {
      "id": 27,
      "value": "Ford",
      "viewValue": "Ford"
    },
    {
      "id": 28,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 29,
      "value": "Fulton",
      "viewValue": "Fulton"
    },
    {
      "id": 30,
      "value": "Gallatin",
      "viewValue": "Gallatin"
    },
    {
      "id": 31,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 32,
      "value": "Grundy",
      "viewValue": "Grundy"
    },
    {
      "id": 33,
      "value": "Hamilton",
      "viewValue": "Hamilton"
    },
    {
      "id": 34,
      "value": "Hancock",
      "viewValue": "Hancock"
    },
    {
      "id": 35,
      "value": "Hardin",
      "viewValue": "Hardin"
    },
    {
      "id": 36,
      "value": "Henderson",
      "viewValue": "Henderson"
    },
    {
      "id": 37,
      "value": "Henry",
      "viewValue": "Henry"
    },
    {
      "id": 38,
      "value": "Iroquois",
      "viewValue": "Iroquois"
    },
    {
      "id": 39,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 40,
      "value": "Jasper",
      "viewValue": "Jasper"
    },
    {
      "id": 41,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 42,
      "value": "Jersey",
      "viewValue": "Jersey"
    },
    {
      "id": 43,
      "value": "Jo Daviess",
      "viewValue": "Jo Daviess"
    },
    {
      "id": 44,
      "value": "Johnson",
      "viewValue": "Johnson"
    },
    {
      "id": 45,
      "value": "Kane",
      "viewValue": "Kane"
    },
    {
      "id": 46,
      "value": "Kankakee",
      "viewValue": "Kankakee"
    },
    {
      "id": 47,
      "value": "Kendall",
      "viewValue": "Kendall"
    },
    {
      "id": 48,
      "value": "Knox",
      "viewValue": "Knox"
    },
    {
      "id": 49,
      "value": "Lake",
      "viewValue": "Lake"
    },
    {
      "id": 50,
      "value": "LaSalle",
      "viewValue": "LaSalle"
    },
    {
      "id": 51,
      "value": "Lawrence",
      "viewValue": "Lawrence"
    },
    {
      "id": 52,
      "value": "Lee",
      "viewValue": "Lee"
    },
    {
      "id": 53,
      "value": "Livingston",
      "viewValue": "Livingston"
    },
    {
      "id": 54,
      "value": "Logan",
      "viewValue": "Logan"
    },
    {
      "id": 55,
      "value": "McDonough",
      "viewValue": "McDonough"
    },
    {
      "id": 56,
      "value": "McHenry",
      "viewValue": "McHenry"
    },
    {
      "id": 57,
      "value": "McLean",
      "viewValue": "McLean"
    },
    {
      "id": 58,
      "value": "Macon",
      "viewValue": "Macon"
    },
    {
      "id": 59,
      "value": "Macoupin",
      "viewValue": "Macoupin"
    },
    {
      "id": 60,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 61,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 62,
      "value": "Marshall",
      "viewValue": "Marshall"
    },
    {
      "id": 63,
      "value": "Mason",
      "viewValue": "Mason"
    },
    {
      "id": 64,
      "value": "Massac",
      "viewValue": "Massac"
    },
    {
      "id": 65,
      "value": "Menard",
      "viewValue": "Menard"
    },
    {
      "id": 66,
      "value": "Mercer",
      "viewValue": "Mercer"
    },
    {
      "id": 67,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 68,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 69,
      "value": "Morgan",
      "viewValue": "Morgan"
    },
    {
      "id": 70,
      "value": "Moultrie",
      "viewValue": "Moultrie"
    },
    {
      "id": 71,
      "value": "Ogle",
      "viewValue": "Ogle"
    },
    {
      "id": 72,
      "value": "Peoria",
      "viewValue": "Peoria"
    },
    {
      "id": 73,
      "value": "Perry",
      "viewValue": "Perry"
    },
    {
      "id": 74,
      "value": "Piatt",
      "viewValue": "Piatt"
    },
    {
      "id": 75,
      "value": "Pike",
      "viewValue": "Pike"
    },
    {
      "id": 76,
      "value": "Pope",
      "viewValue": "Pope"
    },
    {
      "id": 77,
      "value": "Pulaski",
      "viewValue": "Pulaski"
    },
    {
      "id": 78,
      "value": "Putnam",
      "viewValue": "Putnam"
    },
    {
      "id": 79,
      "value": "Randolph",
      "viewValue": "Randolph"
    },
    {
      "id": 80,
      "value": "Richland",
      "viewValue": "Richland"
    },
    {
      "id": 81,
      "value": "Rock Island",
      "viewValue": "Rock Island"
    },
    {
      "id": 82,
      "value": "St. Clair",
      "viewValue": "St. Clair"
    },
    {
      "id": 83,
      "value": "Saline",
      "viewValue": "Saline"
    },
    {
      "id": 84,
      "value": "Sangamon",
      "viewValue": "Sangamon"
    },
    {
      "id": 85,
      "value": "Schuyler",
      "viewValue": "Schuyler"
    },
    {
      "id": 86,
      "value": "Scott",
      "viewValue": "Scott"
    },
    {
      "id": 87,
      "value": "Shelby",
      "viewValue": "Shelby"
    },
    {
      "id": 88,
      "value": "Stark",
      "viewValue": "Stark"
    },
    {
      "id": 89,
      "value": "Stephenson",
      "viewValue": "Stephenson"
    },
    {
      "id": 90,
      "value": "Tazewell",
      "viewValue": "Tazewell"
    },
    {
      "id": 91,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 92,
      "value": "Vermilion",
      "viewValue": "Vermilion"
    },
    {
      "id": 93,
      "value": "Wabash",
      "viewValue": "Wabash"
    },
    {
      "id": 94,
      "value": "Warren",
      "viewValue": "Warren"
    },
    {
      "id": 95,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 96,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 97,
      "value": "White",
      "viewValue": "White"
    },
    {
      "id": 98,
      "value": "Whiteside",
      "viewValue": "Whiteside"
    },
    {
      "id": 99,
      "value": "Will",
      "viewValue": "Will"
    },
    {
      "id": 100,
      "value": "Williamson",
      "viewValue": "Williamson"
    },
    {
      "id": 101,
      "value": "Winnebago",
      "viewValue": "Winnebago"
    },
    {
      "id": 102,
      "value": "Woodford",
      "viewValue": "Woodford"
    }
  ],
  "IN": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adams",
      "viewValue": "Adams"
    },
    {
      "id": 2,
      "value": "Allen",
      "viewValue": "Allen"
    },
    {
      "id": 3,
      "value": "Bartholomew",
      "viewValue": "Bartholomew"
    },
    {
      "id": 4,
      "value": "Benton",
      "viewValue": "Benton"
    },
    {
      "id": 5,
      "value": "Blackford",
      "viewValue": "Blackford"
    },
    {
      "id": 6,
      "value": "Boone",
      "viewValue": "Boone"
    },
    {
      "id": 7,
      "value": "Brown",
      "viewValue": "Brown"
    },
    {
      "id": 8,
      "value": "Carroll",
      "viewValue": "Carroll"
    },
    {
      "id": 9,
      "value": "Cass",
      "viewValue": "Cass"
    },
    {
      "id": 10,
      "value": "Clark",
      "viewValue": "Clark"
    },
    {
      "id": 11,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 12,
      "value": "Clinton",
      "viewValue": "Clinton"
    },
    {
      "id": 13,
      "value": "Crawford",
      "viewValue": "Crawford"
    },
    {
      "id": 14,
      "value": "Daviess",
      "viewValue": "Daviess"
    },
    {
      "id": 15,
      "value": "Dearborn",
      "viewValue": "Dearborn"
    },
    {
      "id": 16,
      "value": "Decatur",
      "viewValue": "Decatur"
    },
    {
      "id": 17,
      "value": "DeKalb",
      "viewValue": "DeKalb"
    },
    {
      "id": 18,
      "value": "Delaware",
      "viewValue": "Delaware"
    },
    {
      "id": 19,
      "value": "Dubois",
      "viewValue": "Dubois"
    },
    {
      "id": 20,
      "value": "Elkhart",
      "viewValue": "Elkhart"
    },
    {
      "id": 21,
      "value": "Fayette",
      "viewValue": "Fayette"
    },
    {
      "id": 22,
      "value": "Floyd",
      "viewValue": "Floyd"
    },
    {
      "id": 23,
      "value": "Fountain",
      "viewValue": "Fountain"
    },
    {
      "id": 24,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 25,
      "value": "Fulton",
      "viewValue": "Fulton"
    },
    {
      "id": 26,
      "value": "Gibson",
      "viewValue": "Gibson"
    },
    {
      "id": 27,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 28,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 29,
      "value": "Hamilton",
      "viewValue": "Hamilton"
    },
    {
      "id": 30,
      "value": "Hancock",
      "viewValue": "Hancock"
    },
    {
      "id": 31,
      "value": "Harrison",
      "viewValue": "Harrison"
    },
    {
      "id": 32,
      "value": "Hendricks",
      "viewValue": "Hendricks"
    },
    {
      "id": 33,
      "value": "Henry",
      "viewValue": "Henry"
    },
    {
      "id": 34,
      "value": "Howard",
      "viewValue": "Howard"
    },
    {
      "id": 35,
      "value": "Huntington",
      "viewValue": "Huntington"
    },
    {
      "id": 36,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 37,
      "value": "Jasper",
      "viewValue": "Jasper"
    },
    {
      "id": 38,
      "value": "Jay",
      "viewValue": "Jay"
    },
    {
      "id": 39,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 40,
      "value": "Jennings",
      "viewValue": "Jennings"
    },
    {
      "id": 41,
      "value": "Johnson",
      "viewValue": "Johnson"
    },
    {
      "id": 42,
      "value": "Knox",
      "viewValue": "Knox"
    },
    {
      "id": 43,
      "value": "Kosciusko",
      "viewValue": "Kosciusko"
    },
    {
      "id": 44,
      "value": "LaGrange",
      "viewValue": "LaGrange"
    },
    {
      "id": 45,
      "value": "Lake",
      "viewValue": "Lake"
    },
    {
      "id": 46,
      "value": "LaPorte",
      "viewValue": "LaPorte"
    },
    {
      "id": 47,
      "value": "Lawrence",
      "viewValue": "Lawrence"
    },
    {
      "id": 48,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 49,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 50,
      "value": "Marshall",
      "viewValue": "Marshall"
    },
    {
      "id": 51,
      "value": "Martin",
      "viewValue": "Martin"
    },
    {
      "id": 52,
      "value": "Miami",
      "viewValue": "Miami"
    },
    {
      "id": 53,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 54,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 55,
      "value": "Morgan",
      "viewValue": "Morgan"
    },
    {
      "id": 56,
      "value": "Newton",
      "viewValue": "Newton"
    },
    {
      "id": 57,
      "value": "Noble",
      "viewValue": "Noble"
    },
    {
      "id": 58,
      "value": "Ohio",
      "viewValue": "Ohio"
    },
    {
      "id": 59,
      "value": "Orange",
      "viewValue": "Orange"
    },
    {
      "id": 60,
      "value": "Owen",
      "viewValue": "Owen"
    },
    {
      "id": 61,
      "value": "Parke",
      "viewValue": "Parke"
    },
    {
      "id": 62,
      "value": "Perry",
      "viewValue": "Perry"
    },
    {
      "id": 63,
      "value": "Pike",
      "viewValue": "Pike"
    },
    {
      "id": 64,
      "value": "Porter",
      "viewValue": "Porter"
    },
    {
      "id": 65,
      "value": "Posey",
      "viewValue": "Posey"
    },
    {
      "id": 66,
      "value": "Pulaski",
      "viewValue": "Pulaski"
    },
    {
      "id": 67,
      "value": "Putnam",
      "viewValue": "Putnam"
    },
    {
      "id": 68,
      "value": "Randolph",
      "viewValue": "Randolph"
    },
    {
      "id": 69,
      "value": "Ripley",
      "viewValue": "Ripley"
    },
    {
      "id": 70,
      "value": "Rush",
      "viewValue": "Rush"
    },
    {
      "id": 71,
      "value": "St. Joseph",
      "viewValue": "St. Joseph"
    },
    {
      "id": 72,
      "value": "Scott",
      "viewValue": "Scott"
    },
    {
      "id": 73,
      "value": "Shelby",
      "viewValue": "Shelby"
    },
    {
      "id": 74,
      "value": "Spencer",
      "viewValue": "Spencer"
    },
    {
      "id": 75,
      "value": "Starke",
      "viewValue": "Starke"
    },
    {
      "id": 76,
      "value": "Steuben",
      "viewValue": "Steuben"
    },
    {
      "id": 77,
      "value": "Sullivan",
      "viewValue": "Sullivan"
    },
    {
      "id": 78,
      "value": "Switzerland",
      "viewValue": "Switzerland"
    },
    {
      "id": 79,
      "value": "Tippecanoe",
      "viewValue": "Tippecanoe"
    },
    {
      "id": 80,
      "value": "Tipton",
      "viewValue": "Tipton"
    },
    {
      "id": 81,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 82,
      "value": "Vanderburgh",
      "viewValue": "Vanderburgh"
    },
    {
      "id": 83,
      "value": "Vermillion",
      "viewValue": "Vermillion"
    },
    {
      "id": 84,
      "value": "Vigo",
      "viewValue": "Vigo"
    },
    {
      "id": 85,
      "value": "Wabash",
      "viewValue": "Wabash"
    },
    {
      "id": 86,
      "value": "Warren",
      "viewValue": "Warren"
    },
    {
      "id": 87,
      "value": "Warrick",
      "viewValue": "Warrick"
    },
    {
      "id": 88,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 89,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 90,
      "value": "Wells",
      "viewValue": "Wells"
    },
    {
      "id": 91,
      "value": "White",
      "viewValue": "White"
    },
    {
      "id": 92,
      "value": "Whitley",
      "viewValue": "Whitley"
    }
  ],
  "IA": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adair",
      "viewValue": "Adair"
    },
    {
      "id": 2,
      "value": "Adams",
      "viewValue": "Adams"
    },
    {
      "id": 3,
      "value": "Allamakee",
      "viewValue": "Allamakee"
    },
    {
      "id": 4,
      "value": "Appanoose",
      "viewValue": "Appanoose"
    },
    {
      "id": 5,
      "value": "Audubon",
      "viewValue": "Audubon"
    },
    {
      "id": 6,
      "value": "Benton",
      "viewValue": "Benton"
    },
    {
      "id": 7,
      "value": "Black Hawk",
      "viewValue": "Black Hawk"
    },
    {
      "id": 8,
      "value": "Boone",
      "viewValue": "Boone"
    },
    {
      "id": 9,
      "value": "Bremer",
      "viewValue": "Bremer"
    },
    {
      "id": 10,
      "value": "Buchanan",
      "viewValue": "Buchanan"
    },
    {
      "id": 11,
      "value": "Buena Vista",
      "viewValue": "Buena Vista"
    },
    {
      "id": 12,
      "value": "Butler",
      "viewValue": "Butler"
    },
    {
      "id": 13,
      "value": "Calhoun",
      "viewValue": "Calhoun"
    },
    {
      "id": 14,
      "value": "Carroll",
      "viewValue": "Carroll"
    },
    {
      "id": 15,
      "value": "Cass",
      "viewValue": "Cass"
    },
    {
      "id": 16,
      "value": "Cedar",
      "viewValue": "Cedar"
    },
    {
      "id": 17,
      "value": "Cerro Gordo",
      "viewValue": "Cerro Gordo"
    },
    {
      "id": 18,
      "value": "Cherokee",
      "viewValue": "Cherokee"
    },
    {
      "id": 19,
      "value": "Chickasaw",
      "viewValue": "Chickasaw"
    },
    {
      "id": 20,
      "value": "Clarke",
      "viewValue": "Clarke"
    },
    {
      "id": 21,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 22,
      "value": "Clayton",
      "viewValue": "Clayton"
    },
    {
      "id": 23,
      "value": "Clinton",
      "viewValue": "Clinton"
    },
    {
      "id": 24,
      "value": "Crawford",
      "viewValue": "Crawford"
    },
    {
      "id": 25,
      "value": "Dallas",
      "viewValue": "Dallas"
    },
    {
      "id": 26,
      "value": "Davis",
      "viewValue": "Davis"
    },
    {
      "id": 27,
      "value": "Decatur",
      "viewValue": "Decatur"
    },
    {
      "id": 28,
      "value": "Delaware",
      "viewValue": "Delaware"
    },
    {
      "id": 29,
      "value": "Des Moines",
      "viewValue": "Des Moines"
    },
    {
      "id": 30,
      "value": "Dickinson",
      "viewValue": "Dickinson"
    },
    {
      "id": 31,
      "value": "Dubuque",
      "viewValue": "Dubuque"
    },
    {
      "id": 32,
      "value": "Emmet",
      "viewValue": "Emmet"
    },
    {
      "id": 33,
      "value": "Fayette",
      "viewValue": "Fayette"
    },
    {
      "id": 34,
      "value": "Floyd",
      "viewValue": "Floyd"
    },
    {
      "id": 35,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 36,
      "value": "Fremont",
      "viewValue": "Fremont"
    },
    {
      "id": 37,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 38,
      "value": "Grundy",
      "viewValue": "Grundy"
    },
    {
      "id": 39,
      "value": "Guthrie",
      "viewValue": "Guthrie"
    },
    {
      "id": 40,
      "value": "Hamilton",
      "viewValue": "Hamilton"
    },
    {
      "id": 41,
      "value": "Hancock",
      "viewValue": "Hancock"
    },
    {
      "id": 42,
      "value": "Hardin",
      "viewValue": "Hardin"
    },
    {
      "id": 43,
      "value": "Harrison",
      "viewValue": "Harrison"
    },
    {
      "id": 44,
      "value": "Henry",
      "viewValue": "Henry"
    },
    {
      "id": 45,
      "value": "Howard",
      "viewValue": "Howard"
    },
    {
      "id": 46,
      "value": "Humboldt",
      "viewValue": "Humboldt"
    },
    {
      "id": 47,
      "value": "Ida",
      "viewValue": "Ida"
    },
    {
      "id": 48,
      "value": "Iowa",
      "viewValue": "Iowa"
    },
    {
      "id": 49,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 50,
      "value": "Jasper",
      "viewValue": "Jasper"
    },
    {
      "id": 51,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 52,
      "value": "Johnson",
      "viewValue": "Johnson"
    },
    {
      "id": 53,
      "value": "Jones",
      "viewValue": "Jones"
    },
    {
      "id": 54,
      "value": "Keokuk",
      "viewValue": "Keokuk"
    },
    {
      "id": 55,
      "value": "Kossuth",
      "viewValue": "Kossuth"
    },
    {
      "id": 56,
      "value": "Lee",
      "viewValue": "Lee"
    },
    {
      "id": 57,
      "value": "Linn",
      "viewValue": "Linn"
    },
    {
      "id": 58,
      "value": "Louisa",
      "viewValue": "Louisa"
    },
    {
      "id": 59,
      "value": "Lucas",
      "viewValue": "Lucas"
    },
    {
      "id": 60,
      "value": "Lyon",
      "viewValue": "Lyon"
    },
    {
      "id": 61,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 62,
      "value": "Mahaska",
      "viewValue": "Mahaska"
    },
    {
      "id": 63,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 64,
      "value": "Marshall",
      "viewValue": "Marshall"
    },
    {
      "id": 65,
      "value": "Mills",
      "viewValue": "Mills"
    },
    {
      "id": 66,
      "value": "Mitchell",
      "viewValue": "Mitchell"
    },
    {
      "id": 67,
      "value": "Monona",
      "viewValue": "Monona"
    },
    {
      "id": 68,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 69,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 70,
      "value": "Muscatine",
      "viewValue": "Muscatine"
    },
    {
      "id": 71,
      "value": "O'Brien",
      "viewValue": "O'Brien"
    },
    {
      "id": 72,
      "value": "Osceola",
      "viewValue": "Osceola"
    },
    {
      "id": 73,
      "value": "Page",
      "viewValue": "Page"
    },
    {
      "id": 74,
      "value": "Palo Alto",
      "viewValue": "Palo Alto"
    },
    {
      "id": 75,
      "value": "Plymouth",
      "viewValue": "Plymouth"
    },
    {
      "id": 76,
      "value": "Pocahontas",
      "viewValue": "Pocahontas"
    },
    {
      "id": 77,
      "value": "Polk",
      "viewValue": "Polk"
    },
    {
      "id": 78,
      "value": "Pottawattamie",
      "viewValue": "Pottawattamie"
    },
    {
      "id": 79,
      "value": "Poweshiek",
      "viewValue": "Poweshiek"
    },
    {
      "id": 80,
      "value": "Ringgold",
      "viewValue": "Ringgold"
    },
    {
      "id": 81,
      "value": "Sac",
      "viewValue": "Sac"
    },
    {
      "id": 82,
      "value": "Scott",
      "viewValue": "Scott"
    },
    {
      "id": 83,
      "value": "Shelby",
      "viewValue": "Shelby"
    },
    {
      "id": 84,
      "value": "Sioux",
      "viewValue": "Sioux"
    },
    {
      "id": 85,
      "value": "Story",
      "viewValue": "Story"
    },
    {
      "id": 86,
      "value": "Tama",
      "viewValue": "Tama"
    },
    {
      "id": 87,
      "value": "Taylor",
      "viewValue": "Taylor"
    },
    {
      "id": 88,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 89,
      "value": "Van Buren",
      "viewValue": "Van Buren"
    },
    {
      "id": 90,
      "value": "Wapello",
      "viewValue": "Wapello"
    },
    {
      "id": 91,
      "value": "Warren",
      "viewValue": "Warren"
    },
    {
      "id": 92,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 93,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 94,
      "value": "Webster",
      "viewValue": "Webster"
    },
    {
      "id": 95,
      "value": "Winnebago",
      "viewValue": "Winnebago"
    },
    {
      "id": 96,
      "value": "Winneshiek",
      "viewValue": "Winneshiek"
    },
    {
      "id": 97,
      "value": "Woodbury",
      "viewValue": "Woodbury"
    },
    {
      "id": 98,
      "value": "Worth",
      "viewValue": "Worth"
    },
    {
      "id": 99,
      "value": "Wright",
      "viewValue": "Wright"
    }
  ],
  "KS": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Allen",
      "viewValue": "Allen"
    },
    {
      "id": 2,
      "value": "Anderson",
      "viewValue": "Anderson"
    },
    {
      "id": 3,
      "value": "Atchison",
      "viewValue": "Atchison"
    },
    {
      "id": 4,
      "value": "Barber",
      "viewValue": "Barber"
    },
    {
      "id": 5,
      "value": "Barton",
      "viewValue": "Barton"
    },
    {
      "id": 6,
      "value": "Bourbon",
      "viewValue": "Bourbon"
    },
    {
      "id": 7,
      "value": "Brown",
      "viewValue": "Brown"
    },
    {
      "id": 8,
      "value": "Butler",
      "viewValue": "Butler"
    },
    {
      "id": 9,
      "value": "Chase",
      "viewValue": "Chase"
    },
    {
      "id": 10,
      "value": "Chautauqua",
      "viewValue": "Chautauqua"
    },
    {
      "id": 11,
      "value": "Cherokee",
      "viewValue": "Cherokee"
    },
    {
      "id": 12,
      "value": "Cheyenne",
      "viewValue": "Cheyenne"
    },
    {
      "id": 13,
      "value": "Clark",
      "viewValue": "Clark"
    },
    {
      "id": 14,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 15,
      "value": "Cloud",
      "viewValue": "Cloud"
    },
    {
      "id": 16,
      "value": "Coffey",
      "viewValue": "Coffey"
    },
    {
      "id": 17,
      "value": "Comanche",
      "viewValue": "Comanche"
    },
    {
      "id": 18,
      "value": "Cowley",
      "viewValue": "Cowley"
    },
    {
      "id": 19,
      "value": "Crawford",
      "viewValue": "Crawford"
    },
    {
      "id": 20,
      "value": "Decatur",
      "viewValue": "Decatur"
    },
    {
      "id": 21,
      "value": "Dickinson",
      "viewValue": "Dickinson"
    },
    {
      "id": 22,
      "value": "Doniphan",
      "viewValue": "Doniphan"
    },
    {
      "id": 23,
      "value": "Douglas",
      "viewValue": "Douglas"
    },
    {
      "id": 24,
      "value": "Edwards",
      "viewValue": "Edwards"
    },
    {
      "id": 25,
      "value": "Elk",
      "viewValue": "Elk"
    },
    {
      "id": 26,
      "value": "Ellis",
      "viewValue": "Ellis"
    },
    {
      "id": 27,
      "value": "Ellsworth",
      "viewValue": "Ellsworth"
    },
    {
      "id": 28,
      "value": "Finney",
      "viewValue": "Finney"
    },
    {
      "id": 29,
      "value": "Ford",
      "viewValue": "Ford"
    },
    {
      "id": 30,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 31,
      "value": "Geary",
      "viewValue": "Geary"
    },
    {
      "id": 32,
      "value": "Gove",
      "viewValue": "Gove"
    },
    {
      "id": 33,
      "value": "Graham",
      "viewValue": "Graham"
    },
    {
      "id": 34,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 35,
      "value": "Gray",
      "viewValue": "Gray"
    },
    {
      "id": 36,
      "value": "Greeley",
      "viewValue": "Greeley"
    },
    {
      "id": 37,
      "value": "Greenwood",
      "viewValue": "Greenwood"
    },
    {
      "id": 38,
      "value": "Hamilton",
      "viewValue": "Hamilton"
    },
    {
      "id": 39,
      "value": "Harper",
      "viewValue": "Harper"
    },
    {
      "id": 40,
      "value": "Harvey",
      "viewValue": "Harvey"
    },
    {
      "id": 41,
      "value": "Haskell",
      "viewValue": "Haskell"
    },
    {
      "id": 42,
      "value": "Hodgeman",
      "viewValue": "Hodgeman"
    },
    {
      "id": 43,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 44,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 45,
      "value": "Jewell",
      "viewValue": "Jewell"
    },
    {
      "id": 46,
      "value": "Johnson",
      "viewValue": "Johnson"
    },
    {
      "id": 47,
      "value": "Kearny",
      "viewValue": "Kearny"
    },
    {
      "id": 48,
      "value": "Kingman",
      "viewValue": "Kingman"
    },
    {
      "id": 49,
      "value": "Kiowa",
      "viewValue": "Kiowa"
    },
    {
      "id": 50,
      "value": "Labette",
      "viewValue": "Labette"
    },
    {
      "id": 51,
      "value": "Lane",
      "viewValue": "Lane"
    },
    {
      "id": 52,
      "value": "Leavenworth",
      "viewValue": "Leavenworth"
    },
    {
      "id": 53,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 54,
      "value": "Linn",
      "viewValue": "Linn"
    },
    {
      "id": 55,
      "value": "Logan",
      "viewValue": "Logan"
    },
    {
      "id": 56,
      "value": "Lyon",
      "viewValue": "Lyon"
    },
    {
      "id": 57,
      "value": "McPherson",
      "viewValue": "McPherson"
    },
    {
      "id": 58,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 59,
      "value": "Marshall",
      "viewValue": "Marshall"
    },
    {
      "id": 60,
      "value": "Meade",
      "viewValue": "Meade"
    },
    {
      "id": 61,
      "value": "Miami",
      "viewValue": "Miami"
    },
    {
      "id": 62,
      "value": "Mitchell",
      "viewValue": "Mitchell"
    },
    {
      "id": 63,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 64,
      "value": "Morris",
      "viewValue": "Morris"
    },
    {
      "id": 65,
      "value": "Morton",
      "viewValue": "Morton"
    },
    {
      "id": 66,
      "value": "Nemaha",
      "viewValue": "Nemaha"
    },
    {
      "id": 67,
      "value": "Neosho",
      "viewValue": "Neosho"
    },
    {
      "id": 68,
      "value": "Ness",
      "viewValue": "Ness"
    },
    {
      "id": 69,
      "value": "Norton",
      "viewValue": "Norton"
    },
    {
      "id": 70,
      "value": "Osage",
      "viewValue": "Osage"
    },
    {
      "id": 71,
      "value": "Osborne",
      "viewValue": "Osborne"
    },
    {
      "id": 72,
      "value": "Ottawa",
      "viewValue": "Ottawa"
    },
    {
      "id": 73,
      "value": "Pawnee",
      "viewValue": "Pawnee"
    },
    {
      "id": 74,
      "value": "Phillips",
      "viewValue": "Phillips"
    },
    {
      "id": 75,
      "value": "Pottawatomie",
      "viewValue": "Pottawatomie"
    },
    {
      "id": 76,
      "value": "Pratt",
      "viewValue": "Pratt"
    },
    {
      "id": 77,
      "value": "Rawlins",
      "viewValue": "Rawlins"
    },
    {
      "id": 78,
      "value": "Reno",
      "viewValue": "Reno"
    },
    {
      "id": 79,
      "value": "Republic",
      "viewValue": "Republic"
    },
    {
      "id": 80,
      "value": "Rice",
      "viewValue": "Rice"
    },
    {
      "id": 81,
      "value": "Riley",
      "viewValue": "Riley"
    },
    {
      "id": 82,
      "value": "Rooks",
      "viewValue": "Rooks"
    },
    {
      "id": 83,
      "value": "Rush",
      "viewValue": "Rush"
    },
    {
      "id": 84,
      "value": "Russell",
      "viewValue": "Russell"
    },
    {
      "id": 85,
      "value": "Saline",
      "viewValue": "Saline"
    },
    {
      "id": 86,
      "value": "Scott",
      "viewValue": "Scott"
    },
    {
      "id": 87,
      "value": "Sedgwick",
      "viewValue": "Sedgwick"
    },
    {
      "id": 88,
      "value": "Seward",
      "viewValue": "Seward"
    },
    {
      "id": 89,
      "value": "Shawnee",
      "viewValue": "Shawnee"
    },
    {
      "id": 90,
      "value": "Sheridan",
      "viewValue": "Sheridan"
    },
    {
      "id": 91,
      "value": "Sherman",
      "viewValue": "Sherman"
    },
    {
      "id": 92,
      "value": "Smith",
      "viewValue": "Smith"
    },
    {
      "id": 93,
      "value": "Stafford",
      "viewValue": "Stafford"
    },
    {
      "id": 94,
      "value": "Stanton",
      "viewValue": "Stanton"
    },
    {
      "id": 95,
      "value": "Stevens",
      "viewValue": "Stevens"
    },
    {
      "id": 96,
      "value": "Sumner",
      "viewValue": "Sumner"
    },
    {
      "id": 97,
      "value": "Thomas",
      "viewValue": "Thomas"
    },
    {
      "id": 98,
      "value": "Trego",
      "viewValue": "Trego"
    },
    {
      "id": 99,
      "value": "Wabaunsee",
      "viewValue": "Wabaunsee"
    },
    {
      "id": 100,
      "value": "Wallace",
      "viewValue": "Wallace"
    },
    {
      "id": 101,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 102,
      "value": "Wichita",
      "viewValue": "Wichita"
    },
    {
      "id": 103,
      "value": "Wilson",
      "viewValue": "Wilson"
    },
    {
      "id": 104,
      "value": "Woodson",
      "viewValue": "Woodson"
    },
    {
      "id": 105,
      "value": "Wyandotte",
      "viewValue": "Wyandotte"
    }
  ],
  "KY": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adair",
      "viewValue": "Adair"
    },
    {
      "id": 2,
      "value": "Allen",
      "viewValue": "Allen"
    },
    {
      "id": 3,
      "value": "Anderson",
      "viewValue": "Anderson"
    },
    {
      "id": 4,
      "value": "Ballard",
      "viewValue": "Ballard"
    },
    {
      "id": 5,
      "value": "Barren",
      "viewValue": "Barren"
    },
    {
      "id": 6,
      "value": "Bath",
      "viewValue": "Bath"
    },
    {
      "id": 7,
      "value": "Bell",
      "viewValue": "Bell"
    },
    {
      "id": 8,
      "value": "Boone",
      "viewValue": "Boone"
    },
    {
      "id": 9,
      "value": "Bourbon",
      "viewValue": "Bourbon"
    },
    {
      "id": 10,
      "value": "Boyd",
      "viewValue": "Boyd"
    },
    {
      "id": 11,
      "value": "Boyle",
      "viewValue": "Boyle"
    },
    {
      "id": 12,
      "value": "Bracken",
      "viewValue": "Bracken"
    },
    {
      "id": 13,
      "value": "Breathitt",
      "viewValue": "Breathitt"
    },
    {
      "id": 14,
      "value": "Breckinridge",
      "viewValue": "Breckinridge"
    },
    {
      "id": 15,
      "value": "Bullitt",
      "viewValue": "Bullitt"
    },
    {
      "id": 16,
      "value": "Butler",
      "viewValue": "Butler"
    },
    {
      "id": 17,
      "value": "Caldwell",
      "viewValue": "Caldwell"
    },
    {
      "id": 18,
      "value": "Calloway",
      "viewValue": "Calloway"
    },
    {
      "id": 19,
      "value": "Campbell",
      "viewValue": "Campbell"
    },
    {
      "id": 20,
      "value": "Carlisle",
      "viewValue": "Carlisle"
    },
    {
      "id": 21,
      "value": "Carroll",
      "viewValue": "Carroll"
    },
    {
      "id": 22,
      "value": "Carter",
      "viewValue": "Carter"
    },
    {
      "id": 23,
      "value": "Casey",
      "viewValue": "Casey"
    },
    {
      "id": 24,
      "value": "Christian",
      "viewValue": "Christian"
    },
    {
      "id": 25,
      "value": "Clark",
      "viewValue": "Clark"
    },
    {
      "id": 26,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 27,
      "value": "Clinton",
      "viewValue": "Clinton"
    },
    {
      "id": 28,
      "value": "Crittenden",
      "viewValue": "Crittenden"
    },
    {
      "id": 29,
      "value": "Cumberland",
      "viewValue": "Cumberland"
    },
    {
      "id": 30,
      "value": "Daviess",
      "viewValue": "Daviess"
    },
    {
      "id": 31,
      "value": "Edmonson",
      "viewValue": "Edmonson"
    },
    {
      "id": 32,
      "value": "Elliott",
      "viewValue": "Elliott"
    },
    {
      "id": 33,
      "value": "Estill",
      "viewValue": "Estill"
    },
    {
      "id": 34,
      "value": "Fayette",
      "viewValue": "Fayette"
    },
    {
      "id": 35,
      "value": "Fleming",
      "viewValue": "Fleming"
    },
    {
      "id": 36,
      "value": "Floyd",
      "viewValue": "Floyd"
    },
    {
      "id": 37,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 38,
      "value": "Fulton",
      "viewValue": "Fulton"
    },
    {
      "id": 39,
      "value": "Gallatin",
      "viewValue": "Gallatin"
    },
    {
      "id": 40,
      "value": "Garrard",
      "viewValue": "Garrard"
    },
    {
      "id": 41,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 42,
      "value": "Graves",
      "viewValue": "Graves"
    },
    {
      "id": 43,
      "value": "Grayson",
      "viewValue": "Grayson"
    },
    {
      "id": 44,
      "value": "Green",
      "viewValue": "Green"
    },
    {
      "id": 45,
      "value": "Greenup",
      "viewValue": "Greenup"
    },
    {
      "id": 46,
      "value": "Hancock",
      "viewValue": "Hancock"
    },
    {
      "id": 47,
      "value": "Hardin",
      "viewValue": "Hardin"
    },
    {
      "id": 48,
      "value": "Harlan",
      "viewValue": "Harlan"
    },
    {
      "id": 49,
      "value": "Harrison",
      "viewValue": "Harrison"
    },
    {
      "id": 50,
      "value": "Hart",
      "viewValue": "Hart"
    },
    {
      "id": 51,
      "value": "Henderson",
      "viewValue": "Henderson"
    },
    {
      "id": 52,
      "value": "Henry",
      "viewValue": "Henry"
    },
    {
      "id": 53,
      "value": "Hickman",
      "viewValue": "Hickman"
    },
    {
      "id": 54,
      "value": "Hopkins",
      "viewValue": "Hopkins"
    },
    {
      "id": 55,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 56,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 57,
      "value": "Jessamine",
      "viewValue": "Jessamine"
    },
    {
      "id": 58,
      "value": "Johnson",
      "viewValue": "Johnson"
    },
    {
      "id": 59,
      "value": "Kenton",
      "viewValue": "Kenton"
    },
    {
      "id": 60,
      "value": "Knott",
      "viewValue": "Knott"
    },
    {
      "id": 61,
      "value": "Knox",
      "viewValue": "Knox"
    },
    {
      "id": 62,
      "value": "Larue",
      "viewValue": "Larue"
    },
    {
      "id": 63,
      "value": "Laurel",
      "viewValue": "Laurel"
    },
    {
      "id": 64,
      "value": "Lawrence",
      "viewValue": "Lawrence"
    },
    {
      "id": 65,
      "value": "Lee",
      "viewValue": "Lee"
    },
    {
      "id": 66,
      "value": "Leslie",
      "viewValue": "Leslie"
    },
    {
      "id": 67,
      "value": "Letcher",
      "viewValue": "Letcher"
    },
    {
      "id": 68,
      "value": "Lewis",
      "viewValue": "Lewis"
    },
    {
      "id": 69,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 70,
      "value": "Livingston",
      "viewValue": "Livingston"
    },
    {
      "id": 71,
      "value": "Logan",
      "viewValue": "Logan"
    },
    {
      "id": 72,
      "value": "Lyon",
      "viewValue": "Lyon"
    },
    {
      "id": 73,
      "value": "McCracken",
      "viewValue": "McCracken"
    },
    {
      "id": 74,
      "value": "McCreary",
      "viewValue": "McCreary"
    },
    {
      "id": 75,
      "value": "McLean",
      "viewValue": "McLean"
    },
    {
      "id": 76,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 77,
      "value": "Magoffin",
      "viewValue": "Magoffin"
    },
    {
      "id": 78,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 79,
      "value": "Marshall",
      "viewValue": "Marshall"
    },
    {
      "id": 80,
      "value": "Martin",
      "viewValue": "Martin"
    },
    {
      "id": 81,
      "value": "Mason",
      "viewValue": "Mason"
    },
    {
      "id": 82,
      "value": "Meade",
      "viewValue": "Meade"
    },
    {
      "id": 83,
      "value": "Menifee",
      "viewValue": "Menifee"
    },
    {
      "id": 84,
      "value": "Mercer",
      "viewValue": "Mercer"
    },
    {
      "id": 85,
      "value": "Metcalfe",
      "viewValue": "Metcalfe"
    },
    {
      "id": 86,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 87,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 88,
      "value": "Morgan",
      "viewValue": "Morgan"
    },
    {
      "id": 89,
      "value": "Muhlenberg",
      "viewValue": "Muhlenberg"
    },
    {
      "id": 90,
      "value": "Nelson",
      "viewValue": "Nelson"
    },
    {
      "id": 91,
      "value": "Nicholas",
      "viewValue": "Nicholas"
    },
    {
      "id": 92,
      "value": "Ohio",
      "viewValue": "Ohio"
    },
    {
      "id": 93,
      "value": "Oldham",
      "viewValue": "Oldham"
    },
    {
      "id": 94,
      "value": "Owen",
      "viewValue": "Owen"
    },
    {
      "id": 95,
      "value": "Owsley",
      "viewValue": "Owsley"
    },
    {
      "id": 96,
      "value": "Pendleton",
      "viewValue": "Pendleton"
    },
    {
      "id": 97,
      "value": "Perry",
      "viewValue": "Perry"
    },
    {
      "id": 98,
      "value": "Pike",
      "viewValue": "Pike"
    },
    {
      "id": 99,
      "value": "Powell",
      "viewValue": "Powell"
    },
    {
      "id": 100,
      "value": "Pulaski",
      "viewValue": "Pulaski"
    },
    {
      "id": 101,
      "value": "Robertson",
      "viewValue": "Robertson"
    },
    {
      "id": 102,
      "value": "Rockcastle",
      "viewValue": "Rockcastle"
    },
    {
      "id": 103,
      "value": "Rowan",
      "viewValue": "Rowan"
    },
    {
      "id": 104,
      "value": "Russell",
      "viewValue": "Russell"
    },
    {
      "id": 105,
      "value": "Scott",
      "viewValue": "Scott"
    },
    {
      "id": 106,
      "value": "Shelby",
      "viewValue": "Shelby"
    },
    {
      "id": 107,
      "value": "Simpson",
      "viewValue": "Simpson"
    },
    {
      "id": 108,
      "value": "Spencer",
      "viewValue": "Spencer"
    },
    {
      "id": 109,
      "value": "Taylor",
      "viewValue": "Taylor"
    },
    {
      "id": 110,
      "value": "Todd",
      "viewValue": "Todd"
    },
    {
      "id": 111,
      "value": "Trigg",
      "viewValue": "Trigg"
    },
    {
      "id": 112,
      "value": "Trimble",
      "viewValue": "Trimble"
    },
    {
      "id": 113,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 114,
      "value": "Warren",
      "viewValue": "Warren"
    },
    {
      "id": 115,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 116,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 117,
      "value": "Webster",
      "viewValue": "Webster"
    },
    {
      "id": 118,
      "value": "Whitley",
      "viewValue": "Whitley"
    },
    {
      "id": 119,
      "value": "Wolfe",
      "viewValue": "Wolfe"
    },
    {
      "id": 120,
      "value": "Woodford",
      "viewValue": "Woodford"
    }
  ],
  "LA": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Acadia",
      "viewValue": "Acadia"
    },
    {
      "id": 2,
      "value": "Allen",
      "viewValue": "Allen"
    },
    {
      "id": 3,
      "value": "Ascension",
      "viewValue": "Ascension"
    },
    {
      "id": 4,
      "value": "Assumption",
      "viewValue": "Assumption"
    },
    {
      "id": 5,
      "value": "Avoyelles",
      "viewValue": "Avoyelles"
    },
    {
      "id": 6,
      "value": "Beauregard",
      "viewValue": "Beauregard"
    },
    {
      "id": 7,
      "value": "Bienville",
      "viewValue": "Bienville"
    },
    {
      "id": 8,
      "value": "Bossier",
      "viewValue": "Bossier"
    },
    {
      "id": 9,
      "value": "Caddo",
      "viewValue": "Caddo"
    },
    {
      "id": 10,
      "value": "Calcasieu",
      "viewValue": "Calcasieu"
    },
    {
      "id": 11,
      "value": "Caldwell",
      "viewValue": "Caldwell"
    },
    {
      "id": 12,
      "value": "Cameron",
      "viewValue": "Cameron"
    },
    {
      "id": 13,
      "value": "Catahoula",
      "viewValue": "Catahoula"
    },
    {
      "id": 14,
      "value": "Claiborne",
      "viewValue": "Claiborne"
    },
    {
      "id": 15,
      "value": "Concordia",
      "viewValue": "Concordia"
    },
    {
      "id": 16,
      "value": "De Soto",
      "viewValue": "De Soto"
    },
    {
      "id": 17,
      "value": "East Baton Rouge",
      "viewValue": "East Baton Rouge"
    },
    {
      "id": 18,
      "value": "East Carroll",
      "viewValue": "East Carroll"
    },
    {
      "id": 19,
      "value": "East Feliciana",
      "viewValue": "East Feliciana"
    },
    {
      "id": 20,
      "value": "Evangeline",
      "viewValue": "Evangeline"
    },
    {
      "id": 21,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 22,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 23,
      "value": "Iberia",
      "viewValue": "Iberia"
    },
    {
      "id": 24,
      "value": "Iberville",
      "viewValue": "Iberville"
    },
    {
      "id": 25,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 26,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 27,
      "value": "Jefferson Davis",
      "viewValue": "Jefferson Davis"
    },
    {
      "id": 28,
      "value": "Lafayette",
      "viewValue": "Lafayette"
    },
    {
      "id": 29,
      "value": "Lafourche",
      "viewValue": "Lafourche"
    },
    {
      "id": 30,
      "value": "La Salle",
      "viewValue": "La Salle"
    },
    {
      "id": 31,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 32,
      "value": "Livingston",
      "viewValue": "Livingston"
    },
    {
      "id": 33,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 34,
      "value": "Morehouse",
      "viewValue": "Morehouse"
    },
    {
      "id": 35,
      "value": "Natchitoches",
      "viewValue": "Natchitoches"
    },
    {
      "id": 36,
      "value": "Orleans",
      "viewValue": "Orleans"
    },
    {
      "id": 37,
      "value": "Ouachita",
      "viewValue": "Ouachita"
    },
    {
      "id": 38,
      "value": "Plaquemines",
      "viewValue": "Plaquemines"
    },
    {
      "id": 39,
      "value": "Pointe Coupee",
      "viewValue": "Pointe Coupee"
    },
    {
      "id": 40,
      "value": "Rapides",
      "viewValue": "Rapides"
    },
    {
      "id": 41,
      "value": "Red River",
      "viewValue": "Red River"
    },
    {
      "id": 42,
      "value": "Richland",
      "viewValue": "Richland"
    },
    {
      "id": 43,
      "value": "Sabine",
      "viewValue": "Sabine"
    },
    {
      "id": 44,
      "value": "St. Bernard",
      "viewValue": "St. Bernard"
    },
    {
      "id": 45,
      "value": "St. Charles",
      "viewValue": "St. Charles"
    },
    {
      "id": 46,
      "value": "St. Helena",
      "viewValue": "St. Helena"
    },
    {
      "id": 47,
      "value": "St. James",
      "viewValue": "St. James"
    },
    {
      "id": 48,
      "value": "St. John the Baptist",
      "viewValue": "St. John the Baptist"
    },
    {
      "id": 49,
      "value": "St. Landry",
      "viewValue": "St. Landry"
    },
    {
      "id": 50,
      "value": "St. Martin",
      "viewValue": "St. Martin"
    },
    {
      "id": 51,
      "value": "St. Mary",
      "viewValue": "St. Mary"
    },
    {
      "id": 52,
      "value": "St. Tammany",
      "viewValue": "St. Tammany"
    },
    {
      "id": 53,
      "value": "Tangipahoa",
      "viewValue": "Tangipahoa"
    },
    {
      "id": 54,
      "value": "Tensas",
      "viewValue": "Tensas"
    },
    {
      "id": 55,
      "value": "Terrebonne",
      "viewValue": "Terrebonne"
    },
    {
      "id": 56,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 57,
      "value": "Vermilion",
      "viewValue": "Vermilion"
    },
    {
      "id": 58,
      "value": "Vernon",
      "viewValue": "Vernon"
    },
    {
      "id": 59,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 60,
      "value": "Webster",
      "viewValue": "Webster"
    },
    {
      "id": 61,
      "value": "West Baton Rouge",
      "viewValue": "West Baton Rouge"
    },
    {
      "id": 62,
      "value": "West Carroll",
      "viewValue": "West Carroll"
    },
    {
      "id": 63,
      "value": "West Feliciana",
      "viewValue": "West Feliciana"
    },
    {
      "id": 64,
      "value": "Winn",
      "viewValue": "Winn"
    }
  ],
  "ME": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Androscoggin",
      "viewValue": "Androscoggin"
    },
    {
      "id": 2,
      "value": "Aroostook",
      "viewValue": "Aroostook"
    },
    {
      "id": 3,
      "value": "Cumberland",
      "viewValue": "Cumberland"
    },
    {
      "id": 4,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 5,
      "value": "Hancock",
      "viewValue": "Hancock"
    },
    {
      "id": 6,
      "value": "Kennebec",
      "viewValue": "Kennebec"
    },
    {
      "id": 7,
      "value": "Knox",
      "viewValue": "Knox"
    },
    {
      "id": 8,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 9,
      "value": "Oxford",
      "viewValue": "Oxford"
    },
    {
      "id": 10,
      "value": "Penobscot",
      "viewValue": "Penobscot"
    },
    {
      "id": 11,
      "value": "Piscataquis",
      "viewValue": "Piscataquis"
    },
    {
      "id": 12,
      "value": "Sagadahoc",
      "viewValue": "Sagadahoc"
    },
    {
      "id": 13,
      "value": "Somerset",
      "viewValue": "Somerset"
    },
    {
      "id": 14,
      "value": "Waldo",
      "viewValue": "Waldo"
    },
    {
      "id": 15,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 16,
      "value": "York",
      "viewValue": "York"
    }
  ],
  "MD": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Allegany",
      "viewValue": "Allegany"
    },
    {
      "id": 2,
      "value": "Anne Arundel",
      "viewValue": "Anne Arundel"
    },
    {
      "id": 3,
      "value": "Baltimore",
      "viewValue": "Baltimore"
    },
    {
      "id": 4,
      "value": "Calvert",
      "viewValue": "Calvert"
    },
    {
      "id": 5,
      "value": "Caroline",
      "viewValue": "Caroline"
    },
    {
      "id": 6,
      "value": "Carroll",
      "viewValue": "Carroll"
    },
    {
      "id": 7,
      "value": "Cecil",
      "viewValue": "Cecil"
    },
    {
      "id": 8,
      "value": "Charles",
      "viewValue": "Charles"
    },
    {
      "id": 9,
      "value": "Dorchester",
      "viewValue": "Dorchester"
    },
    {
      "id": 10,
      "value": "Frederick",
      "viewValue": "Frederick"
    },
    {
      "id": 11,
      "value": "Garrett",
      "viewValue": "Garrett"
    },
    {
      "id": 12,
      "value": "Harford",
      "viewValue": "Harford"
    },
    {
      "id": 13,
      "value": "Howard",
      "viewValue": "Howard"
    },
    {
      "id": 14,
      "value": "Kent",
      "viewValue": "Kent"
    },
    {
      "id": 15,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 16,
      "value": "Prince George's",
      "viewValue": "Prince George's"
    },
    {
      "id": 17,
      "value": "Queen Anne's",
      "viewValue": "Queen Anne's"
    },
    {
      "id": 18,
      "value": "Somerset",
      "viewValue": "Somerset"
    },
    {
      "id": 19,
      "value": "St. Mary's",
      "viewValue": "St. Mary's"
    },
    {
      "id": 20,
      "value": "Talbot",
      "viewValue": "Talbot"
    },
    {
      "id": 21,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 22,
      "value": "Wicomico",
      "viewValue": "Wicomico"
    },
    {
      "id": 23,
      "value": "Worcester",
      "viewValue": "Worcester"
    },
    {
      "id": 24,
      "value": "Baltimore",
      "viewValue": "Baltimore"
    }
  ],
  "MA": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Barnstable",
      "viewValue": "Barnstable"
    },
    {
      "id": 2,
      "value": "Berkshire",
      "viewValue": "Berkshire"
    },
    {
      "id": 3,
      "value": "Bristol",
      "viewValue": "Bristol"
    },
    {
      "id": 4,
      "value": "Dukes",
      "viewValue": "Dukes"
    },
    {
      "id": 5,
      "value": "Essex",
      "viewValue": "Essex"
    },
    {
      "id": 6,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 7,
      "value": "Hampden",
      "viewValue": "Hampden"
    },
    {
      "id": 8,
      "value": "Hampshire",
      "viewValue": "Hampshire"
    },
    {
      "id": 9,
      "value": "Middlesex",
      "viewValue": "Middlesex"
    },
    {
      "id": 10,
      "value": "Nantucket",
      "viewValue": "Nantucket"
    },
    {
      "id": 11,
      "value": "Norfolk",
      "viewValue": "Norfolk"
    },
    {
      "id": 12,
      "value": "Plymouth",
      "viewValue": "Plymouth"
    },
    {
      "id": 13,
      "value": "Suffolk",
      "viewValue": "Suffolk"
    },
    {
      "id": 14,
      "value": "Worcester",
      "viewValue": "Worcester"
    }
  ],
  "MI": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Alcona",
      "viewValue": "Alcona"
    },
    {
      "id": 2,
      "value": "Alger",
      "viewValue": "Alger"
    },
    {
      "id": 3,
      "value": "Allegan",
      "viewValue": "Allegan"
    },
    {
      "id": 4,
      "value": "Alpena",
      "viewValue": "Alpena"
    },
    {
      "id": 5,
      "value": "Antrim",
      "viewValue": "Antrim"
    },
    {
      "id": 6,
      "value": "Arenac",
      "viewValue": "Arenac"
    },
    {
      "id": 7,
      "value": "Baraga",
      "viewValue": "Baraga"
    },
    {
      "id": 8,
      "value": "Barry",
      "viewValue": "Barry"
    },
    {
      "id": 9,
      "value": "Bay",
      "viewValue": "Bay"
    },
    {
      "id": 10,
      "value": "Benzie",
      "viewValue": "Benzie"
    },
    {
      "id": 11,
      "value": "Berrien",
      "viewValue": "Berrien"
    },
    {
      "id": 12,
      "value": "Branch",
      "viewValue": "Branch"
    },
    {
      "id": 13,
      "value": "Calhoun",
      "viewValue": "Calhoun"
    },
    {
      "id": 14,
      "value": "Cass",
      "viewValue": "Cass"
    },
    {
      "id": 15,
      "value": "Charlevoix",
      "viewValue": "Charlevoix"
    },
    {
      "id": 16,
      "value": "Cheboygan",
      "viewValue": "Cheboygan"
    },
    {
      "id": 17,
      "value": "Chippewa",
      "viewValue": "Chippewa"
    },
    {
      "id": 18,
      "value": "Clare",
      "viewValue": "Clare"
    },
    {
      "id": 19,
      "value": "Clinton",
      "viewValue": "Clinton"
    },
    {
      "id": 20,
      "value": "Crawford",
      "viewValue": "Crawford"
    },
    {
      "id": 21,
      "value": "Delta",
      "viewValue": "Delta"
    },
    {
      "id": 22,
      "value": "Dickinson",
      "viewValue": "Dickinson"
    },
    {
      "id": 23,
      "value": "Eaton",
      "viewValue": "Eaton"
    },
    {
      "id": 24,
      "value": "Emmet",
      "viewValue": "Emmet"
    },
    {
      "id": 25,
      "value": "Genesee",
      "viewValue": "Genesee"
    },
    {
      "id": 26,
      "value": "Gladwin",
      "viewValue": "Gladwin"
    },
    {
      "id": 27,
      "value": "Gogebic",
      "viewValue": "Gogebic"
    },
    {
      "id": 28,
      "value": "Grand Traverse",
      "viewValue": "Grand Traverse"
    },
    {
      "id": 29,
      "value": "Gratiot",
      "viewValue": "Gratiot"
    },
    {
      "id": 30,
      "value": "Hillsdale",
      "viewValue": "Hillsdale"
    },
    {
      "id": 31,
      "value": "Houghton",
      "viewValue": "Houghton"
    },
    {
      "id": 32,
      "value": "Huron",
      "viewValue": "Huron"
    },
    {
      "id": 33,
      "value": "Ingham",
      "viewValue": "Ingham"
    },
    {
      "id": 34,
      "value": "Ionia",
      "viewValue": "Ionia"
    },
    {
      "id": 35,
      "value": "Iosco",
      "viewValue": "Iosco"
    },
    {
      "id": 36,
      "value": "Iron",
      "viewValue": "Iron"
    },
    {
      "id": 37,
      "value": "Isabella",
      "viewValue": "Isabella"
    },
    {
      "id": 38,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 39,
      "value": "Kalamazoo",
      "viewValue": "Kalamazoo"
    },
    {
      "id": 40,
      "value": "Kalkaska",
      "viewValue": "Kalkaska"
    },
    {
      "id": 41,
      "value": "Kent",
      "viewValue": "Kent"
    },
    {
      "id": 42,
      "value": "Keweenaw",
      "viewValue": "Keweenaw"
    },
    {
      "id": 43,
      "value": "Lake",
      "viewValue": "Lake"
    },
    {
      "id": 44,
      "value": "Lapeer",
      "viewValue": "Lapeer"
    },
    {
      "id": 45,
      "value": "Leelanau",
      "viewValue": "Leelanau"
    },
    {
      "id": 46,
      "value": "Lenawee",
      "viewValue": "Lenawee"
    },
    {
      "id": 47,
      "value": "Livingston",
      "viewValue": "Livingston"
    },
    {
      "id": 48,
      "value": "Luce",
      "viewValue": "Luce"
    },
    {
      "id": 49,
      "value": "Mackinac",
      "viewValue": "Mackinac"
    },
    {
      "id": 50,
      "value": "Macomb",
      "viewValue": "Macomb"
    },
    {
      "id": 51,
      "value": "Manistee",
      "viewValue": "Manistee"
    },
    {
      "id": 52,
      "value": "Marquette",
      "viewValue": "Marquette"
    },
    {
      "id": 53,
      "value": "Mason",
      "viewValue": "Mason"
    },
    {
      "id": 54,
      "value": "Mecosta",
      "viewValue": "Mecosta"
    },
    {
      "id": 55,
      "value": "Menominee",
      "viewValue": "Menominee"
    },
    {
      "id": 56,
      "value": "Midland",
      "viewValue": "Midland"
    },
    {
      "id": 57,
      "value": "Missaukee",
      "viewValue": "Missaukee"
    },
    {
      "id": 58,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 59,
      "value": "Montcalm",
      "viewValue": "Montcalm"
    },
    {
      "id": 60,
      "value": "Montmorency",
      "viewValue": "Montmorency"
    },
    {
      "id": 61,
      "value": "Muskegon",
      "viewValue": "Muskegon"
    },
    {
      "id": 62,
      "value": "Newaygo",
      "viewValue": "Newaygo"
    },
    {
      "id": 63,
      "value": "Oakland",
      "viewValue": "Oakland"
    },
    {
      "id": 64,
      "value": "Oceana",
      "viewValue": "Oceana"
    },
    {
      "id": 65,
      "value": "Ogemaw",
      "viewValue": "Ogemaw"
    },
    {
      "id": 66,
      "value": "Ontonagon",
      "viewValue": "Ontonagon"
    },
    {
      "id": 67,
      "value": "Osceola",
      "viewValue": "Osceola"
    },
    {
      "id": 68,
      "value": "Oscoda",
      "viewValue": "Oscoda"
    },
    {
      "id": 69,
      "value": "Otsego",
      "viewValue": "Otsego"
    },
    {
      "id": 70,
      "value": "Ottawa",
      "viewValue": "Ottawa"
    },
    {
      "id": 71,
      "value": "Presque Isle",
      "viewValue": "Presque Isle"
    },
    {
      "id": 72,
      "value": "Roscommon",
      "viewValue": "Roscommon"
    },
    {
      "id": 73,
      "value": "Saginaw",
      "viewValue": "Saginaw"
    },
    {
      "id": 74,
      "value": "St. Clair",
      "viewValue": "St. Clair"
    },
    {
      "id": 75,
      "value": "St. Joseph",
      "viewValue": "St. Joseph"
    },
    {
      "id": 76,
      "value": "Sanilac",
      "viewValue": "Sanilac"
    },
    {
      "id": 77,
      "value": "Schoolcraft",
      "viewValue": "Schoolcraft"
    },
    {
      "id": 78,
      "value": "Shiawassee",
      "viewValue": "Shiawassee"
    },
    {
      "id": 79,
      "value": "Tuscola",
      "viewValue": "Tuscola"
    },
    {
      "id": 80,
      "value": "Van Buren",
      "viewValue": "Van Buren"
    },
    {
      "id": 81,
      "value": "Washtenaw",
      "viewValue": "Washtenaw"
    },
    {
      "id": 82,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 83,
      "value": "Wexford",
      "viewValue": "Wexford"
    }
  ],
  "MN": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Aitkin",
      "viewValue": "Aitkin"
    },
    {
      "id": 2,
      "value": "Anoka",
      "viewValue": "Anoka"
    },
    {
      "id": 3,
      "value": "Becker",
      "viewValue": "Becker"
    },
    {
      "id": 4,
      "value": "Beltrami",
      "viewValue": "Beltrami"
    },
    {
      "id": 5,
      "value": "Benton",
      "viewValue": "Benton"
    },
    {
      "id": 6,
      "value": "Big Stone",
      "viewValue": "Big Stone"
    },
    {
      "id": 7,
      "value": "Blue Earth",
      "viewValue": "Blue Earth"
    },
    {
      "id": 8,
      "value": "Brown",
      "viewValue": "Brown"
    },
    {
      "id": 9,
      "value": "Carlton",
      "viewValue": "Carlton"
    },
    {
      "id": 10,
      "value": "Carver",
      "viewValue": "Carver"
    },
    {
      "id": 11,
      "value": "Cass",
      "viewValue": "Cass"
    },
    {
      "id": 12,
      "value": "Chippewa",
      "viewValue": "Chippewa"
    },
    {
      "id": 13,
      "value": "Chisago",
      "viewValue": "Chisago"
    },
    {
      "id": 14,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 15,
      "value": "Clearwater",
      "viewValue": "Clearwater"
    },
    {
      "id": 16,
      "value": "Cook",
      "viewValue": "Cook"
    },
    {
      "id": 17,
      "value": "Cottonwood",
      "viewValue": "Cottonwood"
    },
    {
      "id": 18,
      "value": "Crow Wing",
      "viewValue": "Crow Wing"
    },
    {
      "id": 19,
      "value": "Dakota",
      "viewValue": "Dakota"
    },
    {
      "id": 20,
      "value": "Dodge",
      "viewValue": "Dodge"
    },
    {
      "id": 21,
      "value": "Douglas",
      "viewValue": "Douglas"
    },
    {
      "id": 22,
      "value": "Faribault",
      "viewValue": "Faribault"
    },
    {
      "id": 23,
      "value": "Fillmore",
      "viewValue": "Fillmore"
    },
    {
      "id": 24,
      "value": "Freeborn",
      "viewValue": "Freeborn"
    },
    {
      "id": 25,
      "value": "Goodhue",
      "viewValue": "Goodhue"
    },
    {
      "id": 26,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 27,
      "value": "Hennepin",
      "viewValue": "Hennepin"
    },
    {
      "id": 28,
      "value": "Houston",
      "viewValue": "Houston"
    },
    {
      "id": 29,
      "value": "Hubbard",
      "viewValue": "Hubbard"
    },
    {
      "id": 30,
      "value": "Isanti",
      "viewValue": "Isanti"
    },
    {
      "id": 31,
      "value": "Itasca",
      "viewValue": "Itasca"
    },
    {
      "id": 32,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 33,
      "value": "Kanabec",
      "viewValue": "Kanabec"
    },
    {
      "id": 34,
      "value": "Kandiyohi",
      "viewValue": "Kandiyohi"
    },
    {
      "id": 35,
      "value": "Kittson",
      "viewValue": "Kittson"
    },
    {
      "id": 36,
      "value": "Koochiching",
      "viewValue": "Koochiching"
    },
    {
      "id": 37,
      "value": "Lac qui Parle",
      "viewValue": "Lac qui Parle"
    },
    {
      "id": 38,
      "value": "Lake",
      "viewValue": "Lake"
    },
    {
      "id": 39,
      "value": "Lake of the Woods",
      "viewValue": "Lake of the Woods"
    },
    {
      "id": 40,
      "value": "Le Sueur",
      "viewValue": "Le Sueur"
    },
    {
      "id": 41,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 42,
      "value": "Lyon",
      "viewValue": "Lyon"
    },
    {
      "id": 43,
      "value": "Mahnomen",
      "viewValue": "Mahnomen"
    },
    {
      "id": 44,
      "value": "McLeod",
      "viewValue": "McLeod"
    },
    {
      "id": 45,
      "value": "Marshall",
      "viewValue": "Marshall"
    },
    {
      "id": 46,
      "value": "Martin",
      "viewValue": "Martin"
    },
    {
      "id": 47,
      "value": "Meeker",
      "viewValue": "Meeker"
    },
    {
      "id": 48,
      "value": "Mille Lacs",
      "viewValue": "Mille Lacs"
    },
    {
      "id": 49,
      "value": "Morrison",
      "viewValue": "Morrison"
    },
    {
      "id": 50,
      "value": "Mower",
      "viewValue": "Mower"
    },
    {
      "id": 51,
      "value": "Murray",
      "viewValue": "Murray"
    },
    {
      "id": 52,
      "value": "Nicollet",
      "viewValue": "Nicollet"
    },
    {
      "id": 53,
      "value": "Nobles",
      "viewValue": "Nobles"
    },
    {
      "id": 54,
      "value": "Norman",
      "viewValue": "Norman"
    },
    {
      "id": 55,
      "value": "Olmsted",
      "viewValue": "Olmsted"
    },
    {
      "id": 56,
      "value": "Otter Tail",
      "viewValue": "Otter Tail"
    },
    {
      "id": 57,
      "value": "Pennington",
      "viewValue": "Pennington"
    },
    {
      "id": 58,
      "value": "Pine",
      "viewValue": "Pine"
    },
    {
      "id": 59,
      "value": "Pipestone",
      "viewValue": "Pipestone"
    },
    {
      "id": 60,
      "value": "Polk",
      "viewValue": "Polk"
    },
    {
      "id": 61,
      "value": "Pope",
      "viewValue": "Pope"
    },
    {
      "id": 62,
      "value": "Ramsey",
      "viewValue": "Ramsey"
    },
    {
      "id": 63,
      "value": "Red Lake",
      "viewValue": "Red Lake"
    },
    {
      "id": 64,
      "value": "Redwood",
      "viewValue": "Redwood"
    },
    {
      "id": 65,
      "value": "Renville",
      "viewValue": "Renville"
    },
    {
      "id": 66,
      "value": "Rice",
      "viewValue": "Rice"
    },
    {
      "id": 67,
      "value": "Rock",
      "viewValue": "Rock"
    },
    {
      "id": 68,
      "value": "Roseau",
      "viewValue": "Roseau"
    },
    {
      "id": 69,
      "value": "St. Louis",
      "viewValue": "St. Louis"
    },
    {
      "id": 70,
      "value": "Scott",
      "viewValue": "Scott"
    },
    {
      "id": 71,
      "value": "Sherburne",
      "viewValue": "Sherburne"
    },
    {
      "id": 72,
      "value": "Sibley",
      "viewValue": "Sibley"
    },
    {
      "id": 73,
      "value": "Stearns",
      "viewValue": "Stearns"
    },
    {
      "id": 74,
      "value": "Steele",
      "viewValue": "Steele"
    },
    {
      "id": 75,
      "value": "Stevens",
      "viewValue": "Stevens"
    },
    {
      "id": 76,
      "value": "Swift",
      "viewValue": "Swift"
    },
    {
      "id": 77,
      "value": "Todd",
      "viewValue": "Todd"
    },
    {
      "id": 78,
      "value": "Traverse",
      "viewValue": "Traverse"
    },
    {
      "id": 79,
      "value": "Wabasha",
      "viewValue": "Wabasha"
    },
    {
      "id": 80,
      "value": "Wadena",
      "viewValue": "Wadena"
    },
    {
      "id": 81,
      "value": "Waseca",
      "viewValue": "Waseca"
    },
    {
      "id": 82,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 83,
      "value": "Watonwan",
      "viewValue": "Watonwan"
    },
    {
      "id": 84,
      "value": "Wilkin",
      "viewValue": "Wilkin"
    },
    {
      "id": 85,
      "value": "Winona",
      "viewValue": "Winona"
    },
    {
      "id": 86,
      "value": "Wright",
      "viewValue": "Wright"
    },
    {
      "id": 87,
      "value": "Yellow Medicine",
      "viewValue": "Yellow Medicine"
    }
  ],
  "MS": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adams",
      "viewValue": "Adams"
    },
    {
      "id": 2,
      "value": "Alcorn",
      "viewValue": "Alcorn"
    },
    {
      "id": 3,
      "value": "Amite",
      "viewValue": "Amite"
    },
    {
      "id": 4,
      "value": "Attala",
      "viewValue": "Attala"
    },
    {
      "id": 5,
      "value": "Benton",
      "viewValue": "Benton"
    },
    {
      "id": 6,
      "value": "Bolivar",
      "viewValue": "Bolivar"
    },
    {
      "id": 7,
      "value": "Calhoun",
      "viewValue": "Calhoun"
    },
    {
      "id": 8,
      "value": "Carroll",
      "viewValue": "Carroll"
    },
    {
      "id": 9,
      "value": "Chickasaw",
      "viewValue": "Chickasaw"
    },
    {
      "id": 10,
      "value": "Choctaw",
      "viewValue": "Choctaw"
    },
    {
      "id": 11,
      "value": "Claiborne",
      "viewValue": "Claiborne"
    },
    {
      "id": 12,
      "value": "Clarke",
      "viewValue": "Clarke"
    },
    {
      "id": 13,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 14,
      "value": "Coahoma",
      "viewValue": "Coahoma"
    },
    {
      "id": 15,
      "value": "Copiah",
      "viewValue": "Copiah"
    },
    {
      "id": 16,
      "value": "Covington",
      "viewValue": "Covington"
    },
    {
      "id": 17,
      "value": "DeSoto",
      "viewValue": "DeSoto"
    },
    {
      "id": 18,
      "value": "Forrest",
      "viewValue": "Forrest"
    },
    {
      "id": 19,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 20,
      "value": "George",
      "viewValue": "George"
    },
    {
      "id": 21,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 22,
      "value": "Grenada",
      "viewValue": "Grenada"
    },
    {
      "id": 23,
      "value": "Hancock",
      "viewValue": "Hancock"
    },
    {
      "id": 24,
      "value": "Harrison",
      "viewValue": "Harrison"
    },
    {
      "id": 25,
      "value": "Hinds",
      "viewValue": "Hinds"
    },
    {
      "id": 26,
      "value": "Holmes",
      "viewValue": "Holmes"
    },
    {
      "id": 27,
      "value": "Humphreys",
      "viewValue": "Humphreys"
    },
    {
      "id": 28,
      "value": "Issaquena",
      "viewValue": "Issaquena"
    },
    {
      "id": 29,
      "value": "Itawamba",
      "viewValue": "Itawamba"
    },
    {
      "id": 30,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 31,
      "value": "Jasper",
      "viewValue": "Jasper"
    },
    {
      "id": 32,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 33,
      "value": "Jefferson Davis",
      "viewValue": "Jefferson Davis"
    },
    {
      "id": 34,
      "value": "Jones",
      "viewValue": "Jones"
    },
    {
      "id": 35,
      "value": "Kemper",
      "viewValue": "Kemper"
    },
    {
      "id": 36,
      "value": "Lafayette",
      "viewValue": "Lafayette"
    },
    {
      "id": 37,
      "value": "Lamar",
      "viewValue": "Lamar"
    },
    {
      "id": 38,
      "value": "Lauderdale",
      "viewValue": "Lauderdale"
    },
    {
      "id": 39,
      "value": "Lawrence",
      "viewValue": "Lawrence"
    },
    {
      "id": 40,
      "value": "Leake",
      "viewValue": "Leake"
    },
    {
      "id": 41,
      "value": "Lee",
      "viewValue": "Lee"
    },
    {
      "id": 42,
      "value": "Leflore",
      "viewValue": "Leflore"
    },
    {
      "id": 43,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 44,
      "value": "Lowndes",
      "viewValue": "Lowndes"
    },
    {
      "id": 45,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 46,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 47,
      "value": "Marshall",
      "viewValue": "Marshall"
    },
    {
      "id": 48,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 49,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 50,
      "value": "Neshoba",
      "viewValue": "Neshoba"
    },
    {
      "id": 51,
      "value": "Newton",
      "viewValue": "Newton"
    },
    {
      "id": 52,
      "value": "Noxubee",
      "viewValue": "Noxubee"
    },
    {
      "id": 53,
      "value": "Oktibbeha",
      "viewValue": "Oktibbeha"
    },
    {
      "id": 54,
      "value": "Panola",
      "viewValue": "Panola"
    },
    {
      "id": 55,
      "value": "Pearl River",
      "viewValue": "Pearl River"
    },
    {
      "id": 56,
      "value": "Perry",
      "viewValue": "Perry"
    },
    {
      "id": 57,
      "value": "Pike",
      "viewValue": "Pike"
    },
    {
      "id": 58,
      "value": "Pontotoc",
      "viewValue": "Pontotoc"
    },
    {
      "id": 59,
      "value": "Prentiss",
      "viewValue": "Prentiss"
    },
    {
      "id": 60,
      "value": "Quitman",
      "viewValue": "Quitman"
    },
    {
      "id": 61,
      "value": "Rankin",
      "viewValue": "Rankin"
    },
    {
      "id": 62,
      "value": "Scott",
      "viewValue": "Scott"
    },
    {
      "id": 63,
      "value": "Sharkey",
      "viewValue": "Sharkey"
    },
    {
      "id": 64,
      "value": "Simpson",
      "viewValue": "Simpson"
    },
    {
      "id": 65,
      "value": "Smith",
      "viewValue": "Smith"
    },
    {
      "id": 66,
      "value": "Stone",
      "viewValue": "Stone"
    },
    {
      "id": 67,
      "value": "Sunflower",
      "viewValue": "Sunflower"
    },
    {
      "id": 68,
      "value": "Tallahatchie",
      "viewValue": "Tallahatchie"
    },
    {
      "id": 69,
      "value": "Tate",
      "viewValue": "Tate"
    },
    {
      "id": 70,
      "value": "Tippah",
      "viewValue": "Tippah"
    },
    {
      "id": 71,
      "value": "Tishomingo",
      "viewValue": "Tishomingo"
    },
    {
      "id": 72,
      "value": "Tunica",
      "viewValue": "Tunica"
    },
    {
      "id": 73,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 74,
      "value": "Walthall",
      "viewValue": "Walthall"
    },
    {
      "id": 75,
      "value": "Warren",
      "viewValue": "Warren"
    },
    {
      "id": 76,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 77,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 78,
      "value": "Webster",
      "viewValue": "Webster"
    },
    {
      "id": 79,
      "value": "Wilkinson",
      "viewValue": "Wilkinson"
    },
    {
      "id": 80,
      "value": "Winston",
      "viewValue": "Winston"
    },
    {
      "id": 81,
      "value": "Yalobusha",
      "viewValue": "Yalobusha"
    },
    {
      "id": 82,
      "value": "Yazoo",
      "viewValue": "Yazoo"
    }
  ],
  "MO": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adair",
      "viewValue": "Adair"
    },
    {
      "id": 2,
      "value": "Andrew",
      "viewValue": "Andrew"
    },
    {
      "id": 3,
      "value": "Atchison",
      "viewValue": "Atchison"
    },
    {
      "id": 4,
      "value": "Audrain",
      "viewValue": "Audrain"
    },
    {
      "id": 5,
      "value": "Barry",
      "viewValue": "Barry"
    },
    {
      "id": 6,
      "value": "Barton",
      "viewValue": "Barton"
    },
    {
      "id": 7,
      "value": "Bates",
      "viewValue": "Bates"
    },
    {
      "id": 8,
      "value": "Benton",
      "viewValue": "Benton"
    },
    {
      "id": 9,
      "value": "Bollinger",
      "viewValue": "Bollinger"
    },
    {
      "id": 10,
      "value": "Boone",
      "viewValue": "Boone"
    },
    {
      "id": 11,
      "value": "Buchanan",
      "viewValue": "Buchanan"
    },
    {
      "id": 12,
      "value": "Butler",
      "viewValue": "Butler"
    },
    {
      "id": 13,
      "value": "Caldwell",
      "viewValue": "Caldwell"
    },
    {
      "id": 14,
      "value": "Callaway",
      "viewValue": "Callaway"
    },
    {
      "id": 15,
      "value": "Camden",
      "viewValue": "Camden"
    },
    {
      "id": 16,
      "value": "Cape Girardeau",
      "viewValue": "Cape Girardeau"
    },
    {
      "id": 17,
      "value": "Carroll",
      "viewValue": "Carroll"
    },
    {
      "id": 18,
      "value": "Carter",
      "viewValue": "Carter"
    },
    {
      "id": 19,
      "value": "Cass",
      "viewValue": "Cass"
    },
    {
      "id": 20,
      "value": "Cedar",
      "viewValue": "Cedar"
    },
    {
      "id": 21,
      "value": "Chariton",
      "viewValue": "Chariton"
    },
    {
      "id": 22,
      "value": "Christian",
      "viewValue": "Christian"
    },
    {
      "id": 23,
      "value": "Clark",
      "viewValue": "Clark"
    },
    {
      "id": 24,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 25,
      "value": "Clinton",
      "viewValue": "Clinton"
    },
    {
      "id": 26,
      "value": "Cole",
      "viewValue": "Cole"
    },
    {
      "id": 27,
      "value": "Cooper",
      "viewValue": "Cooper"
    },
    {
      "id": 28,
      "value": "Crawford",
      "viewValue": "Crawford"
    },
    {
      "id": 29,
      "value": "Dade",
      "viewValue": "Dade"
    },
    {
      "id": 30,
      "value": "Dallas",
      "viewValue": "Dallas"
    },
    {
      "id": 31,
      "value": "Daviess",
      "viewValue": "Daviess"
    },
    {
      "id": 32,
      "value": "DeKalb",
      "viewValue": "DeKalb"
    },
    {
      "id": 33,
      "value": "Dent",
      "viewValue": "Dent"
    },
    {
      "id": 34,
      "value": "Douglas",
      "viewValue": "Douglas"
    },
    {
      "id": 35,
      "value": "Dunklin",
      "viewValue": "Dunklin"
    },
    {
      "id": 36,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 37,
      "value": "Gasconade",
      "viewValue": "Gasconade"
    },
    {
      "id": 38,
      "value": "Gentry",
      "viewValue": "Gentry"
    },
    {
      "id": 39,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 40,
      "value": "Grundy",
      "viewValue": "Grundy"
    },
    {
      "id": 41,
      "value": "Harrison",
      "viewValue": "Harrison"
    },
    {
      "id": 42,
      "value": "Henry",
      "viewValue": "Henry"
    },
    {
      "id": 43,
      "value": "Hickory",
      "viewValue": "Hickory"
    },
    {
      "id": 44,
      "value": "Holt",
      "viewValue": "Holt"
    },
    {
      "id": 45,
      "value": "Howard",
      "viewValue": "Howard"
    },
    {
      "id": 46,
      "value": "Howell",
      "viewValue": "Howell"
    },
    {
      "id": 47,
      "value": "Iron",
      "viewValue": "Iron"
    },
    {
      "id": 48,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 49,
      "value": "Jasper",
      "viewValue": "Jasper"
    },
    {
      "id": 50,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 51,
      "value": "Johnson",
      "viewValue": "Johnson"
    },
    {
      "id": 52,
      "value": "Knox",
      "viewValue": "Knox"
    },
    {
      "id": 53,
      "value": "Laclede",
      "viewValue": "Laclede"
    },
    {
      "id": 54,
      "value": "Lafayette",
      "viewValue": "Lafayette"
    },
    {
      "id": 55,
      "value": "Lawrence",
      "viewValue": "Lawrence"
    },
    {
      "id": 56,
      "value": "Lewis",
      "viewValue": "Lewis"
    },
    {
      "id": 57,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 58,
      "value": "Linn",
      "viewValue": "Linn"
    },
    {
      "id": 59,
      "value": "Livingston",
      "viewValue": "Livingston"
    },
    {
      "id": 60,
      "value": "McDonald",
      "viewValue": "McDonald"
    },
    {
      "id": 61,
      "value": "Macon",
      "viewValue": "Macon"
    },
    {
      "id": 62,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 63,
      "value": "Maries",
      "viewValue": "Maries"
    },
    {
      "id": 64,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 65,
      "value": "Mercer",
      "viewValue": "Mercer"
    },
    {
      "id": 66,
      "value": "Miller",
      "viewValue": "Miller"
    },
    {
      "id": 67,
      "value": "Mississippi",
      "viewValue": "Mississippi"
    },
    {
      "id": 68,
      "value": "Moniteau",
      "viewValue": "Moniteau"
    },
    {
      "id": 69,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 70,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 71,
      "value": "Morgan",
      "viewValue": "Morgan"
    },
    {
      "id": 72,
      "value": "New Madrid",
      "viewValue": "New Madrid"
    },
    {
      "id": 73,
      "value": "Newton",
      "viewValue": "Newton"
    },
    {
      "id": 74,
      "value": "Nodaway",
      "viewValue": "Nodaway"
    },
    {
      "id": 75,
      "value": "Oregon",
      "viewValue": "Oregon"
    },
    {
      "id": 76,
      "value": "Osage",
      "viewValue": "Osage"
    },
    {
      "id": 77,
      "value": "Ozark",
      "viewValue": "Ozark"
    },
    {
      "id": 78,
      "value": "Pemiscot",
      "viewValue": "Pemiscot"
    },
    {
      "id": 79,
      "value": "Perry",
      "viewValue": "Perry"
    },
    {
      "id": 80,
      "value": "Pettis",
      "viewValue": "Pettis"
    },
    {
      "id": 81,
      "value": "Phelps",
      "viewValue": "Phelps"
    },
    {
      "id": 82,
      "value": "Pike",
      "viewValue": "Pike"
    },
    {
      "id": 83,
      "value": "Platte",
      "viewValue": "Platte"
    },
    {
      "id": 84,
      "value": "Polk",
      "viewValue": "Polk"
    },
    {
      "id": 85,
      "value": "Pulaski",
      "viewValue": "Pulaski"
    },
    {
      "id": 86,
      "value": "Putnam",
      "viewValue": "Putnam"
    },
    {
      "id": 87,
      "value": "Ralls",
      "viewValue": "Ralls"
    },
    {
      "id": 88,
      "value": "Randolph",
      "viewValue": "Randolph"
    },
    {
      "id": 89,
      "value": "Ray",
      "viewValue": "Ray"
    },
    {
      "id": 90,
      "value": "Reynolds",
      "viewValue": "Reynolds"
    },
    {
      "id": 91,
      "value": "Ripley",
      "viewValue": "Ripley"
    },
    {
      "id": 92,
      "value": "Saline",
      "viewValue": "Saline"
    },
    {
      "id": 93,
      "value": "Schuyler",
      "viewValue": "Schuyler"
    },
    {
      "id": 94,
      "value": "Scotland",
      "viewValue": "Scotland"
    },
    {
      "id": 95,
      "value": "Scott",
      "viewValue": "Scott"
    },
    {
      "id": 96,
      "value": "Shannon",
      "viewValue": "Shannon"
    },
    {
      "id": 97,
      "value": "Shelby",
      "viewValue": "Shelby"
    },
    {
      "id": 98,
      "value": "St. Charles",
      "viewValue": "St. Charles"
    },
    {
      "id": 99,
      "value": "St. Clair",
      "viewValue": "St. Clair"
    },
    {
      "id": 100,
      "value": "St. Francois",
      "viewValue": "St. Francois"
    },
    {
      "id": 101,
      "value": "St. Louis",
      "viewValue": "St. Louis"
    },
    {
      "id": 102,
      "value": "Ste. Genevieve",
      "viewValue": "Ste. Genevieve"
    },
    {
      "id": 103,
      "value": "Stoddard",
      "viewValue": "Stoddard"
    },
    {
      "id": 104,
      "value": "Stone",
      "viewValue": "Stone"
    },
    {
      "id": 105,
      "value": "Sullivan",
      "viewValue": "Sullivan"
    },
    {
      "id": 106,
      "value": "Taney",
      "viewValue": "Taney"
    },
    {
      "id": 107,
      "value": "Texas",
      "viewValue": "Texas"
    },
    {
      "id": 108,
      "value": "Vernon",
      "viewValue": "Vernon"
    },
    {
      "id": 109,
      "value": "Warren",
      "viewValue": "Warren"
    },
    {
      "id": 110,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 111,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 112,
      "value": "Webster",
      "viewValue": "Webster"
    },
    {
      "id": 113,
      "value": "Worth",
      "viewValue": "Worth"
    },
    {
      "id": 114,
      "value": "Wright",
      "viewValue": "Wright"
    },
    {
      "id": 115,
      "value": "St. Louis",
      "viewValue": "St. Louis"
    }
  ],
  "MT": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Beaverhead",
      "viewValue": "Beaverhead"
    },
    {
      "id": 2,
      "value": "Big Horn",
      "viewValue": "Big Horn"
    },
    {
      "id": 3,
      "value": "Blaine",
      "viewValue": "Blaine"
    },
    {
      "id": 4,
      "value": "Broadwater",
      "viewValue": "Broadwater"
    },
    {
      "id": 5,
      "value": "Carbon",
      "viewValue": "Carbon"
    },
    {
      "id": 6,
      "value": "Carter",
      "viewValue": "Carter"
    },
    {
      "id": 7,
      "value": "Cascade",
      "viewValue": "Cascade"
    },
    {
      "id": 8,
      "value": "Chouteau",
      "viewValue": "Chouteau"
    },
    {
      "id": 9,
      "value": "Custer",
      "viewValue": "Custer"
    },
    {
      "id": 10,
      "value": "Daniels",
      "viewValue": "Daniels"
    },
    {
      "id": 11,
      "value": "Dawson",
      "viewValue": "Dawson"
    },
    {
      "id": 12,
      "value": "Deer Lodge",
      "viewValue": "Deer Lodge"
    },
    {
      "id": 13,
      "value": "Fallon",
      "viewValue": "Fallon"
    },
    {
      "id": 14,
      "value": "Fergus",
      "viewValue": "Fergus"
    },
    {
      "id": 15,
      "value": "Flathead",
      "viewValue": "Flathead"
    },
    {
      "id": 16,
      "value": "Gallatin",
      "viewValue": "Gallatin"
    },
    {
      "id": 17,
      "value": "Garfield",
      "viewValue": "Garfield"
    },
    {
      "id": 18,
      "value": "Glacier",
      "viewValue": "Glacier"
    },
    {
      "id": 19,
      "value": "Golden Valley",
      "viewValue": "Golden Valley"
    },
    {
      "id": 20,
      "value": "Granite",
      "viewValue": "Granite"
    },
    {
      "id": 21,
      "value": "Hill",
      "viewValue": "Hill"
    },
    {
      "id": 22,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 23,
      "value": "Judith Basin",
      "viewValue": "Judith Basin"
    },
    {
      "id": 24,
      "value": "Lake",
      "viewValue": "Lake"
    },
    {
      "id": 25,
      "value": "Lewis and Clark",
      "viewValue": "Lewis and Clark"
    },
    {
      "id": 26,
      "value": "Liberty",
      "viewValue": "Liberty"
    },
    {
      "id": 27,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 28,
      "value": "McCone",
      "viewValue": "McCone"
    },
    {
      "id": 29,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 30,
      "value": "Meagher",
      "viewValue": "Meagher"
    },
    {
      "id": 31,
      "value": "Mineral",
      "viewValue": "Mineral"
    },
    {
      "id": 32,
      "value": "Missoula",
      "viewValue": "Missoula"
    },
    {
      "id": 33,
      "value": "Musselshell",
      "viewValue": "Musselshell"
    },
    {
      "id": 34,
      "value": "Park",
      "viewValue": "Park"
    },
    {
      "id": 35,
      "value": "Petroleum",
      "viewValue": "Petroleum"
    },
    {
      "id": 36,
      "value": "Phillips",
      "viewValue": "Phillips"
    },
    {
      "id": 37,
      "value": "Pondera",
      "viewValue": "Pondera"
    },
    {
      "id": 38,
      "value": "Powder River",
      "viewValue": "Powder River"
    },
    {
      "id": 39,
      "value": "Powell",
      "viewValue": "Powell"
    },
    {
      "id": 40,
      "value": "Prairie",
      "viewValue": "Prairie"
    },
    {
      "id": 41,
      "value": "Ravalli",
      "viewValue": "Ravalli"
    },
    {
      "id": 42,
      "value": "Richland",
      "viewValue": "Richland"
    },
    {
      "id": 43,
      "value": "Roosevelt",
      "viewValue": "Roosevelt"
    },
    {
      "id": 44,
      "value": "Rosebud",
      "viewValue": "Rosebud"
    },
    {
      "id": 45,
      "value": "Sanders",
      "viewValue": "Sanders"
    },
    {
      "id": 46,
      "value": "Sheridan",
      "viewValue": "Sheridan"
    },
    {
      "id": 47,
      "value": "Silver Bow",
      "viewValue": "Silver Bow"
    },
    {
      "id": 48,
      "value": "Stillwater",
      "viewValue": "Stillwater"
    },
    {
      "id": 49,
      "value": "Sweet Grass",
      "viewValue": "Sweet Grass"
    },
    {
      "id": 50,
      "value": "Teton",
      "viewValue": "Teton"
    },
    {
      "id": 51,
      "value": "Toole",
      "viewValue": "Toole"
    },
    {
      "id": 52,
      "value": "Treasure",
      "viewValue": "Treasure"
    },
    {
      "id": 53,
      "value": "Valley",
      "viewValue": "Valley"
    },
    {
      "id": 54,
      "value": "Wheatland",
      "viewValue": "Wheatland"
    },
    {
      "id": 55,
      "value": "Wibaux",
      "viewValue": "Wibaux"
    },
    {
      "id": 56,
      "value": "Yellowstone",
      "viewValue": "Yellowstone"
    }
  ],
  "NE": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adams",
      "viewValue": "Adams"
    },
    {
      "id": 2,
      "value": "Antelope",
      "viewValue": "Antelope"
    },
    {
      "id": 3,
      "value": "Arthur",
      "viewValue": "Arthur"
    },
    {
      "id": 4,
      "value": "Banner",
      "viewValue": "Banner"
    },
    {
      "id": 5,
      "value": "Blaine",
      "viewValue": "Blaine"
    },
    {
      "id": 6,
      "value": "Boone",
      "viewValue": "Boone"
    },
    {
      "id": 7,
      "value": "Box Butte",
      "viewValue": "Box Butte"
    },
    {
      "id": 8,
      "value": "Boyd",
      "viewValue": "Boyd"
    },
    {
      "id": 9,
      "value": "Brown",
      "viewValue": "Brown"
    },
    {
      "id": 10,
      "value": "Buffalo",
      "viewValue": "Buffalo"
    },
    {
      "id": 11,
      "value": "Burt",
      "viewValue": "Burt"
    },
    {
      "id": 12,
      "value": "Butler",
      "viewValue": "Butler"
    },
    {
      "id": 13,
      "value": "Cass",
      "viewValue": "Cass"
    },
    {
      "id": 14,
      "value": "Cedar",
      "viewValue": "Cedar"
    },
    {
      "id": 15,
      "value": "Chase",
      "viewValue": "Chase"
    },
    {
      "id": 16,
      "value": "Cherry",
      "viewValue": "Cherry"
    },
    {
      "id": 17,
      "value": "Cheyenne",
      "viewValue": "Cheyenne"
    },
    {
      "id": 18,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 19,
      "value": "Colfax",
      "viewValue": "Colfax"
    },
    {
      "id": 20,
      "value": "Cuming",
      "viewValue": "Cuming"
    },
    {
      "id": 21,
      "value": "Custer",
      "viewValue": "Custer"
    },
    {
      "id": 22,
      "value": "Dakota",
      "viewValue": "Dakota"
    },
    {
      "id": 23,
      "value": "Dawes",
      "viewValue": "Dawes"
    },
    {
      "id": 24,
      "value": "Dawson",
      "viewValue": "Dawson"
    },
    {
      "id": 25,
      "value": "Deuel",
      "viewValue": "Deuel"
    },
    {
      "id": 26,
      "value": "Dixon",
      "viewValue": "Dixon"
    },
    {
      "id": 27,
      "value": "Dodge",
      "viewValue": "Dodge"
    },
    {
      "id": 28,
      "value": "Douglas",
      "viewValue": "Douglas"
    },
    {
      "id": 29,
      "value": "Dundy",
      "viewValue": "Dundy"
    },
    {
      "id": 30,
      "value": "Fillmore",
      "viewValue": "Fillmore"
    },
    {
      "id": 31,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 32,
      "value": "Frontier",
      "viewValue": "Frontier"
    },
    {
      "id": 33,
      "value": "Furnas",
      "viewValue": "Furnas"
    },
    {
      "id": 34,
      "value": "Gage",
      "viewValue": "Gage"
    },
    {
      "id": 35,
      "value": "Garden",
      "viewValue": "Garden"
    },
    {
      "id": 36,
      "value": "Garfield",
      "viewValue": "Garfield"
    },
    {
      "id": 37,
      "value": "Gosper",
      "viewValue": "Gosper"
    },
    {
      "id": 38,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 39,
      "value": "Greeley",
      "viewValue": "Greeley"
    },
    {
      "id": 40,
      "value": "Hall",
      "viewValue": "Hall"
    },
    {
      "id": 41,
      "value": "Hamilton",
      "viewValue": "Hamilton"
    },
    {
      "id": 42,
      "value": "Harlan",
      "viewValue": "Harlan"
    },
    {
      "id": 43,
      "value": "Hayes",
      "viewValue": "Hayes"
    },
    {
      "id": 44,
      "value": "Hitchcock",
      "viewValue": "Hitchcock"
    },
    {
      "id": 45,
      "value": "Holt",
      "viewValue": "Holt"
    },
    {
      "id": 46,
      "value": "Hooker",
      "viewValue": "Hooker"
    },
    {
      "id": 47,
      "value": "Howard",
      "viewValue": "Howard"
    },
    {
      "id": 48,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 49,
      "value": "Johnson",
      "viewValue": "Johnson"
    },
    {
      "id": 50,
      "value": "Kearney",
      "viewValue": "Kearney"
    },
    {
      "id": 51,
      "value": "Keith",
      "viewValue": "Keith"
    },
    {
      "id": 52,
      "value": "Keya Paha",
      "viewValue": "Keya Paha"
    },
    {
      "id": 53,
      "value": "Kimball",
      "viewValue": "Kimball"
    },
    {
      "id": 54,
      "value": "Knox",
      "viewValue": "Knox"
    },
    {
      "id": 55,
      "value": "Lancaster",
      "viewValue": "Lancaster"
    },
    {
      "id": 56,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 57,
      "value": "Logan",
      "viewValue": "Logan"
    },
    {
      "id": 58,
      "value": "Loup",
      "viewValue": "Loup"
    },
    {
      "id": 59,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 60,
      "value": "McPherson",
      "viewValue": "McPherson"
    },
    {
      "id": 61,
      "value": "Merrick",
      "viewValue": "Merrick"
    },
    {
      "id": 62,
      "value": "Morrill",
      "viewValue": "Morrill"
    },
    {
      "id": 63,
      "value": "Nance",
      "viewValue": "Nance"
    },
    {
      "id": 64,
      "value": "Nemaha",
      "viewValue": "Nemaha"
    },
    {
      "id": 65,
      "value": "Nuckolls",
      "viewValue": "Nuckolls"
    },
    {
      "id": 66,
      "value": "Otoe",
      "viewValue": "Otoe"
    },
    {
      "id": 67,
      "value": "Pawnee",
      "viewValue": "Pawnee"
    },
    {
      "id": 68,
      "value": "Perkins",
      "viewValue": "Perkins"
    },
    {
      "id": 69,
      "value": "Phelps",
      "viewValue": "Phelps"
    },
    {
      "id": 70,
      "value": "Pierce",
      "viewValue": "Pierce"
    },
    {
      "id": 71,
      "value": "Platte",
      "viewValue": "Platte"
    },
    {
      "id": 72,
      "value": "Polk",
      "viewValue": "Polk"
    },
    {
      "id": 73,
      "value": "Red Willow",
      "viewValue": "Red Willow"
    },
    {
      "id": 74,
      "value": "Richardson",
      "viewValue": "Richardson"
    },
    {
      "id": 75,
      "value": "Rock",
      "viewValue": "Rock"
    },
    {
      "id": 76,
      "value": "Saline",
      "viewValue": "Saline"
    },
    {
      "id": 77,
      "value": "Sarpy",
      "viewValue": "Sarpy"
    },
    {
      "id": 78,
      "value": "Saunders",
      "viewValue": "Saunders"
    },
    {
      "id": 79,
      "value": "Scotts Bluff",
      "viewValue": "Scotts Bluff"
    },
    {
      "id": 80,
      "value": "Seward",
      "viewValue": "Seward"
    },
    {
      "id": 81,
      "value": "Sheridan",
      "viewValue": "Sheridan"
    },
    {
      "id": 82,
      "value": "Sherman",
      "viewValue": "Sherman"
    },
    {
      "id": 83,
      "value": "Sioux",
      "viewValue": "Sioux"
    },
    {
      "id": 84,
      "value": "Stanton",
      "viewValue": "Stanton"
    },
    {
      "id": 85,
      "value": "Thayer",
      "viewValue": "Thayer"
    },
    {
      "id": 86,
      "value": "Thomas",
      "viewValue": "Thomas"
    },
    {
      "id": 87,
      "value": "Thurston",
      "viewValue": "Thurston"
    },
    {
      "id": 88,
      "value": "Valley",
      "viewValue": "Valley"
    },
    {
      "id": 89,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 90,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 91,
      "value": "Webster",
      "viewValue": "Webster"
    },
    {
      "id": 92,
      "value": "Wheeler",
      "viewValue": "Wheeler"
    },
    {
      "id": 93,
      "value": "York",
      "viewValue": "York"
    }
  ],
  "NV": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Churchill",
      "viewValue": "Churchill"
    },
    {
      "id": 2,
      "value": "Clark",
      "viewValue": "Clark"
    },
    {
      "id": 3,
      "value": "Douglas",
      "viewValue": "Douglas"
    },
    {
      "id": 4,
      "value": "Elko",
      "viewValue": "Elko"
    },
    {
      "id": 5,
      "value": "Esmeralda",
      "viewValue": "Esmeralda"
    },
    {
      "id": 6,
      "value": "Eureka",
      "viewValue": "Eureka"
    },
    {
      "id": 7,
      "value": "Humboldt",
      "viewValue": "Humboldt"
    },
    {
      "id": 8,
      "value": "Lander",
      "viewValue": "Lander"
    },
    {
      "id": 9,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 10,
      "value": "Lyon",
      "viewValue": "Lyon"
    },
    {
      "id": 11,
      "value": "Mineral",
      "viewValue": "Mineral"
    },
    {
      "id": 12,
      "value": "Nye",
      "viewValue": "Nye"
    },
    {
      "id": 13,
      "value": "Pershing",
      "viewValue": "Pershing"
    },
    {
      "id": 14,
      "value": "Storey",
      "viewValue": "Storey"
    },
    {
      "id": 15,
      "value": "Washoe",
      "viewValue": "Washoe"
    },
    {
      "id": 16,
      "value": "White Pine",
      "viewValue": "White Pine"
    },
    {
      "id": 17,
      "value": "Carson City",
      "viewValue": "Carson City"
    }
  ],
  "NH": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Belknap",
      "viewValue": "Belknap"
    },
    {
      "id": 2,
      "value": "Carroll",
      "viewValue": "Carroll"
    },
    {
      "id": 3,
      "value": "Cheshire",
      "viewValue": "Cheshire"
    },
    {
      "id": 4,
      "value": "Coos",
      "viewValue": "Coos"
    },
    {
      "id": 5,
      "value": "Grafton",
      "viewValue": "Grafton"
    },
    {
      "id": 6,
      "value": "Hillsborough",
      "viewValue": "Hillsborough"
    },
    {
      "id": 7,
      "value": "Merrimack",
      "viewValue": "Merrimack"
    },
    {
      "id": 8,
      "value": "Rockingham",
      "viewValue": "Rockingham"
    },
    {
      "id": 9,
      "value": "Strafford",
      "viewValue": "Strafford"
    },
    {
      "id": 10,
      "value": "Sullivan",
      "viewValue": "Sullivan"
    }
  ],
  "NJ": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Atlantic",
      "viewValue": "Atlantic"
    },
    {
      "id": 2,
      "value": "Bergen",
      "viewValue": "Bergen"
    },
    {
      "id": 3,
      "value": "Burlington",
      "viewValue": "Burlington"
    },
    {
      "id": 4,
      "value": "Camden",
      "viewValue": "Camden"
    },
    {
      "id": 5,
      "value": "Cape May",
      "viewValue": "Cape May"
    },
    {
      "id": 6,
      "value": "Cumberland",
      "viewValue": "Cumberland"
    },
    {
      "id": 7,
      "value": "Essex",
      "viewValue": "Essex"
    },
    {
      "id": 8,
      "value": "Gloucester",
      "viewValue": "Gloucester"
    },
    {
      "id": 9,
      "value": "Hudson",
      "viewValue": "Hudson"
    },
    {
      "id": 10,
      "value": "Hunterdon",
      "viewValue": "Hunterdon"
    },
    {
      "id": 11,
      "value": "Mercer",
      "viewValue": "Mercer"
    },
    {
      "id": 12,
      "value": "Middlesex",
      "viewValue": "Middlesex"
    },
    {
      "id": 13,
      "value": "Monmouth",
      "viewValue": "Monmouth"
    },
    {
      "id": 14,
      "value": "Morris",
      "viewValue": "Morris"
    },
    {
      "id": 15,
      "value": "Ocean",
      "viewValue": "Ocean"
    },
    {
      "id": 16,
      "value": "Passaic",
      "viewValue": "Passaic"
    },
    {
      "id": 17,
      "value": "Salem",
      "viewValue": "Salem"
    },
    {
      "id": 18,
      "value": "Somerset",
      "viewValue": "Somerset"
    },
    {
      "id": 19,
      "value": "Sussex",
      "viewValue": "Sussex"
    },
    {
      "id": 20,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 21,
      "value": "Warren",
      "viewValue": "Warren"
    }
  ],
  "NM": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Bernalillo",
      "viewValue": "Bernalillo"
    },
    {
      "id": 2,
      "value": "Catron",
      "viewValue": "Catron"
    },
    {
      "id": 3,
      "value": "Chaves",
      "viewValue": "Chaves"
    },
    {
      "id": 4,
      "value": "Cibola",
      "viewValue": "Cibola"
    },
    {
      "id": 5,
      "value": "Colfax",
      "viewValue": "Colfax"
    },
    {
      "id": 6,
      "value": "Curry",
      "viewValue": "Curry"
    },
    {
      "id": 7,
      "value": "De Baca",
      "viewValue": "De Baca"
    },
    {
      "id": 8,
      "value": "Dona Ana",
      "viewValue": "Dona Ana"
    },
    {
      "id": 9,
      "value": "Eddy",
      "viewValue": "Eddy"
    },
    {
      "id": 10,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 11,
      "value": "Guadalupe",
      "viewValue": "Guadalupe"
    },
    {
      "id": 12,
      "value": "Harding",
      "viewValue": "Harding"
    },
    {
      "id": 13,
      "value": "Hidalgo",
      "viewValue": "Hidalgo"
    },
    {
      "id": 14,
      "value": "Lea",
      "viewValue": "Lea"
    },
    {
      "id": 15,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 16,
      "value": "Los Alamos",
      "viewValue": "Los Alamos"
    },
    {
      "id": 17,
      "value": "Luna",
      "viewValue": "Luna"
    },
    {
      "id": 18,
      "value": "McKinley",
      "viewValue": "McKinley"
    },
    {
      "id": 19,
      "value": "Mora",
      "viewValue": "Mora"
    },
    {
      "id": 20,
      "value": "Otero",
      "viewValue": "Otero"
    },
    {
      "id": 21,
      "value": "Quay",
      "viewValue": "Quay"
    },
    {
      "id": 22,
      "value": "Rio Arriba",
      "viewValue": "Rio Arriba"
    },
    {
      "id": 23,
      "value": "Roosevelt",
      "viewValue": "Roosevelt"
    },
    {
      "id": 24,
      "value": "Sandoval",
      "viewValue": "Sandoval"
    },
    {
      "id": 25,
      "value": "San Juan",
      "viewValue": "San Juan"
    },
    {
      "id": 26,
      "value": "San Miguel",
      "viewValue": "San Miguel"
    },
    {
      "id": 27,
      "value": "Santa Fe",
      "viewValue": "Santa Fe"
    },
    {
      "id": 28,
      "value": "Sierra",
      "viewValue": "Sierra"
    },
    {
      "id": 29,
      "value": "Socorro",
      "viewValue": "Socorro"
    },
    {
      "id": 30,
      "value": "Taos",
      "viewValue": "Taos"
    },
    {
      "id": 31,
      "value": "Torrance",
      "viewValue": "Torrance"
    },
    {
      "id": 32,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 33,
      "value": "Valencia",
      "viewValue": "Valencia"
    }
  ],
  "NY": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Albany",
      "viewValue": "Albany"
    },
    {
      "id": 2,
      "value": "Allegany",
      "viewValue": "Allegany"
    },
    {
      "id": 3,
      "value": "Bronx",
      "viewValue": "Bronx"
    },
    {
      "id": 4,
      "value": "Broome",
      "viewValue": "Broome"
    },
    {
      "id": 5,
      "value": "Cattaraugus",
      "viewValue": "Cattaraugus"
    },
    {
      "id": 6,
      "value": "Cayuga",
      "viewValue": "Cayuga"
    },
    {
      "id": 7,
      "value": "Chautauqua",
      "viewValue": "Chautauqua"
    },
    {
      "id": 8,
      "value": "Chemung",
      "viewValue": "Chemung"
    },
    {
      "id": 9,
      "value": "Chenango",
      "viewValue": "Chenango"
    },
    {
      "id": 10,
      "value": "Clinton",
      "viewValue": "Clinton"
    },
    {
      "id": 11,
      "value": "Columbia",
      "viewValue": "Columbia"
    },
    {
      "id": 12,
      "value": "Cortland",
      "viewValue": "Cortland"
    },
    {
      "id": 13,
      "value": "Delaware",
      "viewValue": "Delaware"
    },
    {
      "id": 14,
      "value": "Dutchess",
      "viewValue": "Dutchess"
    },
    {
      "id": 15,
      "value": "Erie",
      "viewValue": "Erie"
    },
    {
      "id": 16,
      "value": "Essex",
      "viewValue": "Essex"
    },
    {
      "id": 17,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 18,
      "value": "Fulton",
      "viewValue": "Fulton"
    },
    {
      "id": 19,
      "value": "Genesee",
      "viewValue": "Genesee"
    },
    {
      "id": 20,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 21,
      "value": "Hamilton",
      "viewValue": "Hamilton"
    },
    {
      "id": 22,
      "value": "Herkimer",
      "viewValue": "Herkimer"
    },
    {
      "id": 23,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 24,
      "value": "Kings",
      "viewValue": "Kings"
    },
    {
      "id": 25,
      "value": "Lewis",
      "viewValue": "Lewis"
    },
    {
      "id": 26,
      "value": "Livingston",
      "viewValue": "Livingston"
    },
    {
      "id": 27,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 28,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 29,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 30,
      "value": "Nassau",
      "viewValue": "Nassau"
    },
    {
      "id": 31,
      "value": "New York",
      "viewValue": "New York"
    },
    {
      "id": 32,
      "value": "Niagara",
      "viewValue": "Niagara"
    },
    {
      "id": 33,
      "value": "Oneida",
      "viewValue": "Oneida"
    },
    {
      "id": 34,
      "value": "Onondaga",
      "viewValue": "Onondaga"
    },
    {
      "id": 35,
      "value": "Ontario",
      "viewValue": "Ontario"
    },
    {
      "id": 36,
      "value": "Orange",
      "viewValue": "Orange"
    },
    {
      "id": 37,
      "value": "Orleans",
      "viewValue": "Orleans"
    },
    {
      "id": 38,
      "value": "Oswego",
      "viewValue": "Oswego"
    },
    {
      "id": 39,
      "value": "Otsego",
      "viewValue": "Otsego"
    },
    {
      "id": 40,
      "value": "Putnam",
      "viewValue": "Putnam"
    },
    {
      "id": 41,
      "value": "Queens",
      "viewValue": "Queens"
    },
    {
      "id": 42,
      "value": "Rensselaer",
      "viewValue": "Rensselaer"
    },
    {
      "id": 43,
      "value": "Richmond",
      "viewValue": "Richmond"
    },
    {
      "id": 44,
      "value": "Rockland",
      "viewValue": "Rockland"
    },
    {
      "id": 45,
      "value": "St. Lawrence",
      "viewValue": "St. Lawrence"
    },
    {
      "id": 46,
      "value": "Saratoga",
      "viewValue": "Saratoga"
    },
    {
      "id": 47,
      "value": "Schenectady",
      "viewValue": "Schenectady"
    },
    {
      "id": 48,
      "value": "Schoharie",
      "viewValue": "Schoharie"
    },
    {
      "id": 49,
      "value": "Schuyler",
      "viewValue": "Schuyler"
    },
    {
      "id": 50,
      "value": "Seneca",
      "viewValue": "Seneca"
    },
    {
      "id": 51,
      "value": "Steuben",
      "viewValue": "Steuben"
    },
    {
      "id": 52,
      "value": "Suffolk",
      "viewValue": "Suffolk"
    },
    {
      "id": 53,
      "value": "Sullivan",
      "viewValue": "Sullivan"
    },
    {
      "id": 54,
      "value": "Tioga",
      "viewValue": "Tioga"
    },
    {
      "id": 55,
      "value": "Tompkins",
      "viewValue": "Tompkins"
    },
    {
      "id": 56,
      "value": "Ulster",
      "viewValue": "Ulster"
    },
    {
      "id": 57,
      "value": "Warren",
      "viewValue": "Warren"
    },
    {
      "id": 58,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 59,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 60,
      "value": "Westchester",
      "viewValue": "Westchester"
    },
    {
      "id": 61,
      "value": "Wyoming",
      "viewValue": "Wyoming"
    },
    {
      "id": 62,
      "value": "Yates",
      "viewValue": "Yates"
    }
  ],
  "NC": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Alamance",
      "viewValue": "Alamance"
    },
    {
      "id": 2,
      "value": "Alexander",
      "viewValue": "Alexander"
    },
    {
      "id": 3,
      "value": "Alleghany",
      "viewValue": "Alleghany"
    },
    {
      "id": 4,
      "value": "Anson",
      "viewValue": "Anson"
    },
    {
      "id": 5,
      "value": "Ashe",
      "viewValue": "Ashe"
    },
    {
      "id": 6,
      "value": "Avery",
      "viewValue": "Avery"
    },
    {
      "id": 7,
      "value": "Beaufort",
      "viewValue": "Beaufort"
    },
    {
      "id": 8,
      "value": "Bertie",
      "viewValue": "Bertie"
    },
    {
      "id": 9,
      "value": "Bladen",
      "viewValue": "Bladen"
    },
    {
      "id": 10,
      "value": "Brunswick",
      "viewValue": "Brunswick"
    },
    {
      "id": 11,
      "value": "Buncombe",
      "viewValue": "Buncombe"
    },
    {
      "id": 12,
      "value": "Burke",
      "viewValue": "Burke"
    },
    {
      "id": 13,
      "value": "Cabarrus",
      "viewValue": "Cabarrus"
    },
    {
      "id": 14,
      "value": "Caldwell",
      "viewValue": "Caldwell"
    },
    {
      "id": 15,
      "value": "Camden",
      "viewValue": "Camden"
    },
    {
      "id": 16,
      "value": "Carteret",
      "viewValue": "Carteret"
    },
    {
      "id": 17,
      "value": "Caswell",
      "viewValue": "Caswell"
    },
    {
      "id": 18,
      "value": "Catawba",
      "viewValue": "Catawba"
    },
    {
      "id": 19,
      "value": "Chatham",
      "viewValue": "Chatham"
    },
    {
      "id": 20,
      "value": "Cherokee",
      "viewValue": "Cherokee"
    },
    {
      "id": 21,
      "value": "Chowan",
      "viewValue": "Chowan"
    },
    {
      "id": 22,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 23,
      "value": "Cleveland",
      "viewValue": "Cleveland"
    },
    {
      "id": 24,
      "value": "Columbus",
      "viewValue": "Columbus"
    },
    {
      "id": 25,
      "value": "Craven",
      "viewValue": "Craven"
    },
    {
      "id": 26,
      "value": "Cumberland",
      "viewValue": "Cumberland"
    },
    {
      "id": 27,
      "value": "Currituck",
      "viewValue": "Currituck"
    },
    {
      "id": 28,
      "value": "Dare",
      "viewValue": "Dare"
    },
    {
      "id": 29,
      "value": "Davidson",
      "viewValue": "Davidson"
    },
    {
      "id": 30,
      "value": "Davie",
      "viewValue": "Davie"
    },
    {
      "id": 31,
      "value": "Duplin",
      "viewValue": "Duplin"
    },
    {
      "id": 32,
      "value": "Durham",
      "viewValue": "Durham"
    },
    {
      "id": 33,
      "value": "Edgecombe",
      "viewValue": "Edgecombe"
    },
    {
      "id": 34,
      "value": "Forsyth",
      "viewValue": "Forsyth"
    },
    {
      "id": 35,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 36,
      "value": "Gaston",
      "viewValue": "Gaston"
    },
    {
      "id": 37,
      "value": "Gates",
      "viewValue": "Gates"
    },
    {
      "id": 38,
      "value": "Graham",
      "viewValue": "Graham"
    },
    {
      "id": 39,
      "value": "Granville",
      "viewValue": "Granville"
    },
    {
      "id": 40,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 41,
      "value": "Guilford",
      "viewValue": "Guilford"
    },
    {
      "id": 42,
      "value": "Halifax",
      "viewValue": "Halifax"
    },
    {
      "id": 43,
      "value": "Harnett",
      "viewValue": "Harnett"
    },
    {
      "id": 44,
      "value": "Haywood",
      "viewValue": "Haywood"
    },
    {
      "id": 45,
      "value": "Henderson",
      "viewValue": "Henderson"
    },
    {
      "id": 46,
      "value": "Hertford",
      "viewValue": "Hertford"
    },
    {
      "id": 47,
      "value": "Hoke",
      "viewValue": "Hoke"
    },
    {
      "id": 48,
      "value": "Hyde",
      "viewValue": "Hyde"
    },
    {
      "id": 49,
      "value": "Iredell",
      "viewValue": "Iredell"
    },
    {
      "id": 50,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 51,
      "value": "Johnston",
      "viewValue": "Johnston"
    },
    {
      "id": 52,
      "value": "Jones",
      "viewValue": "Jones"
    },
    {
      "id": 53,
      "value": "Lee",
      "viewValue": "Lee"
    },
    {
      "id": 54,
      "value": "Lenoir",
      "viewValue": "Lenoir"
    },
    {
      "id": 55,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 56,
      "value": "McDowell",
      "viewValue": "McDowell"
    },
    {
      "id": 57,
      "value": "Macon",
      "viewValue": "Macon"
    },
    {
      "id": 58,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 59,
      "value": "Martin",
      "viewValue": "Martin"
    },
    {
      "id": 60,
      "value": "Mecklenburg",
      "viewValue": "Mecklenburg"
    },
    {
      "id": 61,
      "value": "Mitchell",
      "viewValue": "Mitchell"
    },
    {
      "id": 62,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 63,
      "value": "Moore",
      "viewValue": "Moore"
    },
    {
      "id": 64,
      "value": "Nash",
      "viewValue": "Nash"
    },
    {
      "id": 65,
      "value": "New Hanover",
      "viewValue": "New Hanover"
    },
    {
      "id": 66,
      "value": "Northampton",
      "viewValue": "Northampton"
    },
    {
      "id": 67,
      "value": "Onslow",
      "viewValue": "Onslow"
    },
    {
      "id": 68,
      "value": "Orange",
      "viewValue": "Orange"
    },
    {
      "id": 69,
      "value": "Pamlico",
      "viewValue": "Pamlico"
    },
    {
      "id": 70,
      "value": "Pasquotank",
      "viewValue": "Pasquotank"
    },
    {
      "id": 71,
      "value": "Pender",
      "viewValue": "Pender"
    },
    {
      "id": 72,
      "value": "Perquimans",
      "viewValue": "Perquimans"
    },
    {
      "id": 73,
      "value": "Person",
      "viewValue": "Person"
    },
    {
      "id": 74,
      "value": "Pitt",
      "viewValue": "Pitt"
    },
    {
      "id": 75,
      "value": "Polk",
      "viewValue": "Polk"
    },
    {
      "id": 76,
      "value": "Randolph",
      "viewValue": "Randolph"
    },
    {
      "id": 77,
      "value": "Richmond",
      "viewValue": "Richmond"
    },
    {
      "id": 78,
      "value": "Robeson",
      "viewValue": "Robeson"
    },
    {
      "id": 79,
      "value": "Rockingham",
      "viewValue": "Rockingham"
    },
    {
      "id": 80,
      "value": "Rowan",
      "viewValue": "Rowan"
    },
    {
      "id": 81,
      "value": "Rutherford",
      "viewValue": "Rutherford"
    },
    {
      "id": 82,
      "value": "Sampson",
      "viewValue": "Sampson"
    },
    {
      "id": 83,
      "value": "Scotland",
      "viewValue": "Scotland"
    },
    {
      "id": 84,
      "value": "Stanly",
      "viewValue": "Stanly"
    },
    {
      "id": 85,
      "value": "Stokes",
      "viewValue": "Stokes"
    },
    {
      "id": 86,
      "value": "Surry",
      "viewValue": "Surry"
    },
    {
      "id": 87,
      "value": "Swain",
      "viewValue": "Swain"
    },
    {
      "id": 88,
      "value": "Transylvania",
      "viewValue": "Transylvania"
    },
    {
      "id": 89,
      "value": "Tyrrell",
      "viewValue": "Tyrrell"
    },
    {
      "id": 90,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 91,
      "value": "Vance",
      "viewValue": "Vance"
    },
    {
      "id": 92,
      "value": "Wake",
      "viewValue": "Wake"
    },
    {
      "id": 93,
      "value": "Warren",
      "viewValue": "Warren"
    },
    {
      "id": 94,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 95,
      "value": "Watauga",
      "viewValue": "Watauga"
    },
    {
      "id": 96,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 97,
      "value": "Wilkes",
      "viewValue": "Wilkes"
    },
    {
      "id": 98,
      "value": "Wilson",
      "viewValue": "Wilson"
    },
    {
      "id": 99,
      "value": "Yadkin",
      "viewValue": "Yadkin"
    },
    {
      "id": 100,
      "value": "Yancey",
      "viewValue": "Yancey"
    }
  ],
  "ND": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adams",
      "viewValue": "Adams"
    },
    {
      "id": 2,
      "value": "Barnes",
      "viewValue": "Barnes"
    },
    {
      "id": 3,
      "value": "Benson",
      "viewValue": "Benson"
    },
    {
      "id": 4,
      "value": "Billings",
      "viewValue": "Billings"
    },
    {
      "id": 5,
      "value": "Bottineau",
      "viewValue": "Bottineau"
    },
    {
      "id": 6,
      "value": "Bowman",
      "viewValue": "Bowman"
    },
    {
      "id": 7,
      "value": "Burke",
      "viewValue": "Burke"
    },
    {
      "id": 8,
      "value": "Burleigh",
      "viewValue": "Burleigh"
    },
    {
      "id": 9,
      "value": "Cass",
      "viewValue": "Cass"
    },
    {
      "id": 10,
      "value": "Cavalier",
      "viewValue": "Cavalier"
    },
    {
      "id": 11,
      "value": "Dickey",
      "viewValue": "Dickey"
    },
    {
      "id": 12,
      "value": "Divide",
      "viewValue": "Divide"
    },
    {
      "id": 13,
      "value": "Dunn",
      "viewValue": "Dunn"
    },
    {
      "id": 14,
      "value": "Eddy",
      "viewValue": "Eddy"
    },
    {
      "id": 15,
      "value": "Emmons",
      "viewValue": "Emmons"
    },
    {
      "id": 16,
      "value": "Foster",
      "viewValue": "Foster"
    },
    {
      "id": 17,
      "value": "Golden Valley",
      "viewValue": "Golden Valley"
    },
    {
      "id": 18,
      "value": "Grand Forks",
      "viewValue": "Grand Forks"
    },
    {
      "id": 19,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 20,
      "value": "Griggs",
      "viewValue": "Griggs"
    },
    {
      "id": 21,
      "value": "Hettinger",
      "viewValue": "Hettinger"
    },
    {
      "id": 22,
      "value": "Kidder",
      "viewValue": "Kidder"
    },
    {
      "id": 23,
      "value": "LaMoure",
      "viewValue": "LaMoure"
    },
    {
      "id": 24,
      "value": "Logan",
      "viewValue": "Logan"
    },
    {
      "id": 25,
      "value": "McHenry",
      "viewValue": "McHenry"
    },
    {
      "id": 26,
      "value": "McIntosh",
      "viewValue": "McIntosh"
    },
    {
      "id": 27,
      "value": "McKenzie",
      "viewValue": "McKenzie"
    },
    {
      "id": 28,
      "value": "McLean",
      "viewValue": "McLean"
    },
    {
      "id": 29,
      "value": "Mercer",
      "viewValue": "Mercer"
    },
    {
      "id": 30,
      "value": "Morton",
      "viewValue": "Morton"
    },
    {
      "id": 31,
      "value": "Mountrail",
      "viewValue": "Mountrail"
    },
    {
      "id": 32,
      "value": "Nelson",
      "viewValue": "Nelson"
    },
    {
      "id": 33,
      "value": "Oliver",
      "viewValue": "Oliver"
    },
    {
      "id": 34,
      "value": "Pembina",
      "viewValue": "Pembina"
    },
    {
      "id": 35,
      "value": "Pierce",
      "viewValue": "Pierce"
    },
    {
      "id": 36,
      "value": "Ramsey",
      "viewValue": "Ramsey"
    },
    {
      "id": 37,
      "value": "Ransom",
      "viewValue": "Ransom"
    },
    {
      "id": 38,
      "value": "Renville",
      "viewValue": "Renville"
    },
    {
      "id": 39,
      "value": "Richland",
      "viewValue": "Richland"
    },
    {
      "id": 40,
      "value": "Rolette",
      "viewValue": "Rolette"
    },
    {
      "id": 41,
      "value": "Sargent",
      "viewValue": "Sargent"
    },
    {
      "id": 42,
      "value": "Sheridan",
      "viewValue": "Sheridan"
    },
    {
      "id": 43,
      "value": "Sioux",
      "viewValue": "Sioux"
    },
    {
      "id": 44,
      "value": "Slope",
      "viewValue": "Slope"
    },
    {
      "id": 45,
      "value": "Stark",
      "viewValue": "Stark"
    },
    {
      "id": 46,
      "value": "Steele",
      "viewValue": "Steele"
    },
    {
      "id": 47,
      "value": "Stutsman",
      "viewValue": "Stutsman"
    },
    {
      "id": 48,
      "value": "Towner",
      "viewValue": "Towner"
    },
    {
      "id": 49,
      "value": "Traill",
      "viewValue": "Traill"
    },
    {
      "id": 50,
      "value": "Walsh",
      "viewValue": "Walsh"
    },
    {
      "id": 51,
      "value": "Ward",
      "viewValue": "Ward"
    },
    {
      "id": 52,
      "value": "Wells",
      "viewValue": "Wells"
    },
    {
      "id": 53,
      "value": "Williams",
      "viewValue": "Williams"
    }
  ],
  "OH": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adams",
      "viewValue": "Adams"
    },
    {
      "id": 2,
      "value": "Allen",
      "viewValue": "Allen"
    },
    {
      "id": 3,
      "value": "Ashland",
      "viewValue": "Ashland"
    },
    {
      "id": 4,
      "value": "Ashtabula",
      "viewValue": "Ashtabula"
    },
    {
      "id": 5,
      "value": "Athens",
      "viewValue": "Athens"
    },
    {
      "id": 6,
      "value": "Auglaize",
      "viewValue": "Auglaize"
    },
    {
      "id": 7,
      "value": "Belmont",
      "viewValue": "Belmont"
    },
    {
      "id": 8,
      "value": "Brown",
      "viewValue": "Brown"
    },
    {
      "id": 9,
      "value": "Butler",
      "viewValue": "Butler"
    },
    {
      "id": 10,
      "value": "Carroll",
      "viewValue": "Carroll"
    },
    {
      "id": 11,
      "value": "Champaign",
      "viewValue": "Champaign"
    },
    {
      "id": 12,
      "value": "Clark",
      "viewValue": "Clark"
    },
    {
      "id": 13,
      "value": "Clermont",
      "viewValue": "Clermont"
    },
    {
      "id": 14,
      "value": "Clinton",
      "viewValue": "Clinton"
    },
    {
      "id": 15,
      "value": "Columbiana",
      "viewValue": "Columbiana"
    },
    {
      "id": 16,
      "value": "Coshocton",
      "viewValue": "Coshocton"
    },
    {
      "id": 17,
      "value": "Crawford",
      "viewValue": "Crawford"
    },
    {
      "id": 18,
      "value": "Cuyahoga",
      "viewValue": "Cuyahoga"
    },
    {
      "id": 19,
      "value": "Darke",
      "viewValue": "Darke"
    },
    {
      "id": 20,
      "value": "Defiance",
      "viewValue": "Defiance"
    },
    {
      "id": 21,
      "value": "Delaware",
      "viewValue": "Delaware"
    },
    {
      "id": 22,
      "value": "Erie",
      "viewValue": "Erie"
    },
    {
      "id": 23,
      "value": "Fairfield",
      "viewValue": "Fairfield"
    },
    {
      "id": 24,
      "value": "Fayette",
      "viewValue": "Fayette"
    },
    {
      "id": 25,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 26,
      "value": "Fulton",
      "viewValue": "Fulton"
    },
    {
      "id": 27,
      "value": "Gallia",
      "viewValue": "Gallia"
    },
    {
      "id": 28,
      "value": "Geauga",
      "viewValue": "Geauga"
    },
    {
      "id": 29,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 30,
      "value": "Guernsey",
      "viewValue": "Guernsey"
    },
    {
      "id": 31,
      "value": "Hamilton",
      "viewValue": "Hamilton"
    },
    {
      "id": 32,
      "value": "Hancock",
      "viewValue": "Hancock"
    },
    {
      "id": 33,
      "value": "Hardin",
      "viewValue": "Hardin"
    },
    {
      "id": 34,
      "value": "Harrison",
      "viewValue": "Harrison"
    },
    {
      "id": 35,
      "value": "Henry",
      "viewValue": "Henry"
    },
    {
      "id": 36,
      "value": "Highland",
      "viewValue": "Highland"
    },
    {
      "id": 37,
      "value": "Hocking",
      "viewValue": "Hocking"
    },
    {
      "id": 38,
      "value": "Holmes",
      "viewValue": "Holmes"
    },
    {
      "id": 39,
      "value": "Huron",
      "viewValue": "Huron"
    },
    {
      "id": 40,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 41,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 42,
      "value": "Knox",
      "viewValue": "Knox"
    },
    {
      "id": 43,
      "value": "Lake",
      "viewValue": "Lake"
    },
    {
      "id": 44,
      "value": "Lawrence",
      "viewValue": "Lawrence"
    },
    {
      "id": 45,
      "value": "Licking",
      "viewValue": "Licking"
    },
    {
      "id": 46,
      "value": "Logan",
      "viewValue": "Logan"
    },
    {
      "id": 47,
      "value": "Lorain",
      "viewValue": "Lorain"
    },
    {
      "id": 48,
      "value": "Lucas",
      "viewValue": "Lucas"
    },
    {
      "id": 49,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 50,
      "value": "Mahoning",
      "viewValue": "Mahoning"
    },
    {
      "id": 51,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 52,
      "value": "Medina",
      "viewValue": "Medina"
    },
    {
      "id": 53,
      "value": "Meigs",
      "viewValue": "Meigs"
    },
    {
      "id": 54,
      "value": "Mercer",
      "viewValue": "Mercer"
    },
    {
      "id": 55,
      "value": "Miami",
      "viewValue": "Miami"
    },
    {
      "id": 56,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 57,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 58,
      "value": "Morgan",
      "viewValue": "Morgan"
    },
    {
      "id": 59,
      "value": "Morrow",
      "viewValue": "Morrow"
    },
    {
      "id": 60,
      "value": "Muskingum",
      "viewValue": "Muskingum"
    },
    {
      "id": 61,
      "value": "Noble",
      "viewValue": "Noble"
    },
    {
      "id": 62,
      "value": "Ottawa",
      "viewValue": "Ottawa"
    },
    {
      "id": 63,
      "value": "Paulding",
      "viewValue": "Paulding"
    },
    {
      "id": 64,
      "value": "Perry",
      "viewValue": "Perry"
    },
    {
      "id": 65,
      "value": "Pickaway",
      "viewValue": "Pickaway"
    },
    {
      "id": 66,
      "value": "Pike",
      "viewValue": "Pike"
    },
    {
      "id": 67,
      "value": "Portage",
      "viewValue": "Portage"
    },
    {
      "id": 68,
      "value": "Preble",
      "viewValue": "Preble"
    },
    {
      "id": 69,
      "value": "Putnam",
      "viewValue": "Putnam"
    },
    {
      "id": 70,
      "value": "Richland",
      "viewValue": "Richland"
    },
    {
      "id": 71,
      "value": "Ross",
      "viewValue": "Ross"
    },
    {
      "id": 72,
      "value": "Sandusky",
      "viewValue": "Sandusky"
    },
    {
      "id": 73,
      "value": "Scioto",
      "viewValue": "Scioto"
    },
    {
      "id": 74,
      "value": "Seneca",
      "viewValue": "Seneca"
    },
    {
      "id": 75,
      "value": "Shelby",
      "viewValue": "Shelby"
    },
    {
      "id": 76,
      "value": "Stark",
      "viewValue": "Stark"
    },
    {
      "id": 77,
      "value": "Summit",
      "viewValue": "Summit"
    },
    {
      "id": 78,
      "value": "Trumbull",
      "viewValue": "Trumbull"
    },
    {
      "id": 79,
      "value": "Tuscarawas",
      "viewValue": "Tuscarawas"
    },
    {
      "id": 80,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 81,
      "value": "Van Wert",
      "viewValue": "Van Wert"
    },
    {
      "id": 82,
      "value": "Vinton",
      "viewValue": "Vinton"
    },
    {
      "id": 83,
      "value": "Warren",
      "viewValue": "Warren"
    },
    {
      "id": 84,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 85,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 86,
      "value": "Williams",
      "viewValue": "Williams"
    },
    {
      "id": 87,
      "value": "Wood",
      "viewValue": "Wood"
    },
    {
      "id": 88,
      "value": "Wyandot",
      "viewValue": "Wyandot"
    }
  ],
  "OK": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adair",
      "viewValue": "Adair"
    },
    {
      "id": 2,
      "value": "Alfalfa",
      "viewValue": "Alfalfa"
    },
    {
      "id": 3,
      "value": "Atoka",
      "viewValue": "Atoka"
    },
    {
      "id": 4,
      "value": "Beaver",
      "viewValue": "Beaver"
    },
    {
      "id": 5,
      "value": "Beckham",
      "viewValue": "Beckham"
    },
    {
      "id": 6,
      "value": "Blaine",
      "viewValue": "Blaine"
    },
    {
      "id": 7,
      "value": "Bryan",
      "viewValue": "Bryan"
    },
    {
      "id": 8,
      "value": "Caddo",
      "viewValue": "Caddo"
    },
    {
      "id": 9,
      "value": "Canadian",
      "viewValue": "Canadian"
    },
    {
      "id": 10,
      "value": "Carter",
      "viewValue": "Carter"
    },
    {
      "id": 11,
      "value": "Cherokee",
      "viewValue": "Cherokee"
    },
    {
      "id": 12,
      "value": "Choctaw",
      "viewValue": "Choctaw"
    },
    {
      "id": 13,
      "value": "Cimarron",
      "viewValue": "Cimarron"
    },
    {
      "id": 14,
      "value": "Cleveland",
      "viewValue": "Cleveland"
    },
    {
      "id": 15,
      "value": "Coal",
      "viewValue": "Coal"
    },
    {
      "id": 16,
      "value": "Comanche",
      "viewValue": "Comanche"
    },
    {
      "id": 17,
      "value": "Cotton",
      "viewValue": "Cotton"
    },
    {
      "id": 18,
      "value": "Craig",
      "viewValue": "Craig"
    },
    {
      "id": 19,
      "value": "Creek",
      "viewValue": "Creek"
    },
    {
      "id": 20,
      "value": "Custer",
      "viewValue": "Custer"
    },
    {
      "id": 21,
      "value": "Delaware",
      "viewValue": "Delaware"
    },
    {
      "id": 22,
      "value": "Dewey",
      "viewValue": "Dewey"
    },
    {
      "id": 23,
      "value": "Ellis",
      "viewValue": "Ellis"
    },
    {
      "id": 24,
      "value": "Garfield",
      "viewValue": "Garfield"
    },
    {
      "id": 25,
      "value": "Garvin",
      "viewValue": "Garvin"
    },
    {
      "id": 26,
      "value": "Grady",
      "viewValue": "Grady"
    },
    {
      "id": 27,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 28,
      "value": "Greer",
      "viewValue": "Greer"
    },
    {
      "id": 29,
      "value": "Harmon",
      "viewValue": "Harmon"
    },
    {
      "id": 30,
      "value": "Harper",
      "viewValue": "Harper"
    },
    {
      "id": 31,
      "value": "Haskell",
      "viewValue": "Haskell"
    },
    {
      "id": 32,
      "value": "Hughes",
      "viewValue": "Hughes"
    },
    {
      "id": 33,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 34,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 35,
      "value": "Johnston",
      "viewValue": "Johnston"
    },
    {
      "id": 36,
      "value": "Kay",
      "viewValue": "Kay"
    },
    {
      "id": 37,
      "value": "Kingfisher",
      "viewValue": "Kingfisher"
    },
    {
      "id": 38,
      "value": "Kiowa",
      "viewValue": "Kiowa"
    },
    {
      "id": 39,
      "value": "Latimer",
      "viewValue": "Latimer"
    },
    {
      "id": 40,
      "value": "Le Flore",
      "viewValue": "Le Flore"
    },
    {
      "id": 41,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 42,
      "value": "Logan",
      "viewValue": "Logan"
    },
    {
      "id": 43,
      "value": "Love",
      "viewValue": "Love"
    },
    {
      "id": 44,
      "value": "McClain",
      "viewValue": "McClain"
    },
    {
      "id": 45,
      "value": "McCurtain",
      "viewValue": "McCurtain"
    },
    {
      "id": 46,
      "value": "McIntosh",
      "viewValue": "McIntosh"
    },
    {
      "id": 47,
      "value": "Major",
      "viewValue": "Major"
    },
    {
      "id": 48,
      "value": "Marshall",
      "viewValue": "Marshall"
    },
    {
      "id": 49,
      "value": "Mayes",
      "viewValue": "Mayes"
    },
    {
      "id": 50,
      "value": "Murray",
      "viewValue": "Murray"
    },
    {
      "id": 51,
      "value": "Muskogee",
      "viewValue": "Muskogee"
    },
    {
      "id": 52,
      "value": "Noble",
      "viewValue": "Noble"
    },
    {
      "id": 53,
      "value": "Nowata",
      "viewValue": "Nowata"
    },
    {
      "id": 54,
      "value": "Okfuskee",
      "viewValue": "Okfuskee"
    },
    {
      "id": 55,
      "value": "Oklahoma",
      "viewValue": "Oklahoma"
    },
    {
      "id": 56,
      "value": "Okmulgee",
      "viewValue": "Okmulgee"
    },
    {
      "id": 57,
      "value": "Osage",
      "viewValue": "Osage"
    },
    {
      "id": 58,
      "value": "Ottawa",
      "viewValue": "Ottawa"
    },
    {
      "id": 59,
      "value": "Pawnee",
      "viewValue": "Pawnee"
    },
    {
      "id": 60,
      "value": "Payne",
      "viewValue": "Payne"
    },
    {
      "id": 61,
      "value": "Pittsburg",
      "viewValue": "Pittsburg"
    },
    {
      "id": 62,
      "value": "Pontotoc",
      "viewValue": "Pontotoc"
    },
    {
      "id": 63,
      "value": "Pottawatomie",
      "viewValue": "Pottawatomie"
    },
    {
      "id": 64,
      "value": "Pushmataha",
      "viewValue": "Pushmataha"
    },
    {
      "id": 65,
      "value": "Roger Mills",
      "viewValue": "Roger Mills"
    },
    {
      "id": 66,
      "value": "Rogers",
      "viewValue": "Rogers"
    },
    {
      "id": 67,
      "value": "Seminole",
      "viewValue": "Seminole"
    },
    {
      "id": 68,
      "value": "Sequoyah",
      "viewValue": "Sequoyah"
    },
    {
      "id": 69,
      "value": "Stephens",
      "viewValue": "Stephens"
    },
    {
      "id": 70,
      "value": "Texas",
      "viewValue": "Texas"
    },
    {
      "id": 71,
      "value": "Tillman",
      "viewValue": "Tillman"
    },
    {
      "id": 72,
      "value": "Tulsa",
      "viewValue": "Tulsa"
    },
    {
      "id": 73,
      "value": "Wagoner",
      "viewValue": "Wagoner"
    },
    {
      "id": 74,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 75,
      "value": "Washita",
      "viewValue": "Washita"
    },
    {
      "id": 76,
      "value": "Woods",
      "viewValue": "Woods"
    },
    {
      "id": 77,
      "value": "Woodward",
      "viewValue": "Woodward"
    }
  ],
  "OR": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Baker",
      "viewValue": "Baker"
    },
    {
      "id": 2,
      "value": "Benton",
      "viewValue": "Benton"
    },
    {
      "id": 3,
      "value": "Clackamas",
      "viewValue": "Clackamas"
    },
    {
      "id": 4,
      "value": "Clatsop",
      "viewValue": "Clatsop"
    },
    {
      "id": 5,
      "value": "Columbia",
      "viewValue": "Columbia"
    },
    {
      "id": 6,
      "value": "Coos",
      "viewValue": "Coos"
    },
    {
      "id": 7,
      "value": "Crook",
      "viewValue": "Crook"
    },
    {
      "id": 8,
      "value": "Curry",
      "viewValue": "Curry"
    },
    {
      "id": 9,
      "value": "Deschutes",
      "viewValue": "Deschutes"
    },
    {
      "id": 10,
      "value": "Douglas",
      "viewValue": "Douglas"
    },
    {
      "id": 11,
      "value": "Gilliam",
      "viewValue": "Gilliam"
    },
    {
      "id": 12,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 13,
      "value": "Harney",
      "viewValue": "Harney"
    },
    {
      "id": 14,
      "value": "Hood River",
      "viewValue": "Hood River"
    },
    {
      "id": 15,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 16,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 17,
      "value": "Josephine",
      "viewValue": "Josephine"
    },
    {
      "id": 18,
      "value": "Klamath",
      "viewValue": "Klamath"
    },
    {
      "id": 19,
      "value": "Lake",
      "viewValue": "Lake"
    },
    {
      "id": 20,
      "value": "Lane",
      "viewValue": "Lane"
    },
    {
      "id": 21,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 22,
      "value": "Linn",
      "viewValue": "Linn"
    },
    {
      "id": 23,
      "value": "Malheur",
      "viewValue": "Malheur"
    },
    {
      "id": 24,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 25,
      "value": "Morrow",
      "viewValue": "Morrow"
    },
    {
      "id": 26,
      "value": "Multnomah",
      "viewValue": "Multnomah"
    },
    {
      "id": 27,
      "value": "Polk",
      "viewValue": "Polk"
    },
    {
      "id": 28,
      "value": "Sherman",
      "viewValue": "Sherman"
    },
    {
      "id": 29,
      "value": "Tillamook",
      "viewValue": "Tillamook"
    },
    {
      "id": 30,
      "value": "Umatilla",
      "viewValue": "Umatilla"
    },
    {
      "id": 31,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 32,
      "value": "Wallowa",
      "viewValue": "Wallowa"
    },
    {
      "id": 33,
      "value": "Wasco",
      "viewValue": "Wasco"
    },
    {
      "id": 34,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 35,
      "value": "Wheeler",
      "viewValue": "Wheeler"
    },
    {
      "id": 36,
      "value": "Yamhill",
      "viewValue": "Yamhill"
    }
  ],
  "PA": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adams",
      "viewValue": "Adams"
    },
    {
      "id": 2,
      "value": "Allegheny",
      "viewValue": "Allegheny"
    },
    {
      "id": 3,
      "value": "Armstrong",
      "viewValue": "Armstrong"
    },
    {
      "id": 4,
      "value": "Beaver",
      "viewValue": "Beaver"
    },
    {
      "id": 5,
      "value": "Bedford",
      "viewValue": "Bedford"
    },
    {
      "id": 6,
      "value": "Berks",
      "viewValue": "Berks"
    },
    {
      "id": 7,
      "value": "Blair",
      "viewValue": "Blair"
    },
    {
      "id": 8,
      "value": "Bradford",
      "viewValue": "Bradford"
    },
    {
      "id": 9,
      "value": "Bucks",
      "viewValue": "Bucks"
    },
    {
      "id": 10,
      "value": "Butler",
      "viewValue": "Butler"
    },
    {
      "id": 11,
      "value": "Cambria",
      "viewValue": "Cambria"
    },
    {
      "id": 12,
      "value": "Cameron",
      "viewValue": "Cameron"
    },
    {
      "id": 13,
      "value": "Carbon",
      "viewValue": "Carbon"
    },
    {
      "id": 14,
      "value": "Centre",
      "viewValue": "Centre"
    },
    {
      "id": 15,
      "value": "Chester",
      "viewValue": "Chester"
    },
    {
      "id": 16,
      "value": "Clarion",
      "viewValue": "Clarion"
    },
    {
      "id": 17,
      "value": "Clearfield",
      "viewValue": "Clearfield"
    },
    {
      "id": 18,
      "value": "Clinton",
      "viewValue": "Clinton"
    },
    {
      "id": 19,
      "value": "Columbia",
      "viewValue": "Columbia"
    },
    {
      "id": 20,
      "value": "Crawford",
      "viewValue": "Crawford"
    },
    {
      "id": 21,
      "value": "Cumberland",
      "viewValue": "Cumberland"
    },
    {
      "id": 22,
      "value": "Dauphin",
      "viewValue": "Dauphin"
    },
    {
      "id": 23,
      "value": "Delaware",
      "viewValue": "Delaware"
    },
    {
      "id": 24,
      "value": "Elk",
      "viewValue": "Elk"
    },
    {
      "id": 25,
      "value": "Erie",
      "viewValue": "Erie"
    },
    {
      "id": 26,
      "value": "Fayette",
      "viewValue": "Fayette"
    },
    {
      "id": 27,
      "value": "Forest",
      "viewValue": "Forest"
    },
    {
      "id": 28,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 29,
      "value": "Fulton",
      "viewValue": "Fulton"
    },
    {
      "id": 30,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 31,
      "value": "Huntingdon",
      "viewValue": "Huntingdon"
    },
    {
      "id": 32,
      "value": "Indiana",
      "viewValue": "Indiana"
    },
    {
      "id": 33,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 34,
      "value": "Juniata",
      "viewValue": "Juniata"
    },
    {
      "id": 35,
      "value": "Lackawanna",
      "viewValue": "Lackawanna"
    },
    {
      "id": 36,
      "value": "Lancaster",
      "viewValue": "Lancaster"
    },
    {
      "id": 37,
      "value": "Lawrence",
      "viewValue": "Lawrence"
    },
    {
      "id": 38,
      "value": "Lebanon",
      "viewValue": "Lebanon"
    },
    {
      "id": 39,
      "value": "Lehigh",
      "viewValue": "Lehigh"
    },
    {
      "id": 40,
      "value": "Luzerne",
      "viewValue": "Luzerne"
    },
    {
      "id": 41,
      "value": "Lycoming",
      "viewValue": "Lycoming"
    },
    {
      "id": 42,
      "value": "McKean",
      "viewValue": "McKean"
    },
    {
      "id": 43,
      "value": "Mercer",
      "viewValue": "Mercer"
    },
    {
      "id": 44,
      "value": "Mifflin",
      "viewValue": "Mifflin"
    },
    {
      "id": 45,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 46,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 47,
      "value": "Montour",
      "viewValue": "Montour"
    },
    {
      "id": 48,
      "value": "Northampton",
      "viewValue": "Northampton"
    },
    {
      "id": 49,
      "value": "Northumberland",
      "viewValue": "Northumberland"
    },
    {
      "id": 50,
      "value": "Perry",
      "viewValue": "Perry"
    },
    {
      "id": 51,
      "value": "Philadelphia",
      "viewValue": "Philadelphia"
    },
    {
      "id": 52,
      "value": "Pike",
      "viewValue": "Pike"
    },
    {
      "id": 53,
      "value": "Potter",
      "viewValue": "Potter"
    },
    {
      "id": 54,
      "value": "Schuylkill",
      "viewValue": "Schuylkill"
    },
    {
      "id": 55,
      "value": "Snyder",
      "viewValue": "Snyder"
    },
    {
      "id": 56,
      "value": "Somerset",
      "viewValue": "Somerset"
    },
    {
      "id": 57,
      "value": "Sullivan",
      "viewValue": "Sullivan"
    },
    {
      "id": 58,
      "value": "Susquehanna",
      "viewValue": "Susquehanna"
    },
    {
      "id": 59,
      "value": "Tioga",
      "viewValue": "Tioga"
    },
    {
      "id": 60,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 61,
      "value": "Venango",
      "viewValue": "Venango"
    },
    {
      "id": 62,
      "value": "Warren",
      "viewValue": "Warren"
    },
    {
      "id": 63,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 64,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 65,
      "value": "Westmoreland",
      "viewValue": "Westmoreland"
    },
    {
      "id": 66,
      "value": "Wyoming",
      "viewValue": "Wyoming"
    },
    {
      "id": 67,
      "value": "York",
      "viewValue": "York"
    }
  ],
  "RI": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Bristol",
      "viewValue": "Bristol"
    },
    {
      "id": 2,
      "value": "Kent",
      "viewValue": "Kent"
    },
    {
      "id": 3,
      "value": "Newport",
      "viewValue": "Newport"
    },
    {
      "id": 4,
      "value": "Providence",
      "viewValue": "Providence"
    },
    {
      "id": 5,
      "value": "Washington",
      "viewValue": "Washington"
    }
  ],
  "SC": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Abbeville",
      "viewValue": "Abbeville"
    },
    {
      "id": 2,
      "value": "Aiken",
      "viewValue": "Aiken"
    },
    {
      "id": 3,
      "value": "Allendale",
      "viewValue": "Allendale"
    },
    {
      "id": 4,
      "value": "Anderson",
      "viewValue": "Anderson"
    },
    {
      "id": 5,
      "value": "Bamberg",
      "viewValue": "Bamberg"
    },
    {
      "id": 6,
      "value": "Barnwell",
      "viewValue": "Barnwell"
    },
    {
      "id": 7,
      "value": "Beaufort",
      "viewValue": "Beaufort"
    },
    {
      "id": 8,
      "value": "Berkeley",
      "viewValue": "Berkeley"
    },
    {
      "id": 9,
      "value": "Calhoun",
      "viewValue": "Calhoun"
    },
    {
      "id": 10,
      "value": "Charleston",
      "viewValue": "Charleston"
    },
    {
      "id": 11,
      "value": "Cherokee",
      "viewValue": "Cherokee"
    },
    {
      "id": 12,
      "value": "Chester",
      "viewValue": "Chester"
    },
    {
      "id": 13,
      "value": "Chesterfield",
      "viewValue": "Chesterfield"
    },
    {
      "id": 14,
      "value": "Clarendon",
      "viewValue": "Clarendon"
    },
    {
      "id": 15,
      "value": "Colleton",
      "viewValue": "Colleton"
    },
    {
      "id": 16,
      "value": "Darlington",
      "viewValue": "Darlington"
    },
    {
      "id": 17,
      "value": "Dillon",
      "viewValue": "Dillon"
    },
    {
      "id": 18,
      "value": "Dorchester",
      "viewValue": "Dorchester"
    },
    {
      "id": 19,
      "value": "Edgefield",
      "viewValue": "Edgefield"
    },
    {
      "id": 20,
      "value": "Fairfield",
      "viewValue": "Fairfield"
    },
    {
      "id": 21,
      "value": "Florence",
      "viewValue": "Florence"
    },
    {
      "id": 22,
      "value": "Georgetown",
      "viewValue": "Georgetown"
    },
    {
      "id": 23,
      "value": "Greenville",
      "viewValue": "Greenville"
    },
    {
      "id": 24,
      "value": "Greenwood",
      "viewValue": "Greenwood"
    },
    {
      "id": 25,
      "value": "Hampton",
      "viewValue": "Hampton"
    },
    {
      "id": 26,
      "value": "Horry",
      "viewValue": "Horry"
    },
    {
      "id": 27,
      "value": "Jasper",
      "viewValue": "Jasper"
    },
    {
      "id": 28,
      "value": "Kershaw",
      "viewValue": "Kershaw"
    },
    {
      "id": 29,
      "value": "Lancaster",
      "viewValue": "Lancaster"
    },
    {
      "id": 30,
      "value": "Laurens",
      "viewValue": "Laurens"
    },
    {
      "id": 31,
      "value": "Lee",
      "viewValue": "Lee"
    },
    {
      "id": 32,
      "value": "Lexington",
      "viewValue": "Lexington"
    },
    {
      "id": 33,
      "value": "McCormick",
      "viewValue": "McCormick"
    },
    {
      "id": 34,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 35,
      "value": "Marlboro",
      "viewValue": "Marlboro"
    },
    {
      "id": 36,
      "value": "Newberry",
      "viewValue": "Newberry"
    },
    {
      "id": 37,
      "value": "Oconee",
      "viewValue": "Oconee"
    },
    {
      "id": 38,
      "value": "Orangeburg",
      "viewValue": "Orangeburg"
    },
    {
      "id": 39,
      "value": "Pickens",
      "viewValue": "Pickens"
    },
    {
      "id": 40,
      "value": "Richland",
      "viewValue": "Richland"
    },
    {
      "id": 41,
      "value": "Saluda",
      "viewValue": "Saluda"
    },
    {
      "id": 42,
      "value": "Spartanburg",
      "viewValue": "Spartanburg"
    },
    {
      "id": 43,
      "value": "Sumter",
      "viewValue": "Sumter"
    },
    {
      "id": 44,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 45,
      "value": "Williamsburg",
      "viewValue": "Williamsburg"
    },
    {
      "id": 46,
      "value": "York",
      "viewValue": "York"
    }
  ],
  "SD": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Aurora",
      "viewValue": "Aurora"
    },
    {
      "id": 2,
      "value": "Beadle",
      "viewValue": "Beadle"
    },
    {
      "id": 3,
      "value": "Bennett",
      "viewValue": "Bennett"
    },
    {
      "id": 4,
      "value": "Bon Homme",
      "viewValue": "Bon Homme"
    },
    {
      "id": 5,
      "value": "Brookings",
      "viewValue": "Brookings"
    },
    {
      "id": 6,
      "value": "Brown",
      "viewValue": "Brown"
    },
    {
      "id": 7,
      "value": "Brule",
      "viewValue": "Brule"
    },
    {
      "id": 8,
      "value": "Buffalo",
      "viewValue": "Buffalo"
    },
    {
      "id": 9,
      "value": "Butte",
      "viewValue": "Butte"
    },
    {
      "id": 10,
      "value": "Campbell",
      "viewValue": "Campbell"
    },
    {
      "id": 11,
      "value": "Charles Mix",
      "viewValue": "Charles Mix"
    },
    {
      "id": 12,
      "value": "Clark",
      "viewValue": "Clark"
    },
    {
      "id": 13,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 14,
      "value": "Codington",
      "viewValue": "Codington"
    },
    {
      "id": 15,
      "value": "Corson",
      "viewValue": "Corson"
    },
    {
      "id": 16,
      "value": "Custer",
      "viewValue": "Custer"
    },
    {
      "id": 17,
      "value": "Davison",
      "viewValue": "Davison"
    },
    {
      "id": 18,
      "value": "Day",
      "viewValue": "Day"
    },
    {
      "id": 19,
      "value": "Deuel",
      "viewValue": "Deuel"
    },
    {
      "id": 20,
      "value": "Dewey",
      "viewValue": "Dewey"
    },
    {
      "id": 21,
      "value": "Douglas",
      "viewValue": "Douglas"
    },
    {
      "id": 22,
      "value": "Edmunds",
      "viewValue": "Edmunds"
    },
    {
      "id": 23,
      "value": "Fall River",
      "viewValue": "Fall River"
    },
    {
      "id": 24,
      "value": "Faulk",
      "viewValue": "Faulk"
    },
    {
      "id": 25,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 26,
      "value": "Gregory",
      "viewValue": "Gregory"
    },
    {
      "id": 27,
      "value": "Haakon",
      "viewValue": "Haakon"
    },
    {
      "id": 28,
      "value": "Hamlin",
      "viewValue": "Hamlin"
    },
    {
      "id": 29,
      "value": "Hand",
      "viewValue": "Hand"
    },
    {
      "id": 30,
      "value": "Hanson",
      "viewValue": "Hanson"
    },
    {
      "id": 31,
      "value": "Harding",
      "viewValue": "Harding"
    },
    {
      "id": 32,
      "value": "Hughes",
      "viewValue": "Hughes"
    },
    {
      "id": 33,
      "value": "Hutchinson",
      "viewValue": "Hutchinson"
    },
    {
      "id": 34,
      "value": "Hyde",
      "viewValue": "Hyde"
    },
    {
      "id": 35,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 36,
      "value": "Jerauld",
      "viewValue": "Jerauld"
    },
    {
      "id": 37,
      "value": "Jones",
      "viewValue": "Jones"
    },
    {
      "id": 38,
      "value": "Kingsbury",
      "viewValue": "Kingsbury"
    },
    {
      "id": 39,
      "value": "Lake",
      "viewValue": "Lake"
    },
    {
      "id": 40,
      "value": "Lawrence",
      "viewValue": "Lawrence"
    },
    {
      "id": 41,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 42,
      "value": "Lyman",
      "viewValue": "Lyman"
    },
    {
      "id": 43,
      "value": "McCook",
      "viewValue": "McCook"
    },
    {
      "id": 44,
      "value": "McPherson",
      "viewValue": "McPherson"
    },
    {
      "id": 45,
      "value": "Marshall",
      "viewValue": "Marshall"
    },
    {
      "id": 46,
      "value": "Meade",
      "viewValue": "Meade"
    },
    {
      "id": 47,
      "value": "Mellette",
      "viewValue": "Mellette"
    },
    {
      "id": 48,
      "value": "Miner",
      "viewValue": "Miner"
    },
    {
      "id": 49,
      "value": "Minnehaha",
      "viewValue": "Minnehaha"
    },
    {
      "id": 50,
      "value": "Moody",
      "viewValue": "Moody"
    },
    {
      "id": 51,
      "value": "Pennington",
      "viewValue": "Pennington"
    },
    {
      "id": 52,
      "value": "Perkins",
      "viewValue": "Perkins"
    },
    {
      "id": 53,
      "value": "Potter",
      "viewValue": "Potter"
    },
    {
      "id": 54,
      "value": "Roberts",
      "viewValue": "Roberts"
    },
    {
      "id": 55,
      "value": "Sanborn",
      "viewValue": "Sanborn"
    },
    {
      "id": 56,
      "value": "Shannon",
      "viewValue": "Shannon"
    },
    {
      "id": 57,
      "value": "Spink",
      "viewValue": "Spink"
    },
    {
      "id": 58,
      "value": "Stanley",
      "viewValue": "Stanley"
    },
    {
      "id": 59,
      "value": "Sully",
      "viewValue": "Sully"
    },
    {
      "id": 60,
      "value": "Todd",
      "viewValue": "Todd"
    },
    {
      "id": 61,
      "value": "Tripp",
      "viewValue": "Tripp"
    },
    {
      "id": 62,
      "value": "Turner",
      "viewValue": "Turner"
    },
    {
      "id": 63,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 64,
      "value": "Walworth",
      "viewValue": "Walworth"
    },
    {
      "id": 65,
      "value": "Yankton",
      "viewValue": "Yankton"
    },
    {
      "id": 66,
      "value": "Ziebach",
      "viewValue": "Ziebach"
    }
  ],
  "TN": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Anderson",
      "viewValue": "Anderson"
    },
    {
      "id": 2,
      "value": "Bedford",
      "viewValue": "Bedford"
    },
    {
      "id": 3,
      "value": "Benton",
      "viewValue": "Benton"
    },
    {
      "id": 4,
      "value": "Bledsoe",
      "viewValue": "Bledsoe"
    },
    {
      "id": 5,
      "value": "Blount",
      "viewValue": "Blount"
    },
    {
      "id": 6,
      "value": "Bradley",
      "viewValue": "Bradley"
    },
    {
      "id": 7,
      "value": "Campbell",
      "viewValue": "Campbell"
    },
    {
      "id": 8,
      "value": "Cannon",
      "viewValue": "Cannon"
    },
    {
      "id": 9,
      "value": "Carroll",
      "viewValue": "Carroll"
    },
    {
      "id": 10,
      "value": "Carter",
      "viewValue": "Carter"
    },
    {
      "id": 11,
      "value": "Cheatham",
      "viewValue": "Cheatham"
    },
    {
      "id": 12,
      "value": "Chester",
      "viewValue": "Chester"
    },
    {
      "id": 13,
      "value": "Claiborne",
      "viewValue": "Claiborne"
    },
    {
      "id": 14,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 15,
      "value": "Cocke",
      "viewValue": "Cocke"
    },
    {
      "id": 16,
      "value": "Coffee",
      "viewValue": "Coffee"
    },
    {
      "id": 17,
      "value": "Crockett",
      "viewValue": "Crockett"
    },
    {
      "id": 18,
      "value": "Cumberland",
      "viewValue": "Cumberland"
    },
    {
      "id": 19,
      "value": "Davidson",
      "viewValue": "Davidson"
    },
    {
      "id": 20,
      "value": "DeKalb",
      "viewValue": "DeKalb"
    },
    {
      "id": 21,
      "value": "Decatur",
      "viewValue": "Decatur"
    },
    {
      "id": 22,
      "value": "Dickson",
      "viewValue": "Dickson"
    },
    {
      "id": 23,
      "value": "Dyer",
      "viewValue": "Dyer"
    },
    {
      "id": 24,
      "value": "Fayette",
      "viewValue": "Fayette"
    },
    {
      "id": 25,
      "value": "Fentress",
      "viewValue": "Fentress"
    },
    {
      "id": 26,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 27,
      "value": "Gibson",
      "viewValue": "Gibson"
    },
    {
      "id": 28,
      "value": "Giles",
      "viewValue": "Giles"
    },
    {
      "id": 29,
      "value": "Grainger",
      "viewValue": "Grainger"
    },
    {
      "id": 30,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 31,
      "value": "Grundy",
      "viewValue": "Grundy"
    },
    {
      "id": 32,
      "value": "Hamblen",
      "viewValue": "Hamblen"
    },
    {
      "id": 33,
      "value": "Hamilton",
      "viewValue": "Hamilton"
    },
    {
      "id": 34,
      "value": "Hancock",
      "viewValue": "Hancock"
    },
    {
      "id": 35,
      "value": "Hardeman",
      "viewValue": "Hardeman"
    },
    {
      "id": 36,
      "value": "Hardin",
      "viewValue": "Hardin"
    },
    {
      "id": 37,
      "value": "Hawkins",
      "viewValue": "Hawkins"
    },
    {
      "id": 38,
      "value": "Haywood",
      "viewValue": "Haywood"
    },
    {
      "id": 39,
      "value": "Henderson",
      "viewValue": "Henderson"
    },
    {
      "id": 40,
      "value": "Henry",
      "viewValue": "Henry"
    },
    {
      "id": 41,
      "value": "Hickman",
      "viewValue": "Hickman"
    },
    {
      "id": 42,
      "value": "Houston",
      "viewValue": "Houston"
    },
    {
      "id": 43,
      "value": "Humphreys",
      "viewValue": "Humphreys"
    },
    {
      "id": 44,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 45,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 46,
      "value": "Johnson",
      "viewValue": "Johnson"
    },
    {
      "id": 47,
      "value": "Knox",
      "viewValue": "Knox"
    },
    {
      "id": 48,
      "value": "Lake",
      "viewValue": "Lake"
    },
    {
      "id": 49,
      "value": "Lauderdale",
      "viewValue": "Lauderdale"
    },
    {
      "id": 50,
      "value": "Lawrence",
      "viewValue": "Lawrence"
    },
    {
      "id": 51,
      "value": "Lewis",
      "viewValue": "Lewis"
    },
    {
      "id": 52,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 53,
      "value": "Loudon",
      "viewValue": "Loudon"
    },
    {
      "id": 54,
      "value": "McMinn",
      "viewValue": "McMinn"
    },
    {
      "id": 55,
      "value": "McNairy",
      "viewValue": "McNairy"
    },
    {
      "id": 56,
      "value": "Macon",
      "viewValue": "Macon"
    },
    {
      "id": 57,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 58,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 59,
      "value": "Marshall",
      "viewValue": "Marshall"
    },
    {
      "id": 60,
      "value": "Maury",
      "viewValue": "Maury"
    },
    {
      "id": 61,
      "value": "Meigs",
      "viewValue": "Meigs"
    },
    {
      "id": 62,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 63,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 64,
      "value": "Moore",
      "viewValue": "Moore"
    },
    {
      "id": 65,
      "value": "Morgan",
      "viewValue": "Morgan"
    },
    {
      "id": 66,
      "value": "Obion",
      "viewValue": "Obion"
    },
    {
      "id": 67,
      "value": "Overton",
      "viewValue": "Overton"
    },
    {
      "id": 68,
      "value": "Perry",
      "viewValue": "Perry"
    },
    {
      "id": 69,
      "value": "Pickett",
      "viewValue": "Pickett"
    },
    {
      "id": 70,
      "value": "Polk",
      "viewValue": "Polk"
    },
    {
      "id": 71,
      "value": "Putnam",
      "viewValue": "Putnam"
    },
    {
      "id": 72,
      "value": "Rhea",
      "viewValue": "Rhea"
    },
    {
      "id": 73,
      "value": "Roane",
      "viewValue": "Roane"
    },
    {
      "id": 74,
      "value": "Robertson",
      "viewValue": "Robertson"
    },
    {
      "id": 75,
      "value": "Rutherford",
      "viewValue": "Rutherford"
    },
    {
      "id": 76,
      "value": "Scott",
      "viewValue": "Scott"
    },
    {
      "id": 77,
      "value": "Sequatchie",
      "viewValue": "Sequatchie"
    },
    {
      "id": 78,
      "value": "Sevier",
      "viewValue": "Sevier"
    },
    {
      "id": 79,
      "value": "Shelby",
      "viewValue": "Shelby"
    },
    {
      "id": 80,
      "value": "Smith",
      "viewValue": "Smith"
    },
    {
      "id": 81,
      "value": "Stewart",
      "viewValue": "Stewart"
    },
    {
      "id": 82,
      "value": "Sullivan",
      "viewValue": "Sullivan"
    },
    {
      "id": 83,
      "value": "Sumner",
      "viewValue": "Sumner"
    },
    {
      "id": 84,
      "value": "Tipton",
      "viewValue": "Tipton"
    },
    {
      "id": 85,
      "value": "Trousdale",
      "viewValue": "Trousdale"
    },
    {
      "id": 86,
      "value": "Unicoi",
      "viewValue": "Unicoi"
    },
    {
      "id": 87,
      "value": "Union",
      "viewValue": "Union"
    },
    {
      "id": 88,
      "value": "Van Buren",
      "viewValue": "Van Buren"
    },
    {
      "id": 89,
      "value": "Warren",
      "viewValue": "Warren"
    },
    {
      "id": 90,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 91,
      "value": "Wayne",
      "viewValue": "Wayne"
    },
    {
      "id": 92,
      "value": "Weakley",
      "viewValue": "Weakley"
    },
    {
      "id": 93,
      "value": "White",
      "viewValue": "White"
    },
    {
      "id": 94,
      "value": "Williamson",
      "viewValue": "Williamson"
    },
    {
      "id": 95,
      "value": "Wilson",
      "viewValue": "Wilson"
    }
  ],
  "TX": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Anderson",
      "viewValue": "Anderson"
    },
    {
      "id": 2,
      "value": "Andrews",
      "viewValue": "Andrews"
    },
    {
      "id": 3,
      "value": "Angelina",
      "viewValue": "Angelina"
    },
    {
      "id": 4,
      "value": "Aransas",
      "viewValue": "Aransas"
    },
    {
      "id": 5,
      "value": "Archer",
      "viewValue": "Archer"
    },
    {
      "id": 6,
      "value": "Armstrong",
      "viewValue": "Armstrong"
    },
    {
      "id": 7,
      "value": "Atascosa",
      "viewValue": "Atascosa"
    },
    {
      "id": 8,
      "value": "Austin",
      "viewValue": "Austin"
    },
    {
      "id": 9,
      "value": "Bailey",
      "viewValue": "Bailey"
    },
    {
      "id": 10,
      "value": "Bandera",
      "viewValue": "Bandera"
    },
    {
      "id": 11,
      "value": "Bastrop",
      "viewValue": "Bastrop"
    },
    {
      "id": 12,
      "value": "Baylor",
      "viewValue": "Baylor"
    },
    {
      "id": 13,
      "value": "Bee",
      "viewValue": "Bee"
    },
    {
      "id": 14,
      "value": "Bell",
      "viewValue": "Bell"
    },
    {
      "id": 15,
      "value": "Bexar",
      "viewValue": "Bexar"
    },
    {
      "id": 16,
      "value": "Blanco",
      "viewValue": "Blanco"
    },
    {
      "id": 17,
      "value": "Borden",
      "viewValue": "Borden"
    },
    {
      "id": 18,
      "value": "Bosque",
      "viewValue": "Bosque"
    },
    {
      "id": 19,
      "value": "Bowie",
      "viewValue": "Bowie"
    },
    {
      "id": 20,
      "value": "Brazoria",
      "viewValue": "Brazoria"
    },
    {
      "id": 21,
      "value": "Brazos",
      "viewValue": "Brazos"
    },
    {
      "id": 22,
      "value": "Brewster",
      "viewValue": "Brewster"
    },
    {
      "id": 23,
      "value": "Briscoe",
      "viewValue": "Briscoe"
    },
    {
      "id": 24,
      "value": "Brooks",
      "viewValue": "Brooks"
    },
    {
      "id": 25,
      "value": "Brown",
      "viewValue": "Brown"
    },
    {
      "id": 26,
      "value": "Burleson",
      "viewValue": "Burleson"
    },
    {
      "id": 27,
      "value": "Burnet",
      "viewValue": "Burnet"
    },
    {
      "id": 28,
      "value": "Caldwell",
      "viewValue": "Caldwell"
    },
    {
      "id": 29,
      "value": "Calhoun",
      "viewValue": "Calhoun"
    },
    {
      "id": 30,
      "value": "Callahan",
      "viewValue": "Callahan"
    },
    {
      "id": 31,
      "value": "Cameron",
      "viewValue": "Cameron"
    },
    {
      "id": 32,
      "value": "Camp",
      "viewValue": "Camp"
    },
    {
      "id": 33,
      "value": "Carson",
      "viewValue": "Carson"
    },
    {
      "id": 34,
      "value": "Cass",
      "viewValue": "Cass"
    },
    {
      "id": 35,
      "value": "Castro",
      "viewValue": "Castro"
    },
    {
      "id": 36,
      "value": "Chambers",
      "viewValue": "Chambers"
    },
    {
      "id": 37,
      "value": "Cherokee",
      "viewValue": "Cherokee"
    },
    {
      "id": 38,
      "value": "Childress",
      "viewValue": "Childress"
    },
    {
      "id": 39,
      "value": "Clay",
      "viewValue": "Clay"
    },
    {
      "id": 40,
      "value": "Cochran",
      "viewValue": "Cochran"
    },
    {
      "id": 41,
      "value": "Coke",
      "viewValue": "Coke"
    },
    {
      "id": 42,
      "value": "Coleman",
      "viewValue": "Coleman"
    },
    {
      "id": 43,
      "value": "Collin",
      "viewValue": "Collin"
    },
    {
      "id": 44,
      "value": "Collingsworth",
      "viewValue": "Collingsworth"
    },
    {
      "id": 45,
      "value": "Colorado",
      "viewValue": "Colorado"
    },
    {
      "id": 46,
      "value": "Comal",
      "viewValue": "Comal"
    },
    {
      "id": 47,
      "value": "Comanche",
      "viewValue": "Comanche"
    },
    {
      "id": 48,
      "value": "Concho",
      "viewValue": "Concho"
    },
    {
      "id": 49,
      "value": "Cooke",
      "viewValue": "Cooke"
    },
    {
      "id": 50,
      "value": "Coryell",
      "viewValue": "Coryell"
    },
    {
      "id": 51,
      "value": "Cottle",
      "viewValue": "Cottle"
    },
    {
      "id": 52,
      "value": "Crane",
      "viewValue": "Crane"
    },
    {
      "id": 53,
      "value": "Crockett",
      "viewValue": "Crockett"
    },
    {
      "id": 54,
      "value": "Crosby",
      "viewValue": "Crosby"
    },
    {
      "id": 55,
      "value": "Culberson",
      "viewValue": "Culberson"
    },
    {
      "id": 56,
      "value": "Dallam",
      "viewValue": "Dallam"
    },
    {
      "id": 57,
      "value": "Dallas",
      "viewValue": "Dallas"
    },
    {
      "id": 58,
      "value": "Dawson",
      "viewValue": "Dawson"
    },
    {
      "id": 59,
      "value": "DeWitt",
      "viewValue": "DeWitt"
    },
    {
      "id": 60,
      "value": "Deaf Smith",
      "viewValue": "Deaf Smith"
    },
    {
      "id": 61,
      "value": "Delta",
      "viewValue": "Delta"
    },
    {
      "id": 62,
      "value": "Denton",
      "viewValue": "Denton"
    },
    {
      "id": 63,
      "value": "Dickens",
      "viewValue": "Dickens"
    },
    {
      "id": 64,
      "value": "Dimmit",
      "viewValue": "Dimmit"
    },
    {
      "id": 65,
      "value": "Donley",
      "viewValue": "Donley"
    },
    {
      "id": 66,
      "value": "Duval",
      "viewValue": "Duval"
    },
    {
      "id": 67,
      "value": "Eastland",
      "viewValue": "Eastland"
    },
    {
      "id": 68,
      "value": "Ector",
      "viewValue": "Ector"
    },
    {
      "id": 69,
      "value": "Edwards",
      "viewValue": "Edwards"
    },
    {
      "id": 70,
      "value": "Ellis",
      "viewValue": "Ellis"
    },
    {
      "id": 71,
      "value": "El Paso",
      "viewValue": "El Paso"
    },
    {
      "id": 72,
      "value": "Erath",
      "viewValue": "Erath"
    },
    {
      "id": 73,
      "value": "Falls",
      "viewValue": "Falls"
    },
    {
      "id": 74,
      "value": "Fannin",
      "viewValue": "Fannin"
    },
    {
      "id": 75,
      "value": "Fayette",
      "viewValue": "Fayette"
    },
    {
      "id": 76,
      "value": "Fisher",
      "viewValue": "Fisher"
    },
    {
      "id": 77,
      "value": "Floyd",
      "viewValue": "Floyd"
    },
    {
      "id": 78,
      "value": "Foard",
      "viewValue": "Foard"
    },
    {
      "id": 79,
      "value": "Fort Bend",
      "viewValue": "Fort Bend"
    },
    {
      "id": 80,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 81,
      "value": "Freestone",
      "viewValue": "Freestone"
    },
    {
      "id": 82,
      "value": "Frio",
      "viewValue": "Frio"
    },
    {
      "id": 83,
      "value": "Gaines",
      "viewValue": "Gaines"
    },
    {
      "id": 84,
      "value": "Galveston",
      "viewValue": "Galveston"
    },
    {
      "id": 85,
      "value": "Garza",
      "viewValue": "Garza"
    },
    {
      "id": 86,
      "value": "Gillespie",
      "viewValue": "Gillespie"
    },
    {
      "id": 87,
      "value": "Glasscock",
      "viewValue": "Glasscock"
    },
    {
      "id": 88,
      "value": "Goliad",
      "viewValue": "Goliad"
    },
    {
      "id": 89,
      "value": "Gonzales",
      "viewValue": "Gonzales"
    },
    {
      "id": 90,
      "value": "Gray",
      "viewValue": "Gray"
    },
    {
      "id": 91,
      "value": "Grayson",
      "viewValue": "Grayson"
    },
    {
      "id": 92,
      "value": "Gregg",
      "viewValue": "Gregg"
    },
    {
      "id": 93,
      "value": "Grimes",
      "viewValue": "Grimes"
    },
    {
      "id": 94,
      "value": "Guadalupe",
      "viewValue": "Guadalupe"
    },
    {
      "id": 95,
      "value": "Hale",
      "viewValue": "Hale"
    },
    {
      "id": 96,
      "value": "Hall",
      "viewValue": "Hall"
    },
    {
      "id": 97,
      "value": "Hamilton",
      "viewValue": "Hamilton"
    },
    {
      "id": 98,
      "value": "Hansford",
      "viewValue": "Hansford"
    },
    {
      "id": 99,
      "value": "Hardeman",
      "viewValue": "Hardeman"
    },
    {
      "id": 100,
      "value": "Hardin",
      "viewValue": "Hardin"
    },
    {
      "id": 101,
      "value": "Harris",
      "viewValue": "Harris"
    },
    {
      "id": 102,
      "value": "Harrison",
      "viewValue": "Harrison"
    },
    {
      "id": 103,
      "value": "Hartley",
      "viewValue": "Hartley"
    },
    {
      "id": 104,
      "value": "Haskell",
      "viewValue": "Haskell"
    },
    {
      "id": 105,
      "value": "Hays",
      "viewValue": "Hays"
    },
    {
      "id": 106,
      "value": "Hemphill",
      "viewValue": "Hemphill"
    },
    {
      "id": 107,
      "value": "Henderson",
      "viewValue": "Henderson"
    },
    {
      "id": 108,
      "value": "Hidalgo",
      "viewValue": "Hidalgo"
    },
    {
      "id": 109,
      "value": "Hill",
      "viewValue": "Hill"
    },
    {
      "id": 110,
      "value": "Hockley",
      "viewValue": "Hockley"
    },
    {
      "id": 111,
      "value": "Hood",
      "viewValue": "Hood"
    },
    {
      "id": 112,
      "value": "Hopkins",
      "viewValue": "Hopkins"
    },
    {
      "id": 113,
      "value": "Houston",
      "viewValue": "Houston"
    },
    {
      "id": 114,
      "value": "Howard",
      "viewValue": "Howard"
    },
    {
      "id": 115,
      "value": "Hudspeth",
      "viewValue": "Hudspeth"
    },
    {
      "id": 116,
      "value": "Hunt",
      "viewValue": "Hunt"
    },
    {
      "id": 117,
      "value": "Hutchinson",
      "viewValue": "Hutchinson"
    },
    {
      "id": 118,
      "value": "Irion",
      "viewValue": "Irion"
    },
    {
      "id": 119,
      "value": "Jack",
      "viewValue": "Jack"
    },
    {
      "id": 120,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 121,
      "value": "Jasper",
      "viewValue": "Jasper"
    },
    {
      "id": 122,
      "value": "Jeff Davis",
      "viewValue": "Jeff Davis"
    },
    {
      "id": 123,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 124,
      "value": "Jim Hogg",
      "viewValue": "Jim Hogg"
    },
    {
      "id": 125,
      "value": "Jim Wells",
      "viewValue": "Jim Wells"
    },
    {
      "id": 126,
      "value": "Johnson",
      "viewValue": "Johnson"
    },
    {
      "id": 127,
      "value": "Jones",
      "viewValue": "Jones"
    },
    {
      "id": 128,
      "value": "Karnes",
      "viewValue": "Karnes"
    },
    {
      "id": 129,
      "value": "Kaufman",
      "viewValue": "Kaufman"
    },
    {
      "id": 130,
      "value": "Kendall",
      "viewValue": "Kendall"
    },
    {
      "id": 131,
      "value": "Kenedy",
      "viewValue": "Kenedy"
    },
    {
      "id": 132,
      "value": "Kent",
      "viewValue": "Kent"
    },
    {
      "id": 133,
      "value": "Kerr",
      "viewValue": "Kerr"
    },
    {
      "id": 134,
      "value": "Kimble",
      "viewValue": "Kimble"
    },
    {
      "id": 135,
      "value": "King",
      "viewValue": "King"
    },
    {
      "id": 136,
      "value": "Kinney",
      "viewValue": "Kinney"
    },
    {
      "id": 137,
      "value": "Kleberg",
      "viewValue": "Kleberg"
    },
    {
      "id": 138,
      "value": "Knox",
      "viewValue": "Knox"
    },
    {
      "id": 139,
      "value": "La Salle",
      "viewValue": "La Salle"
    },
    {
      "id": 140,
      "value": "Lamar",
      "viewValue": "Lamar"
    },
    {
      "id": 141,
      "value": "Lamb",
      "viewValue": "Lamb"
    },
    {
      "id": 142,
      "value": "Lampasas",
      "viewValue": "Lampasas"
    },
    {
      "id": 143,
      "value": "Lavaca",
      "viewValue": "Lavaca"
    },
    {
      "id": 144,
      "value": "Lee",
      "viewValue": "Lee"
    },
    {
      "id": 145,
      "value": "Leon",
      "viewValue": "Leon"
    },
    {
      "id": 146,
      "value": "Liberty",
      "viewValue": "Liberty"
    },
    {
      "id": 147,
      "value": "Limestone",
      "viewValue": "Limestone"
    },
    {
      "id": 148,
      "value": "Lipscomb",
      "viewValue": "Lipscomb"
    },
    {
      "id": 149,
      "value": "Live Oak",
      "viewValue": "Live Oak"
    },
    {
      "id": 150,
      "value": "Llano",
      "viewValue": "Llano"
    },
    {
      "id": 151,
      "value": "Loving",
      "viewValue": "Loving"
    },
    {
      "id": 152,
      "value": "Lubbock",
      "viewValue": "Lubbock"
    },
    {
      "id": 153,
      "value": "Lynn",
      "viewValue": "Lynn"
    },
    {
      "id": 154,
      "value": "McCulloch",
      "viewValue": "McCulloch"
    },
    {
      "id": 155,
      "value": "McLennan",
      "viewValue": "McLennan"
    },
    {
      "id": 156,
      "value": "McMullen",
      "viewValue": "McMullen"
    },
    {
      "id": 157,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 158,
      "value": "Marion",
      "viewValue": "Marion"
    },
    {
      "id": 159,
      "value": "Martin",
      "viewValue": "Martin"
    },
    {
      "id": 160,
      "value": "Mason",
      "viewValue": "Mason"
    },
    {
      "id": 161,
      "value": "Matagorda",
      "viewValue": "Matagorda"
    },
    {
      "id": 162,
      "value": "Maverick",
      "viewValue": "Maverick"
    },
    {
      "id": 163,
      "value": "Medina",
      "viewValue": "Medina"
    },
    {
      "id": 164,
      "value": "Menard",
      "viewValue": "Menard"
    },
    {
      "id": 165,
      "value": "Midland",
      "viewValue": "Midland"
    },
    {
      "id": 166,
      "value": "Milam",
      "viewValue": "Milam"
    },
    {
      "id": 167,
      "value": "Mills",
      "viewValue": "Mills"
    },
    {
      "id": 168,
      "value": "Mitchell",
      "viewValue": "Mitchell"
    },
    {
      "id": 169,
      "value": "Montague",
      "viewValue": "Montague"
    },
    {
      "id": 170,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 171,
      "value": "Moore",
      "viewValue": "Moore"
    },
    {
      "id": 172,
      "value": "Morris",
      "viewValue": "Morris"
    },
    {
      "id": 173,
      "value": "Motley",
      "viewValue": "Motley"
    },
    {
      "id": 174,
      "value": "Nacogdoches",
      "viewValue": "Nacogdoches"
    },
    {
      "id": 175,
      "value": "Navarro",
      "viewValue": "Navarro"
    },
    {
      "id": 176,
      "value": "Newton",
      "viewValue": "Newton"
    },
    {
      "id": 177,
      "value": "Nolan",
      "viewValue": "Nolan"
    },
    {
      "id": 178,
      "value": "Nueces",
      "viewValue": "Nueces"
    },
    {
      "id": 179,
      "value": "Ochiltree",
      "viewValue": "Ochiltree"
    },
    {
      "id": 180,
      "value": "Oldham",
      "viewValue": "Oldham"
    },
    {
      "id": 181,
      "value": "Orange",
      "viewValue": "Orange"
    },
    {
      "id": 182,
      "value": "Palo Pinto",
      "viewValue": "Palo Pinto"
    },
    {
      "id": 183,
      "value": "Panola",
      "viewValue": "Panola"
    },
    {
      "id": 184,
      "value": "Parker",
      "viewValue": "Parker"
    },
    {
      "id": 185,
      "value": "Parmer",
      "viewValue": "Parmer"
    },
    {
      "id": 186,
      "value": "Pecos",
      "viewValue": "Pecos"
    },
    {
      "id": 187,
      "value": "Polk",
      "viewValue": "Polk"
    },
    {
      "id": 188,
      "value": "Potter",
      "viewValue": "Potter"
    },
    {
      "id": 189,
      "value": "Presidio",
      "viewValue": "Presidio"
    },
    {
      "id": 190,
      "value": "Rains",
      "viewValue": "Rains"
    },
    {
      "id": 191,
      "value": "Randall",
      "viewValue": "Randall"
    },
    {
      "id": 192,
      "value": "Reagan",
      "viewValue": "Reagan"
    },
    {
      "id": 193,
      "value": "Real",
      "viewValue": "Real"
    },
    {
      "id": 194,
      "value": "Red River",
      "viewValue": "Red River"
    },
    {
      "id": 195,
      "value": "Reeves",
      "viewValue": "Reeves"
    },
    {
      "id": 196,
      "value": "Refugio",
      "viewValue": "Refugio"
    },
    {
      "id": 197,
      "value": "Roberts",
      "viewValue": "Roberts"
    },
    {
      "id": 198,
      "value": "Robertson",
      "viewValue": "Robertson"
    },
    {
      "id": 199,
      "value": "Rockwall",
      "viewValue": "Rockwall"
    },
    {
      "id": 200,
      "value": "Runnels",
      "viewValue": "Runnels"
    },
    {
      "id": 201,
      "value": "Rusk",
      "viewValue": "Rusk"
    },
    {
      "id": 202,
      "value": "Sabine",
      "viewValue": "Sabine"
    },
    {
      "id": 203,
      "value": "San Augustine",
      "viewValue": "San Augustine"
    },
    {
      "id": 204,
      "value": "San Jacinto",
      "viewValue": "San Jacinto"
    },
    {
      "id": 205,
      "value": "San Patricio",
      "viewValue": "San Patricio"
    },
    {
      "id": 206,
      "value": "San Saba",
      "viewValue": "San Saba"
    },
    {
      "id": 207,
      "value": "Schleicher",
      "viewValue": "Schleicher"
    },
    {
      "id": 208,
      "value": "Scurry",
      "viewValue": "Scurry"
    },
    {
      "id": 209,
      "value": "Shackelford",
      "viewValue": "Shackelford"
    },
    {
      "id": 210,
      "value": "Shelby",
      "viewValue": "Shelby"
    },
    {
      "id": 211,
      "value": "Sherman",
      "viewValue": "Sherman"
    },
    {
      "id": 212,
      "value": "Smith",
      "viewValue": "Smith"
    },
    {
      "id": 213,
      "value": "Somervell",
      "viewValue": "Somervell"
    },
    {
      "id": 214,
      "value": "Starr",
      "viewValue": "Starr"
    },
    {
      "id": 215,
      "value": "Stephens",
      "viewValue": "Stephens"
    },
    {
      "id": 216,
      "value": "Sterling",
      "viewValue": "Sterling"
    },
    {
      "id": 217,
      "value": "Stonewall",
      "viewValue": "Stonewall"
    },
    {
      "id": 218,
      "value": "Sutton",
      "viewValue": "Sutton"
    },
    {
      "id": 219,
      "value": "Swisher",
      "viewValue": "Swisher"
    },
    {
      "id": 220,
      "value": "Tarrant",
      "viewValue": "Tarrant"
    },
    {
      "id": 221,
      "value": "Taylor",
      "viewValue": "Taylor"
    },
    {
      "id": 222,
      "value": "Terrell",
      "viewValue": "Terrell"
    },
    {
      "id": 223,
      "value": "Terry",
      "viewValue": "Terry"
    },
    {
      "id": 224,
      "value": "Throckmorton",
      "viewValue": "Throckmorton"
    },
    {
      "id": 225,
      "value": "Titus",
      "viewValue": "Titus"
    },
    {
      "id": 226,
      "value": "Tom Green",
      "viewValue": "Tom Green"
    },
    {
      "id": 227,
      "value": "Travis",
      "viewValue": "Travis"
    },
    {
      "id": 228,
      "value": "Trinity",
      "viewValue": "Trinity"
    },
    {
      "id": 229,
      "value": "Tyler",
      "viewValue": "Tyler"
    },
    {
      "id": 230,
      "value": "Upshur",
      "viewValue": "Upshur"
    },
    {
      "id": 231,
      "value": "Williamson",
      "viewValue": "Williamson"
    }
  ],
  "UT": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Beaver",
      "viewValue": "Beaver"
    },
    {
      "id": 2,
      "value": "Box Elder",
      "viewValue": "Box Elder"
    },
    {
      "id": 3,
      "value": "Cache",
      "viewValue": "Cache"
    },
    {
      "id": 4,
      "value": "Carbon",
      "viewValue": "Carbon"
    },
    {
      "id": 5,
      "value": "Daggett",
      "viewValue": "Daggett"
    },
    {
      "id": 6,
      "value": "Davis",
      "viewValue": "Davis"
    },
    {
      "id": 7,
      "value": "Duchesne",
      "viewValue": "Duchesne"
    },
    {
      "id": 8,
      "value": "Emery",
      "viewValue": "Emery"
    },
    {
      "id": 9,
      "value": "Garfield",
      "viewValue": "Garfield"
    },
    {
      "id": 10,
      "value": "Grand",
      "viewValue": "Grand"
    },
    {
      "id": 11,
      "value": "Iron",
      "viewValue": "Iron"
    },
    {
      "id": 12,
      "value": "Juab",
      "viewValue": "Juab"
    },
    {
      "id": 13,
      "value": "Kane",
      "viewValue": "Kane"
    },
    {
      "id": 14,
      "value": "Millard",
      "viewValue": "Millard"
    },
    {
      "id": 15,
      "value": "Morgan",
      "viewValue": "Morgan"
    },
    {
      "id": 16,
      "value": "Piute",
      "viewValue": "Piute"
    },
    {
      "id": 17,
      "value": "Rich",
      "viewValue": "Rich"
    },
    {
      "id": 18,
      "value": "Salt Lake",
      "viewValue": "Salt Lake"
    },
    {
      "id": 19,
      "value": "San Juan",
      "viewValue": "San Juan"
    },
    {
      "id": 20,
      "value": "Sanpete",
      "viewValue": "Sanpete"
    },
    {
      "id": 21,
      "value": "Sevier",
      "viewValue": "Sevier"
    },
    {
      "id": 22,
      "value": "Summit",
      "viewValue": "Summit"
    },
    {
      "id": 23,
      "value": "Tooele",
      "viewValue": "Tooele"
    },
    {
      "id": 24,
      "value": "Uintah",
      "viewValue": "Uintah"
    },
    {
      "id": 25,
      "value": "Utah",
      "viewValue": "Utah"
    }
  ],
  "VT": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Addison",
      "viewValue": "Addison"
    },
    {
      "id": 2,
      "value": "Bennington",
      "viewValue": "Bennington"
    },
    {
      "id": 3,
      "value": "Caledonia",
      "viewValue": "Caledonia"
    },
    {
      "id": 4,
      "value": "Chittenden",
      "viewValue": "Chittenden"
    },
    {
      "id": 5,
      "value": "Essex",
      "viewValue": "Essex"
    },
    {
      "id": 6,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 7,
      "value": "Grand Isle",
      "viewValue": "Grand Isle"
    },
    {
      "id": 8,
      "value": "Lamoille",
      "viewValue": "Lamoille"
    },
    {
      "id": 9,
      "value": "Orange",
      "viewValue": "Orange"
    },
    {
      "id": 10,
      "value": "Orleans",
      "viewValue": "Orleans"
    },
    {
      "id": 11,
      "value": "Rutland",
      "viewValue": "Rutland"
    },
    {
      "id": 12,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 13,
      "value": "Windham",
      "viewValue": "Windham"
    },
    {
      "id": 14,
      "value": "Windsor",
      "viewValue": "Windsor"
    }
  ],
  "VA": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Accomack",
      "viewValue": "Accomack"
    },
    {
      "id": 2,
      "value": "Albemarle",
      "viewValue": "Albemarle"
    },
    {
      "id": 3,
      "value": "Alexandria",
      "viewValue": "Alexandria"
    },
    {
      "id": 4,
      "value": "Alleghany",
      "viewValue": "Alleghany"
    },
    {
      "id": 5,
      "value": "Amelia",
      "viewValue": "Amelia"
    },
    {
      "id": 6,
      "value": "Amherst",
      "viewValue": "Amherst"
    },
    {
      "id": 7,
      "value": "Appomattox",
      "viewValue": "Appomattox"
    },
    {
      "id": 8,
      "value": "Arlington",
      "viewValue": "Arlington"
    },
    {
      "id": 9,
      "value": "Augusta",
      "viewValue": "Augusta"
    },
    {
      "id": 10,
      "value": "Bath",
      "viewValue": "Bath"
    },
    {
      "id": 11,
      "value": "Bedford",
      "viewValue": "Bedford"
    },
    {
      "id": 12,
      "value": "Bland",
      "viewValue": "Bland"
    },
    {
      "id": 13,
      "value": "Botetourt",
      "viewValue": "Botetourt"
    },
    {
      "id": 14,
      "value": "Bristol",
      "viewValue": "Bristol"
    },
    {
      "id": 15,
      "value": "Brunswick",
      "viewValue": "Brunswick"
    },
    {
      "id": 16,
      "value": "Buchanan",
      "viewValue": "Buchanan"
    },
    {
      "id": 17,
      "value": "Buckingham",
      "viewValue": "Buckingham"
    },
    {
      "id": 18,
      "value": "Buena Vista",
      "viewValue": "Buena Vista"
    },
    {
      "id": 19,
      "value": "Campbell",
      "viewValue": "Campbell"
    },
    {
      "id": 20,
      "value": "Caroline",
      "viewValue": "Caroline"
    },
    {
      "id": 21,
      "value": "Carroll",
      "viewValue": "Carroll"
    },
    {
      "id": 22,
      "value": "Charles City",
      "viewValue": "Charles City"
    },
    {
      "id": 23,
      "value": "Charlotte",
      "viewValue": "Charlotte"
    },
    {
      "id": 24,
      "value": "Chesapeake",
      "viewValue": "Chesapeake"
    },
    {
      "id": 25,
      "value": "Chesterfield",
      "viewValue": "Chesterfield"
    },
    {
      "id": 26,
      "value": "Clarke",
      "viewValue": "Clarke"
    },
    {
      "id": 27,
      "value": "Colonial Heights",
      "viewValue": "Colonial Heights"
    },
    {
      "id": 28,
      "value": "Craig",
      "viewValue": "Craig"
    },
    {
      "id": 29,
      "value": "Culpeper",
      "viewValue": "Culpeper"
    },
    {
      "id": 30,
      "value": "Cumberland",
      "viewValue": "Cumberland"
    },
    {
      "id": 31,
      "value": "Danville",
      "viewValue": "Danville"
    },
    {
      "id": 32,
      "value": "Dickenson",
      "viewValue": "Dickenson"
    },
    {
      "id": 33,
      "value": "Dinwiddie",
      "viewValue": "Dinwiddie"
    },
    {
      "id": 34,
      "value": "Essex",
      "viewValue": "Essex"
    },
    {
      "id": 35,
      "value": "Fairfax",
      "viewValue": "Fairfax"
    },
    {
      "id": 36,
      "value": "Fauquier",
      "viewValue": "Fauquier"
    },
    {
      "id": 37,
      "value": "Floyd",
      "viewValue": "Floyd"
    },
    {
      "id": 38,
      "value": "Fluvanna",
      "viewValue": "Fluvanna"
    },
    {
      "id": 39,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 40,
      "value": "Frederick",
      "viewValue": "Frederick"
    },
    {
      "id": 41,
      "value": "Galax",
      "viewValue": "Galax"
    },
    {
      "id": 42,
      "value": "Giles",
      "viewValue": "Giles"
    },
    {
      "id": 43,
      "value": "Gloucester",
      "viewValue": "Gloucester"
    },
    {
      "id": 44,
      "value": "Goochland",
      "viewValue": "Goochland"
    },
    {
      "id": 45,
      "value": "Grayson",
      "viewValue": "Grayson"
    },
    {
      "id": 46,
      "value": "Greene",
      "viewValue": "Greene"
    },
    {
      "id": 47,
      "value": "Greensville",
      "viewValue": "Greensville"
    },
    {
      "id": 48,
      "value": "Halifax",
      "viewValue": "Halifax"
    },
    {
      "id": 49,
      "value": "Hampton",
      "viewValue": "Hampton"
    },
    {
      "id": 50,
      "value": "Hanover",
      "viewValue": "Hanover"
    },
    {
      "id": 51,
      "value": "Harrisonburg",
      "viewValue": "Harrisonburg"
    },
    {
      "id": 52,
      "value": "Henrico",
      "viewValue": "Henrico"
    },
    {
      "id": 53,
      "value": "Henry",
      "viewValue": "Henry"
    },
    {
      "id": 54,
      "value": "Highland",
      "viewValue": "Highland"
    },
    {
      "id": 55,
      "value": "Hopewell",
      "viewValue": "Hopewell"
    },
    {
      "id": 56,
      "value": "Isle of Wight",
      "viewValue": "Isle of Wight"
    },
    {
      "id": 57,
      "value": "James City",
      "viewValue": "James City"
    },
    {
      "id": 58,
      "value": "King and Queen",
      "viewValue": "King and Queen"
    },
    {
      "id": 59,
      "value": "King George",
      "viewValue": "King George"
    },
    {
      "id": 60,
      "value": "King William",
      "viewValue": "King William"
    },
    {
      "id": 61,
      "value": "Lancaster",
      "viewValue": "Lancaster"
    },
    {
      "id": 62,
      "value": "Lee",
      "viewValue": "Lee"
    },
    {
      "id": 63,
      "value": "Loudoun",
      "viewValue": "Loudoun"
    },
    {
      "id": 64,
      "value": "Louisa",
      "viewValue": "Louisa"
    },
    {
      "id": 65,
      "value": "Lunenburg",
      "viewValue": "Lunenburg"
    },
    {
      "id": 66,
      "value": "Lynchburg",
      "viewValue": "Lynchburg"
    },
    {
      "id": 67,
      "value": "Madison",
      "viewValue": "Madison"
    },
    {
      "id": 68,
      "value": "Manassas Park",
      "viewValue": "Manassas Park"
    },
    {
      "id": 69,
      "value": "Mathews",
      "viewValue": "Mathews"
    },
    {
      "id": 70,
      "value": "Mecklenburg",
      "viewValue": "Mecklenburg"
    },
    {
      "id": 71,
      "value": "Middlesex",
      "viewValue": "Middlesex"
    },
    {
      "id": 72,
      "value": "Montgomery",
      "viewValue": "Montgomery"
    },
    {
      "id": 73,
      "value": "Nelson",
      "viewValue": "Nelson"
    },
    {
      "id": 74,
      "value": "New Kent",
      "viewValue": "New Kent"
    },
    {
      "id": 75,
      "value": "Newport News",
      "viewValue": "Newport News"
    },
    {
      "id": 76,
      "value": "Norfolk",
      "viewValue": "Norfolk"
    },
    {
      "id": 77,
      "value": "Northampton",
      "viewValue": "Northampton"
    },
    {
      "id": 78,
      "value": "Northumberland",
      "viewValue": "Northumberland"
    },
    {
      "id": 79,
      "value": "Nottoway",
      "viewValue": "Nottoway"
    },
    {
      "id": 80,
      "value": "Orange",
      "viewValue": "Orange"
    },
    {
      "id": 81,
      "value": "Page",
      "viewValue": "Page"
    },
    {
      "id": 82,
      "value": "Patrick",
      "viewValue": "Patrick"
    },
    {
      "id": 83,
      "value": "Petersburg",
      "viewValue": "Petersburg"
    },
    {
      "id": 84,
      "value": "Pittsylvania",
      "viewValue": "Pittsylvania"
    },
    {
      "id": 85,
      "value": "Poquoson",
      "viewValue": "Poquoson"
    },
    {
      "id": 86,
      "value": "Portsmouth",
      "viewValue": "Portsmouth"
    },
    {
      "id": 87,
      "value": "Powhatan",
      "viewValue": "Powhatan"
    },
    {
      "id": 88,
      "value": "Prince Edward",
      "viewValue": "Prince Edward"
    },
    {
      "id": 89,
      "value": "Prince George",
      "viewValue": "Prince George"
    },
    {
      "id": 90,
      "value": "Prince William",
      "viewValue": "Prince William"
    },
    {
      "id": 91,
      "value": "Pulaski",
      "viewValue": "Pulaski"
    },
    {
      "id": 92,
      "value": "Rappahannock",
      "viewValue": "Rappahannock"
    },
    {
      "id": 93,
      "value": "Richmond",
      "viewValue": "Richmond"
    },
    {
      "id": 94,
      "value": "Roanoke",
      "viewValue": "Roanoke"
    },
    {
      "id": 95,
      "value": "Rockbridge",
      "viewValue": "Rockbridge"
    },
    {
      "id": 96,
      "value": "Rockingham",
      "viewValue": "Rockingham"
    },
    {
      "id": 97,
      "value": "Russell",
      "viewValue": "Russell"
    },
    {
      "id": 98,
      "value": "Salem",
      "viewValue": "Salem"
    },
    {
      "id": 99,
      "value": "Scott",
      "viewValue": "Scott"
    },
    {
      "id": 100,
      "value": "Shenandoah",
      "viewValue": "Shenandoah"
    },
    {
      "id": 101,
      "value": "Smyth",
      "viewValue": "Smyth"
    },
    {
      "id": 102,
      "value": "Southampton",
      "viewValue": "Southampton"
    },
    {
      "id": 103,
      "value": "Spotsylvania",
      "viewValue": "Spotsylvania"
    },
    {
      "id": 104,
      "value": "Stafford",
      "viewValue": "Stafford"
    },
    {
      "id": 105,
      "value": "Staunton",
      "viewValue": "Staunton"
    },
    {
      "id": 106,
      "value": "Suffolk",
      "viewValue": "Suffolk"
    },
    {
      "id": 107,
      "value": "Surry",
      "viewValue": "Surry"
    },
    {
      "id": 108,
      "value": "Sussex",
      "viewValue": "Sussex"
    },
    {
      "id": 109,
      "value": "Tazewell",
      "viewValue": "Tazewell"
    },
    {
      "id": 110,
      "value": "Virginia Beach",
      "viewValue": "Virginia Beach"
    },
    {
      "id": 111,
      "value": "Warren",
      "viewValue": "Warren"
    },
    {
      "id": 112,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 113,
      "value": "Waynesboro",
      "viewValue": "Waynesboro"
    },
    {
      "id": 114,
      "value": "Westmoreland",
      "viewValue": "Westmoreland"
    },
    {
      "id": 115,
      "value": "Williamsburg",
      "viewValue": "Williamsburg"
    },
    {
      "id": 116,
      "value": "Wise",
      "viewValue": "Wise"
    },
    {
      "id": 117,
      "value": "Wythe",
      "viewValue": "Wythe"
    },
    {
      "id": 118,
      "value": "York",
      "viewValue": "York"
    },
    {
      "id": 119,
      "value": "Bedford",
      "viewValue": "Bedford"
    },
    {
      "id": 120,
      "value": "Charlottesville",
      "viewValue": "Charlottesville"
    },
    {
      "id": 121,
      "value": "Covington",
      "viewValue": "Covington"
    },
    {
      "id": 122,
      "value": "Emporia",
      "viewValue": "Emporia"
    },
    {
      "id": 123,
      "value": "Fairfax",
      "viewValue": "Fairfax"
    },
    {
      "id": 124,
      "value": "Falls Church",
      "viewValue": "Falls Church"
    },
    {
      "id": 125,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 126,
      "value": "Fredericksburg",
      "viewValue": "Fredericksburg"
    },
    {
      "id": 127,
      "value": "Lexington",
      "viewValue": "Lexington"
    },
    {
      "id": 128,
      "value": "Manassas",
      "viewValue": "Manassas"
    },
    {
      "id": 129,
      "value": "Martinsville",
      "viewValue": "Martinsville"
    },
    {
      "id": 130,
      "value": "Norton",
      "viewValue": "Norton"
    },
    {
      "id": 131,
      "value": "Radford",
      "viewValue": "Radford"
    },
    {
      "id": 132,
      "value": "Richmond",
      "viewValue": "Richmond"
    },
    {
      "id": 133,
      "value": "Roanoke",
      "viewValue": "Roanoke"
    },
    {
      "id": 134,
      "value": "Winchester",
      "viewValue": "Winchester"
    }
  ],
  "WA": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adams",
      "viewValue": "Adams"
    },
    {
      "id": 2,
      "value": "Asotin",
      "viewValue": "Asotin"
    },
    {
      "id": 3,
      "value": "Benton",
      "viewValue": "Benton"
    },
    {
      "id": 4,
      "value": "Chelan",
      "viewValue": "Chelan"
    },
    {
      "id": 5,
      "value": "Clallam",
      "viewValue": "Clallam"
    },
    {
      "id": 6,
      "value": "Clark",
      "viewValue": "Clark"
    },
    {
      "id": 7,
      "value": "Columbia",
      "viewValue": "Columbia"
    },
    {
      "id": 8,
      "value": "Cowlitz",
      "viewValue": "Cowlitz"
    },
    {
      "id": 9,
      "value": "Douglas",
      "viewValue": "Douglas"
    },
    {
      "id": 10,
      "value": "Ferry",
      "viewValue": "Ferry"
    },
    {
      "id": 11,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 12,
      "value": "Garfield",
      "viewValue": "Garfield"
    },
    {
      "id": 13,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 14,
      "value": "Grays Harbor",
      "viewValue": "Grays Harbor"
    },
    {
      "id": 15,
      "value": "Island",
      "viewValue": "Island"
    },
    {
      "id": 16,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 17,
      "value": "King",
      "viewValue": "King"
    },
    {
      "id": 18,
      "value": "Kitsap",
      "viewValue": "Kitsap"
    },
    {
      "id": 19,
      "value": "Kittitas",
      "viewValue": "Kittitas"
    },
    {
      "id": 20,
      "value": "Klickitat",
      "viewValue": "Klickitat"
    },
    {
      "id": 21,
      "value": "Lewis",
      "viewValue": "Lewis"
    },
    {
      "id": 22,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 23,
      "value": "Mason",
      "viewValue": "Mason"
    },
    {
      "id": 24,
      "value": "Okanogan",
      "viewValue": "Okanogan"
    },
    {
      "id": 25,
      "value": "Pacific",
      "viewValue": "Pacific"
    },
    {
      "id": 26,
      "value": "Pend Oreille",
      "viewValue": "Pend Oreille"
    },
    {
      "id": 27,
      "value": "Pierce",
      "viewValue": "Pierce"
    },
    {
      "id": 28,
      "value": "San Juan",
      "viewValue": "San Juan"
    },
    {
      "id": 29,
      "value": "Skagit",
      "viewValue": "Skagit"
    },
    {
      "id": 30,
      "value": "Skamania",
      "viewValue": "Skamania"
    },
    {
      "id": 311,
      "value": "Snohomish",
      "viewValue": "Snohomish"
    },
    {
      "id": 32,
      "value": "Spokane",
      "viewValue": "Spokane"
    },
    {
      "id": 33,
      "value": "Stevens",
      "viewValue": "Stevens"
    },
    {
      "id": 34,
      "value": "Thurston",
      "viewValue": "Thurston"
    },
    {
      "id": 35,
      "value": "Wahkiakum",
      "viewValue": "Wahkiakum"
    },
    {
      "id": 36,
      "value": "Walla Walla",
      "viewValue": "Walla Walla"
    },
    {
      "id": 37,
      "value": "Whatcom",
      "viewValue": "Whatcom"
    },
    {
      "id": 38,
      "value": "Whitman",
      "viewValue": "Whitman"
    },
    {
      "id": 39,
      "value": "Yakima",
      "viewValue": "Yakima"
    }
  ],
  "WV": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adams",
      "viewValue": "Adams"
    },
    {
      "id": 2,
      "value": "Asotin",
      "viewValue": "Asotin"
    },
    {
      "id": 3,
      "value": "Benton",
      "viewValue": "Benton"
    },
    {
      "id": 4,
      "value": "Chelan",
      "viewValue": "Chelan"
    },
    {
      "id": 5,
      "value": "Clallam",
      "viewValue": "Clallam"
    },
    {
      "id": 6,
      "value": "Clark",
      "viewValue": "Clark"
    },
    {
      "id": 7,
      "value": "Columbia",
      "viewValue": "Columbia"
    },
    {
      "id": 8,
      "value": "Cowlitz",
      "viewValue": "Cowlitz"
    },
    {
      "id": 9,
      "value": "Douglas",
      "viewValue": "Douglas"
    },
    {
      "id": 10,
      "value": "Ferry",
      "viewValue": "Ferry"
    },
    {
      "id": 11,
      "value": "Franklin",
      "viewValue": "Franklin"
    },
    {
      "id": 12,
      "value": "Garfield",
      "viewValue": "Garfield"
    },
    {
      "id": 13,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 14,
      "value": "Grays Harbor",
      "viewValue": "Grays Harbor"
    },
    {
      "id": 15,
      "value": "Island",
      "viewValue": "Island"
    },
    {
      "id": 16,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 17,
      "value": "King",
      "viewValue": "King"
    },
    {
      "id": 18,
      "value": "Kitsap",
      "viewValue": "Kitsap"
    },
    {
      "id": 19,
      "value": "Kittitas",
      "viewValue": "Kittitas"
    },
    {
      "id": 20,
      "value": "Klickitat",
      "viewValue": "Klickitat"
    },
    {
      "id": 21,
      "value": "Lewis",
      "viewValue": "Lewis"
    },
    {
      "id": 22,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 23,
      "value": "Mason",
      "viewValue": "Mason"
    },
    {
      "id": 24,
      "value": "Okanogan",
      "viewValue": "Okanogan"
    },
    {
      "id": 25,
      "value": "Pacific",
      "viewValue": "Pacific"
    },
    {
      "id": 26,
      "value": "Pend Oreille",
      "viewValue": "Pend Oreille"
    },
    {
      "id": 27,
      "value": "Pierce",
      "viewValue": "Pierce"
    },
    {
      "id": 28,
      "value": "San Juan",
      "viewValue": "San Juan"
    },
    {
      "id": 29,
      "value": "Skagit",
      "viewValue": "Skagit"
    },
    {
      "id": 30,
      "value": "Skamania",
      "viewValue": "Skamania"
    },
    {
      "id": 31,
      "value": "Snohomish",
      "viewValue": "Snohomish"
    },
    {
      "id": 32,
      "value": "Spokane",
      "viewValue": "Spokane"
    },
    {
      "id": 33,
      "value": "Stevens",
      "viewValue": "Stevens"
    },
    {
      "id": 34,
      "value": "Thurston",
      "viewValue": "Thurston"
    },
    {
      "id": 35,
      "value": "Wahkiakum",
      "viewValue": "Wahkiakum"
    },
    {
      "id": 36,
      "value": "Walla Walla",
      "viewValue": "Walla Walla"
    },
    {
      "id": 37,
      "value": "Whatcom",
      "viewValue": "Whatcom"
    },
    {
      "id": 38,
      "value": "Whitman",
      "viewValue": "Whitman"
    },
    {
      "id": 39,
      "value": "Yakima",
      "viewValue": "Yakima"
    }
  ],
  "WI": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Adams",
      "viewValue": "Adams"
    },
    {
      "id": 2,
      "value": "Ashland",
      "viewValue": "Ashland"
    },
    {
      "id": 3,
      "value": "Barron",
      "viewValue": "Barron"
    },
    {
      "id": 4,
      "value": "Bayfield",
      "viewValue": "Bayfield"
    },
    {
      "id": 5,
      "value": "Brown",
      "viewValue": "Brown"
    },
    {
      "id": 6,
      "value": "Buffalo",
      "viewValue": "Buffalo"
    },
    {
      "id": 7,
      "value": "Burnett",
      "viewValue": "Burnett"
    },
    {
      "id": 8,
      "value": "Calumet",
      "viewValue": "Calumet"
    },
    {
      "id": 9,
      "value": "Chippewa",
      "viewValue": "Chippewa"
    },
    {
      "id": 10,
      "value": "Clark",
      "viewValue": "Clark"
    },
    {
      "id": 11,
      "value": "Columbia",
      "viewValue": "Columbia"
    },
    {
      "id": 12,
      "value": "Crawford",
      "viewValue": "Crawford"
    },
    {
      "id": 13,
      "value": "Dane",
      "viewValue": "Dane"
    },
    {
      "id": 14,
      "value": "Dodge",
      "viewValue": "Dodge"
    },
    {
      "id": 15,
      "value": "Door",
      "viewValue": "Door"
    },
    {
      "id": 16,
      "value": "Douglas",
      "viewValue": "Douglas"
    },
    {
      "id": 17,
      "value": "Dunn",
      "viewValue": "Dunn"
    },
    {
      "id": 18,
      "value": "Eau Claire",
      "viewValue": "Eau Claire"
    },
    {
      "id": 19,
      "value": "Florence",
      "viewValue": "Florence"
    },
    {
      "id": 20,
      "value": "Fond du Lac",
      "viewValue": "Fond du Lac"
    },
    {
      "id": 21,
      "value": "Forest",
      "viewValue": "Forest"
    },
    {
      "id": 22,
      "value": "Grant",
      "viewValue": "Grant"
    },
    {
      "id": 23,
      "value": "Green",
      "viewValue": "Green"
    },
    {
      "id": 24,
      "value": "Green Lake",
      "viewValue": "Green Lake"
    },
    {
      "id": 25,
      "value": "Iowa",
      "viewValue": "Iowa"
    },
    {
      "id": 26,
      "value": "Iron",
      "viewValue": "Iron"
    },
    {
      "id": 27,
      "value": "Jackson",
      "viewValue": "Jackson"
    },
    {
      "id": 28,
      "value": "Jefferson",
      "viewValue": "Jefferson"
    },
    {
      "id": 29,
      "value": "Juneau",
      "viewValue": "Juneau"
    },
    {
      "id": 30,
      "value": "Kenosha",
      "viewValue": "Kenosha"
    },
    {
      "id": 31,
      "value": "Kewaunee",
      "viewValue": "Kewaunee"
    },
    {
      "id": 32,
      "value": "La Crosse",
      "viewValue": "La Crosse"
    },
    {
      "id": 33,
      "value": "Lafayette",
      "viewValue": "Lafayette"
    },
    {
      "id": 34,
      "value": "Langlade",
      "viewValue": "Langlade"
    },
    {
      "id": 35,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 36,
      "value": "Manitowoc",
      "viewValue": "Manitowoc"
    },
    {
      "id": 37,
      "value": "Marathon",
      "viewValue": "Marathon"
    },
    {
      "id": 38,
      "value": "Marinette",
      "viewValue": "Marinette"
    },
    {
      "id": 39,
      "value": "Marquette",
      "viewValue": "Marquette"
    },
    {
      "id": 40,
      "value": "Menominee",
      "viewValue": "Menominee"
    },
    {
      "id": 41,
      "value": "Milwaukee",
      "viewValue": "Milwaukee"
    },
    {
      "id": 42,
      "value": "Monroe",
      "viewValue": "Monroe"
    },
    {
      "id": 43,
      "value": "Oconto",
      "viewValue": "Oconto"
    },
    {
      "id": 44,
      "value": "Oneida",
      "viewValue": "Oneida"
    },
    {
      "id": 45,
      "value": "Outagamie",
      "viewValue": "Outagamie"
    },
    {
      "id": 46,
      "value": "Ozaukee",
      "viewValue": "Ozaukee"
    },
    {
      "id": 47,
      "value": "Pepin",
      "viewValue": "Pepin"
    },
    {
      "id": 48,
      "value": "Pierce",
      "viewValue": "Pierce"
    },
    {
      "id": 49,
      "value": "Polk",
      "viewValue": "Polk"
    },
    {
      "id": 50,
      "value": "Portage",
      "viewValue": "Portage"
    },
    {
      "id": 51,
      "value": "Price",
      "viewValue": "Price"
    },
    {
      "id": 52,
      "value": "Racine",
      "viewValue": "Racine"
    },
    {
      "id": 53,
      "value": "Richland",
      "viewValue": "Richland"
    },
    {
      "id": 54,
      "value": "Rock",
      "viewValue": "Rock"
    },
    {
      "id": 55,
      "value": "Rusk",
      "viewValue": "Rusk"
    },
    {
      "id": 56,
      "value": "St. Croix",
      "viewValue": "St. Croix"
    },
    {
      "id": 57,
      "value": "Sauk",
      "viewValue": "Sauk"
    },
    {
      "id": 58,
      "value": "Sawyer",
      "viewValue": "Sawyer"
    },
    {
      "id": 59,
      "value": "Shawano",
      "viewValue": "Shawano"
    },
    {
      "id": 60,
      "value": "Sheboygan",
      "viewValue": "Sheboygan"
    },
    {
      "id": 61,
      "value": "Taylor",
      "viewValue": "Taylor"
    },
    {
      "id": 62,
      "value": "Trempealeau",
      "viewValue": "Trempealeau"
    },
    {
      "id": 63,
      "value": "Vernon",
      "viewValue": "Vernon"
    },
    {
      "id": 64,
      "value": "Vilas",
      "viewValue": "Vilas"
    },
    {
      "id": 65,
      "value": "Walworth",
      "viewValue": "Walworth"
    },
    {
      "id": 66,
      "value": "Washburn",
      "viewValue": "Washburn"
    },
    {
      "id": 67,
      "value": "Washington",
      "viewValue": "Washington"
    },
    {
      "id": 68,
      "value": "Waukesha",
      "viewValue": "Waukesha"
    },
    {
      "id": 69,
      "value": "Waupaca",
      "viewValue": "Waupaca"
    },
    {
      "id": 70,
      "value": "Waushara",
      "viewValue": "Waushara"
    },
    {
      "id": 71,
      "value": "Winnebago",
      "viewValue": "Winnebago"
    },
    {
      "id": 72,
      "value": "Wood",
      "viewValue": "Wood"
    }
  ],
  "WY": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Albany",
      "viewValue": "Albany"
    },
    {
      "id": 2,
      "value": "Big Horn",
      "viewValue": "Big Horn"
    },
    {
      "id": 3,
      "value": "Campbell",
      "viewValue": "Campbell"
    },
    {
      "id": 4,
      "value": "Carbon",
      "viewValue": "Carbon"
    },
    {
      "id": 5,
      "value": "Converse",
      "viewValue": "Converse"
    },
    {
      "id": 6,
      "value": "Crook",
      "viewValue": "Crook"
    },
    {
      "id": 7,
      "value": "Fremont",
      "viewValue": "Fremont"
    },
    {
      "id": 8,
      "value": "Goshen",
      "viewValue": "Goshen"
    },
    {
      "id": 9,
      "value": "Hot Springs",
      "viewValue": "Hot Springs"
    },
    {
      "id": 10,
      "value": "Johnson",
      "viewValue": "Johnson"
    },
    {
      "id": 11,
      "value": "Laramie",
      "viewValue": "Laramie"
    },
    {
      "id": 12,
      "value": "Lincoln",
      "viewValue": "Lincoln"
    },
    {
      "id": 13,
      "value": "Natrona",
      "viewValue": "Natrona"
    },
    {
      "id": 14,
      "value": "Niobrara",
      "viewValue": "Niobrara"
    },
    {
      "id": 15,
      "value": "Park",
      "viewValue": "Park"
    },
    {
      "id": 16,
      "value": "Platte",
      "viewValue": "Platte"
    },
    {
      "id": 17,
      "value": "Sheridan",
      "viewValue": "Sheridan"
    },
    {
      "id": 18,
      "value": "Sublette",
      "viewValue": "Sublette"
    },
    {
      "id": 19,
      "value": "Sweetwater",
      "viewValue": "Sweetwater"
    },
    {
      "id": 20,
      "value": "Teton",
      "viewValue": "Teton"
    },
    {
      "id": 21,
      "value": "Uinta",
      "viewValue": "Uinta"
    },
    {
      "id": 22,
      "value": "Washakie",
      "viewValue": "Washakie"
    },
    {
      "id": 23,
      "value": "Weston",
      "viewValue": "Weston"
    }
  ],
  "DC": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Washington",
      "viewValue": "Washington"
    }
  ],
  "VI": [
    {
      "id": 0,
      "value": 'Unknown',
      "viewValue": "Unknown"
    },
    {
      "id": 1,
      "value": "Charlotte Amalie",
      "viewValue": "Charlotte Amalie"
    },
    {
      "id": 2,
      "value": "East End",
      "viewValue": "East End"
    },
    {
      "id": 3,
      "value": "Northside",
      "viewValue": "Northside"
    },
    {
      "id": 4,
      "value": "Southside",
      "viewValue": "Southside"
    },
    {
      "id": 5,
      "value": "Tutu",
      "viewValue": "Tutu"
    },
    {
      "id": 6,
      "value": "Water Island",
      "viewValue": "Water Island"
    },
    {
      "id": 7,
      "value": "West End",
      "viewValue": "West End"
    },
    {
      "id": 8,
      "value": "Central",
      "viewValue": "Central"
    },
    {
      "id": 9,
      "value": "Coral Bay",
      "viewValue": "Coral Bay"
    },
    {
      "id": 10,
      "value": "Cruz Bay",
      "viewValue": "Cruz Bay"
    },
    {
      "id": 11,
      "value": "East End",
      "viewValue": "East End"
    },
    {
      "id": 12,
      "value": "Anna's Hope Village",
      "viewValue": "Anna's Hope Village"
    },
    {
      "id": 13,
      "value": "Christiansted",
      "viewValue": "Christiansted"
    },
    {
      "id": 14,
      "value": "East End",
      "viewValue": "East End"
    },
    {
      "id": 15,
      "value": "Frederiksted",
      "viewValue": "Frederiksted"
    },
    {
      "id": 16,
      "value": "Northcentral",
      "viewValue": "Northcentral"
    },
    {
      "id": 17,
      "value": "Northwest",
      "viewValue": "Northwest"
    },
    {
      "id": 18,
      "value": "Sion Farm",
      "viewValue": "Sion Farm"
    },
    {
      "id": 19,
      "value": "Southcentral",
      "viewValue": "Southcentral"
    },
    {
      "id": 20,
      "value": "Southwest",
      "viewValue": "Southwest"
    }
  ]
};

export const ORDER_STATUSES_SELECT_ITEMS: Array<SelectItem> = [
  {
    id: 0,
    value: ORDER_STATUSES.NEW,
    viewValue: ORDER_STATUSES.NEW
  },
  {
    id: 1,
    value: ORDER_STATUSES.ASSIGNED,
    viewValue: ORDER_STATUSES.ASSIGNED
  },
  {
    id: 2,
    value: ORDER_STATUSES.RESEARCH,
    viewValue: ORDER_STATUSES.RESEARCH
  },
  {
    id: 3,
    value: ORDER_STATUSES.TO_BE_BUILT,
    viewValue: ORDER_STATUSES.TO_BE_BUILT
  },
  {
    id: 4,
    value: ORDER_STATUSES.REVIEW,
    viewValue: ORDER_STATUSES.REVIEW
  },
  {
    id: 5,
    value: ORDER_STATUSES.SENT_TO_CLIENT,
    viewValue: ORDER_STATUSES.SENT_TO_CLIENT
  },
  {
    id: 6,
    value: ORDER_STATUSES.HOLD,
    viewValue: ORDER_STATUSES.HOLD
  },
  {
    id: 7,
    value: ORDER_STATUSES.CANCELLATION_PENDING,
    viewValue: ORDER_STATUSES.CANCELLATION_PENDING
  },
  {
    id: 8,
    value: ORDER_STATUSES.CANCELLED,
    viewValue: ORDER_STATUSES.CANCELLED
  },
  {
    id: 9,
    value: ORDER_STATUSES.CANCELLED_HARD_COST_PAYABLE,
    viewValue: ORDER_STATUSES.CANCELLED_HARD_COST_PAYABLE
  },
  {
    id: 10,
    value: ORDER_STATUSES.CANCELLED_ESTOPPEL_REFUND_NEEDED,
    viewValue: ORDER_STATUSES.CANCELLED_ESTOPPEL_REFUND_NEEDED
  },
  {
    id: 11,
    value: ORDER_STATUSES.ACCOUNTING_CANCELLED,
    viewValue: ORDER_STATUSES.ACCOUNTING_CANCELLED
  },
  {
    id: 12,
    value: ORDER_STATUSES.ACCOUNTING_CANCELLED_HARD_COST_PAYABLE,
    viewValue: ORDER_STATUSES.ACCOUNTING_CANCELLED_HARD_COST_PAYABLE
  },
  {
    id: 13,
    value: ORDER_STATUSES.PENDING_ESTOPPEL,
    viewValue: ORDER_STATUSES.PENDING_ESTOPPEL
  },
  {
    id: 14,
    value: ORDER_STATUSES.INACTIVE,
    viewValue: ORDER_STATUSES.INACTIVE
  },
  {
    id: 15,
    value: ORDER_STATUSES.COMPLETED,
    viewValue: ORDER_STATUSES.COMPLETED
  },
  {
    id: 16,
    value: ORDER_STATUSES.UPDATED,
    viewValue: ORDER_STATUSES.UPDATED
  }
];

export const ORDER_ISSUE_STATUSES: Array<SelectItem> = [
  {
    id: 0,
    value: 'Complete',
    viewValue: 'Complete'
  },
  {
    id: 1,
    value: 'Cancelled',
    viewValue: 'Cancelled'
  },
  {
    id: 2,
    value: 'Rejected',
    viewValue: 'Rejected'
  }
];

export const ORDER_FILE_TYPES: Array<SelectItem> = [
  {
    id: 0,
    value: 'external',
    viewValue: 'External'
  },
  {
    id: 1,
    value: 'internal',
    viewValue: 'Internal'
  }
];

export const PAYMENT_VISIBILITY_TYPES: Array<SelectItem> = [
  {
    id: 0,
    value: 0,
    viewValue: 'Employees and Customers'
  },
  {
    id: 1,
    value: 1,
    viewValue: 'Employees'
  }
];

export const ACTIVE_INACTIVE: Array<SelectItem> = [
  {
    id: 0,
    value: '0',
    viewValue: 'Inactive'
  },
  {
    id: 1,
    value: '1',
    viewValue: 'Active'
  }
];

export const DISCOUNT_NUMBERS: Array<SelectItem> = [
  {
    id: 0,
    value: 1,
    viewValue: 'U1'
  },
  {
    id: 1,
    value: 2,
    viewValue: 'U2'
  },
  {
    id: 2,
    value: 3,
    viewValue: 'U3'
  },
  {
    id: 3,
    value: 4,
    viewValue: 'U4'
  },
  {
    id: 4,
    value: 5,
    viewValue: 'U5'
  },
  {
    id: 5,
    value: 6,
    viewValue: 'U6'
  },
  {
    id: 6,
    value: 7,
    viewValue: 'U7'
  },
  {
    id: 7,
    value: 8,
    viewValue: 'U8'
  },
  {
    id: 8,
    value: 9,
    viewValue: 'U9'
  },
  {
    id: 9,
    value: 10,
    viewValue: 'U10'
  }
];

export const YES_NO: Array<SelectItem> = [
  {
    id: 0,
    value: '0',
    viewValue: 'No'
  },
  {
    id: 1,
    value: '1',
    viewValue: 'Yes'
  }
];

export const ROLES: Array<SelectItem> = [
  {
    id: 0,
    value: 'admin',
    viewValue: 'Admin'
  },
  {
    id: 1,
    value: 'manager',
    viewValue: 'Manager'
  },
  {
    id: 2,
    value: 'marketer',
    viewValue: 'Marketer'
  },
  {
    id: 3,
    value: 'accountant',
    viewValue: 'Accountant'
  },
  {
    id: 4,
    value: 'lead',
    viewValue: 'Lead'
  },
  {
    id: 5,
    value: 'researcher',
    viewValue: 'Researcher'
  },
  {
    id: 6,
    value: 'qc',
    viewValue: 'Qc'
  }
];

export const RECIPIENTS: Array<SelectItem> = [
  {
    id: 0,
    value: 'ignore',
    viewValue: 'Ignore'
  },
  {
    id: 1,
    value: 'from',
    viewValue: 'From'
  },
  {
    id: 2,
    value: 'to',
    viewValue: 'To'
  },
  {
    id: 3,
    value: 'cc',
    viewValue: 'Cc'
  },
  {
    id: 4,
    value: 'bcc',
    viewValue: 'bcc'
  }
];

export const PAYMENT_TYPES: Array<SelectItem> = [
  {
    id: 0,
    value: 'Check',
    viewValue: 'Check'
  },
  {
    id: 1,
    value: 'Credit Card',
    viewValue: 'Credit Card'
  },
  {
    id: 2,
    value: 'Cash',
    viewValue: 'Cash'
  }
];

export const PAGINATION_PAGE_SIZE: Array<SelectItem> = [
  {
    id: 0,
    value: 10,
    viewValue: '10'
  },
  {
    id: 1,
    value: 25,
    viewValue: '25'
  },
  {
    id: 2,
    value: 50,
    viewValue: '50'
  },
  {
    id: 3,
    value: 100,
    viewValue: '100'
  },
  {
    id: 4,
    value: 200,
    viewValue: '200'
  },
  {
    id: 5,
    value: 1000000,
    viewValue: 'All'
  }
];
