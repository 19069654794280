import { Pipe, PipeTransform } from '@angular/core';

import { AuthService } from '@services/auth.service';
import { ROLE_MATRIX } from '@configs/role-matrix';
import { MenuItem } from '@models/menu-item';

@Pipe({
  name: 'authHeaderMenu'
})
export class AuthHeaderMenuPipe implements PipeTransform {
  constructor(private service: AuthService) { }

  public transform(items: Array<MenuItem>): Array<MenuItem> {
    const role: string = this.service.role;
    return items.filter((item: MenuItem): boolean => ROLE_MATRIX.headerMenu[item.value].includes(role));
  }
}
