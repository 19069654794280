import { Component, Injector } from '@angular/core';

import { ICellRendererParams } from 'ag-grid-enterprise';

import {
  DropdownRendererComponent
} from '@components/table/cell-renderers/dropdown-renderer/dropdown-renderer.component';
import { AuthService } from '@services/auth.service';
import {
  BATCH_TABLE_ACTIONS,
  COMPANY_PRICING_TABLE_ACTIONS,
  DEFAULT_TABLE_ACTIONS,
  FLAG_EVENT_TABLE_ACTIONS,
  ORDER_FILE_TABLE_ACTIONS,
  ORDER_NOTE_TABLE_ACTIONS,
  ORDER_PAYMENT_TABLE_ACTIONS,
  ORDER_TABLE_ACTIONS,
  ORDER_TASK_TABLE_ACTIONS,
  TABLE_TYPES
} from '@configs/table';
import { ORDER_STATUSES } from '@configs/order';
import { MenuItem } from '@models/menu-item';
import { Order } from '@models/order';
import { File } from '@models/file';
import { Task } from '@models/task';
import { OrderNote } from '@models/note';
import { BatchPayment, Payment } from '@models/payment';
import { Pricing } from '@models/pricing';
import { ActionRendererParams } from '@models/renderer-parameters';

@Component({
  selector: 'app-action-renderer',
  templateUrl: './action-renderer.component.html'
})
export class ActionRendererComponent extends DropdownRendererComponent {
  protected authService: AuthService;

  private customParams!: ActionRendererParams;

  constructor(injector: Injector) {
    super(injector);

    this.authService = injector.get(AuthService);
  }

  public override agInit(params: ICellRendererParams): void {
    super.agInit(params);
    const data: Order | File | OrderNote | Task | Payment | Pricing | BatchPayment = params.data,
          value: string = params.value;

    this.customParams = params['customParams'];

    switch (this.customParams.type) {
      case 'order':
        this.data = {
          ...ORDER_TABLE_ACTIONS,
          children: ORDER_TABLE_ACTIONS.children.filter((item: MenuItem): boolean =>
            !(item.action === 'cancel' && (data as Order).status === ORDER_STATUSES.CANCELLATION_PENDING)
          )
        };
        break;
      case 'file':
        this.data = this.authService.filterTableActions(TABLE_TYPES.FILE, {
          ...ORDER_FILE_TABLE_ACTIONS,
          children: ORDER_FILE_TABLE_ACTIONS.children.filter((item: MenuItem): boolean =>
            this.fileFilter(item.action, value === '1')
          )
        });
        break;
      case 'task':
        this.data = ORDER_TASK_TABLE_ACTIONS[(<Task>data).stage?.toLowerCase()] || ORDER_TASK_TABLE_ACTIONS['new'];
        break;
      case 'note':
        this.data = this.authService.filterTableActions(TABLE_TYPES.NOTE, {
          ...ORDER_NOTE_TABLE_ACTIONS,
          children: ORDER_NOTE_TABLE_ACTIONS.children.filter((item: MenuItem): boolean =>
            this.noteFilter(item.action, value === '1')
          )
        });
        break;
      case 'order-payment':
        this.data = this.authService.filterTableActions(TABLE_TYPES.ORDER_PAYMENT, {
          ...ORDER_PAYMENT_TABLE_ACTIONS,
          children: ORDER_PAYMENT_TABLE_ACTIONS.children.filter((item: MenuItem): boolean =>
            this.paymentFilter(item.action, <Payment>data)
          )
        });
        break;
      case 'pricing':
        this.data = {
          ...COMPANY_PRICING_TABLE_ACTIONS,
          children: COMPANY_PRICING_TABLE_ACTIONS.children.filter((): boolean => (<Pricing>data).active !== '0')
        };
        break;
      case 'flag':
        this.data = FLAG_EVENT_TABLE_ACTIONS;
        break;
      case 'batch':
        this.data = {
          ...BATCH_TABLE_ACTIONS,
          children: BATCH_TABLE_ACTIONS.children.filter((item: MenuItem): boolean =>
            !this.canDeleteBatch(item.action, <BatchPayment>data)
          )
        };
        break;
      default:
        this.data = DEFAULT_TABLE_ACTIONS;
    }
  }

  private noteFilter(action: string, isDeleted: boolean): boolean {
    return !((action === 'restore-note' && !isDeleted) || (action === 'delete-note' && isDeleted));
  }

  private fileFilter(action: string, isDeleted: boolean): boolean {
    return !((action === 'restore-file' && !isDeleted) || (action === 'delete-file' && isDeleted));
  }

  private paymentFilter(action: string, payment: Payment): boolean {
    return !(
      (action === 'refund-payment' && !this.canRefund(payment))
      || (action === 'transfer-payment' && !this.canTransfer(payment))
      || (action === 'return-transfer-payment' && !this.canReturnTransfer(payment))
    );
  }

  private canRefund(payment: Payment): boolean {
    const {is_transfer, amount, available_balance} = payment;
    return !is_transfer && Number(amount) > 0 && available_balance > 0;
  }

  private canTransfer(payment: Payment): boolean {
    const {is_transfer, amount, available_balance} = payment;
    return !is_transfer && Number(amount) > 0 && available_balance > 0;
  }

  private canReturnTransfer(payment: Payment): boolean {
    const {is_transfer, amount, available_balance} = payment;
    return !!is_transfer && Number(amount) > 0 && available_balance > 0;
  }

  // TODO: the name or logical operators should be vice versa (cannotDeleteBatch)
  private canDeleteBatch(action: string, batch: BatchPayment): boolean {
    return (action === 'delete-batch' || action === 'delete-batch-with-reset-search-fee')
      && (batch.refund_count > 0
      || !!batch.original_transferred_payment
      || !!batch.transferred_from
      || !!batch.transferred_to);
  }
}
