import { NgModule, SkipSelf, Optional } from '@angular/core';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AuthInterceptor } from '@interceptors/auth.interceptor';
import { HandlersInterceptor } from '@interceptors/handlers.interceptor';
import { FlagIconPipe } from '@pipes/flag-icon.pipe';
import { FlagClassPipe } from '@pipes/flag-class.pipe';
import { FlagDisabledPipe } from '@pipes/flag-disabled.pipe';
import { OrderViewValuePipe } from '@pipes/order-view-value.pipe';
import { FlagTooltipPipe } from '@pipes/flag-tooltip.pipe';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  imports: [
    HttpClientModule,
    SharedModule
  ],
  providers: [
    FlagIconPipe,
    FlagClassPipe,
    FlagDisabledPipe,
    FlagTooltipPipe,
    OrderViewValuePipe,
    CurrencyPipe,
    PercentPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandlersInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
