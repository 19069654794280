import { Component, Injector, ViewChild } from '@angular/core';

import { MatSelectChange } from '@angular/material/select';

import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-enterprise';
import { RowNode } from 'ag-grid-community';

import { SelectComponent } from '@components/select/select.component';
import { TableService } from '@services/table.service';
import { SelectItem } from '@models/select';
import { SelectEditorParams } from '@models/editor-parameters';

@Component({
  selector: 'app-select-editor',
  templateUrl: './select-editor.component.html'
})
export class SelectEditorComponent implements ICellEditorAngularComp {
  @ViewChild(SelectComponent) selectComp!: SelectComponent;

  public params!: ICellEditorParams;
  public label: string = 'Choose an option';
  public ariaLabel: string = 'Select';
  public items: Array<SelectItem> = [];
  public selected: SelectItem | null = null;
  public classList: string = 'eos-table-select-editor-container ';
  public panelClassList: string = '';

  protected service: TableService;

  private injector: Injector;
  private customParams!: SelectEditorParams;

  constructor(injector: Injector) {
    this.injector = injector;

    this.service = injector.get(TableService);
  }

  public onSelectionChanged(event: MatSelectChange): void {
    const node: RowNode | undefined = this.params?.node;

    this.service.rendererAction$.next({
      type: this.customParams.eventType,
      item: event.value,
      rowData: node?.data,
      node
    });

    this.params.api.stopEditing();
  }

  public onOpenedChanged(isOpened: boolean): void {
    if (isOpened) { return; }
    this.params.api.stopEditing();
  }

  public agInit(params: ICellEditorParams): void {
    const {customParams, value} = <any>params;

    this.params = params;
    this.customParams = customParams;

    const {
      items = this.items,
      label = this.label,
      ariaLabel = this.ariaLabel,
      classList = '',
      panelClassList = ''
    } = customParams;

    this.label = label;
    this.ariaLabel = ariaLabel;
    this.items = items;
    this.selected = value ? items[Number(value)] : null;
    this.classList += classList;
    this.panelClassList += panelClassList;
  }

  public getValue(): SelectItem | string | null {
    return <string>this.selected?.viewValue;
  }

  public isCancelBeforeStart(): boolean {
    this.selectComp.open();
    return false;
  }

  public isCancelAfterEnd(): boolean {
    return false;
  }
}
