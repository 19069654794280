export enum ORDER_STATUSES {
  NEW = 'New',
  ASSIGNED = 'Assigned',
  RESEARCH = 'Research',
  TO_BE_BUILT = 'To Be Built',
  REVIEW = 'Review',
  SENT_TO_CLIENT = 'Sent to Client',
  HOLD = 'Hold',
  CANCELLATION_PENDING = 'Cancellation Pending',
  CANCELLED = 'Cancelled',
  CANCELLED_HARD_COST_PAYABLE = 'Cancelled - Hard cost payable',
  CANCELLED_ESTOPPEL_REFUND_NEEDED = 'Cancelled - Estoppel Refund Needed',
  ACCOUNTING_CANCELLED = 'Accounting Cancelled',
  ACCOUNTING_CANCELLED_HARD_COST_PAYABLE = 'Accounting Cancelled - Hard Costs Payable',
  PENDING_ESTOPPEL = 'Pending Estoppel',
  INACTIVE = 'Inactive',
  COMPLETED = 'Completed',
  UPDATED = 'Updated'
}

export const ORDER_STATUS_CLASSES: { [key: string]: string } = {
  [ORDER_STATUSES.NEW]: 'success',
  [ORDER_STATUSES.ASSIGNED]: 'warning',
  [ORDER_STATUSES.RESEARCH]: 'warning',
  [ORDER_STATUSES.TO_BE_BUILT]: 'default',
  [ORDER_STATUSES.REVIEW]: 'default',
  [ORDER_STATUSES.SENT_TO_CLIENT]: 'default',
  [ORDER_STATUSES.HOLD]: 'default',
  [ORDER_STATUSES.CANCELLATION_PENDING]: 'danger',
  [ORDER_STATUSES.CANCELLED]: 'danger',
  [ORDER_STATUSES.CANCELLED_HARD_COST_PAYABLE]: 'danger',
  [ORDER_STATUSES.CANCELLED_ESTOPPEL_REFUND_NEEDED]: 'danger',
  [ORDER_STATUSES.ACCOUNTING_CANCELLED]: 'danger',
  [ORDER_STATUSES.ACCOUNTING_CANCELLED_HARD_COST_PAYABLE]: 'danger',
  [ORDER_STATUSES.PENDING_ESTOPPEL]: 'default',
  [ORDER_STATUSES.INACTIVE]: 'danger',
  [ORDER_STATUSES.COMPLETED]: 'info',
  [ORDER_STATUSES.UPDATED]: 'default'
};

export const ORDER_PAYMENT_STATUS_CLASSES: { [key: string]: string } = {
  'Paid in Full': 'success',
  'Partially Paid': 'warning',
  'Unpaid': 'danger'
};

export const ORDER_ISSUE_STATUS_CLASSES: { [key: string]: string } = {
  'Pending Review': 'default',
  'Complete': 'success',
  'Assigned': 'info',
  'Pending Cancel': 'warning',
  'Rejected': 'danger',
  'Cancelled': 'danger',
  'Deleted': 'danger'
};
