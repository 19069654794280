import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { ComponentType } from '@angular/cdk/portal';

import { NoteDialogComponent } from '@components/dialog/note-dialog/note-dialog.component';
import { ConfirmationDialogComponent } from '@components/dialog/confirmation-dialog/confirmation-dialog.component';
import { CancelDialogComponent } from '../../order/dialogs/cancel-dialog/cancel-dialog.component';
import { DIALOG_CONFIG } from '@configs/dialog';
import { RawNoteFormValue, UpdateNoteEvent } from '@models/note';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  public createNote$: Subject<RawNoteFormValue> = new Subject<RawNoteFormValue>();
  public updateNote$: Subject<UpdateNoteEvent> = new Subject<UpdateNoteEvent>();

  constructor(private service: MatDialog) { }

  public openDialog<T, D = unknown, R = unknown>(
    component: ComponentType<T>,
    config: MatDialogConfig<D>
  ): MatDialogRef<T, R> {
    return this.service.open(component, config);
  }

  public closeAllDialogs(): void {
    this.service.closeAll();
  }

  public openNoteDialog(note: string = '', id: string = 'eos-note-dialog'): MatDialogRef<NoteDialogComponent> {
    return this.openDialog(
      NoteDialogComponent,
      {
        ...DIALOG_CONFIG,
        id,
        data: {note}
      }
    );
  }

  public openConfirmationDialog(asset: string, needDelete: boolean): MatDialogRef<ConfirmationDialogComponent> {
    return this.openDialog(
      ConfirmationDialogComponent,
      {
        ...DIALOG_CONFIG,
        id: 'eos-confirm-dialog',
        data: {
          header: needDelete ? `Delete ${asset}` : `Restore ${asset}`,
          title: needDelete
            ? `Would you like to mark the ${asset} as deleted?`
            : `Would you like to restore the ${asset}?`,
          message: needDelete
            ? `The ${asset} will be hidden from everyone unless you are EPR Admin or EPR Manager...`
            : `The ${asset} will be restored...`
        }
      }
    );
  }

  public openConfirmationUnitedTaxOrderUpdate(): MatDialogRef<ConfirmationDialogComponent> {
    return this.openDialog(
      ConfirmationDialogComponent,
      {
        ...DIALOG_CONFIG,
        id: 'eos-confirm-dialog',
        data: {
          header: 'Update Order',
          title: 'Update United Tax Order',
          message: 'Please, make sure that PDF report was attached and marked as External. Only after that move order' +
            ' to “Sent to client” status.',
          cancelBtnLabel: 'Cancel',
          actionBtnLabel: 'Move'
        }
      }
    );
  }

  public openCancelDialog(): MatDialogRef<CancelDialogComponent> {
    return this.openDialog(
      CancelDialogComponent,
      {
        ...DIALOG_CONFIG,
        id: 'eos-cancel-dialog'
      }
    );
  }
}
