import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { SpinnerService } from '@services/spinner.service';
import { QueryParams } from '@models/api';

@Injectable({
  providedIn: 'root'
})
export class BaseApi<EndPoints> {
  public endPoints: EndPoints;

  protected globalPath: string;
  protected versionPath: string;

  protected http: HttpClient;
  protected authService: AuthService;
  protected spinnerService: SpinnerService;

  private injector: Injector;

  constructor(injector: Injector) {
    this.injector = injector;

    this.globalPath = environment.globalPath;
    this.versionPath = environment.versionPath;

    this.http = this.injector.get(HttpClient);
    this.authService = this.injector.get(AuthService);
    this.spinnerService = this.injector.get(SpinnerService);
  }

  public substituteParameters(url: string, parameters: { [key: string]: string | number }): string {
    for (const parameter in parameters) {
      if (parameters.hasOwnProperty(parameter)) {

        if (url.indexOf('${' + parameter + '}') > -1) {
          url = url.replace('${' + parameter + '}', encodeURIComponent(parameters[parameter]));
          delete parameters[parameter];
        }

      }
    }

    return url;
  }

  protected addBaseUrl(endpoints: EndPoints): EndPoints {
    const res: Partial<EndPoints> = { };

    for (const name in endpoints) {
      if (endpoints.hasOwnProperty(name)) {
        res[name] = <any>`${this.globalPath}/${this.versionPath}${endpoints[name]}`;
      }
    }

    return <EndPoints>res;
  }

  protected setQueryParameters(parameters: QueryParams = { }): HttpParams {
    let httpParameters: HttpParams = new HttpParams(),
        value: unknown;

    for (const parameter in parameters) {
      value = parameters[parameter];

      if (parameters.hasOwnProperty(parameter) && (value || value === false)) {
        httpParameters = httpParameters.set(
          parameter,
          parameter === 'sortModel' && (<Array<unknown>>value).length ? JSON.stringify(value) : parameters[parameter]
        );
      }
    }

    return httpParameters;
  }

  protected prepareFormDataBody(formValue: Object | null, files: Array<File> = []): FormData {
    const formData: FormData = new FormData();
    let value: any;

    formValue ??= { };

    files.forEach((file: File, index: number) => formData.set(`files[${index}]`, file));

    for (const name in formValue) {
      if (formValue.hasOwnProperty(name)) {
        value = formValue[name];

        formData.set(name, this.isObject(value) ? JSON.stringify(value) : value);
      }
    }

    return formData;
  }

  private isObject(value: unknown): boolean {
    return typeof value === 'object' && !Array.isArray(value) && value !== null && !(value instanceof File);
  }
}
