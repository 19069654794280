import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseApi } from '@api/base.api';
import { NotificationApiEndPoints, QueryParams } from '@models/api';
import { Payload } from '@models/response';
import { Notification, NotificationAuthor, NotificationEvent, NotificationEventFormValue } from '@models/notification';
import { TableData } from '@models/table';

@Injectable({
  providedIn: 'root'
})
export class NotificationApi extends BaseApi<NotificationApiEndPoints> {
  constructor(injector: Injector) {
    super(injector);

    this.endPoints = this.addBaseUrl({
      GET_NOTIFICATIONS: '/notification/list',
      GET_NOTIFICATION_AUTHORS: '/notification/get-users',
      GET_EVENT_BY_EMAIL_TEMPLATE_ID: '/events/${templateId}',
      GET_EVENTS: '/events',
      PUT_UPDATE_EVENT_BY_EMAIL_TEMPLATE_ID: '/event/${templateId}',
    });
  }

  /**
   * @param id For support get order notification method
   */
  public getNotifications(id: string, params?: QueryParams): Observable<TableData<Notification>> {
    return this.http.get<TableData<Notification>>(
      this.endPoints.GET_NOTIFICATIONS,
      {params: this.setQueryParameters(params)}
    );
  }

  public getNotificationAuthors(params?: QueryParams): Observable<TableData<NotificationAuthor>> {
    return this.http.get<TableData<NotificationAuthor>>(
      this.endPoints.GET_NOTIFICATION_AUTHORS,
      {params: this.setQueryParameters(params)}
    );
  }

  public getEventByEmailTemplateId(templateId: string): Observable<NotificationEvent> {
    const url: string = this.substituteParameters(this.endPoints.GET_EVENT_BY_EMAIL_TEMPLATE_ID, {templateId});
    return this.http
      .get<Payload<NotificationEvent>>(url)
      .pipe(
        map((res: Payload<NotificationEvent>) => res.item)
      );
  }

  public getEvents(params?: QueryParams): Observable<TableData<NotificationEvent>> {
    return this.http.get<TableData<NotificationEvent>>(
      this.endPoints.GET_EVENTS,
      {params: this.setQueryParameters(params)}
    );
  }

  public updateEventByEmailTemplateId(templateId: string, formValue: NotificationEventFormValue): Observable<NotificationEvent> {
    const url: string = this.substituteParameters(
      this.endPoints.PUT_UPDATE_EVENT_BY_EMAIL_TEMPLATE_ID,
      {templateId}
    );

    return this.http
      .put<Payload<NotificationEvent>>(url, formValue)
      .pipe(
        map((res: Payload<NotificationEvent>) => res.item)
      );
  }
}
