import { Component } from '@angular/core';

import { NotificationComponent } from '@components/notifications/notification.component';

@Component({
  selector: 'app-success-notification',
  templateUrl: './success-notification.component.html'
})
export class SuccessNotificationComponent extends NotificationComponent<SuccessNotificationComponent> {
  constructor() {
    super();
  }
}
