import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

import { MediaService } from '@services/media.service';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class DateRangeComponent {
  @Input() public label!: string;
  @Input('aria-label') public ariaLabel: string = 'Date range picker';
  @Input() public controlName!: string;

  constructor(public mediaService: MediaService) { }
}
