import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { MatDialogRef } from '@angular/material/dialog';

import { RowNode } from 'ag-grid-community';
import { IServerSideGetRowsParams } from 'ag-grid-enterprise';

import FileSaver from 'file-saver';

import { ListComponent } from '@components/list/list.component';
import { SearchBoxComponent } from '@components/search-box/search-box.component';
import { TableComponent } from '@components/table/table.component';
import { DragDropComponent } from '@components/drag-drop/drag-drop.component';
import { ConfirmationDialogComponent } from '@components/dialog/confirmation-dialog/confirmation-dialog.component';
import { ListService } from '@services/list.service';
import { UtilitiesService } from '@services/utilities.service';
import { EntityChangeEventType } from '@configs/entity';
import { TableChangeEvent, TableData } from '@models/table';
import { QueryParams } from '@models/api';
import { File } from '@models/file';
import { EntityChangeEvent } from '@models/entity';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html'
})
export class FileListComponent<Api, Service> extends ListComponent<File> implements OnInit {
  @Input() public api!: Api;
  @Input('service') public entityService!: Service;
  @Input() public id!: string;
  @Input() public isSelectable: boolean = false;

  @ViewChild(SearchBoxComponent, {static: true}) public override searchBoxComp!: SearchBoxComponent;
  @ViewChild(DragDropComponent) public dragAndDropComp!: DragDropComponent;
  @ViewChild(TableComponent) protected override tableComp!: TableComponent<File>;

  public selectedRows: Array<RowNode> = [];

  protected override successMsg: string = 'The file has been updated successfully';

  protected service: ListService;

  private utilitiesService: UtilitiesService;

  constructor(injector: Injector) {
    super(injector);

    this.service = injector.get(ListService);
    this.utilitiesService = injector.get(UtilitiesService);

    this.serverSideDatasource = {
      getRows: (params: IServerSideGetRowsParams): void => {
        this.getFiles(params);
      }
    };
  }

  ngOnInit(): void {
    this.searchBoxConfig = this.service.getFileListSearchBoxConfig();
    this.filterSectionConfig = this.searchBoxConfig.filterSection;

    this.tableConfig = this.service.getFileListTableConfig(this.isSelectable);

    if (this.filterSectionConfig) {
      this.setSearchBoxControls();
    }

    (<any>this.entityService).changed$
      .pipe(
        takeUntil(this.destroy$),
        filter((event: EntityChangeEvent): boolean => event.type === EntityChangeEventType.File)
      )
      .subscribe((): void => this.onSearchBoxChanged());

    /**
     * Need only for order files
     * It is the best approach for passing event throw components
     * **/
    (<any>this.entityService)
      .invoiceCreated$?.pipe(takeUntil(this.destroy$))
      .subscribe((): void => this.onSearchBoxChanged());
  }

  public onFileChanged(event: TableChangeEvent): void {
    switch (event.type) {
      case 'type-change-file':
        this.updateType(event);
        break;
      case 'dropdown-download-file':
      case 'link-click':
        this.downloadFile(event);
        break;
      case 'dropdown-delete-file':
      case 'dropdown-restore-file':
        this.updateFile(event);
        break;
    }
  }

  public onFileDropped(files: Array<any>): void {
    if (!files.length) { return; }

    (<any>this.api).uploadFiles(this.id, files)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (): void => {
          this.onSearchBoxChanged();
          this.notificationService.openSuccessNotification('The files has been uploaded successfully');

          this.dragAndDropComp.clearFiles();
        },
        (error: string | Error): void => this.dragAndDropComp.clearFiles()
      );
  }

  public onSelectionChanged(selectedNodes: Array<RowNode>): void {
    this.selectedRows = [...selectedNodes];
  }

  protected getFiles(params: IServerSideGetRowsParams): void {
    const queryParams: QueryParams = this.service.prepareFileListQueryParams(this.searchBoxComp.getValue(), params);

    (<any>this.api).getFiles(this.id, queryParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: TableData<File>): void => {
          params.success({
            rowData: data.items,
            rowCount: data.paginator.total
          });

          this.tableComp.toggleNoRowsOverlay(data.items.length ? 'hide' : 'show');
        },
        (error: string): void => params.fail()
      );
  }

  protected updateType(event: TableChangeEvent): void {
    const {id, rowNode, changedProp} = event,
          nodeData: File = rowNode.data,
          isInternal: string = changedProp.type === 'internal' ? '1' : '0';

    (<any>this.api).updateFile(id, {is_deleted: nodeData.is_deleted, internal: isInternal}, this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (): void => {
          nodeData.internal = isInternal;
          this.onActionSuccess(event, 'internal');
        },
        (error: string | Error): void => this.onActionError(error, event)
      );
  }

  protected downloadFile(event: TableChangeEvent): void {
    const {data: nodeData} = event.rowNode;

    (<any>this.api).getFile(this.id, event.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (url: string): void => {
          FileSaver.saveAs(url, nodeData.name);

          (<any>this.entityService).changed$.next({type: EntityChangeEventType.Audit});
        },
        (error: string | Error): void => { }
      );
  }

  protected updateFile(event: TableChangeEvent): void {
    const {id, type, rowNode} = event,
          nodeData: File = rowNode.data,
          needDelete: boolean = type === 'dropdown-delete-file',
          dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialogService.openConfirmationDialog(
            this.utilitiesService.capitalizeFirstLetter(EntityChangeEventType.File.toLowerCase()),
            needDelete
          ),
          successMsg: string = needDelete
            ? 'The files has been deleted successfully'
            : 'The files has been restored successfully';

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        filter<boolean>(Boolean),
        switchMap((): Observable<File> => {
          return (<any>this.api).updateFile(
            id,
            {is_deleted: needDelete ? '1' : '0', internal: nodeData.internal},
            this.id
          );
        })
      )
      .subscribe(
        (): void => {
          this.onSearchBoxChanged();
          this.notificationService.openSuccessNotification(successMsg);
        },
        (error: string | Error): void => this.onActionError(error, event)
      );
  }

  private setSearchBoxControls(): void {
    this.searchBoxComp.addControl('fileType', this.filterSectionConfig.fileTypeValue);
  }
}
