import { Injectable } from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { Order } from '@models/order';
import { TableData } from '@models/table';

@Injectable({
  providedIn: 'root'
})
export class OrderContext {
  public order$: BehaviorSubject<Order> = new BehaviorSubject<Order>(null);
  public orders$: ReplaySubject<Array<Order>> = new ReplaySubject<Array<Order>>(1);
  public tableData$: ReplaySubject<TableData<Order>> = new ReplaySubject<TableData<Order>>(1);

  public nextOrder(order: Order): void {
    this.order$.next(order);
  }

  public nextOrders(orders: Array<Order>): void {
    this.orders$.next(orders);
  }

  public nextTableData(data: TableData<Order>): void {
    this.tableData$.next(data);
    this.nextOrders(<Array<Order>>data.orders);
  }
}
