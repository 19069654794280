import { Component, Injector, OnChanges, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';

import { takeUntil } from 'rxjs/operators';

import { IServerSideGetRowsParams } from 'ag-grid-enterprise';

import { FileListComponent } from '@components/file/file-list/file-list.component';
import { TableComponent } from '@components/table/table.component';
import { EmailApi } from '@api/email.api';
import { EmailService } from '@services/email.service';
import { TableData } from '@models/table';
import { File } from '@models/file';
import { QueryParams } from '@models/api';

@Component({
  selector: 'app-template-file-list',
  templateUrl: './template-file-list.component.html'
})
export class TemplateFileListComponent extends FileListComponent<EmailApi, EmailService> implements OnChanges {
  @ViewChild(TableComponent) protected override tableComp!: TableComponent<File>;

  private emailService: EmailService;

  constructor(injector: Injector) {
    super(injector);

    this.emailService = injector.get(EmailService);
  }

  override ngOnInit(): void {
    this.searchBoxConfig = null;
    this.tableConfig = this.emailService.getTemplateFilesListTableConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const idChanges: SimpleChange = changes.id;

    if (idChanges && !idChanges.firstChange) {
      this.onSearchBoxChanged();
    }
  }

  protected override getFiles(params: IServerSideGetRowsParams): void {
    const queryParams: QueryParams = this.service.prepareBaseQueryParams(null, params);

    this.api.getFiles(this.id, queryParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: TableData<File>): void => {
          params.success({
            rowData: data.items,
            rowCount: data.paginator.total
          });

          this.tableComp.toggleNoRowsOverlay(data.items.length ? 'hide' : 'show');
        },
        (error: string): void => params.fail()
      );
  }
}
