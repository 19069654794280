import { Component, Injector } from '@angular/core';

import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { TableService } from '@services/table.service';
import { MenuItem } from '@models/menu-item';

@Component({
  selector: 'app-dropdown-renderer',
  templateUrl: './dropdown-renderer.component.html'
})
export class DropdownRendererComponent implements AgRendererComponent {
  public params!: ICellRendererParams;
  public data!: MenuItem;

  protected service: TableService;

  constructor(injector: Injector) {
    this.service = injector.get(TableService);
  }

  public onBtnClick(action: string): void {
    this.service.rendererAction$.next({
      type: `dropdown-${action}`,
      item: null,
      rowData: this.params?.node?.data,
      node: this.params?.node
    });
  }

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = params.data;
  }

  public refresh(): boolean {
    return false;
  }
}
