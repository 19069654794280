<div class="eos-table-link-renderer-container">
  <ng-container *ngTemplateOutlet="params.value ? withLink : noLink"></ng-container>
</div>

<!--Link template-->
<ng-template #withLink>
  <ng-template *ngTemplateOutlet="customParams.external ? externalLink : internalLink"></ng-template>
</ng-template>

<!--No link template-->
<ng-template #noLink>
  <span>---</span>
</ng-template>

<!--Internal link template-->
<ng-template #internalLink>
  <a class="eos-link" [routerLink]="link" (click)="onLinkClick()">{{params.value}}</a>
</ng-template>

<!--External link template-->
<ng-template #externalLink>
  <a class="eos-link" [href]="href" target="_blank" (click)="onLinkClick()">{{params.value}}</a>
</ng-template>
