import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';

import { emptyValueValidator } from '@validators/empty-value.validator';
import { ERROR_MESSAGES } from '@configs/form-errors';

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html'
})
export class CancelDialogComponent {
  public reasonControl: UntypedFormControl = this.formBuilder.control(
    null,
    [Validators.required, emptyValueValidator]
  );

  public errorMsg: string = ERROR_MESSAGES.required;

  private files: Array<File> = [];

  constructor(public dialogRef: MatDialogRef<CancelDialogComponent>, private formBuilder: UntypedFormBuilder) { }

  public onFileDropped(files: Array<File>): void {
    this.files = files;
  }

  public onConfirmBtnClick(): void {
    this.dialogRef.close({
      reason: this.reasonControl.value.replace(/[<>]/g, ''),
      files: this.files
    });
  }
}
