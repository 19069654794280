import { Component, Injector, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Subject } from 'rxjs';

import { DateRange } from '@angular/material/datepicker';

import moment from 'moment';

import { FormService } from '@services/form.service';
import { UtilitiesService } from '@services/utilities.service';
import {
  SearchBoxValue,
  SearchBoxConfig,
  SearchSectionConfig,
  FilterSectionConfig
} from '@models/search-box';
import { Checkbox } from '@models/checkbox';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html'
})
export class SearchBoxComponent implements OnInit {
  @Input() config!: SearchBoxConfig;
  @Input() searchOnInput: boolean = false;
  /**
   * @description
   * Initial value as a input parameter because of default values from query parameters
   * @publicApi
   * **/
  @Input() initialValue!: SearchBoxValue | undefined | null;

  @Output() searchBoxChange: Subject<SearchBoxValue> = new Subject<SearchBoxValue>();

  public form: UntypedFormGroup;
  public searchSectionConfig: SearchSectionConfig | null = null;
  public filterSectionConfig: FilterSectionConfig | null = null;
  public searchSectionActions: boolean = false;
  public showFilters: boolean = false;
  public showFilterBtnIcon!: string;

  protected formBuilder: UntypedFormBuilder;
  protected service: FormService;
  protected utilitiesService: UtilitiesService;

  private injector: Injector;

  private openIcon: string = 'open_in_full';
  private closeIcon: string = 'close_fullscreen';

  constructor(injector: Injector) {
    this.injector = injector;

    this.formBuilder = this.injector.get(UntypedFormBuilder);
    this.service = this.injector.get(FormService);
    this.utilitiesService = this.injector.get(UtilitiesService);

    this.form = this.createForm();
  }

  ngOnInit(): void {
    this.searchSectionConfig = this.config.searchSection;
    this.filterSectionConfig = this.config.filterSection;
    this.searchSectionActions = this.config.searchSectionActions || false;

    this.setupForm();

    this.showFilterBtnIcon = this.showFilters ? this.closeIcon : this.openIcon;
  }

  public onSearchBtnClick(): void {
    this.searchBoxChange.next(this.form.value);
  }

  public onShowFiltersBtnClick(): void {
    this.showFilters = !this.showFilters;
    this.showFilterBtnIcon = this.showFilters ? this.closeIcon : this.openIcon;
  }

  public onClearBtnClick(): void {
    try {
      this.form.patchValue(this.initialValue);
    } catch (error: unknown) { }
  }

  public onInput(): void {
    if (this.form.value.search && !this.searchOnInput) { return; }
    this.onSearchBtnClick();
  }

  public onKeyup(event: KeyboardEvent): void {
    if (!this.utilitiesService.isEnterKey(event)) { return; }
    this.onSearchBtnClick();
  }

  public getValue(): SearchBoxValue {
    return this.form.value;
  }

  public addControl(name: string, value: unknown = null): void {
    this.form.addControl(name, this.formBuilder.control(value));
  }

  public addDateControl(name: string, value: DateRange<moment.Moment> | null = null): void {
    this.service.addDateControl(this.form, name, value);
  }

  public addCheckboxGroupControl(name: string, items: Array<Checkbox>): void {
    this.service.addCheckboxGroupControl(this.form, name, items);
  }

  public isSearchBoxPristine(): boolean {
    return JSON.stringify(this.form.value) === JSON.stringify(this.initialValue);
  }

  protected createForm(): UntypedFormGroup {
    return this.formBuilder.group({ });
  }

  protected setupForm(): void {
    const searchSection: SearchSectionConfig = this.config.searchSection;

    /** Search control (text) **/
    if (searchSection?.search) {
      this.addControl('search', searchSection.searchValue);
    }

    this.initialValue = this.initialValue || this.form.value;
  }
}
