<div class="eos-file-panel-container">
  <app-panel header="Files" [actions]="panelActions" [expanded]="expanded" (actionBtnClicked)="onPanelAction($event)">
    <div class="eos-drag-drop-container" slot="body" appDnd (fileDrop)="onFileDropped($event)">
      <app-file-list
        [api]="api"
        [service]="service"
        [id]="id"
        [isSelectable]="isSelectable"
      #fileListComponent>
      </app-file-list>
    </div>
  </app-panel>
</div>
