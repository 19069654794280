import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

import { Checkbox } from '@models/checkbox';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class CheckboxGroupComponent implements OnInit {
  @Input() public label!: string;
  @Input('class') classList: string = '';
  @Input() public controlName!: string;
  @Input() public items: Array<Checkbox> = [];

  ngOnInit(): void {
    this.classList += ' eos-checkbox.scss-group-container';
  }
}
