import { MatSnackBarConfig } from '@angular/material/snack-bar';

export const NOTIFICATION_CONFIG: MatSnackBarConfig = {
  duration: 5 * 1000,
  horizontalPosition: 'end',
  verticalPosition: 'top',
  panelClass: 'eos-notification'
};

export const SUCCESS_NOTIFICATION_CONFIG: MatSnackBarConfig = {
  ...NOTIFICATION_CONFIG,
  panelClass: [
    <string>NOTIFICATION_CONFIG.panelClass,
    'success'
  ]
};

export const ERROR_NOTIFICATION_CONFIG: MatSnackBarConfig = {
  ...NOTIFICATION_CONFIG,
  panelClass: [
    <string>NOTIFICATION_CONFIG.panelClass,
    'error'
  ]
};
