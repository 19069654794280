import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AutocompleteComponent } from '@components/autocomplete/autocomplete.component';
import { UserApi } from '@api/user.api';
import { QualityAssuranceViewValuePipe } from '@pipes/quality-assurance-view-value.pipe';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';
import { QualityAssurance } from '@models/user';

@Component({
  selector: 'app-quality-assurance-autocomplete',
  templateUrl: './quality-assurance-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => QualityAssuranceAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: QualityAssuranceAutocompleteComponent,
      multi: true
    }
  ]
})
export class QualityAssuranceAutocompleteComponent extends AutocompleteComponent {
  @Input() public override label: string = 'Quality Control';
  @Input() public override placeholder: string = 'Search for a qc...';

  public readOnly: boolean = false;

  protected override readonly switchMap: boolean = true;

  constructor(injector: Injector, private api: UserApi, private qualityAssurancePipe: QualityAssuranceViewValuePipe) {
    super(injector);
  }

  public displayFn = (qa: QualityAssurance): string => {
    return qa ? this.qualityAssurancePipe.transform(qa) : '';
  };

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return this.api.searchForUsersByRole('qc', {keyword: query}).pipe(
      tap((qa: Array<QualityAssurance>): Array<QualityAssurance> => this.allItems = qa),
      map((): Array<AutocompleteValue> => this.composeAutocompleteValues()),
      catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
    );
  }
}
