<div [ngClass]="classList">
  <label [attr.id]="id">{{label}}</label>
  <mat-radio-group [attr.aria-labelledby]="id" [formControlName]="controlName">
    <mat-radio-button
      [aria-label]="item.ariaLabel"
      *ngFor="let item of items"
      [value]="item.value">
      {{item.viewValue}}
    </mat-radio-button>
  </mat-radio-group>
</div>
