import { Component, ElementRef, Injector, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RowNode } from 'ag-grid-community';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { TableService } from '@services/table.service';
import { UtilitiesService } from '@services/utilities.service';
import { ReactiveInputRendererParams } from '@models/renderer-parameters';

@Component({
  selector: 'app-reactive-input-renderer',
  templateUrl: './reactive-input-renderer.component.html'
})
export class ReactiveInputRendererComponent implements OnDestroy, AgRendererComponent {
  @ViewChild('inputEl') inputEl!: ElementRef<HTMLInputElement>;

  public params!: ICellRendererParams;
  public form!: any;
  public control: AbstractControl | null = null;

  public key!: any;
  public type: string = 'text';
  public label: string = 'Label';
  public placeholder: string = 'Placeholder';
  public isRequired: boolean = false;
  public ariaLabel: string = 'input';
  public prefix: string = '';
  public mask: string = '';
  public allowNegativeNumbers: boolean = false;

  private service: TableService;
  private utilitiesService: UtilitiesService;

  private readonly destroy$: Subject<void> = new Subject();
  private customParams!: ReactiveInputRendererParams;
  private value: string | null = null;
  private rowId!: string;

  constructor(injector: Injector) {
    this.service = injector.get(TableService);
    this.utilitiesService = injector.get(UtilitiesService);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public agInit(params: ICellRendererParams): void {
    const {context, customParams, node} = <any>params;

    this.params = params;
    this.customParams = customParams;

    const {
      controlIndex,
      value = this.value,
      type = this.type,
      label = this.label,
      placeholder = this.placeholder,
      isRequired = this.isRequired,
      ariaLabel = this.ariaLabel,
      prefix = this.prefix,
      mask = this.mask,
      allowNegativeNumbers = this.allowNegativeNumbers
    } = customParams;

    this.key = controlIndex;
    this.type = type;
    this.label = label;
    this.placeholder = placeholder;
    this.isRequired = isRequired;
    this.ariaLabel = ariaLabel;
    this.prefix = prefix;
    this.mask = mask;
    this.allowNegativeNumbers = allowNegativeNumbers;
    this.value = value;
    this.rowId = node.id;

    this.form = context.form.controls[this.rowId];
    this.control = this.form?.at(this.key);

    this.control?.patchValue(this.control ? this.control.value : this.value);

    if (customParams.eventType) {
      this.control?.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((value: string): void => this.onValueChanged(value));
    }
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }

  public onKeydown(event: KeyboardEvent): void {
    const onKeydown: (event: KeyboardEvent) => void = this.customParams.onKeydown || ((event: KeyboardEvent): void => { });

    onKeydown.call(this, event, this);
    if (!this.utilitiesService.isNavigationKey(event) && !this.utilitiesService.isCopyPasteKey(event)) { return; }

    event.stopPropagation();
  }

  public onValueChanged(value: string): void {
    const node: RowNode | undefined = this.params?.node;

    this.service.rendererAction$.next({
      type: this.customParams.eventType,
      item: value,
      node,
      rowData: node?.data
    });
  }

  public setFocus(): void {
    this.inputEl.nativeElement.focus();
  }
}
