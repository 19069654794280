import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroupDirective } from '@angular/forms';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import Quill from 'quill';
import ImageResize from 'quill-image-resize-module'
import { QuillModules } from 'ngx-quill/lib/quill-editor.interfaces';

import { FormValidationService } from '@services/form-validation.service';
import { QuillEvent } from '@models/editor';

Quill.register('modules/imageResize', ImageResize);

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class EditorComponent implements OnInit, OnDestroy {
  @Input() public label: string = 'Label';
  @Input() public toolbar: boolean = true;
  @Input() public controlName!: string;
  @Input() public autofocus: boolean = false;
  @Input() public needValidation: boolean = false;

  public modules: QuillModules;

  public editorControl!: AbstractControl;
  public isValid: boolean = true;
  public errorMsg: string = '';

  private readonly destroy$: Subject<void> = new Subject();

  constructor(private parentGroup: FormGroupDirective, private validationService: FormValidationService) {
    this.setUpEditor();
  }

  ngOnInit(): void {
    this.editorControl = this.parentGroup.form.get(this.controlName);

    if (!this.toolbar) {
      this.modules = {
        ...this.modules,
        toolbar: false
      };
    }

    this.editorControl.statusChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((): boolean => this.needValidation)
      )
      .subscribe((): void => this.validate());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onEditorCreated(editor: Quill): void {
    if (this.autofocus) {
      editor.focus();
    }
  }

  public onFocus(event: QuillEvent): void {
    if (this.needValidation) {
      this.validate();
    }
  }

  public onBlur(event: QuillEvent): void {
    this.editorControl.markAsTouched();

    if (this.needValidation) {
      this.validate();
    }
  }

  private setUpEditor(): void {
    this.modules = {
      imageResize: { }
    };
  }

  private validate(): void {
    this.isValid = !(this.needValidation
      && this.editorControl.invalid
      && (this.editorControl.dirty || this.editorControl.touched));

    if (!this.isValid) {
      this.errorMsg = this.validationService.getControlErrorMessage(this.editorControl, this.controlName);
    }
  }
}
