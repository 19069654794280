import { Component, Injector } from '@angular/core';

import { MatSelectChange } from '@angular/material/select';

import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { TableService } from '@services/table.service';
import { SelectItem } from '@models/select';
import { SelectRendererParams } from '@models/renderer-parameters';
import { RowNode } from 'ag-grid-community';

@Component({
  selector: 'app-select-renderer',
  templateUrl: './select-renderer.component.html'
})
export class SelectRendererComponent implements AgRendererComponent {
  public params!: ICellRendererParams;
  public items: Array<SelectItem> = [];
  public label: string = 'Choose an option';
  public ariaLabel: string = 'Select';
  public selected: SelectItem | null = null;
  public classList: string = 'eos-table-select-renderer-container ';

  protected service: TableService;

  private customParams!: SelectRendererParams;

  constructor(injector: Injector) {
    this.service = injector.get(TableService);
  }

  public onSelectionChanged(event: MatSelectChange): void {
    const node: RowNode | undefined = this.params?.node;

    this.service.rendererAction$.next({
      type: this.customParams.eventType,
      item: event.value,
      rowData: node?.data,
      node
    });
  }

  public agInit(params: ICellRendererParams): void {
    const {customParams, value} = <any>params;

    this.params = params;
    this.customParams = customParams;

    const {label, ariaLabel, items, classList = ''} = customParams;

    this.items = items;
    this.selected = value ? items[Number(value)] : null;
    this.label = label;
    this.ariaLabel = ariaLabel;
    this.classList += classList;
  }

  public refresh(): boolean {
    return false;
  }
}
