import { Component, Injector, OnDestroy } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { MatDialogRef } from '@angular/material/dialog';

import { RowNode } from 'ag-grid-community';
import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';

import { SearchBoxComponent } from '@components/search-box/search-box.component';
import { TableComponent } from '@components/table/table.component';
import { NoteDialogComponent } from '@components/dialog/note-dialog/note-dialog.component';
import { PaymentApi } from '@api/payment.api';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { FilterSectionConfig, SearchBoxConfig } from '@models/search-box';
import { TableChangeEvent, TableConfig } from '@models/table';
import { Payment } from '@models/payment';
import { UpdateNoteEvent } from '@models/note';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html'
})
export class ListComponent<Entity> implements OnDestroy {
  public tableConfig: TableConfig;
  public serverSideDatasource: IServerSideDatasource;

  public searchBoxConfig!: SearchBoxConfig;
  public filterSectionConfig: FilterSectionConfig = {};

  public searchBoxComp!: SearchBoxComponent;

  protected tableComp!: TableComponent<Entity>;

  protected title: string;
  protected reportName: string;

  protected successMsg: string;

  protected readonly destroy$: Subject<void> = new Subject();

  protected paymentApi: PaymentApi;
  protected dialogService: DialogService;
  protected notificationService: NotificationService;

  protected serverSideGetRowsParams: IServerSideGetRowsParams | null = null;

  protected injector: Injector;

  constructor(injector: Injector) {
    this.injector = injector;

    this.paymentApi = this.injector.get(PaymentApi);
    this.dialogService = this.injector.get(DialogService);
    this.notificationService = this.injector.get(NotificationService);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSearchBoxChanged(): void {
    this.tableComp.refreshStore();
  }

  protected onActionSuccess(
    event: TableChangeEvent,
    column: string,
    needSetNodeData: boolean = true,
    needShowNotification: boolean = true
  ): void {
    const rowNode: RowNode = event.rowNode,
          {data: nodeData, id: nodeId} = rowNode;

    if (needSetNodeData) {
      this.tableComp.setRowDataById(nodeId, nodeData);
    }
    this.tableComp.flashCell(rowNode, column);

    if (needShowNotification) {
      this.notificationService.openSuccessNotification(this.successMsg);
    }
  }

  protected onActionError(error: string | null | Error, event: TableChangeEvent): void {
    const {data: nodeData, id: nodeId} = event.rowNode;
    this.tableComp.setRowDataById(nodeId, nodeData)
  }

  protected updateClientSideRowData(data: Array<Entity>): void {
    this.tableComp.setRowData(data);
  }

  protected updateNote(event: TableChangeEvent | UpdateNoteEvent): void {
    const nodeData: Payment = (<TableChangeEvent>event).rowNode.data,
          {id, order_id, notes} = nodeData,
          dialogRef: MatDialogRef<NoteDialogComponent> = this.dialogService.openNoteDialog(notes);

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        filter<string>(Boolean),
        switchMap((reason: string): Observable<Payment> =>
          this.paymentApi.updateNote(id, {payment: {id, order_id, notes: reason}})
        )
      )
      .subscribe(
        (payment: Payment): void => {
          nodeData.notes = payment.notes;
          this.onActionSuccess(<TableChangeEvent>event, 'notes');
        },
        (error: string | Error): void => { }
      );
  }
}
