<div class="eos-control-width-100 eos-search-box-container">
  <form class="container-fluid" [formGroup]="form" novalidate>

    <!-- Search section -->
    <fieldset class="row eos-search-box-search-section">
      <div [ngClass]="{'col-lg-8': searchSectionActions, 'col-lg-12': !searchSectionActions}">
        <div class="row">
          <div
            *ngIf="searchSectionConfig"
            [ngClass]="{'col-lg-8': !filterSectionConfig, 'col-lg-7': filterSectionConfig}">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Search</mat-label>
              <input
                type="text"
                placeholder="Search..."
                aria-label="Search"
                formControlName="search"
                (input)="onInput()"
                (keyup)="onKeyup($event)"
                matInput>
              <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
          </div>
          <div [ngClass]="{'col-lg-4': !filterSectionConfig, 'col-lg-5': filterSectionConfig}">
            <div class="eos-search-box-search-section-controls">
              <button
                *ngIf="searchSectionConfig"
                type="button"
                class="eos-btn-primary"
                mat-flat-button
                (click)="onSearchBtnClick()">
                Search
              </button>
              <button
                type="button"
                class="eos-btn"
                *ngIf="filterSectionConfig"
                mat-button
                (click)="onShowFiltersBtnClick()">
                <mat-icon>{{showFilterBtnIcon}}</mat-icon>
                Filters
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="searchSectionActions"
        [ngClass]="{'col-lg-4': true}">
        <div class="eos-search-box-search-section-actions">
          <ng-content select="[slot=search-section-actions]"></ng-content>
        </div>
      </div>
    </fieldset>

    <!-- Filter section -->
    <div class="row eos-search-box-filter-section" *ngIf="showFilters">
      <div class="col-12">
        <div class="row eos-search-box-filter-section-controls justify-content-end">
          <div class="col-auto">
            <button
              type="button"
              class="eos-btn-link-primary"
              mat-flat-button
              (click)="onClearBtnClick()">
              Clear Filters
            </button>
          </div>
        </div>
        <ng-content select="[slot=filter-section]"></ng-content>
      </div>
    </div>

  </form>
</div>
