<app-dialog header="Cancel Order">
  <div class="eos-control-width-100" slot="content">
    <mat-form-field>
      <mat-label>Please enter a reason for cancellation</mat-label>
      <textarea
        placeholder="Please enter a reason for cancellation"
        required
        aria-label="Cancellation reason"
        [formControl]="reasonControl"
        appAutofocus
        matInput>
      </textarea>
      <mat-error *ngIf="reasonControl.invalid && (reasonControl.dirty || reasonControl.touched)">
        {{errorMsg}}
      </mat-error>
    </mat-form-field>

    <app-drag-drop
      label="*All files will be uploaded to the cancelled order"
      (fileDropped)="onFileDropped($event)">
    </app-drag-drop>
  </div>
  <button
    type="button"
    class="eos-btn-primary"
    [disabled]="!reasonControl.valid"
    mat-flat-button
    slot="action"
    (click)="onConfirmBtnClick()">
    Confirm
  </button>
</app-dialog>
