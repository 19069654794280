import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { ERROR_MESSAGES, FORM_ERRORS } from '@configs/form-errors';
import { ControlErrors, FormErrors } from '@models/form-errors';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {
  public getControlErrorMessage(control: AbstractControl, controlName: string = 'default'): string {
    const errors: Array<string> = Object.keys(control.errors);
    let msg: string = '';

    errors.forEach((error: string, index: number) => {
      try {
        msg += `${FORM_ERRORS[controlName][error]}${index < errors.length - 1 ? '\n' : ''}`;
      } catch (error: unknown) {
        msg = ERROR_MESSAGES.unknown;
      }
    });

    return msg;
  }

  public setErrors(group: UntypedFormGroup, errors: FormErrors): void {
    let controlErrors: ControlErrors;

    Object.entries(group.controls).forEach(([controlName, control]) => {
      if (control instanceof UntypedFormGroup) {
        this.setErrors(control, errors);
      } else {
        controlErrors = errors[controlName];

        if (controlErrors) {
          Object.keys(controlErrors).forEach((errorName: string) => {
            (group.get(controlName)).setErrors({[errorName]: true});
          });
        }
      }
    });
  }
}
