import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Subject } from 'rxjs';

import { IntegrationApi } from '@api/integration';
import { FormService } from '@services/form.service';
import { IntegrationInformation } from '@models/integration';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-integration-information-panel',
  templateUrl: './integration-information-panel.component.html'
})
export class IntegrationInformationPanelComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;

  protected formBuilder: UntypedFormBuilder;
  protected api: IntegrationApi;
  protected formService: FormService;
  protected notificationService: NotificationService;

  protected readonly destroy$: Subject<void> = new Subject();
  private injector: Injector;

  constructor(injector: Injector) {
    this.injector = injector;

    this.formBuilder = this.injector.get(UntypedFormBuilder);
    this.api = this.injector.get(IntegrationApi);
    this.formService = this.injector.get(FormService);
    this.notificationService = this.injector.get(NotificationService);

    this.form = this.createForm();
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onUpdateBtnClick(): void { }

  protected updateForm(value: IntegrationInformation): void {
    this.form.setValue(value);
  }

  private createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      information: null
    });
  }
}
