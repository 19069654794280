import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { User } from '@models/user';

export const userEmailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value: string | User = control.value,
        validateEmail: (value: string) => Array<string> | null = (value: string) => {
          return value
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        };

  return validateEmail(typeof value === 'string' ? value : value.email) ? null : {'email': true};
};
