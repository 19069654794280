import { Component, Inject, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PaymentApi } from '@api/payment.api';
import { DialogData } from '@models/dialog';

@Component({
  selector: 'app-return-transfer-dialog',
  templateUrl: './return-transfer-dialog.component.html'
})
export class ReturnTransferDialogComponent implements OnDestroy {
  public message: string;

  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    public dialogRef: MatDialogRef<ReturnTransferDialogComponent>,
    private api: PaymentApi,
  ) {
    const {epr_id, transferred_from_epr_id} = this.data.payment;
    this.message = `Returning this transfer will add a negative transfer charge to this order
     (${epr_id}) and return a positive transfer back to order ${transferred_from_epr_id}`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onYesBtnClick(): void {
    this.api.createBatchTransfer({payments: [this.data.payment]})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (): void => this.dialogRef.close(true),
        (error: string | Error): void => { }
      );
  }
}
