import { Component, Injector, Input } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormControl, FormGroupDirective } from '@angular/forms';

import { filter, takeUntil } from 'rxjs/operators';

import { SelectComponent } from '@components/select/select.component';

@Component({
  selector: 'app-reactive-select',
  templateUrl: './reactive-select.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }]
})
export class ReactiveSelectComponent extends SelectComponent {
  @Input() public control: AbstractControl | null = null;
  @Input() public controlName!: string;

  protected parentGroup: FormGroupDirective;

  constructor(injector: Injector) {
    super(injector);

    this.parentGroup = injector.get(FormGroupDirective);
  }

  override ngOnInit(): void {
    this.selectControl = this.control
      ? <UntypedFormControl>this.control
      : <UntypedFormControl>this.parentGroup.form.get(this.controlName);
    this.setValidators();

    this.selectControl.statusChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((): boolean => this.needValidation)
      )
      .subscribe((): void => this.validate());
  }

  public override onClose(): void {
    if (this.needValidation) {
      this.validate();
    }
  }

  protected override validate(): void {
    super.validate();
    if (this.isValid) { return; }

    this.errorMsg = this.validationService.getControlErrorMessage(this.selectControl, this.controlName);
  }
}
