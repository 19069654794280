<div [ngClass]="classList">
  <app-select
    label="Choose Researcher"
    aria-label="Order researcher"
    [panelClassList]="panelClassList"
    [items]="items"
    [selected]="selected"
    (openedChanged)="onOpenedChanged($event)"
    (selectionChanged)="onSelectionChanged($event)">
  </app-select>
</div>
