import { AfterViewInit, Component, ViewChild } from '@angular/core';

import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-enterprise';
import { RowNode } from 'ag-grid-community';

import {
  FlagChipsAutocompleteComponent
} from '@components/chips-autocomplete/flag-chips-autocomplete/flag-chips-autocomplete.component';
import { TableService } from '@services/table.service';
import { Flag } from '@models/flag';
import { AutocompleteChangeValueEvent } from '@models/autocomplete';
import { Order } from '@models/order';

@Component({
  selector: 'app-flag-chips-autocomplete-editor',
  templateUrl: './flag-chips-autocomplete-editor.component.html',
  styleUrls: ['./flag-chips-autocomplete-editor.component.scss']
})
export class FlagChipsAutocompleteEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild(FlagChipsAutocompleteComponent) chipsAutocompleteComp!: FlagChipsAutocompleteComponent;

  public params!: ICellEditorParams;
  public selectedFlags: Array<Flag> = [];

  private isEditing: boolean = false;

  constructor(private service: TableService) { }

  ngAfterViewInit(): void {
    window.setTimeout(() => this.chipsAutocompleteComp.openPanel());
  }

  public onFlagChange(event: AutocompleteChangeValueEvent): void {
    const node: RowNode<Order> = this.params.node;

    this.isEditing = true;

    this.service.rendererAction$.next({
      type: `flag-${event.type}`,
      item: event.item,
      rowData: node.data,
      node
    });
  }

  public onOpenedChanged(isOpened: boolean): void {
    if (isOpened || this.isEditing) { return; }
    this.stopEditing();
  }

  public agInit(params: ICellEditorParams): void {
    this.params = params;
    this.selectedFlags = [...this.params.value];
  }

  public getValue(): Array<Flag> {
    return this.selectedFlags;
  }

  public isCancelBeforeStart(): boolean {
    return false;
  }

  public isCancelAfterEnd(): boolean {
    return this.isEditing;
  }

  public stopEditing(): void {
    /** The code strings order make sense **/
    this.params.api.stopEditing();
    this.isEditing = false;
  }
}
