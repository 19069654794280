import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { iif, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AutocompleteComponent } from '@components/autocomplete/autocomplete.component';
import { OrderApi } from '@api/order.api';
import { OrderViewValuePipe } from '@pipes/order-view-value.pipe';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';
import { Order } from '@models/order';

@Component({
  selector: 'app-order-autocomplete',
  templateUrl: './order-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => OrderAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: OrderAutocompleteComponent,
      multi: true
    }
  ]
})
export class OrderAutocompleteComponent extends AutocompleteComponent {
  @Input() public override label: string = 'Order';
  @Input() public override placeholder: string = 'Search for an order...';

  protected override readonly startWith: boolean = false;
  protected override readonly switchMap: boolean = true;

  constructor(injector: Injector, private api: OrderApi, private pipe: OrderViewValuePipe) {
    super(injector);
  }

  public onKeydown(event: KeyboardEvent): void {
    if (!this.utilitiesService.isNavigationKey(event)) { return; }
    event.stopPropagation();
  }

  public displayFn = (order: Order): string => {
    return order ? this.pipe.transform(order) : '';
  };

  public setFocus(): void {
    this.inputEl.nativeElement.focus();
  }

  protected override onInputValueChange(query: string | AutocompleteValue): Observable<Array<AutocompleteValue>> {
    return iif(
      (): boolean => this.isString(query) && query.length >= 4,
      this.searchByQuery(<string>query),
      of(this.filterBySelected())
    );
  }

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return this.api.searchForOrders({order_id: query}).pipe(
      tap((orders: Array<Order>): Array<Order> => this.allItems = orders),
      map((): Array<AutocompleteValue> => this.composeAutocompleteValues()),
      catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
    );
  }
}
