import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { IServerSideGetRowsParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';

import { IconRendererComponent } from '@components/table/cell-renderers/icon-renderer/icon-renderer.component';
import { ListService } from '@services/list.service';
import { TimeService } from '@services/time.service';
import { TableService } from '@services/table.service';
import { UtilitiesService } from '@services/utilities.service';
import { COMPANY_SEARCH_CATEGORIES } from '@configs/autocomplete';
import { PAGINATION_PAGE_SIZE } from '@configs/select';
import { Company, CompanyFormValue, RawCompanyFormValue } from '@models/company';
import { SearchBoxConfig, SearchBoxValue } from '@models/search-box';
import { TableConfig } from '@models/table';
import { QueryParams } from '@models/api';
import { EntityChangeEvent } from '@models/entity';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  public changed$: Subject<EntityChangeEvent> = new Subject<EntityChangeEvent>();

  constructor(
    private listService: ListService,
    private timeService: TimeService,
    private tableService: TableService,
    private utilitiesService: UtilitiesService
  ) { }

  public emptyCompany: Company = {
    id: null,
    name: '',
    do_business_as: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: ''
  };

  public getCompanyListTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: '',
          field: 'company_from_qualia',
          cellRenderer: IconRendererComponent,
          valueGetter: (params: ValueGetterParams) => this.tableService.getIconRendererParams(params.data),
          sortable: false,
          minWidth: 68,
          maxWidth: 68
        },
        {
          headerName: 'ID',
          field: 'id',
          minWidth: 60,
          maxWidth: 100
        },
        {
          headerName: 'Name',
          field: 'name',
          minWidth: 350
        },
        {
          headerName: 'Address',
          field: 'address',
          valueFormatter: this.utilitiesService.companyAddressFormatter,
          minWidth: 350
        },
        {
          headerName: 'Phone Number',
          field: 'phone',
          valueFormatter: this.utilitiesService.phoneNumberFormatter
        },
        {
          headerName: 'First Order',
          field: 'first_order_date',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params)
        },
        {
          headerName: 'Last Order',
          field: 'last_order_date',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params)
        },
        {
          headerName: 'Total Users',
          field: 'total_users'
        },
        {
          headerName: 'Total Orders',
          field: 'total_orders'
        }
      ],
      paginationPageSizeItems: PAGINATION_PAGE_SIZE
    };
  }

  public getCompanyListSearchBoxConfig(): SearchBoxConfig {
    return {
      searchSection: {
        search: true
      },
      filterSection: {
        companySearchCategories: true,
        companySearchCategoryItems: COMPANY_SEARCH_CATEGORIES,
        companySearchCategoryValue: [COMPANY_SEARCH_CATEGORIES[0]],
        onlyQualiaCompanies: true,
        doBusinessAs: true
      },
      searchSectionActions: true
    };
  }

  public prepareCompanyListQueryParams(value: SearchBoxValue, params: IServerSideGetRowsParams): QueryParams {
    const {companySearchCategories, onlyQualiaCompanies, doBusinessAs} = value;
    return {
      ...this.listService.prepareBaseQueryParams(value, params),
      keyword_filters: companySearchCategories ? companySearchCategories.join(',') : null,
      onlyQualiaCompanies: onlyQualiaCompanies || null,
      doBusinessAs
    };
  }

  public prepareCompanyFormValue(formValue: RawCompanyFormValue): CompanyFormValue {
    const {integrations, pricingInformation, ...value} = formValue,
          {marketer, first_order_date} = value;

    return {
      ...value,
      marketer: marketer ? marketer.id : null,
      first_order_date: first_order_date
        ? this.timeService.formatDateTime(this.timeService.addEndDay(first_order_date))
        : null,
      company_from_resware: integrations.company_from_resware,
      company_from_qualia: integrations.company_from_qualia,
      company_has_custom_pricing: pricingInformation.company_has_custom_pricing
    };
  }

  /** Customer requirement. "United Tax" company orders should be managed in specific way. **/
  public isUnitedTaxCompany(id: string | number): boolean {
    return id === '3761' || id === 3761; /** "United Tax" company id **/
  }
}
