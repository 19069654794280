import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseApi } from '@api/base.api';
import { OrderApiEndPoints, QueryParams } from '@models/api';
import { TableData } from '@models/table';
import { CloneOrder, Order, OrderFormValue } from '@models/order';
import { Researcher } from '@models/user';
import { Payload } from '@models/response';
import { Flag } from '@models/flag';
import { File as FileModel, FileUpdateFormValue } from '@models/file';
import { OrderNote, OrderNoteFormValue } from '@models/note';
import { Task, TaskUpdateFormValue, TaskFormValue } from '@models/task';
import { AccountingData, AccountingFormValue } from '@models/accounting';
import { Notification } from '@models/notification';
import { Audit } from '@models/audit';
import { Address, Addresses } from '@models/address';

@Injectable({
  providedIn: 'root'
})
export class OrderApi extends BaseApi<OrderApiEndPoints> {
  constructor(injector: Injector) {
    super(injector);

    this.endPoints = this.addBaseUrl({
      GET_ORDER: '/orders/all/${id}',
      GET_ORDERS: '/orders/all',
      GET_CLIENT_ORDER: '/orders/my_company/${id}',
      GET_CLIENT_ORDERS: '/orders/my_company',
      GET_ORDERS_BY_COMPANY_ID: '/orders/company/${companyId}',
      GET_ORDERS_BY_USER_ID: '/orders/user/${userId}',
      GET_FILES: '/order_files/${id}',
      GET_FILE: '/order_files/${id}/download/${fileId}',
      GET_NOTES: '/order_notes/${id}',
      GET_TASKS: '/order_tasks/${id}',
      GET_ACCOUNTING: '/accounting/${id}',
      GET_NOTIFICATIONS: '/order_notifications/${id}',
      GET_NOTIFICATION: '/order_notifications/${orderId}/view/${id}',
      GET_AUDIT: '/order_audits/${id}',
      GET_CREATE_INVOICE: '/invoice/${id}',
      GET_SEARCH_FOR_ORDERS: '/order_search',
      GET_SEARCH_FOR_ADDRESSES: '/address/search',
      GET_ADDRESS: '/address/place/${addressId}/${searchToken}',
      GET_DOWNLOAD_TEMPLATE: '/filegen/${productId}',
      POST_CREATE_ORDER: '/order',
      POST_UPLOAD_FILES: '/upload_files/${id}',
      POST_CREATE_TASK: '/order_task',
      POST_CREATE_NOTE: '/order_note',
      POST_CLONE_ORDER: '/order_clone/${id}',
      POST_UPDATE_STATUS: '/order/update-status/${id}',
      POST_BATCH_UPDATE_STATUS: '/edit-order/multi-status-change',
      POST_ADD_FLAG: '/user/order-flag/add-to-order/${flagId}/${id}',
      POST_BATCH_ADD_FLAG: '/user/order-flag/add-multiple-flags',
      POST_REMOVE_FLAG: '/user/order-flag/remove-from-order/${flagId}/${id}',
      PUT_UPDATE_ORDER: '/order/${id}',
      PUT_UPDATE_ASSIGNEE: '/order/assigned-to/${id}',
      PUT_UPDATE_FILE: '/order_file/${id}',
      PUT_UPDATE_NOTE: '/order_note/${id}',
      PUT_UPDATE_TASK: '/order_task/${id}',
      PUT_UPDATE_ACCOUNTING: '/accounting/${id}'
    });
  }

  public getOrder(id: string): Observable<Order> {
    const url: string = this.substituteParameters(this.endPoints.GET_ORDER, {id});
    return this.http
      .get<Payload<Order>>(url)
      .pipe(
        map((res: Payload<Order>) => res.order)
      );
  }

  public getOrders(params?: QueryParams): Observable<TableData<Order>> {
    return this.http.get<TableData<Order>>(this.endPoints.GET_ORDERS, {params: this.setQueryParameters(params)});
  }

  // TODO: because of server authorization
  public getClientOrder(id: string): Observable<Order> {
    const url: string = this.substituteParameters(this.endPoints.GET_CLIENT_ORDER, {id});
    return this.http
      .get<Payload<Order>>(url)
      .pipe(
        map((res: Payload<Order>) => res.order)
      );
  }

  public getClientOrders(params?: QueryParams): Observable<TableData<Order>> {
    return this.http.get<TableData<Order>>(
      this.endPoints.GET_CLIENT_ORDERS,
      {params: this.setQueryParameters(params)}
    );
  }

  // TODO: because of server authorization

  public getOrdersByCompanyId(companyId: string, params?: QueryParams): Observable<TableData<Order>> {
    const url: string = this.substituteParameters(this.endPoints.GET_ORDERS_BY_COMPANY_ID, {companyId});
    return this.http.get<TableData<Order>>(url, {params: this.setQueryParameters(params)});
  }

  public getOrdersByUserId(userId: string, params?: QueryParams): Observable<TableData<Order>> {
    const url: string = this.substituteParameters(this.endPoints.GET_ORDERS_BY_USER_ID, {userId});
    return this.http.get<TableData<Order>>(url, {params: this.setQueryParameters(params)});
  }

  public downloadOrders(responseType: string, params?: QueryParams): Observable<string | ArrayBuffer> {
    return this.http.get<string | ArrayBuffer>(
      this.endPoints.GET_ORDERS,
      {responseType: <'json'>responseType, params: this.setQueryParameters(params)}
    );
  }

  public getClientOrdersCSV(params?: QueryParams): Observable<string> {
    return this.http.get<string>(
      this.endPoints.GET_CLIENT_ORDERS,
      {responseType: <'json'>'text', params: this.setQueryParameters(params)}
    );
  }

  public getOrdersByCompanyIdCSV(companyId: string, params?: QueryParams): Observable<string> {
    const url: string = this.substituteParameters(this.endPoints.GET_ORDERS_BY_COMPANY_ID, {companyId});
    return this.http.get<string>(
      url,
      {responseType: <'json'>'text', params: this.setQueryParameters(params)}
    );
  }

  public getOrdersByUserIdCSV(userId: string, params?: QueryParams): Observable<string> {
    const url: string = this.substituteParameters(this.endPoints.GET_ORDERS_BY_USER_ID, {userId});
    return this.http.get<string>(
      url,
      {responseType: <'json'>'text', params: this.setQueryParameters(params)}
    );
  }

  public getFiles(id: string, params?: QueryParams): Observable<TableData<FileModel>> {
    const url: string = this.substituteParameters(this.endPoints.GET_FILES, {id});
    return this.http.get<TableData<FileModel>>(url, {params: this.setQueryParameters(params)});
  }

  public getFile(id: string, fileId: string): Observable<string> {
    const url: string = this.substituteParameters(this.endPoints.GET_FILE, {id, fileId});
    return this.http
      .get<Payload<string>>(url)
      .pipe(
        map((res: Payload<string>) => res.url)
      );
  }

  public getNotes(id: string, params?: QueryParams): Observable<TableData<OrderNote>> {
    const url: string = this.substituteParameters(this.endPoints.GET_NOTES, {id});
    return this.http.get<TableData<OrderNote>>(url, {params: this.setQueryParameters(params)});
  }

  public getTasks(id: string, params?: QueryParams): Observable<TableData<Task>> {
    const url: string = this.substituteParameters(this.endPoints.GET_TASKS, {id});
    return this.http.get<TableData<Task>>(url, {params: this.setQueryParameters(params)});
  }

  public getAccounting(id: string, params?: QueryParams): Observable<AccountingData> {
    const url: string = this.substituteParameters(this.endPoints.GET_ACCOUNTING, {id});
    return this.http.get<AccountingData>(url, {params: this.setQueryParameters(params)});
  }

  public getNotifications(id: string, params?: QueryParams): Observable<TableData<Notification>> {
    const url: string = this.substituteParameters(this.endPoints.GET_NOTIFICATIONS, {id});
    return this.http.get<TableData<Notification>>(url, {params: this.setQueryParameters(params)});
  }

  public getNotification(id: string, orderId: string): Observable<Notification> {
    const url: string = this.substituteParameters(this.endPoints.GET_NOTIFICATION, {id, orderId});
    return this.http
      .get<Payload<Notification>>(url)
      .pipe(
        map((res: Payload<Notification>) => res.item)
      );
  }

  public getAudit(id: string, params?: QueryParams): Observable<TableData<Audit>> {
    const url: string = this.substituteParameters(this.endPoints.GET_AUDIT, {id});
    return this.http.get<TableData<Audit>>(url, {params: this.setQueryParameters(params)});
  }

  public createInvoice(id: string): Observable<Order> {
    const url: string = this.substituteParameters(this.endPoints.GET_CREATE_INVOICE, {id});
    return this.http
      .get<Payload<Order>>(url)
      .pipe(
        map((res: Payload<Order>) => res.order)
      );
  }

  public searchForOrders(params?: QueryParams): Observable<Array<Order>> {
    return this.http
      .get<Payload<Array<Order>>>(this.endPoints.GET_SEARCH_FOR_ORDERS, {params: this.setQueryParameters(params)})
      .pipe(
        map((res: Payload<Array<Order>>) => res.items)
      );
  }

  public searchForAddresses(address: string): Observable<Addresses> {
    return this.http
      .get<Addresses>(
        this.endPoints.GET_SEARCH_FOR_ADDRESSES,
        {params: this.setQueryParameters({line_1: address})}
      );
  }

  public getAddress(searchToken: string, addressId: string): Observable<Address> {
    const url: string = this.substituteParameters(this.endPoints.GET_ADDRESS, {searchToken, addressId});
    return this.http.get<Address>(url);
  }

  public downloadTemplate(productId: string, params?: QueryParams): Observable<ArrayBuffer> {
    const url: string = this.substituteParameters(this.endPoints.GET_DOWNLOAD_TEMPLATE, {productId});
    return this.http.get<ArrayBuffer>(
      url,
      {responseType: <'json'>'arraybuffer', params: this.setQueryParameters(params)}
    );
  }

  public createOrder(formValue: OrderFormValue): Observable<Order> {
    return this.http
      .post<Payload<Order>>(this.endPoints.POST_CREATE_ORDER, formValue)
      .pipe(
        map((res: Payload<Order>) => res.order)
      );
  }

  public uploadFiles(id: string, files: Array<File>): Observable<null> {
    const url: string = this.substituteParameters(this.endPoints.POST_UPLOAD_FILES, {id});
    return this.http.post<null>(url, this.prepareFormDataBody(null, files));
  }

  public createTask(formValue: TaskFormValue): Observable<TaskFormValue> {
    return this.http.post<TaskFormValue>(this.endPoints.POST_CREATE_TASK, formValue);
  }

  public createNote(orderId: string, formValue: Omit<OrderNoteFormValue, 'order_id'>): Observable<OrderNote> {
    return this.http
      .post<Payload<OrderNote>>(this.endPoints.POST_CREATE_NOTE, {order_id: orderId, ...formValue})
      .pipe(
        map((res: Payload<OrderNote>) => res.note)
      );
  }

  public cloneOrder(id: string): Observable<CloneOrder> {
    const url: string = this.substituteParameters(this.endPoints.POST_CLONE_ORDER, {id});
    return this.http.post<CloneOrder>(url, null);
  }

  public updateStatus(id: string, status: string): Observable<null> {
    const url: string = this.substituteParameters(this.endPoints.POST_UPDATE_STATUS, {id});
    return this.http.post<null>(url, {status});
  }

  public batchUpdateStatus(ids: Array<string>, status: string): Observable<null> {
    // TODO: 'orderOverrideStatus' what is it?
    return this.http.post<null>(this.endPoints.POST_BATCH_UPDATE_STATUS, {ids, status, orderOverrideStatus: 0});
  }

  public updateOrder(id: string, formValue: OrderFormValue): Observable<Order> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_ORDER, {id});
    return this.http
      .put<Payload<Order>>(url, formValue)
      .pipe(
        map((res: Payload<Order>) => res.order)
      );
  }

  public updateAssignee(id: string, assigned_to: string): Observable<Researcher> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_ASSIGNEE, {id});
    return this.http
      .put<Payload<Researcher>>(url, {assigned_to})
      .pipe(
        map((res: Payload<Researcher>) => res.assigned_to)
      );
  }

  public addFlag(id: string, flagId: string, notes: string): Observable<Array<Flag>> {
    const url: string = this.substituteParameters(this.endPoints.POST_ADD_FLAG, {id, flagId});
    return this.http.post<Array<Flag>>(url, {notes});
  }

  public batchAddFlag(ids: Array<string>, flag: Flag, notes: string): Observable<Array<Flag>> {
    return this.http.post<null>(this.endPoints.POST_BATCH_ADD_FLAG, {order_ids: ids, flag, notes});
  }

  public removeFlag(id: string, flagId: string, notes: null = null): Observable<Array<Flag>> {
    const url: string = this.substituteParameters(this.endPoints.POST_REMOVE_FLAG, {id, flagId});
    return this.http.post<Array<Flag>>(url, {notes});
  }

  public updateFile(id: string, formValue: FileUpdateFormValue): Observable<FileModel> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_FILE, {id});
    return this.http
      .put<Payload<FileModel>>(url, formValue)
      .pipe(
        map((res: Payload<FileModel>) => res.item)
      );
  }

  public updateNote(id: string, note: OrderNote): Observable<OrderNote> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_NOTE, {id});
    return this.http
      .put<Payload<OrderNote>>(url, note)
      .pipe(
        map((res: Payload<OrderNote>) => res.note)
      );
  }

  public updateTask(id: string, formValue: TaskUpdateFormValue): Observable<void> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_TASK, {id});
    return this.http.put<void>(url, formValue);
  }

  public updateAccounting(id: string, formValue: AccountingFormValue): Observable<AccountingData> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_ACCOUNTING, {id});
    return this.http.put<AccountingData>(url, formValue);
  }
}
