import { Pipe, PipeTransform } from '@angular/core';

import { Flag } from 'app/shared/models/flag';
import { AutocompleteValue } from '@models/autocomplete';

@Pipe({
  name: 'flagIcon'
})
export class FlagIconPipe implements PipeTransform {
  public transform(flag: AutocompleteValue): string {
    const outlinedClasses: Array<string> = ['outline', 'white'];
    return outlinedClasses.some(
      (clazz: string): boolean => ((<Flag>flag).color || (<Flag>flag).flag_color).includes(clazz)
    ) ? 'outlined_flag' : 'flag';
  }
}
