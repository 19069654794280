import { Component, ElementRef, Input, Output, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';

import { UtilitiesService } from '@services/utilities.service';
import { FILE_EXTENSIONS } from '@configs/file-extensions';

@Component({
  selector: 'app-drag-drop',
  templateUrl: './drag-drop.component.html',
  styleUrls: ['./drag-drop.component.scss']
})
export class DragDropComponent {
  // TODO: add limit by file's size
  @Input() label: string = '*All files will be uploaded here';
  @Input() containerClass: string = '';
  @Input() multiple: boolean = true;
  @Input() disabled: boolean = false;
  @Input() validFileExtensions: Array<string> = FILE_EXTENSIONS;

  @Output() fileDropped: Subject<Array<File>> = new Subject<Array<File>>();

  @ViewChild('input') private input: ElementRef;

  public files: Array<File> = [];

  constructor(private utilitiesService: UtilitiesService) { }

  public onFileDropped(files: FileList): void {
    if (this.disabled) { return; }

    this.files = this.getFiles(files);

    this.fileDropped.next(this.files);
  }

  public onFileInputChange(event: Event): void {
    if (this.disabled) { return; }

    this.files = this.getFiles((<HTMLInputElement>event.target).files);

    this.input.nativeElement.value = '';

    this.fileDropped.next(this.files);
  }

  public onDeleteBtnClick(index: number): void {
    if (this.disabled) { return; }
    this.files.splice(index, 1);

    this.fileDropped.next(this.files);
  }

  public clearFiles(): void {
    this.files = [];
  }

  public triggerFileInputClick(): void {
    this.input.nativeElement.click();
  }

  private getFiles(files: FileList | Array<File>): Array<File> {
    return this.multiple ? [...this.files, ...this.filterFiles(files)] : [...this.filterFiles([files[0]])];
  }

  private filterFiles(files: FileList | Array<File>): Array<File> {
    return Array.from(files).filter(
      (file: File): boolean => this.utilitiesService.validateFileExtensions(file, this.validFileExtensions)
    );
  }
}
