import { Pipe, PipeTransform } from '@angular/core';

import { Flag } from '@models/flag';

@Pipe({
  name: 'flagDisabled'
})
export class FlagDisabledPipe implements PipeTransform {
  public transform(flag: Flag): boolean {
    return (flag.can_add === null || flag.flag_can_add === null)
           || (flag.can_edit === null || flag.flag_can_edit === null);
  }
}
