import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

import { MenuItem } from 'app/shared/models/menu-item';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {
  @Input() item!: MenuItem;
  @Input() clazz!: string;
  @Input() useAuthPipe: boolean = false;

  /** Fix https://github.com/angular/components/issues/11677 **/
  @Input() needFixMatMenuIssue: boolean = false;
  @ViewChild(MatMenu, {static: true}) private menuComp!: MatMenu;

  public timeoutId!: ReturnType<typeof setTimeout>;

  ngOnInit(): void {
    /** Fix https://github.com/angular/components/issues/11677 **/
    this.menuComp.overlayPanelClass = 'eos-overlay-pane';
  }

  public onMouseEnter(trigger: MatMenuTrigger): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    trigger.openMenu();
  }

  public onMouseLeave(trigger: MatMenuTrigger): void {
    this.timeoutId = setTimeout((): void => trigger.closeMenu(), 50);
  }
}
