<div class="eos-reactive-select-container">
  <mat-form-field [ngClass]="classList">

    <mat-label>{{label}}</mat-label>

    <mat-select
      [required]="isRequired"
      [aria-label]="ariaLabel"
      [formControl]="selectControl"
      (closed)="onClose()"
      (selectionChange)="onSelectionChanged($event)">
      <mat-select-trigger *ngIf="selected?.icon">
        <mat-icon [class]="selected.iconClass">{{selected.icon}}</mat-icon>
        {{selected.viewValue}}
      </mat-select-trigger>
      <mat-option *ngFor="let item of items" [value]="item.value" [disabled]="item.disabled">
        <mat-icon *ngIf="item.icon" [class]="item.iconClass">{{item.icon}}</mat-icon>
        {{item.viewValue}}
      </mat-option>
    </mat-select>

    <mat-hint *ngIf="hint">{{hint}}</mat-hint>

    <mat-error *ngIf="!isValid">{{errorMsg}}</mat-error>

  </mat-form-field>
</div>
