<div class="eos-table-flag-chips-autocomplete-editor-container">
  <app-flag-chips-autocomplete
    label="Flags"
    class="eos-flag-chips-autocomplete-renderer"
    ariaLabel="Search for a flag..."
    [selectedItems]="selectedFlags"
    (openedChanged)="onOpenedChanged($event)"
    (valueChange)="onFlagChange($event)">
  </app-flag-chips-autocomplete>
</div>
