import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AutocompleteComponent } from '@components/autocomplete/autocomplete.component';
import { UserApi } from '@api/user.api';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';
import { Researcher } from '@models/user';

@Component({
  selector: 'app-assignee-autocomplete',
  templateUrl: './assignee-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => AssigneeAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AssigneeAutocompleteComponent,
      multi: true
    }
  ]
})
export class AssigneeAutocompleteComponent extends AutocompleteComponent {
  @Input() public override label: string = 'Researcher';
  @Input() public override placeholder: string = 'Search for a researcher...';

  public readOnly: boolean = false;

  protected override readonly switchMap: boolean = true;

  constructor(injector: Injector, private api: UserApi) {
    super(injector);
  }

  public displayFn = (researcher: Researcher): string => {
    return researcher ? researcher.displayName : '';
  };

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return this.api.searchForUsersByRole('researcher', {keyword: query}).pipe(
      tap((researchers: Array<Researcher>): Array<Researcher> => this.allItems = researchers),
      map((): Array<AutocompleteValue> => this.composeAutocompleteValues()),
      catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
    );
  }
}
