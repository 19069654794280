import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseApi } from '@api/base.api';
import { AuthApiEndPoints } from '@models/api';
import {
  LoginFormValue,
  SignUpFormValue,
  PasswordRecoveryFormValue,
  PasswordNewFormValue,
  AuthTokens
} from '@models/auth';
import { Payload } from '@models/response';
import { User } from '@models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthApi extends BaseApi<AuthApiEndPoints> {
  constructor(injector: Injector) {
    super(injector);

    this.endPoints = this.addBaseUrl({
      POST_LOGIN: '/login',
      POST_SIGN_UP: '/signup',
      POST_PASS_RECOVERY: '/reset/request',
      POST_PASS_NEW: '/reset/change-password',
      POST_UPDATE_DATA_IN_ACCESS_TOKEN: '/refresh_data_token',
      POST_REFRESH_ACCESS_TOKEN: '/refresh_token'
    });
  }

  public login(credentials: LoginFormValue): Observable<AuthTokens> {
    return this.http.post<AuthTokens>(this.endPoints.POST_LOGIN, credentials);
  }

  public signUp(formValue: SignUpFormValue): Observable<User> {
    return this.http
      .post<Payload<User>>(this.endPoints.POST_SIGN_UP, formValue)
      .pipe(
        map((res: Payload<User>) => res.user)
      );
  }

  public passwordRecovery(email: PasswordRecoveryFormValue): Observable<null> {
    return this.http.post<null>(this.endPoints.POST_PASS_RECOVERY, email);
  }

  public setNewPassword(formValue: PasswordNewFormValue): Observable<null> {
    return this.http.post<null>(this.endPoints.POST_PASS_NEW, formValue);
  }

  public updateDataInAccessToken(tokens: AuthTokens): Observable<AuthTokens> {
    return this.http.post<AuthTokens>(this.endPoints.POST_UPDATE_DATA_IN_ACCESS_TOKEN, tokens);
  }

  public refreshAccessToken(tokens: AuthTokens): Observable<AuthTokens> {
    return this.http.post<AuthTokens>(this.endPoints.POST_REFRESH_ACCESS_TOKEN, tokens);
  }
}
