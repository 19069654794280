import { Component, forwardRef, Injector, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ChipsAutocompleteComponent } from '@components/chips-autocomplete/chips-autocomplete.component';
import { NotificationApi } from '@api/notification.api';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { TableData } from '@models/table';
import { NotificationAuthor } from '@models/notification';
import { AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';

@Component({
  selector: 'app-order-notification-author-chips-autocomplete',
  templateUrl: './order-notification-author-chips-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => OrderNotificationAuthorChipsAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: OrderNotificationAuthorChipsAutocompleteComponent,
      multi: true
    }
  ]
})
export class OrderNotificationAuthorChipsAutocompleteComponent extends ChipsAutocompleteComponent {
  @Input() public override label: string = 'Created By';
  @Input() public override placeholder: string = 'Search for a user...';

  protected override readonly switchMap: boolean = true;

  constructor(injector: Injector, private api: NotificationApi) {
    super(injector);
  }

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return this.api.getNotificationAuthors({page: 1, limit: 10, keyword: query}).pipe(
      tap((data: TableData<NotificationAuthor>): TableData<NotificationAuthor> => this.allItems = data.items),
      map((): Array<AutocompleteValue> => this.composeAutocompleteValues()),
      catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
    );
  }
}
