<div class="eos-panel-container {{containerClass}}">
  <mat-accordion>
    <mat-expansion-panel [expanded]="expanded" [disabled]="disabled" [hideToggle]="hideToggle">

      <mat-expansion-panel-header>
        <mat-panel-title>
          <ng-content select="[slot=icon]"></ng-content>
          <h4>{{header}}</h4>
          <mat-chip-list>
            <mat-chip
              class="{{status.class}}"
              *ngFor="let status of statuses"
              [matTooltip]="status.tooltip">{{status.label}}
            </mat-chip>
          </mat-chip-list>
        </mat-panel-title>
        <mat-panel-description>
          <ng-content select="[slot=description]"></ng-content>
          <div>
            <button
              type="button"
              class="eos-btn-icon-primary"
              [attr.aria-label]="action.ariaLabel"
              *ngFor="let action of actions"
              mat-icon-button
              [matTooltip]="action.tooltip"
              (click)="onActionBtnClick($event, action.type)">
              <mat-icon>{{action.icon}}</mat-icon>
            </button>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ng-content select="[slot=body]"></ng-content>

    </mat-expansion-panel>
  </mat-accordion>
</div>
