import { Component, forwardRef, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';

import { iif, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AutocompleteComponent } from '@components/autocomplete/autocomplete.component';
import { UserApi } from '@api/user.api';
import { UserService } from '@services/user.service';
import { userEmailValidator } from '@validators/user-email.validator';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { AutocompleteValue, EmptyAutocompleteValue } from '@models/autocomplete';
import { User } from '@models/user';
import { QueryParams } from '@models/api';

@Component({
  selector: 'app-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => UserAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: UserAutocompleteComponent,
      multi: true
    }
  ]
})
export class UserAutocompleteComponent extends AutocompleteComponent implements OnChanges {
  @Input() public override label: string = 'User';
  @Input() public override placeholder: string = 'Search for a user...';
  @Input() public companyId: string | null = null;
  @Input() public needIcon: boolean = false;
  @Input() public includeEmployee: boolean = false;

  protected router: Router;
  protected api: UserApi;
  protected service: UserService;

  protected override readonly distinctUntilChanged: boolean = false;
  protected override readonly switchMap: boolean = true;

  constructor(injector: Injector) {
    super(injector);

    this.router = injector.get(Router);
    this.api = injector.get(UserApi);
    this.service = injector.get(UserService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyId) {
      this.inputControl.setValue('');
    }
  }

  public onPrefixIconBtnClicked(): void {
    if (!this.selectedItem) { return; }
    const url: string = this.router.serializeUrl(
      this.router.createUrlTree(['user-edit', (this.selectedItem as User).id])
    );

    window.open(url, '_blank');
  }

  public override validate(): ValidationErrors | null {
    const baseErrors: ValidationErrors | null = super.validate(),
          emailErrors: ValidationErrors | null = this.addKeywordFromInput && this.keywordFromInput
            ? userEmailValidator(this.inputControl)
            : null;
    let res: ValidationErrors | null = null;

    if (baseErrors || emailErrors) {

      if (baseErrors) {
        res = baseErrors;
      } else if (emailErrors) {
        this.isValid = false;
        res = emailErrors;
      }

      this.setError(res);
    }

    return res;
  }

  public displayFn = (user: User): string => {
    return user ? user.displayName : '';
  };

  protected override onInputValueChange(query: string | AutocompleteValue): Observable<Array<AutocompleteValue>> {
    return iif(
      (): boolean => !!this.companyId,
      this.getUsers(query),
      of([])
    );
  }

  protected getUsers(query: string | AutocompleteValue): Observable<Array<AutocompleteValue>> {
    return iif(
      (): boolean => this.isString(query),
      this.searchByQuery(<string>query),
      of([])
    );
  }

  protected override searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    let queryParams: QueryParams = { };

    if (this.includeEmployee) {
      queryParams.includeEmployee = this.includeEmployee;
    }

    return this.api.searchForUsersByCompanyId(this.companyId, {...queryParams, keyword: query}).pipe(
      tap((users: Array<User>): Array<User> => this.allItems = users),
      map((): Array<AutocompleteValue> =>
        this.composeAutocompleteValuesWithCustomUserInput(query, this.createUserFromInputValue)
      ),
      catchError((): Observable<Array<EmptyAutocompleteValue>> => of(EMPTY_AUTOCOMPLETE_VALUE))
    );
  }

  protected createUserFromInputValue(value: string): User {
    return {
      ...this.service.createEmptyUser(),
      email: value,
      displayName: value
    };
  }
}
