import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import {
  CellRendererSelectorResult,
  ICellRendererParams,
  IServerSideGetRowsParams,
  RowClassParams,
  ValueFormatterParams
} from 'ag-grid-enterprise';

import { LinkRendererComponent } from '@components/table/cell-renderers/link-renderer/link-renderer.component';
import { SelectRendererComponent } from '@components/table/cell-renderers/select-renderer/select-renderer.component';
import {
  AssigneeRendererComponent
} from '@components/table/cell-renderers/assignee-renderer/assignee-renderer.component';
import { ListService } from '@services/list.service';
import { AuthService } from '@services/auth.service';
import { TimeService } from '@services/time.service';
import { UtilitiesService } from '@services/utilities.service';
import { ACTIVE_INACTIVE_RADIO } from '@configs/radio';
import { USER_ROLES } from '@configs/autocomplete';
import { ACTIVE_INACTIVE, PAGINATION_PAGE_SIZE, YES_NO } from '@configs/select';
import { QualityAssurance, Researcher, User, UserFormValue } from '@models/user';
import { SelectItem } from '@models/select';
import { TableChangeEventProp, TableConfig } from '@models/table';
import { SearchBoxConfig, SearchBoxValue } from '@models/search-box';
import { QueryParams } from '@models/api';
import { EntityChangeEvent } from '@models/entity';
import { CompanyEmail } from '@models/email';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public changed$: Subject<EntityChangeEvent> = new Subject<EntityChangeEvent>();

  constructor(
    private listService: ListService,
    private authService: AuthService,
    private timeService: TimeService,
    private utilitiesService: UtilitiesService
  ) { }

  public getUserListTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'ID',
          field: 'id',
          minWidth: 60,
          maxWidth: 100
        },
        {
          headerName: 'Username',
          field: 'username',
          wrapText: true
        },
        {
          headerName: 'Role',
          field: 'role'
        },
        {
          headerName: 'Company',
          field: 'company_name',
          cellRenderer: LinkRendererComponent,
          cellRendererParams: {
            customParams: {
              static: '/company-edit',
              dynamic: 'company_id'
            }
          }
        },
        {
          headerName: 'First Name',
          field: 'first_name'
        },
        {
          headerName: 'Last Name',
          field: 'last_name'
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: (params: ValueFormatterParams) => this.timeService.timestampFormatter(params)
        },
        {
          headerName: 'Status',
          field: 'state',
          cellRendererSelector: () => this.statusSelector(),
          cellRendererParams: {
            customParams: {
              eventType: 'status-change-user',
              items: ACTIVE_INACTIVE,
              label: 'Status',
              classList: 'eos-table-user-status-renderer-container',
              ariaLabel: 'User status'
            }
          },
          valueFormatter: this.utilitiesService.activeInactiveFormatter
        },
        {
          headerName: 'Administrator',
          field: 'is_company_administrator',
          cellRendererSelector: (params: ICellRendererParams) => this.isCompanyAdminSelector(params),
          cellRendererParams: {
            customParams: {
              eventType: 'administrator-change-user',
              items: YES_NO,
              label: 'Administrator',
              classList: 'eos-table-user-administrator-renderer-container',
              ariaLabel: 'Is the user an administrator'
            }
          },
          valueFormatter: (params: ValueFormatterParams) => this.isCompanyAdminValueFormatter(params)
        }
      ],
      paginationPageSizeItems: PAGINATION_PAGE_SIZE
    };
  }

  public getQaListTableConfig(): TableConfig {
    return {
      colDefs: [
        {
          headerName: 'First Name',
          field: 'first_name'
        },
        {
          headerName: 'Last Name',
          field: 'last_name'
        },
        {
          headerName: 'Email',
          field: 'username',
          wrapText: true
        },
        {
          headerName: 'Researchers',
          field: 'researchers',
          cellRenderer: AssigneeRendererComponent,
          sortable: false,
          minWidth: 800
        }
      ],
      rowClassRules: {'eos-table-row-danger': (params: RowClassParams) => params.data?.active === '0'}
    };
  }

  public getUserListSearchBoxConfig(): SearchBoxConfig {
    return {
      searchSection: {
        search: true
      },
      filterSection: {
        userStatuses: true,
        userStatusItems: ACTIVE_INACTIVE_RADIO,
        userStatusValue: 'active',
        userRoles: true,
        userRoleItems: USER_ROLES
      },
      searchSectionActions: true
    };
  }

  public prepareUserListQueryParams(value: SearchBoxValue, params: IServerSideGetRowsParams): QueryParams {
    const {userRoles} = value;

    return {
      ...this.listService.prepareBaseQueryParams(value, params),
      status: value.userStatus,
      roles: userRoles ? userRoles.join(',') : null
    };
  }

  public prepareUserFormValue(user: User, changeProp: TableChangeEventProp): UserFormValue {
    const {
        company_id,
        username,
        first_name,
        last_name,
        is_company_administrator,
        state,
        role
      } = user,
      {active} = changeProp,
      formValue: UserFormValue = {
        company: {id: company_id},
        username,
        first_name,
        last_name,
        type: company_id ? 'customer' : 'employee',
        is_company_administrator,
        state,
        role,
        ...changeProp
      };

    if (active) {
      formValue.state = <string>active;
    }

    return formValue;
  }

  public getResearcherItems(researchers: Array<Researcher> | null): Array<SelectItem> {
    return researchers?.map((researcher: Researcher) => {
      return {
        id: researcher.user_id,
        value: researcher.user_id,
        viewValue: researcher.display,
        disabled: researcher.active === '0'
      };
    });
  }

  public getResearcherItemByUserId(items: Array<SelectItem>, userId: string): SelectItem {
    return items.find((item: SelectItem) => item.id === userId);
  }

  public getQAItems(QAs: Array<QualityAssurance>): Array<SelectItem> {
    return QAs.map((qa: QualityAssurance) => {
      return {
        id: qa.id,
        value: qa.id,
        viewValue: `${qa.first_name} ${qa.last_name} ${qa.username}`,
        disabled: qa.active === '0'
      };
    });
  }

  public getQAItemById(items: Array<SelectItem>, id: string): SelectItem {
    return items.find((item: SelectItem) => item.id === id);
  }

  public createEmptyUser(): User {
    return {
      id: this.utilitiesService.guid(),
      email: '',
      username: '',
      first_name: '',
      last_name: '',
      created: '',
      profileAvatarUrl: ''
    };
  }

  public createAccountant(user: User): User {
    return {
      ...user,
      displayName: `AC ${user.displayName}`,
      isAccountant: true
    };
  }

  public createAccountants(users: Array<User>): Array<User> {
    return users.map((user: User) => this.createAccountant(user));
  }

  public createPayableUsers(emails: Array<CompanyEmail>): Array<User> {
    return emails.map((payableEmail: CompanyEmail, index: number) => {
      return {
        ...this.createEmptyUser(),
        id: 999 - index, // TODO: need to know if we have intersection of ids
        displayName: `AP ${payableEmail.name} <${payableEmail.email}>`,
        active: payableEmail.active ? '1' : '0',
        username: payableEmail.email,
        email: payableEmail.email,
        isPayable: true
      };
    });
  }

  private statusSelector(): CellRendererSelectorResult | undefined {
    return this.authService.isAdmin() || this.authService.isManager() || this.authService.isClientAdmin()
      ? {component: SelectRendererComponent}
      : undefined;
  }

  private isCompanyAdminSelector(params: ICellRendererParams): CellRendererSelectorResult | undefined {
    return (this.authService.isClient(params.node.data.role))
    && (this.authService.isAdmin() || this.authService.isManager() || this.authService.isClientAdmin())
      ? {component: SelectRendererComponent}
      : undefined;
  }

  private isCompanyAdminValueFormatter(params: ValueFormatterParams): string {
    const isClient: boolean = this.authService.isClient(params.node.data.role);
    let res: string = isClient ? params.value : '---';

    if (!this.authService.isAdmin() && !this.authService.isManager()) {
      res = isClient ? this.utilitiesService.yesNoFormatter(params) : '---';
    }

    return res;
  }
}
