import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogData } from '@models/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent {
  public cancelBtnLabel: string;
  public actionBtnLabel: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.cancelBtnLabel = this.data.cancelBtnLabel ?? 'No';
    this.actionBtnLabel = this.data.actionBtnLabel ?? 'Yes';
  }
}
