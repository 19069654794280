import { ORDER_STATUSES } from '@configs/order';
import { PanelAction } from '@models/panel';

export enum PANEL_TYPES {
  NEW_ORDER = 'newOrder',
  NOTIFICATION = 'notification',
  FILE = 'file',
  NOTE = 'note',
  TASK = 'task',
  PAYMENT = 'payment',
  MARKETER = 'marketer',
  PRICING = 'pricing',
  ISSUE = 'issue',
  VIEW_ISSUE = 'viewIssue',
  NEW_USER = 'newUser',
  TEMPLATE_FILE = 'templateFile'
}

export const ORDER_ISSUE_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'create',
  icon: 'add',
  ariaLabel: 'Add order issue',
  tooltip: 'Add Order Issue'
}];

export const ORDER_VIEW_ISSUE_PANEL_ACTIONS: { [key: string]: Array<PanelAction> } = {
  'Assigned': [
    {
      type: 'edit',
      icon: 'edit',
      ariaLabel: 'Edit order issue',
      tooltip: 'Edit Order Issue'
    },
    {
      type: 'complete',
      icon: 'check',
      ariaLabel: 'Complete order issue',
      tooltip: 'Complete Order Issue'
    },
    {
      type: 'cancel',
      icon: 'delete',
      ariaLabel: 'Cancel order issue',
      tooltip: 'Cancel Order Issue'
    }
  ],
  'Complete': [],
  'Pending Review': [
    {
      type: 'edit',
      icon: 'edit',
      ariaLabel: 'Edit order issue',
      tooltip: 'Edit Order Issue'
    },
    {
      type: 'approve-completion',
      icon: 'check',
      ariaLabel: 'Approve order issue completion',
      tooltip: 'Approve Order Issue Completion'
    },
    {
      type: 'cancel',
      icon: 'delete',
      ariaLabel: 'Cancel order issue',
      tooltip: 'Cancel Order Issue'
    },
    {
      type: 'reject-completion',
      icon: 'cancel',
      ariaLabel: 'Reject order issue completion',
      tooltip: 'Reject Order Issue Completion'
    }
  ],
  'Cancelled': [],
  'Pending Cancel': [
    {
      type: 'edit',
      icon: 'edit',
      ariaLabel: 'Edit order issue',
      tooltip: 'Edit Order Issue'
    },
    {
      type: 'approve-cancellation',
      icon: 'check',
      ariaLabel: 'Approve order issue cancellation',
      tooltip: 'Approve Order Issue Cancellation'
    },
    {
      type: 'reject-cancellation',
      icon: 'cancel',
      ariaLabel: 'Reject order issue cancellation',
      tooltip: 'Reject Order Issue Cancellation'
    }
  ],
  'Rejected': [],
  'Deleted': []
};

export const ORDER_DISCUSSION_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'create-note',
  icon: 'add',
  ariaLabel: 'Add comment',
  tooltip: 'Add Comment'
}];

const DEFAULT_ORDER_PANEL_ACTIONS: Array<PanelAction> = [
  {
    type: 'complete',
    icon: 'check',
    ariaLabel: 'Complete order',
    tooltip: 'Complete Order'
  },
  {
    type: 'clone',
    icon: 'repeat',
    ariaLabel: 'Create update order',
    tooltip: 'Create Update Order'
  },
  {
    type: 'invoice',
    icon: 'text_snippet',
    ariaLabel: 'Create invoice',
    tooltip: 'Create Invoice'
  },
  {
    type: 'email',
    icon: 'email',
    ariaLabel: 'Send email',
    tooltip: 'Send Email'
  },
  {
    type: 'email-invoice',
    icon: 'attach_email',
    ariaLabel: 'Send invoice',
    tooltip: 'Send Invoice'
  },
  {
    type: 'template',
    icon: 'generating_tokens',
    ariaLabel: 'Generate template',
    tooltip: 'Generate Template'
  }
];

const ORDER_PANEL_ACTION_WITHOUT_CLONE: Array<PanelAction> = [...DEFAULT_ORDER_PANEL_ACTIONS];
ORDER_PANEL_ACTION_WITHOUT_CLONE.splice(1, 1);

export const ORDER_PANEL_ACTIONS: { [key: string]: Array<PanelAction> } = {
  [ORDER_STATUSES.NEW]: DEFAULT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.ASSIGNED]: DEFAULT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.RESEARCH]: DEFAULT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.TO_BE_BUILT]: DEFAULT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.REVIEW]: DEFAULT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.SENT_TO_CLIENT]: [
    ...DEFAULT_ORDER_PANEL_ACTIONS.filter((action: PanelAction) => action.type !== 'complete'),
    {
      type: 'cancel',
      icon: 'cancel',
      ariaLabel: 'Cancel order',
      tooltip: 'Cancel Order'
    }
  ],
  [ORDER_STATUSES.HOLD]: DEFAULT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.CANCELLATION_PENDING]: ORDER_PANEL_ACTION_WITHOUT_CLONE,
  [ORDER_STATUSES.CANCELLED]: ORDER_PANEL_ACTION_WITHOUT_CLONE,
  [ORDER_STATUSES.CANCELLED_HARD_COST_PAYABLE]: ORDER_PANEL_ACTION_WITHOUT_CLONE,
  [ORDER_STATUSES.CANCELLED_ESTOPPEL_REFUND_NEEDED]: ORDER_PANEL_ACTION_WITHOUT_CLONE,
  [ORDER_STATUSES.ACCOUNTING_CANCELLED]: ORDER_PANEL_ACTION_WITHOUT_CLONE,
  [ORDER_STATUSES.ACCOUNTING_CANCELLED_HARD_COST_PAYABLE]: ORDER_PANEL_ACTION_WITHOUT_CLONE,
  [ORDER_STATUSES.PENDING_ESTOPPEL]: DEFAULT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.INACTIVE]: ORDER_PANEL_ACTION_WITHOUT_CLONE,
  [ORDER_STATUSES.COMPLETED]: DEFAULT_ORDER_PANEL_ACTIONS.filter((action: PanelAction) => action.type !== 'complete'),
  [ORDER_STATUSES.UPDATED]: ORDER_PANEL_ACTION_WITHOUT_CLONE
};

export const DEFAULT_CLIENT_ORDER_PANEL_ACTIONS: Array<PanelAction> = [
  {
    type: 'clone',
    icon: 'repeat',
    ariaLabel: 'Create update order',
    tooltip: 'Create Update Order'
  },
  {
    type: 'cancel',
    icon: 'cancel',
    ariaLabel: 'Cancel order',
    tooltip: 'Cancel Order'
  }
];

export const CLIENT_ORDER_PANEL_ACTIONS: { [key: string]: Array<PanelAction> } = {
  [ORDER_STATUSES.NEW]: DEFAULT_CLIENT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.ASSIGNED]: DEFAULT_CLIENT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.RESEARCH]: DEFAULT_CLIENT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.TO_BE_BUILT]: DEFAULT_CLIENT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.REVIEW]: DEFAULT_CLIENT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.SENT_TO_CLIENT]: DEFAULT_CLIENT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.HOLD]: DEFAULT_CLIENT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.CANCELLATION_PENDING]: [],
  [ORDER_STATUSES.CANCELLED]: [],
  [ORDER_STATUSES.CANCELLED_HARD_COST_PAYABLE]: [], // TODO: impossible to find
  [ORDER_STATUSES.CANCELLED_ESTOPPEL_REFUND_NEEDED]: [],
  [ORDER_STATUSES.ACCOUNTING_CANCELLED]: [],
  [ORDER_STATUSES.ACCOUNTING_CANCELLED_HARD_COST_PAYABLE]: [],
  [ORDER_STATUSES.PENDING_ESTOPPEL]: DEFAULT_CLIENT_ORDER_PANEL_ACTIONS,
  /** Client does not have possibility to see inactive orders **/
  [ORDER_STATUSES.INACTIVE]: [],
  [ORDER_STATUSES.COMPLETED]: DEFAULT_CLIENT_ORDER_PANEL_ACTIONS,
  [ORDER_STATUSES.UPDATED]: DEFAULT_CLIENT_ORDER_PANEL_ACTIONS.filter((action: PanelAction) => action.type !== 'clone')
};

export const ORDER_TASK_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'create',
  icon: 'add',
  ariaLabel: 'Add order task',
  tooltip: 'Add Order Task'
}];

export const ORDER_NOTIFICATION_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'email',
  icon: 'email',
  ariaLabel: 'Send notification email',
  tooltip: 'Send Notification Email'
}];

export const ORDER_ACCOUNTING_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'invoice',
  icon: 'text_snippet',
  ariaLabel: 'Create invoice',
  tooltip: 'Create Invoice'
}];

export const ORDER_PAYMENT_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'make',
  icon: 'add',
  ariaLabel: 'Make payment',
  tooltip: 'Make Payment'
}];

export const ORDER_MARKETER_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'add',
  icon: 'add',
  ariaLabel: 'Add marketer',
  tooltip: 'Add Marketer'
}];

export const COMPANY_EMAIL_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'add',
  icon: 'add',
  ariaLabel: 'Add email',
  tooltip: 'Add Email'
}];

export const NOTE_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'create',
  icon: 'add',
  ariaLabel: 'Add note',
  tooltip: 'Add Note'
}];

export const FILE_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'upload',
  icon: 'add',
  ariaLabel: 'Add file',
  tooltip: 'Add File'
}];

export const PRICING_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'create',
  icon: 'add',
  ariaLabel: 'Add custom pricing',
  tooltip: 'Add Custom Pricing'
}];

export const DISCOUNT_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'create',
  icon: 'add',
  ariaLabel: 'Add discount',
  tooltip: 'Add Discount'
}];

export const SYSTEM_FLAG_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'create',
  icon: 'add',
  ariaLabel: 'Add system flag assignment',
  tooltip: 'Add Assignment'
}];

export const NEW_USER_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'smtp',
  icon: 'settings_applications',
  ariaLabel: 'Edit user smtp settings',
  tooltip: 'Edit SMTP Settings'
}];

export const FLAGS_CONFIGURATION_PANEL_ACTIONS: Array<PanelAction> = [{
  type: 'create',
  icon: 'add',
  ariaLabel: 'Add user order flag configuration',
  tooltip: 'Add Order Flag Configuration'
}];
