import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-total-pinned-row-renderer',
  templateUrl: './total-pinned-row-renderer.component.html'
})
export class TotalPinnedRowRendererComponent implements ICellRendererAngularComp {
  public params: unknown;

  public agInit(params: unknown): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
