import { Component, OnDestroy } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { Payment } from '@models/payment';

@Component({
  selector: 'app-note-detail-renderer',
  templateUrl: './note-detail-renderer.component.html'
})
export class NoteDetailRendererComponent implements ICellRendererAngularComp, OnDestroy {
  public params: ICellRendererParams;
  public isLink: boolean = false;
  public link!: string;

  private parentDetailRowEl!: HTMLElement;

  ngOnDestroy(): void {
    this.parentDetailRowEl.removeEventListener('click', this.onParentMasterRowClick);
  }

  public agInit(params: ICellRendererParams): void {
    const payment: Payment = params.data,
          isTransfer: boolean = !!payment.is_transfer,
          isRefund: boolean = !!payment.refund_identifier;

    this.params = params;
    this.parentDetailRowEl = params.eParentOfValue;
    this.isLink = isTransfer || isRefund;

    this.parentDetailRowEl.addEventListener('click', this.onParentMasterRowClick);

    if (this.isLink) {
      this.link = `/order-edit/${isTransfer ? payment.transferred_to_order_id : payment.order_id}`;
    }
  }

  public refresh(): boolean {
    return false;
  }

  private onParentMasterRowClick(event: MouseEvent): void {
    event.stopPropagation();
  }
}
