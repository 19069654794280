<div class="oes-integrations-panel-container">
  <app-panel header="Integration Information" [expanded]="true">
    <form [formGroup]="form" slot="body" novalidate>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <app-editor label="Integration Information" controlName="information"></app-editor>
          </div>
        </div>
        <div class="row justify-content-md-center">
          <div class="col-md-3">
            <button
              type="button"
              class="eos-btn-primary"
              [disabled]="!form.valid || form.pristine"
              mat-flat-button
              (click)="onUpdateBtnClick()">Update
            </button>
          </div>
        </div>
      </div>
    </form>
  </app-panel>
</div>
