import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PaymentApi } from '@api/payment.api';
import { FormValidationService } from '@services/form-validation.service';
import { PaymentService } from '@services/payment.service';
import { emptyValueValidator } from '@validators/empty-value.validator';
import { DialogData } from '@models/dialog';
import { BatchPaymentFormValue, Payment } from '@models/payment';

@Component({
  selector: 'app-refund-dialog',
  templateUrl: './refund-dialog.component.html'
})
export class RefundDialogComponent implements OnDestroy {
  public refundForm: UntypedFormGroup;

  public get amountControl(): UntypedFormControl {
    return <UntypedFormControl>this.refundForm.get('amount');
  }

  public get idControl(): UntypedFormControl {
    return <UntypedFormControl>this.refundForm.get('refund_identifier');
  }

  public get noteControl(): UntypedFormControl {
    return <UntypedFormControl>this.refundForm.get('notes');
  }

  public payment: Payment;

  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    public dialogRef: MatDialogRef<RefundDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private api: PaymentApi,
    private validationService: FormValidationService,
    private service: PaymentService
  ) {
    this.payment = this.data.payment;
    this.refundForm = this.createForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onRefundBtnClick(): void {
    const formValue: BatchPaymentFormValue = this.service.prepareBatchFormValue(this.refundForm.value, this.payment);

    this.api.createBatchPayment(formValue)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (): void => this.dialogRef.close(true),
        (error: string | Error): void => { }
      );
  }

  public getErrorMsg(control: UntypedFormControl, controlName: string): string {
    return this.validationService.getControlErrorMessage(control, controlName);
  }

  private createForm(): UntypedFormGroup {
    const requiredValidator: ValidatorFn = Validators.required,
          validators: Array<ValidatorFn> = [requiredValidator, emptyValueValidator],
          {available_balance} = this.payment;

    return this.formBuilder.group({
      amount: [available_balance, [
        requiredValidator,
        Validators.min(0.01),
        Validators.max(available_balance)]
      ],
      refund_identifier: [null, validators],
      notes: [null, validators]
    });
  }
}
