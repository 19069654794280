<div class="eos-editor-container">

  <label>{{label}}</label>

  <quill-editor
    format="html"
    bounds="self"
    [modules]="modules"
    [formControlName]="controlName"
    (onEditorCreated)="onEditorCreated($event)"
    (onFocus)="onFocus($event)"
    (onBlur)="onBlur($event)">
  </quill-editor>

  <mat-error *ngIf="!isValid">{{errorMsg}}</mat-error>

</div>
