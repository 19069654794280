import { ControlErrors, FormErrors } from '@models/form-errors';

export const ERROR_MESSAGES: ControlErrors = {
  required: 'Field is required',
  email: 'Invalid e-mail address',
  minlength: 'Value is too short',
  maxlength: 'Value is too long',
  valueNotSelected: 'Value is not selected',
  unknown: 'Unknown error'
};

export const FORM_ERRORS: FormErrors = {
  default: {
    required: ERROR_MESSAGES.required
  },

  username: {
    required: ERROR_MESSAGES.required,
    email: ERROR_MESSAGES.email,
    objectFound: 'Email already exists',
    hostnameUnknownTld: 'The input appears to be a DNS hostname but cannot match TLD against known list',
    emailAddressInvalidHostname: 'Invalid hostname',
    hostnameLocalNameNotAllowed: 'The input appears to be a local network name but local network names are not allowed'
  },

  password: {
    required: ERROR_MESSAGES.required,
    minlength: ERROR_MESSAGES.minlength,
    maxlength: ERROR_MESSAGES.maxlength,
    pattern: 'Password must have at least 1 letter and 1 number'
  },

  confirmPassword: {
    required: ERROR_MESSAGES.required,
    passwordNotConfirmed: 'Passwords did not match'
  },

  firstName: {
    required: ERROR_MESSAGES.required
  },

  lastName: {
    required: ERROR_MESSAGES.required
  },

  phone: {
    required: ERROR_MESSAGES.required,
    minlength: ERROR_MESSAGES.minlength,
    maxlength: ERROR_MESSAGES.maxlength,
    mask: 'Invalid phone number'
  },

  company: {
    required: ERROR_MESSAGES.required,
    valueNotSelected: ERROR_MESSAGES.valueNotSelected
  },

  folio: {
    required: ERROR_MESSAGES.required
  },

  address: {
    required: ERROR_MESSAGES.required
  },

  city: {
    required: ERROR_MESSAGES.required
  },

  state: {
    required: ERROR_MESSAGES.required
  },

  county: {
    required: ERROR_MESSAGES.required
  },

  zip: {
    required: ERROR_MESSAGES.required,
    stringLengthTooShort: ERROR_MESSAGES.minlength,
    pattern: 'Invalid zip code'
  },

  status: {
    required: ERROR_MESSAGES.required
  },

  flag: {
    required: ERROR_MESSAGES.required
  },

  user: {
    required: ERROR_MESSAGES.required,
    valueNotSelected: 'Value is not selected'
  },

  surveyType: {
    required: ERROR_MESSAGES.required
  },

  surveyContactName: {
    required: ERROR_MESSAGES.required
  },

  surveyInsurance: {
    required: ERROR_MESSAGES.required
  },

  date: {
    required: ERROR_MESSAGES.required
  },

  product: {
    required: ERROR_MESSAGES.required
  },

  loanMortgageCount: {
    minlength: ERROR_MESSAGES.minlength,
    maxlength: ERROR_MESSAGES.maxlength
  },

  loanPayoff: {
    pattern: 'Invalid original payoff'
  },

  loanAmountPaid: {
    pattern: 'Invalid amount paid'
  },

  assigned_qc: {
    required: ERROR_MESSAGES.required
  },

  assigned_researcher: {
    required: ERROR_MESSAGES.required
  },

  name: {
    required: ERROR_MESSAGES.required
  },

  description: {
    required: ERROR_MESSAGES.required
  },

  taskList: {
    required: ERROR_MESSAGES.required
  },

  email_template: {
    required: ERROR_MESSAGES.required
  },

  note: {
    required: ERROR_MESSAGES.required
  },

  price: {
    required: ERROR_MESSAGES.required
  },

  misc: {
    required: ERROR_MESSAGES.required
  },

  discount: {
    required: ERROR_MESSAGES.required
  },

  feeDiscount: {
    required: ERROR_MESSAGES.required
  },

  updateDiscount: {
    required: ERROR_MESSAGES.required
  },

  hard_cost: {
    required: ERROR_MESSAGES.required
  },

  shipping_handling: {
    required: ERROR_MESSAGES.required
  },

  shipping_handling_discount: {
    required: ERROR_MESSAGES.required
  },

  paymentAmount: {
    required: ERROR_MESSAGES.required,
    max: 'Value is greater than max',
    min: 'Value is less than min'
  },

  refundId: {
    required: ERROR_MESSAGES.required
  },

  orderId: {
    required: ERROR_MESSAGES.required,
    valueNotSelected: ERROR_MESSAGES.valueNotSelected
  },

  marketer: {
    required: ERROR_MESSAGES.required,
    valueNotSelected: ERROR_MESSAGES.valueNotSelected
  },

  product_id: {
    required: ERROR_MESSAGES.required
  },

  update_number: {
    required: ERROR_MESSAGES.required
  },

  is_active: {
    required: ERROR_MESSAGES.required
  },

  amount: {
    required: ERROR_MESSAGES.required
  },

  subject: {
    required: ERROR_MESSAGES.required
  },

  files_path: {
    required: ERROR_MESSAGES.required
  },

  key: {
    required: ERROR_MESSAGES.required
  },

  secret: {
    required: ERROR_MESSAGES.required
  },

  email: {
    required: ERROR_MESSAGES.required
  },

  host: {
    required: ERROR_MESSAGES.required
  },

  port: {
    required: ERROR_MESSAGES.required,
    pattern: 'Invalid port'
  },

  role: {
    required: ERROR_MESSAGES.required
  },

  label: {
    required: ERROR_MESSAGES.required
  },

  from: {
    required: ERROR_MESSAGES.required
  },

  to: {
    required: ERROR_MESSAGES.required,
    email: ERROR_MESSAGES.email
  },

  autocomplete: {
    required: ERROR_MESSAGES.required,
    valueNotSelected: ERROR_MESSAGES.valueNotSelected
  },

  clientUrl: {
    pattern: 'Invalid client url address'
  },

  id: {
    required: ERROR_MESSAGES.required,
  },

  api: {
    required: ERROR_MESSAGES.required,
  },

  url: {
    required: ERROR_MESSAGES.required,
  }
};
