import { Directive, HostBinding, HostListener, Input, Output } from '@angular/core';

import { Subject } from 'rxjs';

import { AuthService } from '@services/auth.service';
import { UtilitiesService } from '@services/utilities.service';
import { FILE_EXTENSIONS } from '@configs/file-extensions';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {
  @Input() validFileExtensions: Array<string> = FILE_EXTENSIONS;
  @Input() authorizedRoles: Array<string> = [];
  @Output() fileDrop: Subject<FileList> = new Subject<FileList>();

  private enteredCounter: number = 0;

  @HostBinding('class.active')
  public isDragEnter: boolean = false;

  @HostListener('dragenter', ['$event'])
  public onDragEnter(event: DragEvent): void {
    this.cancelEvent(event);

    if (this.isActionAllowed(event.dataTransfer.items)) {
      this.isDragEnter = true;
      this.enteredCounter++;
    }
  }

  @HostListener('dragover')
  public onDragOver(): boolean {
    return false;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: DragEvent): void {
    this.cancelEvent(event);

    if (this.isActionAllowed(event.dataTransfer.items)) {
      this.enteredCounter--;

      if (this.enteredCounter === 0) {
        this.isDragEnter = false;
      }
    }
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent) {
    this.cancelEvent(event);
    const files: FileList = event.dataTransfer.files;

    if (files.length > 0) {
      this.isDragEnter = false;
      this.fileDrop.next(files);
    }
  }

  constructor(private authService: AuthService, private utilitiesService: UtilitiesService) { }

  private cancelEvent(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private isActionAllowed(files: DataTransferItemList): boolean {
    return this.isFilesValid(files) && this.hasPermissions();
  }

  private isFilesValid(files: DataTransferItemList): boolean {
    return Array.from(files).some((file: DataTransferItem): boolean => {
      return this.utilitiesService.validateFileExtensions(file, this.validFileExtensions);
    });
  }

  private hasPermissions(): boolean {
    return this.authorizedRoles.length
      ? this.authorizedRoles.some((role: string): boolean => role === this.authService.role)
      : true;
  }
}
