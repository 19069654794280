import { Component, Injector, Input, ViewChild } from '@angular/core';

import { FileListComponent } from '@components/file/file-list/file-list.component';
import { UtilitiesService } from '@services/utilities.service';
import { FILE_PANEL_ACTIONS } from '@configs/panel';
import { FILE_EXTENSIONS } from '@configs/file-extensions';
import { PanelAction } from '@models/panel';

@Component({
  selector: 'app-file-panel',
  templateUrl: './file-panel.component.html'
})
export class FilePanelComponent<Api, Service> {
  @Input() public api!: Api;
  @Input() public service!: Service;
  @Input() public id!: string;
  @Input() public expanded: boolean = false;
  @Input() public isSelectable: boolean = false;
  @Input() validFileExtensions: Array<string> = FILE_EXTENSIONS;

  @ViewChild('fileListComponent') public listComp!: FileListComponent<Api, Service>;

  public panelActions: Array<PanelAction> = FILE_PANEL_ACTIONS;

  protected utilitiesService: UtilitiesService

  constructor(injector: Injector) {
    this.utilitiesService = injector.get(UtilitiesService);
  }

  public onPanelAction(type: string): void {
    switch (type) {
      case 'upload':
        this.openUploadDialog();
        break;
    }
  }

  public onFileDropped(fileList: FileList): void {
    this.listComp.onFileDropped(
      [
        ...Array.from(fileList).filter((file: File): boolean => {
          return this.utilitiesService.validateFileExtensions(file, this.validFileExtensions);
        })
      ]
    );
  }

  private openUploadDialog(): void {
    this.listComp.dragAndDropComp.triggerFileInputClick();
  }
}
