import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseApi } from '@api/base.api';
import { EmailApiEndPoints, QueryParams } from '@models/api';
import { TableData } from '@models/table';
import { Payload } from '@models/response';
import {
  EmailTemplate,
  EmailFormValue,
  EmailTemplateData,
  CompanyEmail,
  CompanyEmailsFormValue,
  EmailTemplateFormValue,
  SearchEmailTemplate
} from '@models/email';
import { File as FileModel, FileUpdateFormValue } from '@models/file';

@Injectable({
  providedIn: 'root'
})
export class EmailApi extends BaseApi<EmailApiEndPoints> {
  constructor(injector: Injector) {
    super(injector);

    this.endPoints = this.addBaseUrl({
      GET_TEMPLATE: '/emails/${id}',
      GET_TEMPLATES: '/emails',
      GET_TEMPLATE_FILES: '/emails/files/${id}/search/fields',
      GET_TEMPLATE_FILE: '/emails/files/${id}/download/${fileId}',
      GET_SEARCH_FOR_TEMPLATES: '/notification/email-templates',
      GET_EMAILS_BY_COMPANY_ID: '/company/ap-list/${companyId}',
      GET_TEMPLATE_DATA: '/send_email/formatted-template-data',
      POST_CREATE_TEMPLATE: '/email',
      POST_SEND_EMAIL: '/send_email',
      POST_UPDATE_EMAILS_BY_COMPANY_ID: '/company/ap-list/${companyId}',
      POST_UPLOAD_TEMPLATE_FILES: '/emails/upload_files/${id}',
      PUT_UPDATE_TEMPLATE: '/email/${id}',
      PUT_UPDATE_TEMPLATE_FILE: '/emails/files/${id}/edit/${fileId}'
    });
  }

  public getTemplate(id: string): Observable<EmailTemplate> {
    const url: string = this.substituteParameters(this.endPoints.GET_TEMPLATE, {id});
    return this.http
      .get<Payload<EmailTemplate>>(url)
      .pipe(
        map((res: Payload<EmailTemplate>) => res.item)
      );
  }

  public getTemplates(params?: QueryParams): Observable<TableData<EmailTemplate>> {
    return this.http.get<TableData<EmailTemplate>>(
      this.endPoints.GET_TEMPLATES,
      {params: this.setQueryParameters(params)}
    );
  }

  public getAllTemplates(): Observable<Array<EmailTemplate>> {
    return this.http
      .get<Payload<Array<EmailTemplate>>>(
        this.endPoints.GET_TEMPLATES,
        {params: this.setQueryParameters({show: 'all'})}
      )
      .pipe(
        map((res: Payload<Array<EmailTemplate>>) => res.items)
      );
  }

  public getSearchForTemplates(params?: QueryParams): Observable<TableData<SearchEmailTemplate>> {
    return this.http.get<TableData<SearchEmailTemplate>>(
      this.endPoints.GET_SEARCH_FOR_TEMPLATES,
      {params: this.setQueryParameters(params)}
    );
  }

  public getFiles(id: string, params?: QueryParams): Observable<unknown> {
    const url: string = this.substituteParameters(this.endPoints.GET_TEMPLATE_FILES, {id});
    return this.http.get<TableData<FileModel>>(url, {params: this.setQueryParameters(params)});
  }

  public getFile(id: string, fileId: string): Observable<string> {
    const url: string = this.substituteParameters(this.endPoints.GET_TEMPLATE_FILE, {id, fileId});
    return this.http
      .get<Payload<string>>(url)
      .pipe(
        map((res: Payload<string>) => res.url)
      );
  }

  public getEmails(companyId: string): Observable<Array<CompanyEmail>> {
    const url: string = this.substituteParameters(this.endPoints.GET_EMAILS_BY_COMPANY_ID, {companyId});
    return this.http
      .get<Payload<Array<CompanyEmail>>>(url)
      .pipe(
        map((res: Payload<Array<CompanyEmail>>) => res.ap_list)
      );
  }

  public getTemplateTableData(params?: QueryParams): Observable<TableData<EmailTemplate>> {
    return this.http.get<TableData<EmailTemplate>>(
      this.endPoints.GET_TEMPLATES,
      {params: this.setQueryParameters(params)}
    );
  }

  public getTemplateData(templateId: string, orderId?: string): Observable<EmailTemplateData> {
    return this.http.get<EmailTemplateData>(
      this.endPoints.GET_TEMPLATE_DATA,
      {params: this.setQueryParameters({template_id: templateId, order_id: orderId})}
    );
  }

  public createTemplate(formValue: EmailTemplateFormValue, files: Array<File> = []): Observable<EmailTemplate> {
    return this.http
      .post<Payload<EmailTemplate>>(this.endPoints.POST_CREATE_TEMPLATE, this.prepareFormDataBody(formValue, files))
      .pipe(
        map((res: Payload<EmailTemplate>) => res.item)
      );
  }

  public sendEmail(formValue: EmailFormValue, files: Array<File> = []): Observable<null> {
    return this.http.post<null>(this.endPoints.POST_SEND_EMAIL, this.prepareFormDataBody(formValue, files));
  }

  public updateEmails(companyId: string, formValue: CompanyEmailsFormValue): Observable<Array<CompanyEmail>> {
    const url: string = this.substituteParameters(this.endPoints.POST_UPDATE_EMAILS_BY_COMPANY_ID, {companyId});
    return this.http
      .post<Payload<Array<CompanyEmail>>>(url, formValue)
      .pipe(
        map((res: Payload<Array<CompanyEmail>>) => res.ap_list)
      );
  }

  public uploadFiles(id: string, files: Array<File>): Observable<null> {
    const url: string = this.substituteParameters(this.endPoints.POST_UPLOAD_TEMPLATE_FILES, {id});
    return this.http.post<null>(url, this.prepareFormDataBody(null, files));
  }

  public updateTemplate(id: string, formValue: EmailTemplateFormValue): Observable<EmailTemplate> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_TEMPLATE, {id});
    return this.http
      .put<Payload<EmailTemplate>>(url, formValue)
      .pipe(
        map((res: Payload<EmailTemplate>) => res.item)
      );
  }

  public updateFile(fileId: string, formValue: FileUpdateFormValue, id: string): Observable<FileModel> {
    const url: string = this.substituteParameters(this.endPoints.PUT_UPDATE_TEMPLATE_FILE, {id, fileId});
    return this.http
      .put<Payload<FileModel>>(url, formValue)
      .pipe(
        map((res: Payload<FileModel>) => res.item)
      );
  }
}
