<div class="eos-autocomplete-container">
  <mat-form-field [ngClass]="classList">

    <mat-label>{{label}}</mat-label>

    <input
      type="text"
      [placeholder]="placeholder"
      [readonly]="isReadonly"
      [attr.aria-label]="ariaLabel"
      [formControl]="inputControl"
      [matAutocomplete]="autocomplete"
      matInput
      #inputEl
      (input)="onInput()"
      (blur)="onBlur()">
    <button
      *ngIf="needIcon"
      type="button"
      [disabled]="inputControl.disabled"
      [attr.aria-label]="'Show user'"
      mat-icon-button
      matPrefix
      (click)="onPrefixIconBtnClicked()">
      <mat-icon>person</mat-icon>
    </button>

    <mat-hint *ngIf="hint">{{hint}}</mat-hint>

    <mat-error *ngIf="!isValid && (inputControl.dirty || inputControl.touched)">{{errorMsg}}</mat-error>

    <mat-autocomplete
      [displayWith]="displayFn"
      autoActiveFirstOption
      #autocomplete
      (optionSelected)="onSelected($event)">
      <mat-option
        *ngFor="let item of items$ | async"
        [ngClass]="{'fw-medium': item['isAccountant'] || item['isPayable']}"
        [value]="item"
        [disabled]="item['active'] === '0'">
        {{item['displayName']}}
      </mat-option>
    </mat-autocomplete>

  </mat-form-field>
</div>
