import { Component } from '@angular/core';

import { NotificationComponent } from '@components/notifications/notification.component';

@Component({
  selector: 'app-notification-error',
  templateUrl: './error-notification.component.html'
})
export class ErrorNotificationComponent extends NotificationComponent<ErrorNotificationComponent> {
  constructor() {
    super();
  }
}
