import { Pipe, PipeTransform } from '@angular/core';

import { Flag } from '@models/flag';
import { AutocompleteValue } from '@models/autocomplete';

@Pipe({
  name: 'flagClass'
})
export class FlagClassPipe implements PipeTransform {
  public transform(flag: AutocompleteValue): string {
    return `eos-flag-${(<Flag>flag).color || (<Flag>flag).flag_color}`;
  }
}
