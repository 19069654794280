<div [ngClass]="classList">
  <div *ngIf="form" [formGroup]="form">
    <app-reactive-select
      [label]="label"
      [aria-label]="ariaLabel"
      [control]="control"
      [items]="items">
    </app-reactive-select>
  </div>
</div>
