<div [ngClass]="classList">
  <app-select
    [label]="label"
    [aria-label]="ariaLabel"
    [items]="items"
    [selected]="selected"
    [panelClassList]="panelClassList"
    (openedChanged)="onOpenedChanged($event)"
    (selectionChanged)="onSelectionChanged($event)">
  </app-select>
</div>
