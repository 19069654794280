<div class="eos-table-pagination-container">

  <div class="eos-table-pagination-page-size">
    <app-select
      label="Page Size"
      aria-label="Table pagination page size"
      [items]="pageSizeItems"
      [selected]="pageSizeItem"
      (selectionChanged)="onPageSizeChanged($event)">
    </app-select>
  </div>

  <div class="eos-table-pagination-row-summary-container">
    <div class="eos-table-pagination-row-summary">
      <span>{{ firstRow }}</span>
      <span> to </span>
      <span>{{ lastRow }}</span>
      <span> of </span>
      <span>{{ rowCount }}</span>
    </div>

    <div class="eos-table-pagination-controls">

      <button
        type="button"
        mat-icon-button
        [disabled]="isPreviousBtnDisabled"
        (click)="onGoToFirstPageBtnClick()">
        <mat-icon>first_page</mat-icon>
      </button>

      <button
        type="button"
        mat-icon-button
        [disabled]="isPreviousBtnDisabled"
        (click)="onGoToPreviousPageBtnClick()">
        <mat-icon>navigate_before</mat-icon>
      </button>

      <div class="eos-table-pagination-pages">
        <span>Page </span>
        <mat-form-field appearance="outline">
          <input
            type="number"
            min="1"
            aria-label="Current page"
            [formControl]="currentPageControl"
            matInput
            (blur)="onCurrentPageChanged()"
            (keyup)="onCurrentPageKeyup($event)">
        </mat-form-field>
        <span> of </span>
        <span>{{ pageCount }}</span>
      </div>

      <button
        type="button"
        mat-icon-button
        [disabled]="isNextBtnDisabled"
        (click)="onGoToNextPageBtnClick()">
        <mat-icon>navigate_next</mat-icon>
      </button>

      <button
        type="button"
        mat-icon-button
        [disabled]="isNextBtnDisabled"
        (click)="onGoToLastPageBtnClick()">
        <mat-icon>last_page</mat-icon>
      </button>

    </div>
  </div>

</div>
