import { Injectable } from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { Flag, Flags } from '@models/flag';

@Injectable({
  providedIn: 'root'
})
export class FlagContext {
  public flags$: ReplaySubject<Flags> = new ReplaySubject<Flags>(1);

  public roleFlags$: BehaviorSubject<Array<Flag>> = new BehaviorSubject<Array<Flag>>([]);
  public systemFlags$: BehaviorSubject<Array<Flag>> = new BehaviorSubject<Array<Flag>>([]);
  public userFlags$: ReplaySubject<Array<Flag>> = new ReplaySubject<Array<Flag>>(1);

  public roleSystemFlags$: BehaviorSubject<Array<Flag>> = new BehaviorSubject<Array<Flag>>([]);

  public systemFlagSettings$: BehaviorSubject<Array<Flag>> = new BehaviorSubject<Array<Flag>>([]);

  public orderFlags$: BehaviorSubject<Array<Flag>> = new BehaviorSubject<Array<Flag>>([]);

  public nextFlags(flags: Flags | null): void {
    const {roleFlags = [], systemFlags = [], userFlags = []} = flags || { };
    this.flags$.next(flags);

    this.nextRoleFlags(roleFlags);
    this.nextSystemFlags(systemFlags);
    this.nextUserFlags(userFlags);

    this.nextRoleSystemFlags([...roleFlags, ...systemFlags]);
  }

  public nextRoleFlags(flags: Array<Flag>): void {
    this.roleFlags$.next(flags);
  }

  public nextSystemFlags(flags: Array<Flag>): void {
    this.systemFlags$.next(flags);
  }

  public nextUserFlags(flags: Array<Flag>): void {
    this.userFlags$.next(flags);
  }

  public nextRoleSystemFlags(flags: Array<Flag>): void {
    this.roleSystemFlags$.next(flags);
  }

  public nextSystemFlagSettings(flags: Array<Flag>): void {
    this.systemFlagSettings$.next(flags);
  }

  public nextOrderFlags(flags: Array<Flag>): void {
    this.orderFlags$.next(flags);
  }

  public getFlagById(id: number | string): Flag {
    const flags: Array<Flag> = this.roleSystemFlags$.getValue();
    return flags.find((flag: Flag) => (flag.id || flag.flag_id || flag.order_flag_id) === id);
  }
}
