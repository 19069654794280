import { Injectable } from '@angular/core';
import { Resolve, } from '@angular/router';

import { FlagApi } from '@api/flag.api';
import { FlagContext } from '@contexts/flag.context';
import { Flags } from '@models/flag';

@Injectable({
  providedIn: 'root'
})
export class FlagResolver implements Resolve<void> {
  constructor(private api: FlagApi, private context: FlagContext) { }

  public resolve(): void {
    this.api.getFlags({limit: 'all'}).subscribe(
      (flags: Flags) => this.context.nextFlags(flags),
      (error: string | Error) => { }
    );
  }
}
