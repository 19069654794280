import { Component } from '@angular/core';

import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { IconRendererParams } from '@models/renderer-parameters';

@Component({
  selector: 'app-icon-renderer',
  templateUrl: './icon-renderer.component.html'
})
export class IconRendererComponent implements AgRendererComponent {
  public customParams!: Array<IconRendererParams>;

  private params!: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.customParams = params.value;
  }

  public refresh(): boolean {
    return false;
  }
}
