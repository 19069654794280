import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';

import { iif, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { FormValidationService } from '@services/form-validation.service';
import { UtilitiesService } from '@services/utilities.service';
import { emptyValueValidator } from '@validators/empty-value.validator';
import { ERROR_MESSAGES } from '@configs/form-errors';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import {
  AutocompleteChangeValueEvent,
  AutocompleteUserInputValueGetter,
  AutocompleteValue
} from '@models/autocomplete';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => AutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AutocompleteComponent,
      multi: true
    }
  ]
})
export class AutocompleteComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit, ControlValueAccessor, Validator {
  @Input() public label!: string;
  @Input('class') public classList!: string;
  @Input() public placeholder: string = 'Search for...';
  @Input() public isRequired: boolean = false;
  @Input() public isDisabled: boolean = false;
  @Input() public isReadonly: boolean = false;
  @Input('aria-label') public ariaLabel: string = 'Autocomplete';
  @Input() public allItems: Array<AutocompleteValue> = [];
  @Input() public needValidation: boolean = false;
  @Input() public hint: string | null = null;
  @Input() public addKeywordFromInput: boolean = false;

  @Output() public valueChange: Subject<AutocompleteChangeValueEvent> = new Subject<AutocompleteChangeValueEvent>();
  @Output() public inputValueChange: Subject<string> = new Subject<string>();

  @ViewChild('inputEl', {static: true}) public inputEl: ElementRef<HTMLInputElement>;

  public inputControl: UntypedFormControl;

  public items$!: Observable<Array<AutocompleteValue>>;
  public filteredItems: Array<AutocompleteValue> = [];
  public selectedItem: AutocompleteValue | null = null;

  public isValid: boolean = true;
  public errorMsg: string = '';

  public touched: boolean = false;

  protected formBuilder: UntypedFormBuilder;
  protected validationService: FormValidationService;
  protected utilitiesService: UtilitiesService;

  protected keywordFromInput: boolean = false;

  protected readonly destroy$: Subject<void> = new Subject();

  protected readonly startWith: boolean = true;
  protected readonly distinctUntilChanged: boolean = true;
  protected readonly switchMap: boolean = false;

  private injector: Injector;
  private ngControl: NgControl;

  constructor(injector: Injector) {
    this.injector = injector;

    this.formBuilder = this.injector.get(UntypedFormBuilder);
    this.validationService = this.injector.get(FormValidationService);
    this.utilitiesService = this.injector.get(UtilitiesService);

    this.inputControl = this.formBuilder.control(null);
  }

  ngOnInit(): void {
    if (this.isDisabled) { return; }

    this.setValidators();

    this.items$ = this.getItems();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngDoCheck(): void {
    if (this.touched !== this.ngControl?.touched) {
      this.markAsTouched();
    }
  }

  ngAfterViewInit(): void {
    this.ngControl = this.injector.get(NgControl);
  }

  public onChange(_: any): void { };

  public onTouched(): void { };

  public onValidationChange(): void { };

  public onBlur(): void {
    this.markAsTouched();
  }

  public onInput(): void {
    const value: string = this.inputControl.value,
          needRemoveSelectedItem: boolean = !!this.selectedItem;

    if (needRemoveSelectedItem) {
      this.valueChange.next({type: 'remove', item: this.selectedItem});

      this.selectedItem = null;

      this.nextValue();
    }

    /** Have a problem related to https://github.com/angular/angular/issues/25749 **/
    this.onValidationChange();

    this.inputValueChange.next(value);
  }

  public onSelected(event: MatAutocompleteSelectedEvent): void {
    const value: AutocompleteValue = event.option.value;

    this.markAsTouched();

    this.selectedItem = value;

    this.nextValue({type: 'add', item: value});
  }

  public writeValue(value: string | AutocompleteValue | null): void {
    this.inputControl.setValue(value);
    this.selectedItem = value;
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  public markAsTouched(): void {
    if (this.touched) { return; }

    this.onTouched();
    this.touched = true;

    this.inputControl.markAsTouched();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;

    if (isDisabled) {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
  }

  public validate(): ValidationErrors | null {
    const requiredError: ValidationErrors = {required: true},
          valueNotSelectedError: ValidationErrors = {valueNotSelected: true},
          inputValue: AutocompleteValue | null = this.inputControl.value;
    let res: ValidationErrors | null = null;
    if (!this.needValidation) { return res; }

    this.isValid = !!this.selectedItem;

    if (!this.isValid) {
      res = inputValue && this.isString(inputValue) && this.trim(inputValue) !== ''
        ? valueNotSelectedError
        : requiredError;

      this.setError(res);
    }

    return res;
  }

  protected onInputValueChange(query: string | AutocompleteValue): Observable<Array<AutocompleteValue>> {
    return iif(
      (): boolean => this.isString(query),
      this.searchByQuery(<string>query),
      of(this.filterBySelected())
    );
  }

  protected searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return of([]);
  }

  protected filterBySelected(): Array<AutocompleteValue> {
    return this.selectedItem
      ? this.allItems.filter((item: AutocompleteValue): boolean => {
        return this.isString(item)
          ? item !== this.selectedItem
          : item.id !== (<Exclude<AutocompleteValue, string>>this.selectedItem).id
      })
      : this.allItems;
  }

  protected filterByQuery(query: string): Array<AutocompleteValue> {
    return this.filteredItems.filter((item: string): boolean => item.toLowerCase().includes(query));
  }

  protected setValidators(): void {
    if (this.needValidation) {

      if (this.isRequired) {
        this.inputControl.addValidators([Validators.required, emptyValueValidator]);
      }

      this.inputControl.updateValueAndValidity();
    }
  }

  protected nextValue(event?: AutocompleteChangeValueEvent): void {
    this.onChange(this.selectedItem);

    if (event) {
      this.valueChange.next(event);
    }
  }

  protected setError(errors: ValidationErrors): void {
    this.errorMsg = '';

    Object.entries(errors).forEach(([name, value]) => {
      if (!this.inputControl.hasError(name)) {
        this.inputControl.setErrors({name: value});
      }

      this.errorMsg += `${ERROR_MESSAGES[name]}\n`;
    });
  }

  protected composeAutocompleteValues(value?: unknown): Array<AutocompleteValue> {
    const res: Array<AutocompleteValue> = this.filterBySelected();
    return res.length ? res : EMPTY_AUTOCOMPLETE_VALUE;
  }

  protected composeAutocompleteValuesWithCustomUserInput(
    query: string,
    entityGetter?: AutocompleteUserInputValueGetter,
    values?: Array<AutocompleteValue>
  ): Array<AutocompleteValue> {
    let res: Array<AutocompleteValue> = this.filterBySelected();

    this.keywordFromInput = !res.length && query !== '';

    if (this.keywordFromInput) {
      res = this.addKeywordFromInput ? [entityGetter.call(this, this.inputControl.value)] : EMPTY_AUTOCOMPLETE_VALUE;
    }

    return res;
  }

  protected isString(value: AutocompleteValue): value is string {
    return this.utilitiesService.isString(value);
  }

  protected trim(value: AutocompleteValue): AutocompleteValue {
    return <AutocompleteValue>this.utilitiesService.trim(value);
  }

  protected getItems(): Observable<Array<AutocompleteValue>> {
    return this.inputControl.valueChanges.pipe(
      this.startWith ? startWith('') : tap(),
      debounceTime(400),
      map((value: AutocompleteValue): AutocompleteValue => this.trim(value)),
      this.distinctUntilChanged ? distinctUntilChanged() : tap(),
      this.switchMap
        ? switchMap((query: string | AutocompleteValue): Observable<Array<AutocompleteValue>> =>
          this.onInputValueChange(query)
        )
        : map((query: string): Array<AutocompleteValue> => this.filterByQuery(query))
    );
  }
}
