<app-dialog [header]="data.header" [cancelBtnLabel]="cancelBtnLabel">
  <div slot="content">
    <h4>{{data.title}}</h4>
    <p>{{data.message}}</p>
  </div>
  <button
    type="button"
    class="eos-btn-primary"
    mat-flat-button
    slot="action"
    [mat-dialog-close]="true">
    {{actionBtnLabel}}
  </button>
</app-dialog>
