<div class="eos-table-container">
  <ag-grid-angular
    class="ag-theme-material eos-table {{config.tableClass}}"
    [rowModelType]="config.rowModelType"
    [serverSideDatasource]="serverSideDatasource"
    [serverSideInfiniteScroll]="config.serverSideInfiniteScroll"
    [rowBuffer]="config.rowBuffer"
    [rowData]="rowData"
    [context]="config.context"
    [columnDefs]="config.colDefs"
    [defaultColDef]="config.defaultColDef"
    [domLayout]="config.domLayout"
    [pagination]="config.pagination"
    [paginationPageSize]="config.paginationPageSize"
    [cacheBlockSize]="config.cacheBlockSize"
    [masterDetail]="config.masterDetail"
    [detailCellRenderer]="config.detailCellRenderer"
    [isRowMaster]="config.isRowMaster"
    [detailRowHeight]="config.detailRowHeight"
    [animateRows]="config.animateRows"
    [rowHeight]="config.rowHeight"
    [rowSelection]="config.rowSelection"
    [getRowId]="config.getRowId"
    [getRowClass]="config.getRowClass"
    [getRowHeight]="config.getRowHeight"
    [rowClassRules]="config.rowClassRules"
    [pinnedBottomRowData]="config.pinnedBottomRowData"
    [suppressCellFocus]="config.suppressCellFocus"
    [suppressRowClickSelection]="config.suppressRowClickSelection"
    [suppressContextMenu]="config.suppressContextMenu"
    [suppressLoadingOverlay]="config.suppressLoadingOverlay"
    [suppressColumnMoveAnimation]="config.suppressColumnMoveAnimation"
    [suppressRowVirtualisation]="config.suppressRowVirtualisation"
    [suppressPaginationPanel]="config.suppressPaginationPanel"
    [enableCellTextSelection]="config.enableCellTextSelection"
    [tabToNextCell]="config.tabToNextCell"
    [debug]="config.debug"
    [debounceVerticalScrollbar]="config.debounceVerticalScrollbar"
    [stopEditingWhenCellsLoseFocus]="config.stopEditingWhenCellsLoseFocus"
    [statusBar]="config.statusBar"
    (gridReady)="config.gridReady($event)"
    (firstDataRendered)="config.onFirstDataRendered($event)"
    (gridSizeChanged)="config.onGridSizeChanged($event)"
    (rowSelected)="config.onRowSelected($event)"
    (selectionChanged)="config.onSelectionChanged($event)"
    (rowClicked)="config.onRowClicked($event)"
    (cellClicked)="config.onCellClicked($event)"
    (paginationChanged)="config.onPaginationChanged($event)"
    (window:resize)="gridApi.sizeColumnsToFit()"
    (cellKeyDown)="config.onCellKeyDown($event)">
  </ag-grid-angular>
</div>
