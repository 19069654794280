<div class="eos-array-group-container">
  <form [formGroup]="form">
    <fieldset>

      <legend>
        {{legend}}
        <button
          type="button"
          class="eos-btn-icon-primary"
          [disabled]="isDisabled"
          aria-label="Add new form group"
          mat-icon-button
          (click)="onAddBtnClick()">
          <mat-icon>add</mat-icon>
        </button>
      </legend>

      <ng-container formArrayName="arrayGroup">
        <div *ngFor="let control of arrayControl.controls; index as i">
          <mat-form-field>
            <mat-label>{{config.label}}</mat-label>
            <input
              [type]="config.type"
              [placeholder]="config.placeholder"
              [attr.autocomplete]="config.autocomplete"
              [attr.aria-label]="config.ariaLabel"
              [formControlName]="i"
              matInput
              (input)="onInput()">
            <mat-error *ngIf="config.required && control.invalid && (control.dirty || control.touched)">
              Field is required
            </mat-error>
          </mat-form-field>
          <button
            *ngIf="i > 0"
            type="button"
            class="eos-btn-delete"
            aria-label="Delete form group"
            mat-icon-button
            (click)="onDeleteBtnClick(i)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </ng-container>

    </fieldset>
  </form>
</div>
