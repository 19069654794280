import {
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  Validators,
  NG_VALIDATORS,
  Validator,
  ValidationErrors
} from '@angular/forms';

import { Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { FormValidationService } from '@services/form-validation.service';
import { UtilitiesService } from '@services/utilities.service';
import { ERROR_MESSAGES } from '@configs/form-errors';
import { EMPTY_AUTOCOMPLETE_VALUE } from '@configs/autocomplete';
import { AutocompleteChangeValueEvent, AutocompleteValue } from '@models/autocomplete';

@Component({
  selector: 'app-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): any => ChipsAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ChipsAutocompleteComponent,
      multi: true
    }
  ]
})
export class ChipsAutocompleteComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor, Validator {
  @Input() public label!: string;
  @Input() public placeholder: string = 'Search for...';
  @Input('class') public classList!: string;
  @Input() public isRequired: boolean = false;
  @Input() public isReadonly: boolean = false;
  @Input() public isDisabled: boolean = false;
  @Input('aria-label') public ariaLabel: string = 'Autocomplete';
  @Input() public allItems: Array<AutocompleteValue> = [];
  @Input() public selectedItems: Array<AutocompleteValue> = [];
  @Input() public needValidation: boolean = false;
  @Input() public needSelectAllControls: boolean = true;
  @Input() public hint: string | null = null;

  @Output() public valueChange: Subject<AutocompleteChangeValueEvent> = new Subject<AutocompleteChangeValueEvent>();
  @Output() public inputValueChange: Subject<string> = new Subject<string>();

  @ViewChild('inputEl', {static: true}) public inputEl!: ElementRef<HTMLInputElement>;

  public listControl: UntypedFormControl;
  public inputControl: UntypedFormControl;

  public items$!: Observable<Array<AutocompleteValue>>;
  public filteredItems: Array<AutocompleteValue> = [];

  public isValid: boolean = true;
  public errorMsg: string = '';

  public touched: boolean = false;

  public startWith: boolean = true;

  protected formBuilder: UntypedFormBuilder;
  protected validationService: FormValidationService;
  protected utilitiesService: UtilitiesService;

  protected readonly destroy$: Subject<void> = new Subject();

  protected readonly switchMap: boolean = false;

  constructor(injector: Injector) {
    this.formBuilder = injector.get(UntypedFormBuilder);
    this.validationService = injector.get(FormValidationService);
    this.utilitiesService = injector.get(UtilitiesService);

    this.listControl = this.formBuilder.control(null);
    this.inputControl = this.formBuilder.control(null);
  }

  ngOnInit(needSetItems: boolean = true): void {
    this.setDisabledState(this.isDisabled);
    this.setValidators();

    this.filteredItems = this.filterBySelected();

    if (needSetItems) {
      this.items$ = this.getItems();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const allItemsChange: SimpleChange | undefined = changes.allItems;

    if (allItemsChange && !allItemsChange.firstChange) {
      this.refreshControl();
    }
  }

  public onChange(_: any): void { };

  public onTouched(): void { };

  public onValidationChange(): void { };

  public onBlur(event: FocusEvent): void {
    this.markAsTouched();
  }

  public onInput(): void {
    this.inputValueChange.next(this.inputControl.value);

    this.onValidationChange();
  }

  public onRemoveBtnClick(item: AutocompleteValue): boolean | void {
    const index: number = this.getIndex(item);
    this.selectedItems.splice(index, 1);

    this.onValueChange();
    this.nextValue({type: 'remove', item});
  }

  public onSelected(event: MatAutocompleteSelectedEvent): void {
    const value: AutocompleteValue = event.option.value;

    this.selectedItems.push(value);

    this.onValueChange();
    this.nextValue({type: 'add', item: value});
  }

  public onSelectAllClick(): void {
    this.selectedItems = this.allItems.filter((item: AutocompleteValue): boolean => {
      let res: boolean = true;

      if ((<any>item)?.active) {
        res = (<any>item)?.active === '1';
      }

      return res;
    });

    this.onValueChange();
    this.nextValue();

    this.setListDirtyAndTouched(true);
  }

  public onUnselectAllClick(): void {
    this.selectedItems = [];

    this.onValueChange();
    this.nextValue();

    this.setListDirtyAndTouched(true);
  }

  public writeValue(value: string | Array<AutocompleteValue> | null): void {
    this.selectedItems = value ? [...value] : [];
    this.filteredItems = this.filterBySelected();

    this.listControl.setValue(this.selectedItems);

    this.nextValue();
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  public markAsTouched(): void {
    if (this.touched) { return; }

    this.onTouched();
    this.touched = true;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;

    if (isDisabled) {
      this.listControl.disable();
    } else {
      this.listControl.enable();
    }
  }

  public validate(): ValidationErrors | null {
    const inputValue: AutocompleteValue | null = this.inputControl.value;
    let res: ValidationErrors | null = null;
    if (!this.needValidation) { return res; }

    this.isValid = !!this.selectedItems.length;

    if (!this.isValid) {

      if (inputValue && this.isString(inputValue) && this.trim(inputValue) !== '') {
        this.setError('valueNotSelected');
        res = {required: true};
      } else {
        this.setError('required');
        res = {valueNotSelected: true};
      }

    }

    return res;
  }

  protected onValueChange(): void {
    this.listControl.setValue(this.selectedItems);

    this.markAsTouched();

    this.cleanInput();
  }

  protected searchByQuery(query: string): Observable<Array<AutocompleteValue>> {
    return of([]);
  }

  protected filterBySelected(): Array<AutocompleteValue> {
    return this.allItems.filter(
      (item: AutocompleteValue): boolean => {
        return this.isString(item)
          ? !this.selectedItems.includes(item)
          : !this.selectedItems.find(
            (i: AutocompleteValue): boolean => (<Exclude<AutocompleteValue, string>>i).id === item.id
          )
      }
    );
  }

  protected filterByQuery(query: string): Array<AutocompleteValue> {
    return this.filteredItems.filter((item: string): boolean => item.toLowerCase().includes(query));
  }

  protected cleanInput(): void {
    this.inputEl.nativeElement.value = '';
    this.inputControl.setValue('');

    this.setInputDirtyAndTouched(false);

    this.filteredItems = this.filterBySelected();
  }

  protected cleanList(): void {
    this.listControl.setValue(this.selectedItems);

    this.setListDirtyAndTouched(false);
  }

  protected refreshControl(): void {
    this.selectedItems = [];

    this.cleanInput();
    this.cleanList();
  }

  protected getIndex(item: AutocompleteValue): number {
    return this.isString(item)
      ? this.selectedItems.indexOf(item)
      : this.selectedItems.findIndex(
        (i: AutocompleteValue): boolean => (<Exclude<AutocompleteValue, string>>i).id === item.id
      );
  }

  protected setValidators(): void {
    if (this.needValidation) {

      if (this.isRequired) {
        this.listControl.addValidators(Validators.required);
      }

      this.listControl.updateValueAndValidity();
    }
  }

  protected nextValue(event?: AutocompleteChangeValueEvent): void {
    this.onChange(this.selectedItems);

    if (event) {
      this.valueChange.next(event);
    }
  }

  protected composeAutocompleteValues(value?: unknown): Array<AutocompleteValue> {
    const res: Array<AutocompleteValue> = this.filterBySelected();
    return res.length ? res : EMPTY_AUTOCOMPLETE_VALUE;
  }

  protected isString(value: AutocompleteValue): value is string {
    return this.utilitiesService.isString(value);
  }

  protected trim(value: AutocompleteValue): AutocompleteValue {
    return <AutocompleteValue>this.utilitiesService.trim(value);
  }

  protected getItems(): Observable<Array<AutocompleteValue>> {
    return this.inputControl.valueChanges.pipe(
      this.startWith ? startWith('') : tap(),
      debounceTime(400),
      filter((query: AutocompleteValue): boolean => this.isString(query)),
      this.switchMap
        ? switchMap((query: string): Observable<Array<AutocompleteValue>> => this.searchByQuery(query))
        : map((query: string): Array<AutocompleteValue> => this.filterByQuery(query))
    );
  }

  private setError(errorCode: string): void {
    this.errorMsg = ERROR_MESSAGES[errorCode];
  }

  private setInputDirtyAndTouched(isDirtyAndTouched: boolean): void {
    if (isDirtyAndTouched) {
      this.inputControl.markAsDirty();
      this.inputControl.markAsTouched();
    } else {
      this.inputControl.markAsPristine();
      this.inputControl.markAsUntouched();
    }
  }

  private setListDirtyAndTouched(isDirtyAndTouched: boolean): void {
    if (isDirtyAndTouched) {
      this.listControl.markAsDirty();
      this.listControl.markAsTouched();
    } else {
      this.listControl.markAsPristine();
      this.listControl.markAsUntouched();
    }
  }
}
