<div class="eos-autocomplete-container">
  <mat-form-field [ngClass]="classList">

    <mat-label>{{label}}</mat-label>

    <input
      type="text"
      [placeholder]="placeholder"
      [readonly]="isReadonly"
      [attr.aria-label]="ariaLabel"
      [formControl]="inputControl"
      [matAutocomplete]="autocomplete"
      matInput
      (input)="onInput()"
      (blur)="onBlur()">
    <button
      *ngIf="needIcon"
      type="button"
      [disabled]="inputControl.disabled"
      [attr.aria-label]="'Link to the selected company'"
      mat-icon-button
      matPrefix
      (click)="onPrefixIconBtnClicked()">
      <mat-icon>home</mat-icon>
    </button>

    <mat-error *ngIf="!isValid && (inputControl.dirty || inputControl.touched)">{{errorMsg}}</mat-error>

    <mat-autocomplete
      [displayWith]="displayFn"
      autoActiveFirstOption
      #autocomplete
      (optionSelected)="onSelected($event)">
      <mat-option *ngFor="let item of items$ | async" [value]="item" [disabled]="item['active'] === '0'">
        {{item | companyViewValue}}
      </mat-option>
    </mat-autocomplete>

  </mat-form-field>
</div>
