import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { Subject, takeUntil } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { RowNode } from 'ag-grid-enterprise';

import { DragDropComponent } from '@components/drag-drop/drag-drop.component';
import { FilePanelComponent } from '@components/file/file-panel/file-panel.component';
import { EmailApi } from '@api/email.api';
import { OrderApi } from '@api/order.api';
import { EmailService } from '@services/email.service';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { OrderService } from '@services/order.service';
import { FormValidationService } from '@services/form-validation.service';
import { TimeService } from '@services/time.service';
import { NotificationService } from '@services/notification.service';
import { emptyValueValidator } from '@validators/empty-value.validator';
import { USER_ROLES } from '@configs/role-matrix';
import { EmailTemplateData, SearchEmailTemplate } from '@models/email';
import { DialogData } from '@models/dialog';
import { User } from '@models/user';
import { Order } from '@models/order';
import { ChipListItem } from '@models/chip-list';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html'
})
export class EmailDialogComponent implements OnInit, OnDestroy {
  @ViewChild(DragDropComponent) private dragAndDropComp!: DragDropComponent;
  @ViewChild(FilePanelComponent) private filePanelComp!: FilePanelComponent<OrderApi, OrderService>;

  public form: UntypedFormGroup;

  public get templateControl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('template');
  }

  public get subjectControl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('subject');
  }

  public companyId!: string;

  public header: string = 'Send Email';

  public sendToClientAndEmployee: boolean;

  public userRoles: typeof USER_ROLES = USER_ROLES;
  public currentUserRole: string;

  public orderStatuses: Array<ChipListItem> = [];

  public showInformationPanel: boolean = true;

  public templateId!: string;

  private readonly destroy$: Subject<void> = new Subject();

  private selectedTemplateData: EmailTemplateData | null = null;

  private files: Array<File> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<EmailDialogComponent>,
    public api: EmailApi,
    public orderApi: OrderApi,
    public service: EmailService,
    public orderService: OrderService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private validationService: FormValidationService,
    private timeService: TimeService,
    private notificationService: NotificationService
  ) {
    this.form = this.createForm();
    this.sendToClientAndEmployee = !this.authService.isClient();
    this.currentUserRole = this.authService.user.role;
  }

  ngOnInit(): void {
    const data: DialogData = this.data,
          order: Order | null = data.order || null;

    this.companyId = order?.company_id || data.companyId;

    this.setHeader();

    this.showInformationPanel = !!order || !!(data.openFromReport && data.specialInstructions);

    this.templateControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter<SearchEmailTemplate>(Boolean),
        distinctUntilChanged()
      )
      .subscribe((value: SearchEmailTemplate): void => this.onTemplateChange(value));

    if (data.sendInvoice) {
      this.templateControl.setValue(this.authService.sendInvoiceEmailTemplate);
    }

    if (order) {
      this.orderStatuses = this.orderService.getOrderPanelStatuses(order);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSendBtnClick(): void {
    const data: DialogData = this.data;

    this.api.sendEmail(
      this.service.prepareEmailFormValue(
        this.form.getRawValue(),
        data.order?.id || null,
        this.selectedTemplateData,
        data.withFileList ? this.getAttachedFileIds() : []
      ),
      this.files
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any): void => {
          if (res.formErrors.length) {
            this.notificationService.openErrorNotification(res.formErrors[0]);
          } else {
            this.dialogRef.close(true);
          }
        },
        (error: string | Error): void => { }
      );
  }

  public onFileDropped(files: Array<File>): void {
    this.files = files;
    this.form.markAsDirty();
  }

  public getErrorMsg(control: UntypedFormControl, controlName: string): string {
    return this.validationService.getControlErrorMessage(control, controlName);
  }

  private onTemplateChange(template: SearchEmailTemplate): void {
    const order: Order = this.data.order || <Order>{ },
          templateId: string = template.id;

    this.templateId = templateId;

    this.api.getTemplateData(templateId, order.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: EmailTemplateData): void => {
          this.selectedTemplateData = data;

          this.form.patchValue({
            from: this.getFromUser(data.from),
            to: data.to || '',
            cc: data.cc,
            bcc: data.bcc,
            subject: this.createSubject(data.subject),
            body_html: data.body_html
          });
        },
        (error: string | Error): void => { }
      );
  }

  private createForm(): UntypedFormGroup {
    const requiredValidator: ValidatorFn = Validators.required;

    return this.formBuilder.group({
      template: null,
      from: [this.getFromUser(), requiredValidator],
      to: [null, requiredValidator],
      cc: null,
      bcc: null,
      subject: [this.data.order?.full_address, [requiredValidator, emptyValueValidator]],
      body_html: null
    });
  }

  private createSubject(templateName: string = ''): string {
    const data: DialogData = this.data,
          {full_address, company_name} = data.order || { },
          companyName: string = company_name || data.companyName,
          addressPart: string = full_address ? `- ${full_address}` : '',
          companyPart: string = companyName ? `- ${companyName}` : '';

    return this.isStatementTemplates() && data.openFromReport
      ? `${templateName} ${companyPart} - ${this.timeService.getTodayDate()}` :
      `${templateName} ${addressPart}`;
  }

  private setHeader(): void {
    this.header = this.data.sendInvoice ? 'Send Invoice' : this.header;
  }

  private getAttachedFileIds(): Array<string> {
    return this.filePanelComp.listComp.selectedRows
      .filter((row: RowNode): boolean => row.data.is_deleted !== '1')
      .map((row: RowNode): string => row.data.id);
  }

  private getFromUser(user?: User): User {
    const from: User = user || this.data.from;

    return this.authService.isAccountant()
      ? this.userService.createAccountant(from)
      : from;
  }

  //TODO: hardcoded 'templateId' and 'event_key' parameters
  private isStatementTemplates(): boolean {
    return (this.templateId === '35' && this.selectedTemplateData.event_key === 'statement_template')
      || (this.templateId === '41' && this.selectedTemplateData.event_key === 'statement_template_audit')
      || (this.templateId === '47' && this.selectedTemplateData.event_key === 'statement_template_qualia_clients')
      || (this.templateId === '85' && this.selectedTemplateData.event_key === 'statementPastDue')
      || (this.templateId === '101' && this.selectedTemplateData.event_key === 'statementPastDueOver181Days')
      || (this.templateId === '114' && this.selectedTemplateData.event_key === 'payoffStatementRequestToLendingInstitute')
      || (this.templateId === '115' && this.selectedTemplateData.event_key === 'statementPastDueSurveyUpdate')
      || (this.templateId === '116' && this.selectedTemplateData.event_key === 'statementNewApContactRequired');
  }
}
